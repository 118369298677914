import { Component, OnInit } from '@angular/core';
import { NotificacionCaseta, Turno } from 'src/app/models/dash';
import { NotificacionesPushService } from 'src/app/services/notificaciones-push.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import { getTurno } from 'src/app/utils';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { WebsocketService } from '../../services/websocket.service';
import { TYPE_NOTIFICATION } from '../../utils/enum';
import { ToastrService } from 'ngx-toastr';
import { EventoService } from 'src/app/services/evento.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BitacoraService } from 'src/app/services/bitacora.service';

@Component({
  selector: 'app-home-dashboard',
  templateUrl: './home-dashboard.component.html',
  styleUrls: ['./home-dashboard.component.scss']
})
export class HomeDashboardComponent implements OnInit {

  notificaciones: NotificacionCaseta[] = [];
  fallas: any[] = [];
  usuario: any;
  id_fondo: number;
  turnoActual: Turno;
  notificacion_tipo = [
    { tipo: 'DEVOLUCION', ruta: '/dashboard/devolucion' },
    { tipo: 'RETIRO', ruta: '/dashboard/home' },
    { tipo: 'MORRALLA', ruta: '/dashboard/morralla-carril' },
    { tipo: 'ROLLO', ruta: '/dashboard/morralla-carril' },
  ]
  confirmModal: boolean = false;
  fallaTemp: any = [];

  SECONDS_TO_SYNC: number = 1000;
  polling: any;

  bitacoras: any
  bitacorasCount: any
  verDetalle: boolean = false;
  showAreaNota: boolean = false;
  bitacoraTemp: any;
  nota: string
  constructor(
    private notificacionCaseta: NotificacionesPushService,
    private store: Store<AppState>,
    private socketsService: WebsocketService,
    private eventoService: EventoService,
    private toastr: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private bitacoraService: BitacoraService
  ) { }

  ngOnInit(): void {
    // Obtener turno actual
    this.turnoActual = getTurno();

    // Obtener el usuario
    this.store.select(selectUser).subscribe(usuario => {
      this.usuario = usuario;
      this.id_fondo = this.usuario.caseta_usuario[0].id_fondo;
    });
    this.getData()
    this.pollData();

  }

  ngOnDestroy() {
    clearInterval(this.polling);
  }


  pollData() {
    this.polling = setInterval(() => {
      console.log('SINCRONIZANDO');
      this.syncNotificacion();
      this.syncFalla();
    }, this.SECONDS_TO_SYNC * 5);
  }

  syncNotificacion() {
    this.notificacionCaseta.getNotificacionCaseta(this.turnoActual.id, this.usuario.id).subscribe(notificacionCaseta => {
      this.notificaciones = notificacionCaseta;
      console.log(this.notificaciones);
    });
  }

  syncFalla() {
    this.eventoService.getEventoFalla(this.id_fondo).subscribe(fallas => {
      this.fallas = fallas;
    });
  }

  sendNotification(tipo) {
    if (TYPE_NOTIFICATION.MORRALLA) {
      //se emite la notificacion para todos los carriles activos
      this.socketsService.emitEvent({ message: '¿Alguién necesita morralla?' });
      this.toastr.success('Se ha enviado la notificación', '', { positionClass: 'toast-bottom-right' })
    }

  }

  atenderFalla() {
    this.ngxUiLoaderService.start()
    this.eventoService.updateEventoFalla(this.fallaTemp)
      .subscribe(data => {
        this.toastr.success('Se atendido la falla', '', { positionClass: 'toast-bottom-right' })
        this.confirmModal = false
        this.ngxUiLoaderService.stop()

      })
  }

  saveTempFalla(falla) {
    this.fallaTemp = falla;
  }

  openModal(bitacora) {
    this.verDetalle = true;
    this.showAreaNota = false;
    this.bitacoraTemp = bitacora;

  }

  closeModal() {
    this.verDetalle = false;
    this.showAreaNota = false;
  }

  openSeguimiento() {
    this.showAreaNota = true;
  }
  getData(){

    this.bitacoraService.getBitacora()
      .subscribe(data => {
        this.bitacoras = data
      })

      this.bitacoraService.getBitacoraCount()
      .subscribe(data => {
        this.bitacorasCount = data
      })
  }
  saveSeguimiento() {
    let data = {
      nota: this.nota,
      id_usuario: this.usuario.id,
      id_bitacora: this.bitacoraTemp.id
    }
    this.bitacoraService.saveSeguimientoBitacora(data)
      .subscribe(data => {
        this.nota = ''
        this.verDetalle = false;
        this.showAreaNota = false;
        this.getData()
        this.toastr.success(`Nota agregada correctamente`, 'Correcto', { positionClass: 'toast-bottom-right' });

      })
  }

  getEstado(status) {
    switch (status) {
      case 1:
        return 'Inicial';
        break;
      case 2:
        return 'Proceso';

        break;
      case 3:
        return 'Cerrada';

        break;
      default:
        return 'Validado';

        break;
    }
  }
  getNota(e) {

    this.nota = e.target.value;

  }


}
