import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FondoDenominacion, Turno } from 'src/app/models/dash';
import { AsignarFondoService } from 'src/app/services/asignar-fondo.service';
import { MorrallaService } from 'src/app/services/morralla.service';
import { AppState } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { getTurno } from 'src/app/utils';

@Component({
  selector: 'app-morralla-pago',
  templateUrl: './morralla-pago.component.html',
  styleUrls: ['./morralla-pago.component.scss']
})
export class MorrallaPagoComponent implements OnInit {

  denominaciones: FondoDenominacion[] = [];
  turno: Turno;
  morrallas = [];
  usuario: any;
  devolucionIds: any;
  caseta_id: number = null;
  subtotales: number[] = [];
  morrallaFinal: number = 0;
  pagaMorralla: number = null;

  get denominacionFormato() {
    return this.denominaciones.slice(0, ).reverse();
  }

  constructor(
    private asignarFondo: AsignarFondoService,
    private morrallaService: MorrallaService,
    private toastr: ToastrService,
    private store: Store<AppState>,
    private router: Router,
    private ngxUiLoaderService: NgxUiLoaderService,
  ) { }

  ngOnInit(): void {

    // obtener turno actual
    this.turno = getTurno();
    // obtener casetas de usuario
    this.store.select(selectUser).subscribe( usuario => this.usuario = usuario);

    // obtener fondo para conjunto de casetas
    this.asignarFondo.getFondoDenominacion( this.usuario.caseta_usuario[0].id ).then( () => {
      this.denominaciones = this.asignarFondo.fondoDenominacion;
    });
  }

  getCaseta( caseta: any ) {
    this.caseta_id = Number(caseta.target.value);

    // obtener morralla total de la caseta
    console.log( this.caseta_id );
    this.morrallaService.getMorrallaTotal(this.usuario.id, this.turno.id, this.caseta_id).toPromise().then( morrallaTotal => {
      this.pagaMorralla = morrallaTotal[0].morralla;
      this.devolucionIds =JSON.parse(morrallaTotal[0].devoluciones);
    });

  }

  morrallaTotal(event: any, denominacion) {
    this.morrallaFinal = 0;
    let cantidad = Number(event.target.value);
    let subTotal = cantidad * (denominacion.cantidad);
    this.subtotales[denominacion.id] = subTotal;

    // total
    for (let total in this.subtotales){
      if(this.subtotales[total]){
        this.morrallaFinal += Number(this.subtotales[total]);
      }
    }
    
    this.insertMorralla( cantidad, denominacion)
  }

  insertMorralla(cantidad: number, denominacion: any){
    let index = this.morrallas.findIndex( m => m.id_denominacion === denominacion.id);
    index === -1 ? this.morrallas.push({
      id_denominacion: denominacion.id, 
      cantidad: cantidad, 
      denominacion: denominacion.cantidad
    }): this.morrallas[index].cantidad = cantidad;

    console.log( this.morrallas );
  }

  async saveMorralla() {
    // valida caseta
    if ( this.caseta_id === null ) { return this.toastr.error('Por favor, seleccione una caseta', 'Error', { positionClass: 'toast-bottom-right' } ) }

    // valida cantidad x denominacion
    if ( this.morrallas.length === 0) { return this.toastr.error('Ingresa una cantidad $ para pagar morralla', 'Error', { positionClass: 'toast-bottom-right' } ) }

    // valida pago de faltantate
    if (this.pagaMorralla != this.morrallaFinal ) {
      return this.toastr.error('La cantidades deben coincidir para pagar la morralla', 'Error', { positionClass: 'toast-bottom-right' } )
    }

    console.log( 'GUARDAR MORRALLA', this.morrallas, this.devolucionIds );

    this.ngxUiLoaderService.start()

    let data = {
      morralla: this.morrallas,
      devoluciones: this.devolucionIds
    }

    await this.morrallaService.pagoMorralla(data).toPromise();
    this.toastr.success('Información guardada con éxito', '', { positionClass: 'toast-bottom-right' });
    this.router.navigate(['/dashboard/fondo-operacion']);
    this.ngxUiLoaderService.stop();

  }

}
