import {createAction, props} from '@ngrx/store';
import {BtnRapido} from '../../models';

export enum BtnRapidoActions {
  addBtnRapidoSubtype = '[BtnRapido-ADD] Subtype',
  addBtnRapidoClasificacion = '[BtnRapido-ADD] Clasificacion',
  addBtnRapidoObservacion = '[BtnRapido-ADD] Observacion',
  clearBtnRapido = '[BtnRapido-CLEAR] Clear',
  postBtnRapido = '[BtnRapido-POST] Post',
  postedBtnRapido = '[BtnRapido-POSTED] Posted',
}

export const addBtnRapidoSubType = createAction(
  BtnRapidoActions.addBtnRapidoSubtype,
  props<{ id_subtipo: number, nombreSubtipo: string }>()
);

export const addBtnRapidoClasificacion = createAction(
  BtnRapidoActions.addBtnRapidoClasificacion,
  props<{ id_clasificacion: number, clasificacion: string }>()
);

export const addBtnRapidoObservacion = createAction(
  BtnRapidoActions.addBtnRapidoObservacion,
  props<{ id_cat_observacion: number}>()
);

export const clearBtnRapido = createAction(
  BtnRapidoActions.clearBtnRapido
);

export const postBtnRapido = createAction(
  BtnRapidoActions.postBtnRapido,
  props<{ btnRapido:  BtnRapido }>()

);

export const postedBtnRapido = createAction(
  BtnRapidoActions.postedBtnRapido,
  props<{ BtnRapido: BtnRapido }>()
);
