<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase text-base text-center text-gray-500 dark:text-gray-200">
    <div class="m-2">
        <div class="flex flex-col">

            <span class="text-gray-500 dark:text-gray-50 py-4 font-bold">Asignacion de cabinas</span>
            <hr class="mb-2 dark:border-gray-500">

            <!-- header -->
            <div class="grid grid-cols-5 grid-flow-row gap-1 bg-gray-200 dark:bg-gray-900/50 rounded p-2 font-semibold text-sm mb-2 divide-x divide-gray-500">
                <span class="font-bold">Cabina</span>
                <span class="font-bold">Cobrador</span>
                <span class="col-span-2 font-bold">Evaluacion</span>
                <span class="font-bold">Fondo</span>
            </div>

            <!-- asignaciones -->
            <div class="mb-2 font-semibold">

                <hr class="my-2 dark:border-gray-500">

                <div *ngFor="let corte of cortes; let index = index" class="grid grid-cols-5 grid-flow-row gap-1 auto-cols-fr my-2 items-center">

                    <span>{{ corte.nombre }}</span>

                    <div class="item">
                        <label class="sr-only">Seleccionar cobrador</label>
                        <ng-select class="custom" placeholder="Seleccionar cobrador" [items]="cobradores" bindLabel="nombre" bindValue="id" 
                            (change)="getCobrador(corte.id_corte, $event)">
                        </ng-select>
                    </div>

                    <div class="flex col-span-2 space-x-2 justify-between">
                        <div *ngFor="let formacion of formaciones" class="flex rounded-lg bg-gray-200 dark:bg-gray-700 p-2 items-center">
                            <span class="font-medium dark:text-gray-300 pr-2">{{ formacion.concepto }}</span> 
                            <input [checked]="formacion.id === 1" (change)="updateFormacionList(corte.id_corte, formacion.id, $event)" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300" name="{{ formacion.id }}" id="{{ formacion.id }}" type="checkbox" ngClass="" value="{{ formacion.concepto }}">
                        </div>
                    </div>

                    <span>${{ corte.fondo }}</span>

                    <div class="col-span-5 grid grid-cols-5">
                        <span class="col-span-2"></span>
                        <input (keyup)="justificacion($event, index)" id="j-{{ corte.id_corte }}" autocomplete="off" required class="col-span-2 border rounded border-gray-300 placeholder-gray-500 h-10 px-3 py-2 w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray" placeholder="Escriba justificacion" type="text" />
                        <span></span>
                    </div>

                    <hr class="col-span-5 my-2 dark:border-gray-500">

                </div>

            </div>

            <!-- footer -->
            <div class="flex justify-end">
                <button type="button" class="bg-gray-50 dark:bg-transparent rounded py-4 px-2 text-xs text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 uppercase" (click)="asignarCarril()">Asignar cabinas</button>
            </div>

        </div>
    </div>
</div>
