import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarrilService {

  private ENDPOINT = environment.ENDPOINTS.CARRILCLOSED;

  constructor(
    private http: HttpClient
  ) { }

  getCarrilClosed(id_caseta: number, id_turno: number) {
    return this.http.get<any[]>(this.ENDPOINT + `/${ id_caseta }/${ id_turno }`)
  }

}
