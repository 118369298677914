
<!-- <div class="wrapper">
  <h1 *ngIf="(loading$ | async)"> Cargando ...</h1>
  <header class="header">
    <app-header></app-header>
  </header>

  <main class="menu">
    <app-menu></app-menu>
  </main>

  <main class="main">
    <app-main ></app-main>
  </main>

  <aside class="aside">
    <app-aside ></app-aside>
  </aside>

  <div class="container">
    <app-container></app-container>
  </div>

  <footer class="footer">
    <app-footer></app-footer>
  </footer>

</div> -->

<div class="min-h-screen p-6 bg-gray-100 dark:bg-gray-900">
  <div class="mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
    <div class="grid grid-cols-8 gap-2 mb-2">

      <main class="menu">
        <app-menu></app-menu>
      </main>

      <div class="col-span-7">

        <!-- <h1 *ngIf="(loading$ | async)"> Cargando ...</h1> -->
        <header class="header">
          <app-header></app-header>
        </header>
      
        <main class="main">
          <app-main ></app-main>
        </main>

        <div class="container">
          <app-container></app-container>
        </div>

      </div>


    
      <!-- <aside class="aside">
        <app-aside ></app-aside>
      </aside> -->
    

    
      <!-- <footer class="footer">
        <app-footer></app-footer>
      </footer> -->

    </div>
  </div>
</div>
