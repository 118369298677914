import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {AppState} from '../../state/app.state';
import {Store} from '@ngrx/store';
import {selectUser} from '../../state/selectors/user.selectors';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import { getTurno } from 'src/app/utils';
import { Caseta, Turno } from 'src/app/models/dash';
import { loadedUser } from 'src/app/state/actions/user.actions';
import { changeCaseta } from '../../state/actions/user.actions';
import * as moment from 'moment';
import { NotificacionesPushService } from 'src/app/services/notificaciones-push.service';

enum TIPO_NOTIFICACION {
  DEVOLUCION = 0,
  FALLA = 1,
  MORRALLA = 2,
  RETIRO = 3,
  ROLLO = 4,
}

@Component({
  selector: 'app-header-dashboard',
  templateUrl: './header-dashboard.component.html',
  styleUrls: ['./header-dashboard.component.scss']
})
export class HeaderDashboardComponent implements OnInit {

  time = new Date();
  intervalId;
  tareaModal: boolean = false;
  casetaTroncal: string  = "";
  jefe_turno: any;
  tareaHoy: any = moment().format('DD-MM-YYYY');
  turnoActual: Turno;
  notificacionMorralla: boolean = false;
  notificacionRetiro: boolean = false;
  notificacionDevolucion: boolean = false;
  notificacionRollo: boolean = false;
  notificacionFalla: boolean = false;

  SECONDS_TO_SYNC: number = 600;
  polling: any;

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private notificacionesService: NotificacionesPushService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.turnoActual = getTurno();
    this.getUser();
    this.timeLive();
    this.getCasetaTroncal();

    this.pollData();
  }

  ngOnDestroy() {
    clearInterval(this.polling);
  }

  // 10 minutos
  pollData() {
    this.polling = setInterval(() => {
      this.syncNotificacion();
      this.syncMorallaSolicitada();
    }, this.SECONDS_TO_SYNC * 1000);
  }


  syncNotificacion() {
    // console.log( 'BUSCANDO NOTIFICACIONES' );

    let relacionNotificacion = [0, 0, 0, 0, 0];

    this.notificacionesService.getNotificacionCaseta(this.turnoActual.id, this.jefe_turno.id).subscribe(notificaciones => {
      for (let notificacion of notificaciones) {

        // console.log( 'RAW', notificacion.notificaciones );

        for (let notificacionTipo of notificacion.notificaciones) {
          if (notificacionTipo.estatus === 1) {

            switch (notificacionTipo.tipo_notificacion) {
              case 'DEVOLUCION':
                this.notificacionDevolucion = true;
                relacionNotificacion[TIPO_NOTIFICACION.DEVOLUCION] ++;
                break;
              case 'FALLA':
                this.notificacionFalla = true;
                relacionNotificacion[TIPO_NOTIFICACION.FALLA] ++;
                break;
              case 'MORRALLA':
                this.notificacionMorralla = true;
                relacionNotificacion[TIPO_NOTIFICACION.MORRALLA] ++;
                break;
              case 'RETIRO':
                this.notificacionRetiro = true;
                relacionNotificacion[TIPO_NOTIFICACION.RETIRO] ++;
                break;
              case 'ROLLO':
                this.notificacionRollo = true;
                relacionNotificacion[TIPO_NOTIFICACION.ROLLO] ++;
                break;
              default:
                console.log( 'No se ha encontrado notificacion valida' );
                break;
            }

          }
        }

      }

      let cantidadActivos: number = 0;
      
      relacionNotificacion[TIPO_NOTIFICACION.DEVOLUCION] > 0 ? cantidadActivos ++ : this.notificacionDevolucion = false;
      relacionNotificacion[TIPO_NOTIFICACION.FALLA] > 0 ? cantidadActivos ++ : this.notificacionFalla = false;
      relacionNotificacion[TIPO_NOTIFICACION.MORRALLA] > 0 ? cantidadActivos ++ : this.notificacionMorralla = false;
      relacionNotificacion[TIPO_NOTIFICACION.RETIRO] > 0 ? cantidadActivos ++ : this.notificacionRetiro = false;
      relacionNotificacion[TIPO_NOTIFICACION.ROLLO] > 0 ? cantidadActivos ++ : this.notificacionRollo = false;
  
      cantidadActivos > 0 ? this.tareaModal = true : this.tareaModal = false;

    });
  }

  syncMorallaSolicitada() {
    // console.log( 'BUSCANDO MORRALLA QUE NO HAS DEVUELTO' );
  }

  timeLive(){
    // Using Basic Interval
    this.intervalId = setInterval(() => {
      this.time = new Date();
    }, 1000);
  }

  getUser(){
    this.store.select(selectUser).subscribe(jefe_turno => this.jefe_turno = jefe_turno);
    // console.log(this.jefe_turno);
  }

  getCasetaTroncal() {
    this.store.select('usuario').subscribe( usuario => {
      let caseta_usuario = usuario['usuario'].caseta_usuario;
      for (const caseta in caseta_usuario) {
        if ( caseta_usuario[caseta].tipo === 1 ) {
          this.casetaTroncal = caseta_usuario[caseta].nombre;
        }
      }
    });
  }

  closeModal() {
    this.tareaModal = false;
  }

  openModal() {
    this.tareaModal = true;
  }

}
