import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {CorteService} from 'src/app/services/corte.service';
import {selectUser} from 'src/app/state/selectors/user.selectors';
import {AppState} from '../../state/app.state';
import {UserService} from '../../services/user.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../services/auth.service';
import {LoginService} from '../../services/login.service';

@Component({
  selector: 'app-firma-dashboard',
  templateUrl: './firma-dashboard.component.html',
  styleUrls: ['./firma-dashboard.component.scss']
})
export class FirmaDashboardComponent implements OnInit {

  install: boolean = true;
  jefe_turno: any;
  id_caseta: number;
  password: string = '';

  constructor(
    private router: Router,
    private corteService: CorteService,
    private store: Store<AppState>,
    private loginService: LoginService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.store.select(selectUser).subscribe(jefe_turno => this.jefe_turno = jefe_turno);
    console.log(this.jefe_turno);

    this.getCaseta();
    this.corteService.checkInstalacion(this.id_caseta).subscribe(install => {
      if (install === 0) {
        this.install = false;
      }
    });
  }

  async showAsignar() {
    //Validate not empty
    if (!this.password) {
      return this.toastrService.error('Completa campos', 'Ingresa la firma', {positionClass: 'toast-bottom-right'});
    }

    try {
      //Validate credentials

      this.ngxUiLoaderService.start();
      let user = await this.loginService.loginJT({empleado: this.jefe_turno.num_empleado, password: this.password}).toPromise();

      if (!user) {
        return this.toastrService.error('La constraseña proporcionada es incorrecta', 'Confirmación Fondo', {positionClass: 'toast-bottom-right'});
      }
      //NEW FLUJO, SI YA ASIGNO FONDO REDIRIGE A ASIGNAR CARRIL
      if (this.jefe_turno.id_cat_acciones==3) {
        return this.router.navigate(['dashboard/asignar-carril']);
      }
      if (this.install) {
        this.router.navigate(['dashboard/home']);
      } else {
        this.router.navigate(['dashboard/home']);
        // this.router.navigate(['dashboard/asignar-fondo']);

      }
    } catch (err) {
      console.log(err);
    } finally {
      this.ngxUiLoaderService.stop();
    }

  }

  getCaseta() {
    for (const caseta in this.jefe_turno.caseta_usuario) {
      if (this.jefe_turno.caseta_usuario[caseta].tipo === 1) {
        this.id_caseta = this.jefe_turno.caseta_usuario[caseta].id;
        break;
      }
    }
  }

}
