
<div class="flex items-center justify-center">
  <span class="text-sm pb-1.5 font-medium dark:text-gray-300 mr-4">Selecciona si preliquida el JT saliente</span>
  <input class="w-4 h-4 mt-0.5 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox" (change)="salienteJT($event)">
</div>
<hr>
<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase text-sm text-gray-500 dark:text-gray-200">
    <div class="m-2">

        <div class="bg-white dark:bg-gray-900/50 rounded-md w-full">
            <div class="p-2 overflow-x-auto h-96">
                <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">

                    <table class="w-full text-center text-gray-900 dark:text-gray-300">

                        <thead>
                            <tr class="border-b-2 border-gray-200 dark:border-gray-600 bg-gray-100 dark:bg-gray-900/50 text-gray-600 dark:text-gray-300">
                                <th class="px-2 py-3">Cabina</th>
                                <th class="px-2 py-3">Total de retiros</th>
                                <th class="px-2 py-3">Efectivo final</th>
                                <th class="px-2 py-3">Faltante</th>
                                <th class="px-2 py-3">Efectivo Final Total</th>
                                <th class="px-2 py-3">Sobrante</th>
                                <th class="px-2 py-3">Total efectivo</th>
                            </tr>
                        </thead>

                        <tbody class="font-semibold">

                            <tr *ngFor=" let corte of cortes" class="border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-900/50 ">

                                <td   class="p-2">
                                    <span>{{corte.cabina}}</span>
                                </td>

                                <td class="p-2">
                                    <span>{{corte.totalRetiros || 0}}</span>
                                </td>

                                <td class="p-2">
                                    <span>{{corte.efectivoFinal || 0 }}</span>
                                </td>

                                <td class="p-2">
                                    <span>{{ corte.Faltante }}</span>
                                </td>

                                <td class="p-2">
                                    <span>{{ corte.efectivoFinal + corte.Faltante }}</span>
                                </td>

                                <td class="p-2">
                                    <span>{{ corte.Sobrante }}</span>
                                </td>

                                <td class="p-2">
                                    <span>{{ corte.totalRetiros + corte.efectivoFinal + corte.Faltante }}</span>
                                </td>

                            </tr>

                            <tr class="border-b-2 border-gray-200 dark:border-gray-600 bg-gray-100 dark:bg-gray-900/50 text-gray-600 dark:text-gray-300">

                                <td   class="p-2">
                                    <span>{{totales.nombre}}</span>
                                </td>

                                <td class="p-2">
                                    <span>{{totales.retiroTotal}}</span>
                                </td>

                                <td class="p-2">
                                    <span>{{totales.efectivoFinal || 0 }}</span>
                                </td>

                                <td class="p-2">
                                    <span>{{ totales.faltanteTotal }}</span>
                                </td>

                                <td class="p-2">
                                    <span>{{(totales.efectivoFinal || 0) + totales.faltanteTotal }}</span>
                                </td>

                                <td class="p-2">
                                    <span>{{ totales.sobranteTotal }}</span>
                                </td>

                                <td class="p-2">
                                    <span>{{ totales.efectivoTotal + totales.faltanteTotal }}</span>
                                </td>

                            </tr>

                        </tbody>
                    </table>

                </div>
            </div>
        </div>
        <button *ngIf="dataFormatoFinal.length > 0" (click)="confirmModal = true"  class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md p-1.5 mt-3 bg-white dark:bg-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
          </svg>
          Aceptar resultados
      </button>
    </div>
</div>

<div *ngIf="confirmModal" class="modal-component-container fixed inset-0 overflow-y-auto">
  <div
    class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>

    <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>

    <div
      class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
      <div class=" modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

        <div class="modal-wrapper-flex sm:flex sm:items-start">

          <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">

              <div class="oculto-impresion py-2 text-center">
                  <span>¿Estás seguro de finalizar los cortes?</span>
              </div>
          </div>
        </div>
      </div>

      <div class="oculto-impresion modal-actions bg-gray-50 px-4 py-3 sm:px-6 flex flex-col">
          <div class="flex justify-end">
              <button (click)="confirmModal = false"
                      class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  Cerrar
              </button>
              <button (click)="setStatusCortes()"
                      class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  Aceptar
              </button>
          </div>

      </div>

    </div>
  </div>
</div>

<div *ngIf="confirmModalPrint" class="modal-component-container fixed inset-0 overflow-y-auto">
  <div
    class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>

    <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>

    <div
      class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
      <div class=" modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

        <div class="modal-wrapper-flex sm:flex sm:items-start">

          <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">
            <center>
            <app-print-formato-final [cortes]='cortes'  [totales]='totales'></app-print-formato-final>
            </center>

          </div>
        </div>
      </div>

      <div class="oculto-impresion modal-actions bg-gray-50 px-4 py-3 sm:px-6 flex flex-col">
          <div class="flex justify-end">
              <button (click)="confirmModalPrint = false"
                      class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  Cerrar
              </button>
          </div>

      </div>

    </div>
  </div>
</div>
