<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase font-semibold text-base text-center text-gray-500 dark:text-gray-200">
    <div class="flex flex-col">

        <div class="flex justify-between items-center">
            <div class="flex flex-1 text-sm items-center">
                <span class="font-semibold">Caseta:</span>
                <select (change)="getCaseta($event)" class="appearance-none block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                    <option selected disabled>Seleccionar caseta</option>
                    <option *ngFor="let caseta of usuario.caseta_usuario" value="{{ caseta.id }}">{{ caseta.nombre }}</option>
                </select>
            </div>
            <span class="flex-1 text-gray-500 dark:text-gray-50 py-2 font-bold">Traslado de valores - Entrada / Salida</span>
            <span class="flex-1"></span>
        </div>

        <hr class="dark:border-gray-500 mb-4">

        <div class="flex justify-between">

            <div class="flex flex-1 flex-col">

                <div class="flex w-full justify-center mb-4">
                    <form [formGroup]="tipoSepsaForm" (change)="getTipoSepsa()" class="w-3/4 rounded bg-gray-100 dark:bg-gray-700 p-2 font-medium">
                        <div>
                            <input type="radio" name="tipoSepsa" formControlName="tipoSepsa" value="entrada" id="1" class="peer hidden" />
                            <label for="1" class="block cursor-pointer select-none rounded p-2 text-center peer-checked:bg-blue-500 peer-checked:font-semibold peer-checked:text-white">Entrada</label>
                        </div>
                        <hr class="border-gray-300 dark:border-gray-500">
                        <div>
                            <input type="radio" name="tipoSepsa" formControlName="tipoSepsa" value="salida" id="2" class="peer hidden" />
                            <label for="2" class="block cursor-pointer select-none rounded p-2 text-center peer-checked:bg-blue-500 peer-checked:font-semibold peer-checked:text-white">Salida</label>
                        </div>
                    </form>
                </div>

                <div *ngIf="fondoTraslado.fondo && tipo === 1" class="flex w-full justify-center mb-4">
                  <div class="w-3/4">
                      <label for="1" >Seleccionar traslado</label>
                      <select #transalado [ngModel]="transalado" (ngModelChange)="getTraslado($event)" class="w-full appearance-none block p-2 text-sm font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                        <option selected disabled>Seleccionar traslado</option>
                        <option *ngFor="let trasla of traslados" [ngValue]="trasla">{{ trasla.fondo }}</option>
                    </select>
                  </div>
              </div>


                <div *ngIf="entradaTraslado && tipo === 1" class="flex justify-center text-blue-600 dark:text-blue-50 mb-4">
                    <div class="w-3/4 bg-gray-100 dark:bg-gray-700 rounded text-lg font-bold p-2">
                        <span>Traslado entrante: $ </span>
                        <span>{{ entradaTraslado | number }}</span>
                    </div>
                </div>

                <div class="flex justify-center text-blue-600 dark:text-blue-50 mb-4">
                    <div class="w-3/4 bg-gray-100 dark:bg-gray-700 rounded text-lg font-bold p-2">
                        <span>Total: $ </span>
                        <span>{{ trasladoTotal | number }}</span>
                    </div>
                </div>

                <div class="flex flex-col mb-4 space-y-2">
                    <div class="w-3/4 mx-auto">
                        <button (click)="saveTraslado()" class="btn p-2 w-full bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600">Guardar</button>
                    </div>

                    <div class="w-3/4 mx-auto">
                        <button (click)="ticketModal = true" class="btn p-2 w-full bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600">Generar Ticket</button>
                    </div>
                </div>

            </div>

            <div class="flex flex-1 flex-col">

                <div class="flex w-full mb-4">
                    <span class="flex-1">Denominacion</span>
                    <span class="flex-1">Cantidad</span>
                    <span class="flex-1">Total</span>
                </div>

                <div *ngFor="let denominacion of denominacionFormato" class="flex space-x-2 mb-2">
                    <input class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 font-bold text-base dark:border-gray-600 dark:bg-slate-800 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" disabled value="{{ denominacion.cantidad | number }}" type="text">

                    <input #input id="s{{ denominacion.id }}" (change)="tTotal($event, denominacion, input)" class="flex-1 w-6 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" type="number" min="0">

                    <input [value]="subtotales[denominacion.id] || 0 | number" class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-slate-800 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" disabled type="text">
                </div>

            </div>

            <div class="flex-1 text-center font-bold text-xs">
                <div class="px-4 overflow-y-auto">

                    <table class="border-collapse w-full border border-slate-400 dark:border-slate-500 bg-white dark:bg-slate-800 text-sm shadow-sm">
                        <thead class="bg-slate-50 dark:bg-slate-700">
                            <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">Fecha</th>
                            <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">Cantidad</th>
                        </thead>

                        <tbody *ngIf="tablaTraslados.length > 0; else noTraslados" class="font-semibold text-xs">
                            <tr *ngFor="let tt of tablaTraslados">
                                <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">{{ tt.fecha_hora }}</td>
                                <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">${{ tt.traslado | number }}</td>
                            </tr>
                        </tbody>

                        <ng-template #noTraslados>
                            <tbody class="font-semibold text-xs">
                                <td colspan="3" class="py-4">
                                    Sin traslados salientes, seleccione una caseta para obtener informacion
                                </td>
                            </tbody>
                        </ng-template>

                    </table>

                </div>


                <div class="flex justify-center mt-4">
                    <div class="w-3/4">
                        <button (click)="downloadDeposito()" class="btn p-2 w-full bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600">Descargar Ficha Final</button>
                    </div>
                </div>


                <div class="flex justify-center mt-4">
                    <div class="w-3/4 text-lg text-blue-600 dark:text-blue-50 mb-4 bg-gray-100 dark:bg-gray-700 p-2">
                        <span>Caja de seguridad: $ </span>
                        <span>{{ fichaSepsaCantidad || 0 | number }}</span>
                    </div>
                </div>

                <!-- <div class="flex justify-center flex-col mt-4">
                    <div class="flex w-full justify-center mb-4">
                        <button (click)="downloadDeposito()" class="btn p-2 w-full bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600">Descargar Ficha</button>
                    </div> -->

                    <!-- <div class="w-3/4 bg-gray-100 dark:bg-gray-700 rounded text-lg font-bold p-2">
                        <span>En caja de seguridad: $ </span>
                        <span>{{ fichaSepsaCantidad | number }}</span>
                    </div> -->
                <!-- </div> -->
            </div>

        </div>

    </div>
</div>

<div *ngIf="ticketModal" class="modal-component-container fixed inset-0 overflow-y-auto">
    <div
      class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>
  
      <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>
  
      <div
        class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
        <div class=" modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
  
          <div class="modal-wrapper-flex sm:flex sm:items-start">
  
            <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">

                <div class="flex flex-col justify-center mx-auto text-black font-normal text-center text-lg uppercase w-auto">

                    <img aria-hidden="true" class="mx-auto h-12 w-auto mb-2" src="/assets/images/sogak-logo-light.svg" alt="SOGAK"/>
                    <span class="text-base">{{ dateTime | date:'dd/MM/yyyy' }}</span>
                    <span class="text-base">{{ tipoImpresion }}</span>
                    <span class="text-base">Ticket deposito</span>
                    <span>TURNO: {{ turno?.name }}</span>
                    <span>JT: {{ usuario.nombre }}</span>
                    <span>{{ usuario.apellido_paterno }} {{ usuario.apellido_materno }}</span>
                  
                    <table class="table-fixed text-justify w-max mx-auto mt-4">
                        <thead class="border-y-2 border-black">
                          <tr>
                            <th>Denominacion</th>
                            <th class="w-4">&nbsp;</th>
                            <th>Cantidad</th>
                          </tr>
                        </thead>
                        <tbody *ngIf="traslado.length > 0">
                            <tr *ngFor="let tr of traslado" class="border-b-2 border-black">
                                <td>{{ tr?.denominacion }}</td>
                                <th class="w-4">&nbsp;</th>
                                <td>{{ tr?.cantidad }}</td>
                            </tr>
                            <tr>
                                <td>Monto: </td>
                                <th class="w-4">&nbsp;</th>
                                <td>${{ trasladoTotal | number }}</td>
                            </tr>
                        </tbody>
                    </table>
                  
                    <button (click)="imprent()" class="oculto-impresion inline-flex items-center w-full h-12 p-2 mt-4 font-semibold uppercase bg-gray-50 rounded border-2 border-gray-300 drop-shadow-lg hover:border-red-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                          </svg>
                  
                        <span class="ml-2 w-full">Imprimir</span>
                    </button>

                  </div>
                  
  
            </div>
          </div>
        </div>
  
        <div class="oculto-impresion modal-actions bg-gray-50 px-4 py-3 sm:px-6 flex flex-col">
            <div class="flex justify-end">
                <button (click)="ticketModal = false"
                        class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    Cerrar
                </button>
            </div>
        </div>
  
      </div>
    </div>
  </div>
  
