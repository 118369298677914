import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { TipoVehiculoService } from '../../services/tipo-vehiculo.service';
import {TipoVehiculoActions} from '../actions/tipoVehiculo.actions';



@Injectable()
export class TipoVehiculoEffects {

  loadTipoVehiculo$ = createEffect(() => this.actions$.pipe(
      ofType(TipoVehiculoActions.LOAD_TIPO_VEHICULO),
      mergeMap(() => this.tipoVehiculoService.getTipoVehiculo()
        .pipe(
          map(tipoVehiculo => ({ type: TipoVehiculoActions.LOADED_TIPO_VEHICULO, tipoVehiculo })),
          catchError(() => EMPTY)
        ))
    )
  );

  constructor(
    private actions$: Actions,
    private tipoVehiculoService: TipoVehiculoService
  ) {}
}
