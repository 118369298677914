import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY} from 'rxjs';
import {map, mergeMap, catchError, exhaustMap} from 'rxjs/operators';
import {DevolucionService} from '../../services/Devolucion.service';
import {Devolucion} from '../../models/pouch.db';
import {ActionsDevolucion} from '../actions/devolucion.actions';
import {DevolucionState} from '../../core/models/devolucion.state';

@Injectable()
export class DevolucionEffects {

  devolucion: Devolucion;

  postDevolucion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionsDevolucion.POST_DEVOLUCION),
      exhaustMap((action : DevolucionState) => this.DevolucionService.saveDevolucionLocal(action.devolucion)
        .pipe(
          map(devolucion => ({type: ActionsDevolucion.POSTED_DEVOLUCION, devolucion})),
        ))
    );
  });

  constructor(
    private actions$: Actions,
    private DevolucionService: DevolucionService
  ) {
  }
}
