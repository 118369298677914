import {createReducer, on} from '@ngrx/store';
import {PanelIzquierdoState} from '../../core/models/panelIzquierdo.state';
import {addEvent, changeStatus, clearStatusPanel, setStatus, StatusPanel} from '../actions/panelIzquierdo.actions';


export const initialState:PanelIzquierdoState = {status: StatusPanel.INITIAL, id_denominacion: 0 , type_boton : null, countDevolucion: Number(localStorage.getItem("panelizquierdo")) || 0 };


// @ts-ignore
export const panelIzquierdoReducer = createReducer(
  initialState,
  on(addEvent, (state, {id_denominacion, type_boton, status}) => {
    return {...state, id_denominacion, type_boton, status };
  }),
  on(setStatus, (state,{type_boton}) => {
    localStorage.setItem("panelizquierdo", `${state.countDevolucion+1}`)
    return {type_boton, status: StatusPanel.INITIAL, id_denominacion:  0, countDevolucion: state.countDevolucion+1};
  }),
  on(changeStatus, (state,{type_boton, status}) => {
    return {...state, type_boton, status};
  }),
  on(clearStatusPanel, (state) => {
    return {...state, countDevolucion: 0};
  }),
);
