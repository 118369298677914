<div
  class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 h-fit uppercase text-lg text-gray-500 dark:text-gray-200">
  <div class="m-2">
    <div class="grid grid-cols-2 gap-4">

      <div class="flex flex-col">
        <span class="text-gray-500 dark:text-gray-50 font-bold text-base text-center">Carriles</span>
        <hr class="mb-2 dark:border-gray-500">

        <div class="bg-white dark:bg-gray-900/50 rounded-md w-full">
          <div class="p-2 overflow-x-auto">
            <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">

              <table class="w-full">
                <thead>
                  <tr
                    class="border-b-2 border-gray-200 dark:border-gray-600 bg-gray-100 dark:bg-gray-900/50 uppercase font-semibold text-xs text-center text-gray-600 dark:text-gray-300">
                    <th class="px-2 py-3">Caseta</th>
                    <th class="px-2 py-3">Carril</th>
                    <th class="px-2 py-3">Fondo</th>
                    <th class="px-2 py-3">Devolucion</th>
                    <th class="px-2 py-3">Retiro</th>
                    <th class="px-2 py-3">Morralla</th>
                    <th class="px-2 py-3">Rollo</th>
                  </tr>
                </thead>

                <tbody *ngIf="notificaciones.length > 0; else noNotificaciones">

                  <tr *ngFor="let notificacion of notificaciones; let i = index"
                    class="border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-900/50 text-gray-900 dark:text-gray-300 text-sm text-center h-12">

                    <td class="p-2">
                      <p class="font-bold">{{ notificacion.caseta }}</p>
                    </td>

                    <td class="p-2">
                      <p class="font-bold">{{ notificacion.nombre }}</p>
                    </td>

                    <td class="p-2">
                      <p class="font-bold">${{ notificacion.fondo || 0 | number }}</p>
                    </td>

                    <td *ngFor="let nt of notificacion_tipo; let i = index" class="p-2">
                      <div *ngFor="let nn of notificacion.notificaciones">
                        <span *ngIf="nn.tipo_notificacion === nt.tipo && nn.estatus === 1" class="flex justify-center">
                          <svg routerLink="{{ nt.ruta }}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19"
                            class="h-16 fill-yellow-500 cursor-pointer">
                            <g class="too-big-actually">
                              <g class="bell-whole">
                                <path class="bell-part bell-part--ringer"
                                  d="M9.5,17.5a2,2,0,0,0,2-2h-4A2,2,0,0,0,9.5,17.5Z" />
                                <path class="bell-part bell-part--main"
                                  d="M16.23,12.82c-.6-.65-1.73-1.62-1.73-4.82a4.93,4.93,0,0,0-4-4.85V2.5a1,1,0,0,0-2,0v.65A4.94,4.94,0,0,0,4.5,8c0,3.2-1.13,4.17-1.73,4.82a1,1,0,0,0-.27.68,1,1,0,0,0,1,1h12a1,1,0,0,0,1-1A1,1,0,0,0,16.23,12.82Z" />
                              </g>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </td>

                  </tr>

                </tbody>

                <ng-template #noNotificaciones>
                  <tbody class="font-semibold text-sm">
                    <td colspan="7" class="py-4 text-center">
                      Sin informacion para este turno
                    </td>
                  </tbody>
                </ng-template>

              </table>
            </div>
          </div>
        </div>

      </div>

      <div class="flex flex-col">
        <ol class="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
          <li *ngFor="let bitCount of bitacorasCount" class="flex md:w-full items-center text-blue-600 dark:text-blue-500 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700 ">
              <span class="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500 bang">
                {{bitCount.numero}}  {{bitCount.concepto}}
              </span>
          </li>
      </ol>
        <!-- <div class="grid grid-cols-4 gap-4 ">
          <button type="button" *ngFor="let bitCount of bitacorasCount"
            class="inline-flex items-center px-5 py-2.5 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            &nbsp; &nbsp;{{bitCount.concepto}}&nbsp; &nbsp;
            <span
              class="inline-flex items-center justify-center w-4 h-4 ms-2 text-sm font-semibold text-blue-800 bg-black-200 rounded-full">
              {{bitCount.numero}}
            </span>
          </button>
        </div> -->
        <span class="text-gray-500 dark:text-gray-50 font-bold text-base text-center">Bitacora</span>
        <hr class="mb-2 dark:border-gray-500">

        <div *ngFor="let bitacora of bitacoras"
          class="flex bg-white dark:bg-gray-900/50 shadow-lg rounded-md text-xs text-gray-700 dark:text-gray-300 font-semibold mb-2">
          <div class="flex p-2 w-full cursor-pointer" (click)="openModal(bitacora)">

            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24"
              stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
            </svg>

            <div class="w-full">
              <div class="flex items-center justify-between">
                <p>{{bitacora?.catBitacora?.nombre}}</p>
                <span>{{bitacora?.fecha_apertura}}</span>
              </div>
              <div class="flex items-center justify-between">

                <p>{{bitacora?.observacion}}</p>

              </div>

              <div class="flex justify-end mt-4">
                <div class="flex items-center mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z" />
                  </svg>
                  <span>{{bitacora?.catBitacora?.padre.nombre}}</span>
                </div>

                <div class="flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                  </svg>
                  <span>{{bitacora?.catBitacora?.tipo}}</span>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <!-- <div class="flex flex-col">
        <span class="text-gray-500 dark:text-gray-50 font-bold text-base text-center">FALLAS</span>
        <hr class="mb-2 dark:border-gray-500">

        <div class="bg-white dark:bg-gray-900/50 rounded-md w-full">
          <div class="p-2 overflow-x-auto">
            <div class="inline-block min-w-full shadow rounded-lg h-68 overflow-y-auto">

              <table class="w-full">
                <thead>
                  <tr
                    class="border-b-2 border-gray-200 dark:border-gray-600 bg-gray-100 dark:bg-gray-900/50 uppercase font-semibold text-xs text-center text-gray-600 dark:text-gray-300">
                    <th class="px-2 py-3">Caseta</th>
                    <th class="px-2 py-3">Carril</th>
                    <th class="px-2 py-3">Falla</th>
                    <th class="px-2 py-3">Fecha y hora</th>
                  </tr>
                </thead>

                <tbody *ngIf="fallas.length > 0; else noFallas">

                  <tr (click)="confirmModal = true; saveTempFalla(falla)" *ngFor="let falla of fallas"
                    class="border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-900/50 text-gray-900 dark:text-gray-300 text-sm text-center h-12 "
                    style="cursor: pointer;">
                    <td class="p-2">
                      <p class="font-bold">{{ falla.caseta }}</p>
                    </td>
                    <td class="p-2">
                      <p class="font-bold">{{ falla.carril }}</p>
                    </td>
                    <td class="p-2">
                      <p class="font-bold">{{ falla.falla }}</p>
                    </td>
                    <td class="p-2">
                      <p class="font-bold">{{ falla.fecha_hora }}</p>
                    </td>
                  </tr>

                </tbody>

                <ng-template #noFallas>
                  <tbody class="font-semibold text-sm">
                    <td colspan="4" class="py-4 text-center">
                      Sin fallas reportadas
                    </td>
                  </tbody>
                </ng-template>

              </table>
            </div>
          </div>
        </div>

      </div> -->

    </div>
  </div>
</div>
<!-- <div *ngIf="confirmModal" class="modal-component-container fixed inset-0 overflow-y-auto">
  <div
    class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>

    <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>

    <div
      class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
      <div class="modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

        <div class="modal-wrapper-flex sm:flex sm:items-start">

          <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">

            <div class="flex items-center justify-between">
              <div class="flex items-center rounded py-2">
                <span class="pl-2">¿Estás seguro de atender la falla?</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-actions bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button (click)="confirmModal = false"
          class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          Cerrar
        </button>
        <button (click)="atenderFalla()"
          class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          Aceptar
        </button>
      </div>

    </div>
  </div>
</div> -->

<!-- modal -->
<div *ngIf="verDetalle" class="modal-component-container fixed inset-0 overflow-y-auto">
  <div
    class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>

    <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>

    <div
      class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
      <div class="modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

        <div class="modal-wrapper-flex sm:flex sm:items-start">

          <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">

            <div class="flex items-center justify-between">
              <div class="flex items-center rounded py-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6z" />
                </svg>
                <span
                  class="pl-2">{{bitacoraTemp?.catBitacora.padre.nombre}} {{bitacoraTemp?.catBitacora.nombre}} {{bitacoraTemp?.catBitacora.tipo}}</span>

              </div>
              <div class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                </svg>
                <span class="pl-2">{{bitacoraTemp?.fecha_apertura}}</span>
              </div>
            </div>

            <hr>

            <div class="border rounded p-2">

              <table class="table-auto w-full">
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="font-semibold">Caseta</td>
                    <td class="px-4">:</td>
                    <td>{{bitacoraTemp?.caseta.nombre}}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Carril</td>
                    <td class="px-4">:</td>
                    <td>{{bitacoraTemp?.carril.nombre}}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold ">Turno</td>
                    <td class="px-4">:</td>
                    <td>{{bitacoraTemp?.turno.turno}}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Observaciones</td>
                    <td class="px-4">:</td>
                    <td>{{bitacoraTemp?.observacion}}</td>
                  </tr>

                  <tr>
                    <td class="font-semibold">Estatus</td>
                    <td class="px-4">:</td>
                    <td>{{getEstado(bitacoraTemp.estado)}}</td>
                  </tr>
                  <tr *ngIf="bitacoraTemp?.cobrador != null">
                    <td class="font-semibold">Cobrador</td>
                    <td class="px-4">:</td>
                    <td>{{bitacoraTemp?.cobrador.nombre}} {{bitacoraTemp?.cobrador.apellido_paterno}}
                      {{bitacoraTemp?.cobrador.apellido_materno}}</td>
                  </tr>
                  <tr *ngIf="bitacoraTemp?.suplente != null">
                    <td class="font-semibold">Suplente</td>
                    <td class="px-4">:</td>
                    <td>{{bitacoraTemp?.suplente.nombre}} {{bitacoraTemp?.suplente.apellido_paterno}}
                      {{bitacoraTemp?.suplente.apellido_materno}}</td>
                  </tr>

                </tbody>
              </table>

            </div>

            <hr>

            <div class="flex">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
              </svg>
              <span class="pl-2">SEGUIMIENTO</span>
            </div>

            <div class="flex flex-col border rounded p-2 h-52 space-y-4 overflow-y-auto">

              <div *ngFor="let seg of bitacoraTemp?.seguimiento">
                <div class="bg-gray-300 p-3 rounded-r-lg rounded-bl-lg mb-2">
                  <p class="text-sm">{{seg.nota}}</p>
                </div>
                <div class="flex justify-between">
                  <span class="text-xs text-gray-500 leading-none">{{seg.fecha_hora}}</span>
                  <!-- <span class="text-xs text-gray-500 leading-none">Antonio Luis Rubio</span> -->
                </div>
              </div>

              <input *ngIf="showAreaNota" (change)="getNota($event)" type="text" name="cantidad" id="cantidad"
                class="border rounded border-gray-300 placeholder-gray-500 p-4 text-xs focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue text-left"
                placeholder="Seguimiento">

              <button *ngIf="showAreaNota" (click)="saveSeguimiento()"
                class="inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-blue-700 font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-0 sm:w-auto sm:text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0120.25 6v12A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18V6A2.25 2.25 0 016 3.75h1.5m9 0h-9" />
                </svg>
                <span class="pl-2">Guardar</span>
              </button>

              <button *ngIf="!showAreaNota" (click)="openSeguimiento()"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-green-700 font-medium text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-200 sm:mt-0 sm:ml-0 sm:w-auto sm:text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                </svg>
                <span class="pl-2">Nuevo seguimiento</span>
              </button>

            </div>

          </div>
        </div>
      </div>

      <div class="modal-actions bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

        <button
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-blue-700 font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="pr-2 w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
          </svg> Imprimir
        </button>

        <button
          class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-red-700 font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="pr-2 w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
          </svg> Cerrar Pendiente
        </button>

        <button (click)="closeModal()"
          class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="pr-2 w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg> Salir
        </button>
      </div>

    </div>
  </div>
</div>
