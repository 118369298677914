<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase font-semibold text-base text-center text-gray-500 dark:text-gray-200">
    <div class="flex flex-col">

        <div class="flex justify-between items-center">
            <div class="flex flex-1 text-sm items-center">
                <span class="font-semibold">Caseta:</span>
                <select (change)="getCaseta($event)" class="appearance-none block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                    <option selected disabled>Seleccionar caseta</option>
                    <option *ngFor="let caseta of usuario.caseta_usuario" value="{{ caseta.id }}">{{ caseta.nombre }}</option>
                </select>
            </div>
            <span class="flex-1 text-gray-500 dark:text-gray-50 py-2 font-bold">Asaltos y pago de asaltos</span>
            <span class="flex-1"></span>
        </div>

        <hr class="dark:border-gray-500 mb-4">

        <div class="flex justify-between">

            <div class="flex flex-1 flex-col">

                <div [formGroup]="eventoForm" (change)="getTipoEvento()" class="mx-auto w-3/4 font-medium p-2 rounded bg-gray-100 dark:bg-gray-700 mb-2">
                    <div>
                        <input type="radio" name="tipoEvento" formControlName="tipoEvento" value="0" id="1" class="peer hidden" />
                        <label for="1" class="block cursor-pointer select-none rounded p-2 text-center peer-checked:bg-blue-500 peer-checked:font-semibold peer-checked:text-white">Genera</label>
                    </div>
                    <hr class="border-gray-300 dark:border-gray-500">
                    <div>
                        <input type="radio" name="tipoEvento" formControlName="tipoEvento" value="1" id="2" class="peer hidden" />
                        <label for="2" class="block cursor-pointer select-none rounded p-2 text-center peer-checked:bg-blue-500 peer-checked:font-semibold peer-checked:text-white">Paga</label>
                    </div>
                </div>

                <div [formGroup]="asaltoForm" *ngIf="tipoForm === 0" class="mx-auto w-3/4">

                    <select formControlName="corteCobradorAsalto" class="w-full appearance-none block mb-2 p-2 text-sm font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                        <option value="" selected disabled>Carril - Cobrador</option>
                        <option *ngFor="let cobrador of cobradores" value="{{ cobrador.id_corte_usuario }}">{{ cobrador.carril_nombre }} - {{ cobrador.usuario_nombre }}</option>
                    </select>

                    <textarea formControlName="observacionAsalto" class="resize-y w-full text-sm p-2 mb-1 font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" rows="3" placeholder="Observaciones"></textarea>

                    <div class="bg-gray-100 dark:bg-gray-700 rounded text-lg font-bold mb-2 p-2">
                        <span>Total de Asalto: $ </span>
                        <span>{{ totalAsalto | number }}</span>
                    </div>

                    <button (click)="saveAsalto()" class="btn p-2 w-full bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600">Guardar</button>
                </div>

                <div [formGroup]="pagoForm" *ngIf="tipoForm === 1" class="mx-auto w-3/4">
                    <select formControlName="totalPago" (change)="getPagoAsalto($event)" class="w-full appearance-none block mb-2 p-2 text-sm font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                        <option value="" selected disabled>Seleccione el total del asalto</option>
                        <option *ngFor="let asalto of asaltosList" value="{{ asalto.id_devolucion }}">${{ asalto.total | number }} - {{ asalto.fecha_hora }}</option>
                    </select>

                    <div class="bg-gray-100 dark:bg-gray-700 rounded text-lg font-bold mb-2 p-2">
                        <span>Total ingresado: $ </span>
                        <span>{{ totalAsalto | number }}</span>
                    </div>

                    <div class="bg-gray-100 dark:bg-gray-700 rounded text-lg font-bold mb-2 p-2">
                        <span>Total a pagar: $ </span>
                        <span>{{ totalPaga | number }}</span>
                    </div>

                    <button (click)="saveAsalto()" class="btn p-2 w-full bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600">Guardar</button>
                </div>

            </div>

            <div class="flex flex-1 flex-col">

                <div class="flex w-full mb-4">
                    <span class="flex-1">Denominacion</span>
                    <span class="flex-1">Cantidad</span>
                    <span class="flex-1">Total</span>
                </div>

                <div *ngFor="let denominacion of denominacionFormato" class="flex space-x-2 mb-2">
                    <input class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 font-bold text-base dark:border-gray-600 dark:bg-slate-800 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" disabled value="{{ denominacion.cantidad | number }}" type="text">

                    <input #input id="a{{ denominacion.id }}" (change)="asaltoTotal($event, denominacion, input)" class="flex-1 w-6 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" type="number" min="0">

                    <input [value]="subtotales[denominacion.id] || 0 | number" class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-slate-800 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" disabled type="text">
                </div>

            </div>

            <div class="flex-1 text-center font-bold text-xs">
                <div class="px-4 overflow-y-auto">

                    <table class="border-collapse w-full border border-slate-400 dark:border-slate-500 bg-white dark:bg-slate-800 text-sm shadow-sm">
                        <thead class="bg-slate-50 dark:bg-slate-700">
                            <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">Fecha</th>
                            <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">Cantidad</th>
                        </thead>


                        <tbody *ngIf="asaltosList.length > 0; else noAsaltos" class="font-semibold text-xs">
                            <tr *ngFor="let asalto of asaltosList">
                                <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">{{ asalto.fecha_hora }}</td>
                                <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">${{ asalto.total | number }}</td>
                            </tr>
                        </tbody>
        
                        <ng-template #noAsaltos>
                            <tbody class="font-semibold text-xs">
                                <td colspan="3" class="py-4">
                                    Sin asaltos, seleccione una caseta para obtener informacion
                                </td>
                            </tbody>
                        </ng-template>

                    </table>

                </div>
            </div>

        </div>

    </div>
</div>