<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 h-fit uppercase text-lg text-gray-500 dark:text-gray-200">
    <div class="m-2">
        <div class="flex flex-col">

            <span class="text-gray-500 dark:text-gray-50 font-bold text-base text-center">Folios</span>
            <hr class="mb-2 dark:border-gray-500">

            <div class="bg-white dark:bg-gray-900/50 rounded-md w-full">
                <div class="p-2 overflow-x-auto">
                    <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
                        
                        <table class="w-full">
                            <thead>
                                <tr class="border-b-2 border-gray-200 dark:border-gray-600 bg-gray-100 dark:bg-gray-900/50 uppercase font-semibold text-xs text-center text-gray-600 dark:text-gray-300">
                                    <th class="px-2 py-3">Carril</th>
                                    <th class="px-2 py-3">Fecha</th>
                                    <th class="px-2 py-3">Folio Inicio</th>
                                    <th class="px-2 py-3">Folio Fin</th>
                                    <th class="px-2 py-3">Total</th>
                                </tr>
                            </thead>

                            <tbody>

                                <tr class="border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-900/50 text-gray-900 dark:text-gray-300 text-sm text-center">

                                    <td class="p-2">
                                        <p class="font-bold">1</p>
                                    </td>

                                    <td class="p-2">
                                        <p class="font-bold">12/06/2022 09:23:05</p>
                                    </td>

                                    <td class="p-2">
                                        <p class="font-bold">1</p>
                                    </td>

                                    <td class="p-2">
                                        <p class="font-bold">22</p>
                                    </td>

                                    <td class="p-2">
                                        <p class="font-bold">22</p>
                                    </td>

                                </tr>

                                <tr class="border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-900/50 text-gray-900 dark:text-gray-300 text-sm text-center">

                                    <td class="p-2">
                                        <p class="font-bold">1</p>
                                    </td>

                                    <td class="p-2">
                                        <p class="font-bold">12/06/2022 18:40:35</p>
                                    </td>

                                    <td class="p-2">
                                        <p class="font-bold">23</p>
                                    </td>

                                    <td class="p-2">
                                        <p class="font-bold">40</p>
                                    </td>

                                    <td class="p-2">
                                        <p class="font-bold">18</p>
                                    </td>

                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
