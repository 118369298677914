import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../state/app.state';
import { selectBtnsRetiro, selectCaseta, selectPermisos } from '../../state/selectors/caseta.selectors';
import { Caseta, Denominacion, Retiro } from '../../models';
import { postRetiro } from '../../state/actions/retiro.actions';

import * as moment from 'moment';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TypeBoton } from '../../state/actions/panelIzquierdo.actions';
import { addEventRetiro, changeStatusRetiro, setStatusRetiro, StatusPanel } from '../../state/actions/panelIzquierdoRetiro.actions';
import { PanelIzquieroRetiroState } from 'src/app/core/models/panelIzquieroRetiro.state';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { selectPanelIzquierdoRetiro } from 'src/app/state/selectors/panelIzquierdoRetiro.selectors';
import { PushNotificationService } from 'src/app/services/push-notification.service';
import { STATUS_NOTIFICATION, TYPE_NOTIFICATION, TYPE_PERMISO } from '../../utils/enum';
import { foundPermiso } from '../../utils';
import { OfflineOnlineService } from 'src/app/services/offline-online.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-btn-retiro',
  templateUrl: './btn-retiro.component.html',
  styleUrls: ['./btn-retiro.component.scss']
})
export class BtnRetiroComponent implements OnInit {

  btnsRetiro$: Observable<Denominacion[]> = new Observable<Denominacion[]>();
  user: any;
  offline: boolean = false;
  caseta: Caseta;
  retiroStore: PanelIzquieroRetiroState;
  //permisos
  permisos = [];

  constructor(private store: Store<AppState>,
    private router: Router,
    private toastr: ToastrService,
    private offlineService: OfflineOnlineService,
    private pushNotService: PushNotificationService,
    private ngxServiceLoader: NgxUiLoaderService

  ) {
  }

  ngOnInit(): void {
    this.btnsRetiro$ = this.store.select(selectBtnsRetiro);

    //Get Caseta
    this.store.select(selectCaseta).subscribe(caseta => this.caseta = caseta);

    //Get User
    this.store.select(selectUser).subscribe(user => this.user = user);

    // Permisos para retirar o devolucion por roles
    this.store.select(selectPermisos).subscribe(permiso => {
      this.permisos = permiso
    });

    //Get Retiro state
    this.store.select(selectPanelIzquierdoRetiro).subscribe(retiro => this.retiroStore = retiro)
  }

  showFirma(id: number) {
    //guardar state id denominacion retiro
    this.store.dispatch(addEventRetiro({ id_denominacion: id, status: StatusPanel.PROCESO }))
    if (foundPermiso(TYPE_PERMISO.RETIRO, this.permisos).length > 0) {
      //tiene permiso de hacer retiro
      //CODIGO PARA GUARDAR RETIRO
      this.postRetiro()
    } else {
      this.router.navigate(['/mopa/firma', TypeBoton.RETIRO]);
    }
  }

  postRetiro() {
    let retiro: Retiro = {
      fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
      id_corte_usuario: this.user.id_corte_usuario,
      id_usuario_retira: this.user.id_usuario,
      id_denominacion: this.retiroStore.id_denominacion
    };
    this.store.dispatch(postRetiro({ retiro }));
    this.store.dispatch(setStatusRetiro());
    this.finished('Retiro');
  }

  finished(type: string) {

    this.toastr.success(`${type} guardado con éxito`, '', { positionClass: 'toast-bottom-right' });
    this.router.navigate(['/mopa/home']);
  }

  async notificacion(type: string, isCanceled = false) {
    this.ngxServiceLoader.start()
    try {
      let response = await this.pushNotService.sendNotification({ title: "MOPA", body: `${type}, Carril ${this.user.nom_carril}`, users: [this.user.id_jefe_turno], status: isCanceled ? STATUS_NOTIFICATION.CANCELADO : STATUS_NOTIFICATION.NOTIFICADO, type: TYPE_NOTIFICATION.RETIRO })
      console.log(response);
      this.router.navigate(['/mopa/home']);
      this.toastr.success(`Notificando ${type}`, '', { positionClass: 'toast-bottom-right' });
      //change status
      this.store.dispatch(changeStatusRetiro({ status: StatusPanel.INITIAL }));
    } catch (error) {
      this.toastr.error('No se encontro conexión a Red', 'Red inestable',  { positionClass: 'toast-bottom-right' });

    } finally {
      this.ngxServiceLoader.stop()

    }

  }
  cancelAction() {
    this.notificacion('Acción cancelada Retiro', true)
  }

}
