import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppState} from '../../state/app.state';
import {Store} from '@ngrx/store';
import {loadedUser, loadUser, getUsers} from '../../state/actions/user.actions';
import {Router} from '@angular/router';
import {LoginService} from 'src/app/services/login.service';
import {Usuario} from '../../models';
import {Observable, Subscription} from 'rxjs';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../services/auth.service';
import {getTurno} from '../../utils';
import {CorteService} from '../../services/corte.service';
import {User} from '../../models/dash';
import {UserService} from '../../services/user.service';
import {addInputAction, changeInputAction, clearInputAction} from '../../state/actions/input.action';
import {InputState} from '../../core/models/input.state';
import {selectInputs} from '../../state/selectors/input.selector';
import {SubjectSubscriber} from 'rxjs/internal/Subject';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  isCobrador = true;
  credentials: any = {
    num_empleado: '',
    password: ''
  };

  usurios$: Observable<Usuario> = new Observable<Usuario>();
  userActual: any = {};
  usersAuxiliares: User[] = [];

  polling: any;
  suscribe$: Subscription;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private loginService: LoginService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private toastr: ToastrService,
    private authService: AuthService,
    private corteService: CorteService,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    //Get users casetas auxiliares
    this.userService.getUserAuxiliares().subscribe(users => this.usersAuxiliares = users);

    //this.saveUsersLocal();
    this.getActualUser();
    this.pollData();

    //Get input value
    this.store.select(selectInputs).subscribe(state => {
      //Folio Final
      let inputNumEmpleado = state.inputs.find(i => i.id === 'NUM_EMPLEADO');
      if (inputNumEmpleado) {
        this.credentials.num_empleado = inputNumEmpleado.value;
      }

      //Nuevo Folio
      let inputPassword = state.inputs.find(i => i.id === 'PASSWORD');
      if (inputPassword) {
        this.credentials.password = inputPassword.value;
      }

    });

  }

  ngOnDestroy() {
    clearInterval(this.polling);
    //Clear inputs
    this.store.dispatch(clearInputAction());
    this.suscribe$.unsubscribe();
  }

  saveUsersLocal() {

    this.loginService.getUsers().subscribe(data => {
      console.log(data);

    });
    //  let users = this.store.dispatch(getUsers());
    //   console.log(users);

  }

  onChange(value: string) {
  }

  async confirm(value: string) {
    try {
      this.ngxUiLoaderService.start();
      this.store.dispatch(loadUser());

      if (this.userActual.num_empleado === value) {
        this.store.dispatch(loadedUser({usuario: this.userActual}));
        this.authService.login(this.userActual);

        //Save date to start session
        let res = await this.corteService.saveStartTurn(this.userActual.id_corte_usuario).toPromise();

        return this.router.navigate(['/validacion-folio']);
      }

      //Login casetas auxiliares
      let user = this.usersAuxiliares.find(user => user.num_empleado === value);

      if (user) {
        //Register corte
        let actualUser = await this.corteService.saveCorteUsuario({id_usuario: user.id, id_turno: getTurno().id}).toPromise();

        //state
        this.store.dispatch(loadedUser({usuario: actualUser}));
        this.authService.login(actualUser);

        return this.router.navigate(['/validacion-folio']);
      }


      return this.toastr.error('Captura el número de empleado correcto', 'Error', {positionClass: 'toast-bottom-right'});

    } catch (err) {
      console.log(err);
    } finally {
      this.ngxUiLoaderService.stop();

    }

  }

  pollData() {
    this.polling = setInterval(() => {
      this.getActualUser();

    }, 30 * 1000);
  }

  getActualUser() {
    //si ya existe un user en sesión no hacer la petición
    if (!this.userActual?.num_empleado) {
      this.suscribe$ = this.loginService.getActualUser(this.getActualTurno()).subscribe(user => {
        this.userActual = user;
      });
    }

  }


  getActualTurno(): number {
    //Lógica para calcular el turno actual
    let turno = getTurno();
    return turno.id;
  }

  async onFocusNumEmpleado(ev: any) {

    //Verificar que no exista en el arreglo
    let state = await this.getStateInput();
    let index = state.inputs.findIndex(i => i.id == 'NUM_EMPLEADO');
    if (index != -1) {
      //Cambiar active in true
      this.store.dispatch(changeInputAction({input: {...state.inputs[index], active: true}, index}));
    } else {
      //añadir
      this.store.dispatch(addInputAction({input: {id: 'NUM_EMPLEADO', value: '', active: true}}));
    }

  }

  async onFocusPassword(ev: any) {

    //Verificar que no exista en el arreglo
    let state = await this.getStateInput();
    let index = state.inputs.findIndex(i => i.id == 'PASSWORD');
    if (index != -1) {
      //Cambiar active in true
      this.store.dispatch(changeInputAction({input: {...state.inputs[index], active: true}, index}));
    } else {
      //añadir
      this.store.dispatch(addInputAction({input: {id: 'PASSWORD', value: '', active: true}}));
    }

  }

  getStateInput() {
    return new Promise<InputState>((resolve, reject) => {
      this.store.select(selectInputs).subscribe(state => resolve(state));
    });
  }


  async confirmJT(ev) {
    //validate fields
    if (!(this.credentials.num_empleado && this.credentials.password)) {
      return this.toastr.error('Por favor captura todos los campos', 'Error', {positionClass: 'toast-bottom-right'});
    }

    //Login JT
    try {
      this.ngxUiLoaderService.start();
      const {num_empleado, password} = this.credentials;
      let user = await this.loginService.loginJT({empleado: num_empleado, password}).toPromise();

      if (!user) {
        return this.toastr.error('Usuario o contraseña incorrecta', 'Error', {positionClass: 'toast-bottom-right'});
      }

      //Cargar en el state
      this.store.dispatch(loadedUser({usuario: user}));
      this.authService.login(user);

      this.router.navigate(['/configuracion-carril']);

    } catch (err) {
      console.log(err);
    } finally {
      this.ngxUiLoaderService.stop();
    }
  }

}
