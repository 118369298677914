
<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 h-fit uppercase text-lg text-gray-500 dark:text-gray-200">
    <div class="m-2">
        <div class="flex flex-col text-xs font-semibold">
          <div class="flex items-center justify-center">
            <span class="text-sm pb-1.5 font-medium dark:text-gray-300 mr-4">Selecciona si preliquida el JT saliente</span>
            <input class="w-4 h-4 mt-0.5 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox" (change)="salienteJT($event)">
          </div>
            <hr class="dark:border-gray-500 py-2">

            <div class="space-y-2 mb-6">
                <div class="flex items-center px-2 py-1 text-center font-bold bg-blue-600 text-white rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z" />
                    </svg>
                    <span class="ml-2">Corte</span>
                </div>

                <div class="flex flex-row justify-between">

                    <div class="flex items-center">
                        <span>Cabina:</span>
                        <select (change)="selectCorte($event)" id="corteCabina" class="appearance-none block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                            <option selected disabled>Seleccionar cabina</option>
                            <option *ngFor=" let corteP of cortesPreliquidados" value="{{corteP.id}}">{{corteP.carril.nombre}}-{{corteP.usuario.nombre}} {{corteP.usuario.apellido_paterno}}</option>
                        </select>
                    </div>

                    <div class="flex items-center">
                        <span>Cobrador:</span>
                        <span class="text-sm ml-2 p-1">{{ cobradorCorte[0]?.nombre || 'Sin datos' }}</span>
                    </div>

                    <div class="flex items-center">
                        <span>Turno:</span>
                        <span class="text-sm ml-2 p-1">{{ cobradorCorte[0]?.turno || 'Sin datos' }}</span>
                    </div>

                </div>
            </div>

            <div class="space-y-2 mb-6">
                <div class="flex items-center px-2 py-1 text-center font-bold bg-blue-600 text-white rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z" />
                    </svg>
                    <span class="ml-2">Resumen de efectivo</span>
                </div>

                <div class="flex flex-row justify-between">

                    <div class="flex items-center">
                        <span>Total de retiros:</span>
                        <span class="text-sm ml-2 p-1">${{ cobradorCorte[1]?.totalRetiros || 0 | number }}</span>
                    </div>

                    <div class="flex items-center">
                        <span>Ultimo efectivo:</span>
                        <span class="text-sm ml-2 p-1">${{ cobradorCorte[1]?.efectivoFinal || 0 | number }}</span>
                    </div>

                    <div class="flex items-center">
                        <span>Total efectivo:</span>
                        <span class="text-sm ml-2 p-1">${{ totalEfectivo | number }}</span>
                    </div>

                    <div class="flex items-center">
                        <span>Resultado SGP:</span>
                        <span class="text-sm ml-2 p-1">$</span>
                        <input (change)="getSGP($event)" min="0" autocomplete="off" required class="border rounded border-gray-300 ml-1 placeholder-gray-500 p-1 block text-sm dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"  value="{{resultadoSGP}}" type="number" />
                    </div>

                    <div class="flex items-center">
                        <span>Diferencia:</span>
                        <span class="text-sm ml-2 p-1">${{ diferenciaFinal | number }}</span>
                    </div>

                    <div class="flex items-center" *ngIf="diferenciaFinal < 0">
                        <span>Faltante:</span>
                        <select (change)="getFE($event)" class="appearance-none block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                            <option selected disabled>Opcion de pago</option>
                            <option value="{{totalEfectivo - resultadoSGP}}">Pagado</option>
                            <option value="0">Vale</option>
                        </select>
                    </div>

                    <!-- <div class="flex items-center" *ngIf="diferenciaFinal < 0">
                      <span>Faltante Entregado:</span>
                      <span class="text-sm ml-2 p-1">$</span>
                      <input (change)="getFE($event)" min="0" autocomplete="off" required class="border rounded border-gray-300 ml-1 placeholder-gray-500 p-1 block text-sm dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300"  value="{{faltanteEntregado}}" type="number" />
                    </div> -->

                </div>
            </div>

            <div class="space-y-2 mb-6">
                <div class="flex items-center px-2 py-1 text-center font-bold bg-blue-600 text-white rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z" />
                    </svg>
                    <span class="ml-2">Resumen de folios consumidos</span>
                </div>

                <div class="flex flex-row justify-between">

                    <div class="flex items-center">
                        <span>Boletos consumidos:</span>
                        <span class="text-sm ml-2 p-1">{{ cobradorCorte[2]?.totalFolio || 0 }}</span>
                    </div>

                    <div class="flex items-center">
                        <span>Boletos no emitidos:</span>
                        <span class="text-sm ml-2 p-1">{{ cobradorCorte[2]?.boletosNoEmitido || 0 }}</span>
                    </div>

                    <div class="flex items-center">
                        <span>Boletos cancelados:</span>
                        <span class="text-sm ml-2 p-1">{{ cobradorCorte[2]?.boletosCancelado || 0 }}</span>
                    </div>

                    <div class="flex items-center">
                        <span>Total folios consumidos:</span>
                        <span class="text-sm ml-2 p-1">{{ cobradorCorte[2]?.totalConsumido || 0 }}</span>
                    </div>

                </div>
            </div>

            <div class="space-y-2 mb-6">
                <div class="flex items-center px-2 py-1 text-center font-bold bg-blue-600 text-white rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z" />
                    </svg>
                    <span class="ml-2">Concentrado de aforo</span>
                </div>

                <div class="flex flex-row justify-between">

                    <div class="flex items-center">
                        <span>Aforo pagado:</span>
                        <span class="text-sm ml-2 p-1">{{ cobradorCorte[2]?.totalConsumido || 0 }}</span>
                    </div>

                    <div class="flex items-center">
                        <span>Boletos recibidos:</span>
                        <span class="text-sm ml-2 p-1">{{ cobradorCorte[3]?.boletosRecibidos || 0 }}</span>
                    </div>

                    <div class="flex items-center">
                        <span>Exentos y residentes:</span>
                        <span class="text-sm ml-2 p-1">{{ cobradorCorte[3]?.boletosExe || 0 }}</span>
                    </div>

                    <div class="flex items-center">
                        <span>Tarifa especial:</span>
                        <span class="text-sm ml-2 p-1">{{ cobradorCorte[3]?.boletosEspecial || 0 }}</span>
                    </div>

                    <div class="flex items-center">
                        <span>Eludidos:</span>
                        <span class="text-sm ml-2 p-1">{{ cobradorCorte[3]?.boletosEludidos || 0 }}</span>
                    </div>

                    <div class="flex items-center">
                        <span>Total aforo:</span>
                        <span class="text-sm ml-2 p-1">{{ cobradorCorte[3]?.totalAforo }}</span>
                    </div>

                </div>
            </div>

            <hr class="dark:border-gray-500 py-2">

            <button (click)="confirmModal = true" class="flex items-center space-x-2 w-max p-2 bg-gray-50 text-xs uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 hover:border-blue-600">

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                </svg>

                <span>
                    Preliquidar corte
                </span>

            </button>

        </div>
    </div>
</div>

<div *ngIf="confirmModal" class="modal-component-container fixed inset-0 overflow-y-auto">
    <div
      class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>

      <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>

      <div
        class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
        <div class="modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

          <div class="modal-wrapper-flex sm:flex sm:items-start">

            <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">

                <div class="py-2 text-center">
                    <span>¿Estás seguro de preliquidar este corte?</span>
                </div>

            </div>
          </div>
        </div>

        <div class="modal-actions bg-gray-50 px-4 py-3 sm:px-6 flex flex-col">

            <div class="flex flex-col space-y-3 mb-8">
                <button *ngIf="(totalEfectivo - resultadoSGP + faltanteEntregado) > 0" (click)="downloadPDFPreliquidacion('sobrante')" class="flex items-center w-full justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:w-auto sm:text-sm">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                  </svg>
                  <span class="pl-1 uppercase">Formato Sobrante</span>
                </button>

                <button *ngIf="(totalEfectivo - resultadoSGP - faltanteEntregado) < 0" (click)="downloadPDFPreliquidacion('faltante')"  class="flex items-center w-full justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:w-auto sm:text-sm">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                  </svg>
                  <span class="pl-1 uppercase">Formato Faltante</span>
                </button>

                <div *ngIf="(totalEfectivo - resultadoSGP - faltanteEntregado) < 0" class="flex flex-1 flex-col">

                    <div class="flex w-full mb-2 text-center">
                        <span class="flex-1">Denominacion</span>
                        <span class="flex-1">Cantidad</span>
                        <span class="flex-1">Total</span>
                    </div>

                    <div *ngFor="let denominacion of denominacionFormato" class="flex space-x-2 mb-2">
                        <input class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 font-bold text-base focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue form-input text-center" disabled value="{{ denominacion.cantidad | number }}" type="text">

                        <input #input id="fal{{ denominacion.id }}" (change)="faltanteVale($event, denominacion, input)" class="flex-1 w-6 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue form-input text-center" type="number" min="0">

                        <input [value]="subtotales[denominacion.id] || 0 | number" class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue form-input text-center" disabled type="text">
                    </div>

                </div>

                <div *ngIf="(totalEfectivo - resultadoSGP - faltanteEntregado) < 0"><span class="font-bold text-sm">SUMA:</span> ${{ faltanteSuma | number }}</div>

                <!-- <textarea (change)="getObservacion($event)" *ngIf="(totalEfectivo - resultadoSGP + faltanteEntregado) > 0" class="w-full resize-y text-sm p-2 text-gray-500 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" rows="4" placeholder="Observaciones"></textarea> -->

                <textarea (change)="getObservacion($event)" class="w-full resize-y text-sm p-2 text-gray-500 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" rows="4" placeholder="Observaciones"></textarea>

                <div *ngIf="(totalEfectivo - resultadoSGP - faltanteEntregado) === 0" class="py-2 text-center">
                    <!-- <span>Pagado por el cobrador</span> -->
                    <span>Sin diferencias</span>
                </div>
            </div>

            <div class="flex justify-end">
                <button (click)="closeModal()"
                        class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    Cerrar
                </button>
                <button (click)="savePreliquidacionCorte()"
                        class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    Aceptar
                </button>
            </div>

        </div>

      </div>
    </div>
  </div>


<div *ngIf="finalizarCorteModal" class="modal-component-container fixed inset-0 overflow-y-auto">
<div
    class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>

    <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>

    <div
    class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
    <div class="modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

        <div class="modal-wrapper-flex sm:flex sm:items-start">

        <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">

            <div class="flex items-center justify-between">
            <div class="flex items-center rounded py-2">
                <span class="pl-2">¿Finalizar corte sin datos?</span>
            </div>
            </div>
        </div>
        </div>
    </div>

    <div class="modal-actions bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button (click)="finalizarCorte()"
                class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
        Aceptar
        </button>
    </div>

    </div>
</div>
</div>
