import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {HeaderComponent} from './components/header/header.component';
import {MenuComponent} from './components/menu/menu.component';
import {MainComponent} from './components/main/main.component';
import {AsideComponent} from './components/aside/aside.component';
import {ContainerComponent} from './components/container/container.component';
import {FooterComponent} from './components/footer/footer.component';
import {ClasificacionVehicularComponent} from './components/clasificacion-vehicular/clasificacion-vehicular.component';
import {ContainerHeaderComponent} from './components/container-header/container-header.component';
import {SubtipoCruceComponent} from './components/subtipo-cruce/subtipo-cruce.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
//import {ConnectionServiceModule} from 'ngx-connection-service';
import {ToastrModule} from 'ngx-toastr';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {ROOT_REDUCERS} from './state/app.state';
import {EffectsModule} from '@ngrx/effects';
import {CasetaEffects} from './state/effects/caseta.effects';
import { LoginComponent } from './pages/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MopaLayoutComponent } from './containers/mopa-layout/mopa-layout.component';
import {DevolucionEffects} from './state/effects/devolucion.effects';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { RetiroEffects } from './state/effects/retiro.effects';
import { TipoVehiculoEffects } from './state/effects/tipoVehiculo.effects';
import { TecladoNumericoComponent } from './components/teclado-numerico/teclado-numerico.component';
import { DevolucionDenominacionComponent } from './components/devolucion-denominacion/devolucion-denominacion.component';
import { CorteComponent } from './components/corte/corte.component';
import { BtnRapidoEffects } from './state/effects/btnRapido.effects';
import { MorrallaEffects } from './state/effects/morralla.effects';
import { CruceEffects } from './state/effects/cruce.effects';
import { RolloEffects } from './state/effects/rollo.effects';
import { DineroEludidoComponent } from './components/dinero-eludido/dinero-eludido.component';
import { HomeComponent } from './components/home/home.component';
import { CambioRolloComponent } from './components/cambio-rollo/cambio-rollo.component';
import { ConfirmacionFirmaComponent } from './components/confirmacion-firma/confirmacion-firma.component';
import { ValidacionFolioComponent } from './pages/validacion-folio/validacion-folio.component';
import { BtnRetiroComponent } from './components/btn-retiro/btn-retiro.component';
import { FirmaComponent } from './components/firma/firma.component';
import { EccComponent } from './components/ecc/ecc.component';

import { SwitchThemeComponent } from './components/switch-theme/switch-theme.component';
import { DashboardLayoutComponent } from './containers/dashboard-layout/dashboard-layout.component';
import { LoginMopaComponent } from './pages/login-mopa/login-mopa.component';
import { FondoOperacionComponent } from './components-dashboard/fondo-operacion/fondo-operacion.component';
import { AsideDashboardComponent } from './components-dashboard/aside-dashboard/aside-dashboard.component';
import { HeaderDashboardComponent } from './components-dashboard/header-dashboard/header-dashboard.component';
import { AsignarFondoComponent } from './components-dashboard/asignar-fondo/asignar-fondo.component';
import { HomeDashboardComponent } from './components-dashboard/home-dashboard/home-dashboard.component';
import { AsignarCarrilComponent } from './components-dashboard/asignar-carril/asignar-carril.component';
import { MorrallaCarrilComponent } from './components-dashboard/morralla-carril/morralla-carril.component';
import { BitacoraComponent } from './components-dashboard/bitacora/bitacora.component';
import { DevolucionComponent } from './components-dashboard/devolucion/devolucion.component';
import { CambioComponent } from './components-dashboard/cambio/cambio.component';
import { SepsaComponent } from './components-dashboard/sepsa/sepsa.component';
import { FaltantePagoComponent } from './components-dashboard/faltante-pago/faltante-pago.component';
import { RetiroComponent } from './components-dashboard/retiro/retiro.component';
import { FolioComponent } from './components-dashboard/folio/folio.component';
import { RetiroTotalComponent } from './components-dashboard/retiro-total/retiro-total.component';
import { FormatoResumenComponent } from './components-dashboard/formato-resumen/formato-resumen.component';
import { FormatoCorteComponent } from './components-dashboard/formato-corte/formato-corte.component';
import { FormatoExentoComponent } from './components-dashboard/formato-exento/formato-exento.component';
import { FormatoRetiroComponent } from './components-dashboard/formato-retiro/formato-retiro.component';
import { FormatoHeaderComponent } from './components-dashboard/formato-header/formato-header.component';
import { FormatoIncidenciaComponent } from './components-dashboard/formato-incidencia/formato-incidencia.component';
import { FirmaDashboardComponent } from './components-dashboard/firma-dashboard/firma-dashboard.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UsuarioComponent } from './components-dashboard/usuario/usuario.component';
import { UltimoEfectivoComponent } from './components-dashboard/ultimo-efectivo/ultimo-efectivo.component';
import { UEfectivoComponent } from './components/u-efectivo/u-efectivo.component';
import { UEfectivoFirmaComponent } from './components/u-efectivo-firma/u-efectivo-firma.component';
import { CambioPasswordComponent } from './components-dashboard/cambio-password/cambio-password.component';
import { ConfirmarFondoComponent } from './pages/confirmar-fondo/confirmar-fondo.component';
import { ConfiguracionCarrilComponent } from './pages/configuracion-carril/configuracion-carril.component';
import { AperturarCarrilComponent } from './components-dashboard/aperturar-carril/aperturar-carril.component';
import { ConstruccionComponent } from './components-dashboard/construccion/construccion.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { MorrallaPagoComponent } from './components-dashboard/morralla-pago/morralla-pago.component';
import {AuthInterceptorInterceptor} from './interceptors/auth-interceptor.interceptor';
import { BitacoraPendienteComponent } from './components-dashboard/bitacora-pendiente/bitacora-pendiente.component';
import { AsaltoPagoComponent } from './components-dashboard/asalto-pago/asalto-pago.component';
import { FormatoFinalComponent } from './components-dashboard/formato-final/formato-final.component';
import { SafePipe } from './safe.pipe';
import { PreliquidacionComponent } from './components-dashboard/preliquidacion/preliquidacion.component';
import { PrintRetiroComponent } from './components-dashboard/print-retiro/print-retiro.component';
import { FaltanteFondoComponent } from './components-dashboard/faltante-fondo/faltante-fondo.component';
import { PrintFormatoFinalComponent } from './components-dashboard/print-formato-final/print-formato-final.component';
import { ConfiguracionComponent } from './configuracion-dashboard/configuracion/configuracion.component';
import { CuadrarFondoComponent } from './configuracion-dashboard/cuadrar-fondo/cuadrar-fondo.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    MainComponent,
    AsideComponent,
    ContainerComponent,
    FooterComponent,
    ClasificacionVehicularComponent,
    ContainerHeaderComponent,
    SubtipoCruceComponent,
    LoginComponent,
    MopaLayoutComponent,
    TecladoNumericoComponent,
    DevolucionDenominacionComponent,
    CorteComponent,
    DineroEludidoComponent,
    HomeComponent,
    CambioRolloComponent,
    ConfirmacionFirmaComponent,
    ValidacionFolioComponent,
    BtnRetiroComponent,
    FirmaComponent,
    DashboardLayoutComponent,
    LoginMopaComponent,
    SwitchThemeComponent,
    FondoOperacionComponent,
    AsideDashboardComponent,
    HeaderDashboardComponent,
    AsignarFondoComponent,
    HomeDashboardComponent,
    AsignarCarrilComponent,
    MorrallaCarrilComponent,
    BitacoraComponent,
    DevolucionComponent,
    CambioComponent,
    SepsaComponent,
    FaltantePagoComponent,
    RetiroComponent,
    FolioComponent,
    RetiroTotalComponent,
    FormatoResumenComponent,
    FormatoCorteComponent,
    FormatoExentoComponent,
    FormatoRetiroComponent,
    FormatoHeaderComponent,
    FormatoIncidenciaComponent,
    EccComponent,
    FirmaDashboardComponent,
    UsuarioComponent,
    UltimoEfectivoComponent,
    UEfectivoComponent,
    UEfectivoFirmaComponent,
    CambioPasswordComponent,
    ConfirmarFondoComponent,
    ConfiguracionCarrilComponent,
    AperturarCarrilComponent,
    ConstruccionComponent,
    MorrallaPagoComponent,
    BitacoraPendienteComponent,
    AsaltoPagoComponent,
    FormatoFinalComponent,
    SafePipe,
    PreliquidacionComponent,
    PrintRetiroComponent,
    FaltanteFondoComponent,
    PrintFormatoFinalComponent,
    ConfiguracionComponent,
    CuadrarFondoComponent
  ],
  imports: [
    BrowserModule,
    NgxDatatableModule,
    AppRoutingModule,
    HttpClientModule,
    NgxUiLoaderModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }), StoreModule.forRoot(ROOT_REDUCERS), StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    EffectsModule.forRoot([CasetaEffects, DevolucionEffects,RetiroEffects, TipoVehiculoEffects, BtnRapidoEffects,MorrallaEffects,CruceEffects,RolloEffects])
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
