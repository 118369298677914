import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AsideComponent } from './components/aside/aside.component';
import { ClasificacionVehicularComponent } from './components/clasificacion-vehicular/clasificacion-vehicular.component';
import { ContainerComponent } from './components/container/container.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { MainComponent } from './components/main/main.component';
import { MenuComponent } from './components/menu/menu.component';
import { SubtipoCruceComponent } from './components/subtipo-cruce/subtipo-cruce.component';
import {LoginComponent} from './pages/login/login.component';
import {MopaLayoutComponent} from './containers/mopa-layout/mopa-layout.component';
import { TecladoNumericoComponent } from './components/teclado-numerico/teclado-numerico.component';
import { DevolucionDenominacionComponent } from './components/devolucion-denominacion/devolucion-denominacion.component';
import {CorteComponent} from './components/corte/corte.component';
import { DineroEludidoComponent } from './components/dinero-eludido/dinero-eludido.component';
import {HomeComponent} from './components/home/home.component';
import {CambioRolloComponent} from './components/cambio-rollo/cambio-rollo.component';
import {ValidacionFolioComponent} from './pages/validacion-folio/validacion-folio.component';
import { BtnRetiroComponent } from './components/btn-retiro/btn-retiro.component';
import { FirmaComponent } from './components/firma/firma.component';
import {AuthGuard} from './guards/auth.guard';

import { DashboardLayoutComponent } from './containers/dashboard-layout/dashboard-layout.component';
import { LoginMopaComponent } from './pages/login-mopa/login-mopa.component';
import { FondoOperacionComponent } from './components-dashboard/fondo-operacion/fondo-operacion.component';
import { AsignarFondoComponent } from './components-dashboard/asignar-fondo/asignar-fondo.component';
import { HomeDashboardComponent } from './components-dashboard/home-dashboard/home-dashboard.component';
import { AsignarCarrilComponent } from './components-dashboard/asignar-carril/asignar-carril.component';
import { MorrallaCarrilComponent } from './components-dashboard/morralla-carril/morralla-carril.component';
import { DevolucionComponent } from './components-dashboard/devolucion/devolucion.component';
import { CambioComponent } from './components-dashboard/cambio/cambio.component';
import { SepsaComponent } from './components-dashboard/sepsa/sepsa.component';
import { FaltantePagoComponent } from './components-dashboard/faltante-pago/faltante-pago.component';
import { RetiroComponent } from './components-dashboard/retiro/retiro.component';
import { FolioComponent } from './components-dashboard/folio/folio.component';
import { RetiroTotalComponent } from './components-dashboard/retiro-total/retiro-total.component';
import { FormatoResumenComponent } from './components-dashboard/formato-resumen/formato-resumen.component';
import { FormatoCorteComponent } from './components-dashboard/formato-corte/formato-corte.component';
import { FormatoExentoComponent } from './components-dashboard/formato-exento/formato-exento.component';
import { FormatoRetiroComponent } from './components-dashboard/formato-retiro/formato-retiro.component';
import { FormatoIncidenciaComponent } from './components-dashboard/formato-incidencia/formato-incidencia.component';
import { EccComponent } from './components/ecc/ecc.component';
import { FirmaDashboardComponent } from './components-dashboard/firma-dashboard/firma-dashboard.component';
import { UsuarioComponent } from './components-dashboard/usuario/usuario.component';
import { UltimoEfectivoComponent } from './components-dashboard/ultimo-efectivo/ultimo-efectivo.component';
import { UEfectivoComponent } from './components/u-efectivo/u-efectivo.component';
import { UEfectivoFirmaComponent } from './components/u-efectivo-firma/u-efectivo-firma.component';
import { CambioPasswordComponent } from './components-dashboard/cambio-password/cambio-password.component';
import { ConfirmarFondoComponent } from './pages/confirmar-fondo/confirmar-fondo.component';
import {ConfiguracionCarrilComponent} from './pages/configuracion-carril/configuracion-carril.component';
import { AperturarCarrilComponent } from './components-dashboard/aperturar-carril/aperturar-carril.component';
import { ConstruccionComponent } from './components-dashboard/construccion/construccion.component';
import { RolGuard } from './guards/rol.guard';
import { MorrallaPagoComponent } from './components-dashboard/morralla-pago/morralla-pago.component';
import { BitacoraComponent } from './components-dashboard/bitacora/bitacora.component';
import { BitacoraPendienteComponent } from './components-dashboard/bitacora-pendiente/bitacora-pendiente.component';
import { AsaltoPagoComponent } from './components-dashboard/asalto-pago/asalto-pago.component';
import { FormatoFinalComponent } from './components-dashboard/formato-final/formato-final.component';
import { PreliquidacionComponent } from './components-dashboard/preliquidacion/preliquidacion.component';
import { PrintRetiroComponent } from './components-dashboard/print-retiro/print-retiro.component';
import { FaltanteFondoComponent } from './components-dashboard/faltante-fondo/faltante-fondo.component';
import { ConfiguracionComponent } from './configuracion-dashboard/configuracion/configuracion.component';


const routes: Routes = [
  {
    path:'login-mopa',
    component: LoginMopaComponent
  },
  {
    path:'dashboard',
    component: DashboardLayoutComponent,
    canLoad: [AuthGuard],
    canActivate:[AuthGuard],
    children:[
      {
        path:'fondo-operacion',
        component: FondoOperacionComponent,
        canActivate:[RolGuard]
      },
      {
        path:'configuracion',
        component: ConfiguracionComponent,
        canActivate:[RolGuard]
      },
      {
        path:'firma',
        component: FirmaDashboardComponent,
        // canActivate:[RolGuard]
      },
      {
        path:'asignar-fondo',
        component: AsignarFondoComponent,
        canActivate:[RolGuard]
      },
      {
        path:'asignar-carril',
        component: AsignarCarrilComponent,
        // canActivate:[RolGuard]
      },
      {
        path:'morralla-carril',
        component: MorrallaCarrilComponent,
        canActivate:[RolGuard]
      },
      {
        path:'morralla-pago',
        component: MorrallaPagoComponent,
        // canActivate:[RolGuard]
      },
      {
        path:'devolucion',
        component: DevolucionComponent,
        canActivate:[RolGuard]
      },
      {
        path:'bitacora',
        component: BitacoraComponent,
        canActivate:[RolGuard]
      },
      {
        path:'bitacora-pendiente',
        component: BitacoraPendienteComponent,
        canActivate:[RolGuard]
      },
      {
        path:'asalto-pago',
        component: AsaltoPagoComponent,
        canActivate:[RolGuard]
      },
      {
        path:'cambio',
        component: CambioComponent,
        canActivate:[RolGuard]
      },
      {
        path:'sepsa',
        component: SepsaComponent,
        canActivate:[RolGuard]
      },
      {
        path:'faltante-pago',
        component: FaltantePagoComponent,
        canActivate:[RolGuard]
      },
      {
        path:'retiro',
        component: RetiroComponent,
        canActivate:[RolGuard]
      },
      {
        path:'folio',
        component: FolioComponent,
        canActivate:[RolGuard]
      },
      {
        path:'usuario',
        component: UsuarioComponent,
        canActivate:[RolGuard]
      },
      {
        path:'retiro-total',
        component: RetiroTotalComponent,
        canActivate:[RolGuard]
      },
      {
        path:'ultimo-efectivo',
        component: UltimoEfectivoComponent,
        pathMatch: 'full',
        canActivate:[RolGuard]
      },
      {
        path:'cambio-password',
        component: CambioPasswordComponent,
        canActivate:[RolGuard]
      },
      {
        path:'construccion',
        component: ConstruccionComponent
      },
      {
        path:'aperturar-carril',
        component: AperturarCarrilComponent,
        canActivate:[RolGuard]
      },
      {
        path:'faltante-fondo',
        component: FaltanteFondoComponent,
        canActivate:[RolGuard]
      },
      {
        path:'formato-resumen',
        component: FormatoResumenComponent,
        children:[
          {
            path:'corte',
            component: FormatoCorteComponent,
            canActivate:[RolGuard]
          },
          {
            path:'incidencia',
            component: FormatoIncidenciaComponent,
            canActivate:[RolGuard]
          },
          {
            path:'exento',
            component: FormatoExentoComponent,
            canActivate:[RolGuard]
          },
          {
            path:'retiro',
            component: FormatoRetiroComponent,
            canActivate:[RolGuard]
          },
        ]
      },
      {
        path:'preliquidacion',
        component: PreliquidacionComponent,
        canActivate:[RolGuard]
      },
      {
        path:'formato-final',
        component: FormatoFinalComponent,
        canActivate:[RolGuard]
      },
      {
        path:'home',
        component: HomeDashboardComponent
      },
      {
        path:'**',
        pathMatch: 'full',
        redirectTo: 'home'
      },
    ]
  },
  {
    path:'login',
    component:LoginComponent
  },
  {
    path:'validacion-folio',
    component:ValidacionFolioComponent,
    canLoad: [AuthGuard],
    canActivate:[AuthGuard]
  },
  {
    path:'configuracion-carril',
    component:ConfiguracionCarrilComponent,
    canLoad: [AuthGuard],
    canActivate:[AuthGuard]
  },
  {
    path:'confirmar-fondo',
    component:ConfirmarFondoComponent,
    canLoad: [AuthGuard],
    canActivate:[AuthGuard]
  },
  {
    path:'print-retiro',
    component:PrintRetiroComponent,
    canLoad: [AuthGuard],
    canActivate:[AuthGuard]
  },
  {
    path:'mopa',
    component:MopaLayoutComponent,
    canLoad: [AuthGuard],
    canActivate:[AuthGuard],
    children:[
      {
        path:'clasificacion-vehicular',
        component:ClasificacionVehicularComponent
      },
      {
        path:'devolucion-denominacion',
        component:DevolucionDenominacionComponent
      },
      {
        path:'subtipo/:id',
        component:SubtipoCruceComponent
      },
      {
        path: 'corte',
        component: CorteComponent
      },
      {
        path: 'dinero-eludido',
        component: DineroEludidoComponent
      },
      {
        path:'home',
        component:HomeComponent
      },
      {
        path:'cambio-rollo',
        component: CambioRolloComponent
      },
      {
        path:'btn-retiro',
        component: BtnRetiroComponent
      },
      {
        path:'firma/:type',
        component: FirmaComponent
      },
      {
        path:'ecc',
        component: EccComponent
      },
      {
        path:'u-efectivo',
        component: UEfectivoComponent
      },
      {
        path:'u-efectivo-firma',
        component: UEfectivoFirmaComponent
      },
    ]
  },
  {
    path:'**',
    redirectTo: 'login',
    pathMatch: 'full'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],

  exports: [RouterModule]
})
export class AppRoutingModule { }
