import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import { selectCruce } from '../../state/selectors/cruce.selectors';
import { selectBtnRapido } from '../../state/selectors/btnRapido.selectors';
import { Cruce, BtnRapido, TipoVehiculo } from '../../models';
import { addCruceClasificacion, addCruceObservacion, clearCruce } from '../../state/actions/cruce.actions';
import { addBtnRapidoClasificacion, addBtnRapidoObservacion, postBtnRapido } from '../../state/actions/btnRapido.actions';
import { postCruce } from '../../state/actions/cruce.actions';
import { TYPE_CRUCE, TYPE_BTNS, TYPE_CRUCE_PADRE, TYPE_PERMISO } from '../../utils/enum';
import { loadTipoVehiculo } from 'src/app/state/actions/tipoVehiculo.actions';
import { selectTipoVehiculo } from 'src/app/state/selectors/tipoVehiculo.selectors';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { selectPermisos } from 'src/app/state/selectors/caseta.selectors';

@Component({
  selector: 'app-clasificacion-vehicular',
  templateUrl: './clasificacion-vehicular.component.html',
  styleUrls: ['./clasificacion-vehicular.component.scss']
})
export class ClasificacionVehicularComponent implements OnInit {

  cruce: any = null;
  user: any = null;
  btnRapido: any = null;
  tiposVehiculo$: Observable<TipoVehiculo> = new Observable<TipoVehiculo>();
  toRedirect: boolean = true
  //variables caravaa
  isCaravana
  activeCaravana = false;
  countCaravana: number;
  //variables para no emitidos y cancelados
  btnTemp: any
  id_cat_observacion: number = 0;
  //permisos
  permisos = [];

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private toastr: ToastrService

  ) { }

  ngOnInit(): void {

    this.getCruce()

    this.store.select(selectUser).subscribe(user => this.user = user);

    this.store.select(selectBtnRapido).subscribe(btnRapido => this.btnRapido = btnRapido);
    console.log(this.btnRapido);

    this.getClasificacionVehicular();
    this.isCaravana = TYPE_CRUCE.EXCENTOS

    // Permisos para retirar o devolucion por roles
    this.store.select(selectPermisos).subscribe(permiso => {
      this.permisos = permiso
    });
  }

  addClasificacion(id: number, clasificacion: string, btn) {
    this.store.dispatch(addCruceClasificacion({ clasificacion: clasificacion, id_clasificacion: id }))
    //redirigir si se aprietan ecc o t+r
    this.gotoEcc(id)
    switch (this.cruce.id_type) {
      case TYPE_CRUCE.INCIDENCIAS:
        console.log('Viene de cruce')
        switch (this.cruce.idPadre) {

          case TYPE_CRUCE_PADRE.ELUDIDOS:
            if (this.toRedirect) {
              this.router.navigate(['/mopa/dinero-eludido']);
            }

            break;
          case TYPE_CRUCE_PADRE.NO_EMITIDOS:
          case TYPE_CRUCE_PADRE.CANCELADOS:
            if (this.toRedirect) {

              if (id == 16 || id == 17) {//RPI , TAG
                this.getCruce()
                this.id_cat_observacion = id == 16 ? 1 : 2
                this.store.dispatch(addCruceObservacion({ id_cat_observacion: this.id_cat_observacion }))
                this.toastr.info('Selecciona el tipo de vehiculo ', '', { positionClass: 'toast-bottom-right' })
                //style del boton
                this.isSelected(btn)
                //validar que solo se selecione uno
                if (this.btnTemp) {
                  this.isSelected(this.btnTemp)
                }
                this.btnTemp = btn;

              } else {
                // Se guarda no emitido o cancelado
                let cruce: Cruce = {
                  fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
                  id_corte_usuario: this.user.id_corte_usuario,
                  id_subtipo: this.cruce.idChildren,
                  id_clasificacion: this.cruce.id_clasificacion,
                  id_cat_observacion: this.id_cat_observacion
                }
                this.store.dispatch(postCruce({ cruce }))
                this.finishEvent()
              }
            }

            break;

        }
        break;
      case TYPE_CRUCE.EXCENTOS:
        if (this.toRedirect) {
          console.log('excentos');
          //incrementar contador caravana
          this.countCaravana++;
          //Se guarda la info del cruce para excentos
          let cruce: Cruce = {
            fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
            id_corte_usuario: this.user.id_corte_usuario,
            id_subtipo: this.cruce.idChildren,
            id_clasificacion: id,
          }
          this.store.dispatch(postCruce({ cruce }))
          this.toastr.success('Cruce guardado con éxito', '', { positionClass: 'toast-bottom-right' })
          if (!this.activeCaravana) {
            this.returnPage()
          }
        }
        break;

    }

    if (this.btnRapido.id_subtipo) {
      console.log('Viene de btnRapido')
      this.gotoEcc(id)
      if (this.toRedirect) {

        if (id == 16 || id == 17) {//RPI , TAG
          this.id_cat_observacion = id == 16 ? 1 : 2
          this.store.dispatch(addBtnRapidoObservacion({ id_cat_observacion: this.id_cat_observacion }))
          this.toastr.info('Selecciona el tipo de vehiculo ', '', { positionClass: 'toast-bottom-right' })
          //style del boton
          this.isSelected(btn)
          //validar que solo se selecione uno
          if (this.btnTemp) {
            this.isSelected(this.btnTemp)
          }
          this.btnTemp = btn;

        } else {
          //Si viene de botones rápidos
          this.store.dispatch(addBtnRapidoClasificacion({ clasificacion: clasificacion, id_clasificacion: id }))
          // Se guarda la info de btnRapido
          let BtnRapido: BtnRapido = {
            fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
            id_subtipo: this.btnRapido.id_subtipo,
            id_clasificacion: id,
            id_corte_usuario: this.user.id_corte_usuario,
            id_cat_observacion: this.btnRapido.id_cat_observacion,
          }
          this.store.dispatch(postBtnRapido({ btnRapido: BtnRapido }))
          this.router.navigate(['/mopa',]);
          this.toastr.success('Se registró el evento ' + this.btnRapido.nombreSubtipo, '', { positionClass: 'toast-bottom-right' })
        }
      }




    }

  }

  getCruce() {
    this.store.select(selectCruce).subscribe(cruce => this.cruce = cruce);
    console.log(this.cruce);
  }

  getClasificacionVehicular() {
    this.store.dispatch(loadTipoVehiculo())
    this.tiposVehiculo$ = this.store.select(selectTipoVehiculo)
  }

  returnPage() {
    this.countCaravana = 0
    this.router.navigate(['/mopa'])
  }

  finishEvent() {
    this.toastr.success('Cruce guardado con éxito', '', { positionClass: 'toast-bottom-right' })
    this.router.navigate(['/mopa/home']);
    this.store.dispatch(clearCruce());
  }

  activeCaravanaHandler(btnC) {
    this.activeCaravana = !this.activeCaravana;
    if (!this.activeCaravana) {
      this.returnPage()
    }
    this.countCaravana = 0;
    this.isSelected(btnC);
  }

  gotoEcc(id: number) {
    if (id == 3 || id == 15) {
      this.toRedirect = false;
      this.router.navigate(['/mopa/ecc'])
    }
  }


  hiddenButton(btn) {
    if (this.cruce.id_type == TYPE_CRUCE.EXCENTOS || this.cruce.idPadre == TYPE_CRUCE_PADRE.ELUDIDOS || this.btnRapido.id_subtipo == 61 || this.btnRapido.id_subtipo == 63 || this.btnRapido.id_subtipo == 62 || this.btnRapido.id_subtipo == 58 || this.btnRapido.id_subtipo == 60) {
      if (btn.id == 16 || btn.id == 17) {
        return true
      }
    } else {
      return false
    }


  }

  isSelected(btn) {
    if (btn.classList.contains('selected')) {
      btn.classList.remove('selected');
      btn.classList.remove('dark:bg-blue-800');
      btn.classList.remove('bg-blue-800');
      btn.classList.remove('text-white');
      btn.classList.add('dark:bg-transparent');
    } else {
      btn.classList.add('selected');
      btn.classList.add('dark:bg-blue-800');
      btn.classList.add('bg-blue-800');
      btn.classList.add('text-white');
      btn.classList.remove('dark:bg-transparent');

    }
  }

}
