import { createSelector } from '@ngrx/store';
import {AppState} from '../app.state';
import {InputState} from '../../core/models/input.state';
import {HistoryNotification} from '../../core/models/history.notification';

export const selectNotificationFeature = (state: AppState) => state.history_notification;

export const selectHisNotification = createSelector(
  selectNotificationFeature,
  (state: HistoryNotification) => state.notificaciones
);
