import {Injectable} from '@angular/core';
import {Auth} from '../models';
import {Observable, of} from 'rxjs';
import {Store} from '@ngrx/store';
import {AppState} from '../state/app.state';
import {loadedUser} from '../state/actions/user.actions';
import {clearRetiro} from '../state/actions/retiro.actions';
import {clearDevolution} from '../state/actions/devolucion.actions';
import {clearStatusPanel} from '../state/actions/panelIzquierdo.actions';
import {clearStatusRetiro} from '../state/actions/panelIzquierdoRetiro.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private store: Store<AppState>
  ) {
  }

  login(auth: any) {

    //save current user
    localStorage.setItem('user', btoa(JSON.stringify(auth)));
    // console.log(auth);

  }

  verificationLogin(): Observable<boolean> {
    if (!localStorage.getItem('user')) {
      return of(false);
    }

    let user =  localStorage.getItem('user');
    // console.log(JSON.parse(atob(user)));

    if(!user){
      return of(false);
    }
    //Save to redux
    this.store.dispatch(loadedUser({usuario : JSON.parse(atob(user))}));

    return of(true);
  }

  logout(){
    localStorage.removeItem('user')

    //clean retiro and devolution
    this.store.dispatch(clearRetiro());
    this.store.dispatch(clearDevolution());

    //setar valores
    this.store.dispatch(clearStatusPanel())
    this.store.dispatch(clearStatusRetiro())

    localStorage.setItem("panelizquierdo", '0')
    localStorage.setItem("panelizquierdoretiro", '0')
  }
}
