<div class="flex items-center min-h-screen p-6 bg-gray-100 dark:bg-gray-900">
  <div class="flex-1 h-full max-w-xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
    <div class="flex flex-col overflow-y-auto md:flex-row">

      <!-- <div class="h-32 md:h-auto md:w-1/2">
        <img
          aria-hidden="true"
          class="object-cover w-full h-full dark:hidden"
          src="/assets/images/login-light.svg"
          alt="MOPA"
        />
        <img
          aria-hidden="true"
          class="hidden object-cover w-full h-full dark:block"
          src="/assets/images/login-dark.svg"
          alt="MOPA"
        />
      </div> -->
      <div class="flex items-center justify-center p-6 sm:p-12 md:w-full">
        <div class="w-full">

          <div class="grid grid-cols-2">

            <div class="grid grid-rows-2 text-xs">
            </div>

            <div class="flex justify-end">

              <div class="flex justify-between items-center">
                <button (click)="downloadCer()" class="mr-2 border rounded px-3 py-1 shadow-sm shadow-rose-500">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#d1d5db" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                  </svg>                  
                </button>
                <app-switch-theme></app-switch-theme>
              </div>

            </div>

          </div>

          <hr class="mt-4 mb-8 border-gray-200 dark:border-gray-700"/>

          <img
            aria-hidden="true"
            class="mx-auto h-12 w-auto dark:hidden"
            src="/assets/images/sogak-logo-light.svg"
            alt="SOGAK"
          />
          <img
            aria-hidden="true"
            class="hidden mx-auto h-12 w-auto dark:block"
            src="/assets/images/sogak-logo-dark.svg"
            alt="SOGAK"
          />

          <h1 class="mt-6 mb-6 text-xl text-center font-semibold text-gray-700 dark:text-gray-200">INICIAR SESION</h1>

          <form [formGroup]="form">
            <label class="block text-sm">
              <span class="text-gray-700 dark:text-gray-400 sr-only">Número de empleado</span>
              <input formControlName="empleado" name="empleado" type="number" min="1" max="99999999" autocomplete="off" placeholder="Empleado"
                     required
                     class="border rounded border-gray-300 placeholder-gray-500 px-3 py-2 block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray h-12 form-input"/>
            </label>

            <label class="block text-sm mt-4">
              <span class="text-gray-700 dark:text-gray-400 sr-only">Contrasena</span>
              <input formControlName="password" name="password" type="password" min="1" max="99999999" autocomplete="off" required
                     placeholder="Contraseña"
                     class="border rounded border-gray-300 placeholder-gray-500 px-3 py-2 block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray h-12 form-input"/>
            </label>

            <button type="submit" [disabled]="form.invalid" (click)="loginUser()"
                    class="w-full mt-8 bg-blue-600 p-4 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <img class="h-5 mx-auto" src="/assets/images/btn_send.svg" alt="">
            </button>

          </form>

          <!-- <div class="grid grid-cols-3 grid-flow-row gap-2 mt-6 text-white text-xl">
            <button type="button" class="bg-blue-600 py-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">7</button>
            <button type="button" class="bg-blue-600 py-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">8</button>
            <button type="button" class="bg-blue-600 py-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">9</button>
            <button type="button" class="bg-blue-600 py-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">4</button>
            <button type="button" class="bg-blue-600 py-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">5</button>
            <button type="button" class="bg-blue-600 py-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">6</button>
            <button type="button" class="bg-blue-600 py-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">1</button>
            <button type="button" class="bg-blue-600 py-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">2</button>
            <button type="button" class="bg-blue-600 py-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">3</button>
            <button type="button" class="bg-blue-600 py-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">0</button>
            <button type="button" class="col-span-2 bg-blue-600 py-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <img class="h-6 mx-auto" src="/assets/images/btn_backspace.svg" alt="">
            </button>
            <button type="button" class="bg-blue-600 p-4 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:5ing-offset-2 focus:ring-indigo-500">
              <img class="h-5 mx-auto" src="/assets/images/btn_erase.svg" alt="">
            </button>
            <button type="submit" (click)="loginUser()" class="col-span-2 bg-blue-600 p-4 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <img class="h-5 mx-auto" src="/assets/images/btn_send.svg" alt="">
            </button>
          </div> -->

        </div>
      </div>
    </div>
  </div>
</div>
