import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CorteService } from 'src/app/services/corte.service';
import { LogUsuarioService } from 'src/app/services/log-usuario.service';
import { AppState } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/selectors/user.selectors';

@Component({
  selector: 'app-confirmar-fondo',
  templateUrl: './confirmar-fondo.component.html',
  styleUrls: ['./confirmar-fondo.component.scss']
})
export class ConfirmarFondoComponent implements OnInit {

  botonConfirmar: boolean = true;
  monto: number;
  user: any;
  id_corte_anterior: number
  constructor(
    private router: Router,
    private ngxUiLoaderService: NgxUiLoaderService,
    private logUsuarioSrvice: LogUsuarioService,
    private corteService: CorteService,
    private store: Store<AppState>,
    private toastr: ToastrService,


  ) { }

  ngOnInit(): void {
    this.store.select(selectUser).subscribe(user => this.user = user);
    console.log(this.user);
    this.id_corte_anterior = Number(localStorage.getItem('id_corte_anterior'));
    this.getData();


  }

  montoFondo(e) {
    // this.monto = e;
    // console.log( e );
  }

  onChange(e) {
    this.monto = e;
    console.log(e);
  }

  getData() {
    if (this.id_corte_anterior) {
      console.log('entra');

      this.logUsuarioSrvice.getMontoCorte(this.id_corte_anterior).
        subscribe(fondo => {

          this.monto = fondo[0]['monto'];
        })
    } else {
      this.corteService.getSumaCorte(this.user.id_corte_usuario).
        subscribe(fondo => {
          this.monto = fondo[0]['fondo']
        })
    }

  }

  showHome() {
    if (!this.monto) {
      return this.toastr.error(`Ingrese el fondo`, '', { positionClass: 'toast-bottom-right' });
    }
    
    this.ngxUiLoaderService.start();
    try {
      //guardar accion de confirmacion de auxiliar
      let data = {
        id_usuario: this.user.id_usuario,
        id_cat_acciones: 4,
        id_corte_usuario: this.user.id_corte_usuario,
        id_corte_auxiliar: this.id_corte_anterior,
        monto: this.monto,
        id_caseta: this.user.id_caseta
      }
      this.logUsuarioSrvice.saveLogUsuario(data).
        subscribe(log => {
          this.toastr.success(`Fondo confirmado con éxito`, '', { positionClass: 'toast-bottom-right' });
        })

    } catch (err) {
      console.log(err);
    } finally {
      this.router.navigate(['mopa/home']);
      this.ngxUiLoaderService.stop();
    }
  }

}
