<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase font-semibold text-base text-center text-gray-500 dark:text-gray-200">
    <div class="flex flex-col">

        <div class="flex justify-between items-center">
            <span class="flex-1"></span>
            <span class="flex-1 text-gray-500 dark:text-gray-50 py-2 font-bold">Pago de faltante de fondo</span>
            <span class="flex-1"></span>
        </div>

        <hr class="dark:border-gray-500 mb-4">

        <div class="flex justify-between">

            <div class="flex flex-1 flex-col"> 
                <div class="flex w-full justify-center mb-4">
                    <div class="w-3/4">
                        <label class="sr-only">Seleccionar faltante de fondo</label>
                        <ng-select class="custom" 
                                   placeholder="Seleccionar faltante de fondo" 
                                   [items]="faltantesPendientes"
                                   bindLabel="faltanteFondo" 
                                   bindValue="id"
                                   (change)="getFaltante($event)">
                        </ng-select>
                    </div>
                </div>

                <div *ngIf="faltanteDeuda" class="flex justify-center text-blue-600 dark:text-blue-50 mb-4">
                    <div class="w-3/4 bg-gray-100 dark:bg-gray-700 rounded text-lg font-bold p-2">
                        <span>Pagar: $ </span>
                        <span>{{ faltanteDeuda | number }}</span>
                    </div>
                </div>

                <div class="flex justify-center text-blue-600 dark:text-blue-50 mb-4">
                    <div class="w-3/4 bg-gray-100 dark:bg-gray-700 rounded text-lg font-bold p-2">
                        <span>Total: $ </span>
                        <span>{{ faltanteIngresado || 0 | number }}</span>
                    </div>
                </div>

                <div class="flex justify-center mb-4">
                    <div class="w-3/4">
                        <button (click)="saveFaltante()" class="btn p-2 w-full bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600">Guardar</button>
                    </div>
                </div>
            </div>

            <div class="flex flex-1 flex-col">
                <div class="flex w-full mb-4">
                    <span class="flex-1">Denominacion</span>
                    <span class="flex-1">Cantidad</span>
                    <span class="flex-1">Total</span>
                </div>

                <div *ngFor="let denominacion of denominacionFormato" class="flex space-x-2 mb-2">
                    <input class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 font-bold text-base dark:border-gray-600 dark:bg-slate-800 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" disabled value="{{ denominacion.cantidad | number }}" type="text">

                    <input #input id="f{{ denominacion.id }}" (change)="faltanteTotal($event, denominacion, input)" class="flex-1 w-6 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" type="number" min="0">

                    <input [value]="subtotales[denominacion.id] || 0 | number" class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-slate-800 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" disabled type="text">
                </div>
            </div>

            <div class="flex-1 text-center font-bold text-xs">
                <div class="px-4 overflow-y-auto">

                    <table class="border-collapse w-full border border-slate-400 dark:border-slate-500 bg-white dark:bg-slate-800 text-sm shadow-sm">
                        <thead class="bg-slate-50 dark:bg-slate-700">
                            <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">Fecha</th>
                            <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">Cantidad</th>
                        </thead>

                        <tbody *ngIf="faltantesPendientes.length > 0; else noFaltantes" class="font-semibold text-xs">
                            <tr *ngFor="let faltPend of faltantesPendientes">
                                <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">{{ faltPend.fecha_hora }}</td>
                                <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">${{ faltPend.faltanteFondo | number }}</td>
                            </tr>
                        </tbody>

                        <ng-template #noFaltantes>
                            <tbody class="font-semibold text-xs">
                                <td colspan="3" class="py-4">
                                    Sin faltantes de fondos
                                </td>
                            </tbody>
                        </ng-template>

                    </table>

                </div>
            </div>


        </div>

    </div>
</div>
