import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { Caseta, Denominacion, Devolucion } from '../../models';
import { selectCaseta, selectDevolucion, selectPermisos } from '../../state/selectors/caseta.selectors';
import { Router } from '@angular/router';
import { PanelIzquierdoState } from '../../core/models/panelIzquierdo.state';
import { selectPanelIzquierdo } from '../../state/selectors/panelIzquierdo.selectors';
import { addEvent, changeStatus, setStatus, StatusPanel, TypeBoton } from '../../state/actions/panelIzquierdo.actions';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { postDevolucion } from 'src/app/state/actions/devolucion.actions';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { PushNotificationService } from 'src/app/services/push-notification.service';
import { STATUS_NOTIFICATION, TYPE_NOTIFICATION, TYPE_PERMISO } from '../../utils/enum';
import { foundPermiso } from '../../utils';
import { CAT_EVENTO } from 'src/app/utils/enum';
import { OfflineOnlineService } from 'src/app/services/offline-online.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-devolucion-denominacion',
  templateUrl: './devolucion-denominacion.component.html',
  styleUrls: ['./devolucion-denominacion.component.scss']
})
export class DevolucionDenominacionComponent implements OnInit {
  devolucionDisponibles: Denominacion[] = [];
  devolucion = [];
  totales: number[] = [];
  total: number = 0;
  valor: number[] = [];
  devolucionLocal: Devolucion;
  devolucionStore: PanelIzquierdoState
  user: any;
  caseta: Caseta;
  //permisos
  permisos = [];
  //offline
  isOffline = false;

  constructor(private toastr: ToastrService, private store: Store<AppState>, private router: Router,
    private pushNotService: PushNotificationService,
    private offlineOnlineService: OfflineOnlineService,
    private ngxServiceLoader: NgxUiLoaderService

  ) {
  }

  ngOnInit(): void {

    //Get Caseta
    this.store.select(selectCaseta).subscribe(caseta => this.caseta = caseta);

    //Get User
    this.store.select(selectUser).subscribe(user => this.user = user);

    //Get status devolucion
    this.store.select(selectPanelIzquierdo).subscribe(selectPanelIzquierdo => this.devolucionStore = selectPanelIzquierdo);

    this.store.select(selectDevolucion).subscribe(denominacion => {
      this.devolucionDisponibles = denominacion;
    });

    // Permisos para retirar o devolucion por roles
    this.store.select(selectPermisos).subscribe(permiso => {
      this.permisos = permiso
      console.log(this.permisos);
    });

    //rollo
    this.offlineOnlineService.connectionChanged.subscribe(estatus => {
      this.isOffline = !estatus;
    });


  }

  // getResult(c, e) {
  //   this.total = 0;
  //   let cantidad = e;
  //   let denominacion = c.cantidad;
  //   let cantidadR = cantidad * denominacion;

  //   this.totales[c.id] = cantidadR;
  //   this.insertMorralla(c, Number(cantidad))

  //   //  suma totales
  //   for (let index in this.totales){
  //     if(this.totales[index]){
  //       console.log(this.totales[index])
  //       this.total += Number(this.totales[index]);
  //     }
  //   }

  //   //this.devolucion.push({ 'id': c.id, 'cantidad': cantidad })

  // }

  // insertMorralla(c: Denominacion, cantidad :  number){
  //   let index = this.devolucion.findIndex( m => m.id_denominacion === c.id);
  //   index === -1 ? this.devolucion.push({id_denominacion: c.id, cantidad, denominacion : c.total }) : this.devolucion[index].cantidad = cantidad;
  // }


  // addInput(c){
  //   this.valor[c.id] = this.valor[c.id]? this.valor[c.id] + 1:1;
  //   //console.log(this.valor);
  //   this.getResult(c,this.valor[c.id])
  // }

  // restarInput(c){
  //   this.valor[c.id] = this.valor[c.id]? this.valor[c.id] - 1:0;
  //   //console.log(this.valor);
  //   this.getResult(c,this.valor[c.id])
  // }

  // saveLocalDevolucion(id : number){
  //   let devolucion: Devolucion = {
  //     fecha_hora: moment().format('YYYY-MM-DD HH:mm:SS'),
  //     id_turno_usuario: 1,
  //     id_usuario_retira: 1,
  //     tipo:1, //'entrada'
  //     id_fondo:1,
  //     devolucion_cantidad: id
  //   };
  //   this.store.dispatch(postDevolucion({devolucion}));
  // }

  showFirma(id: number) {
    //guardar state id denominacion devolucion
    this.store.dispatch(addEvent({ type_boton: TypeBoton.DEVOLUCION, status: StatusPanel.PROCESO, id_denominacion: id }))
    if (foundPermiso(TYPE_PERMISO.DEVOLUCION, this.permisos).length > 0) {
      //tiene permiso de hacer devoluciones
      //CODIGO PARA GUARDAR DEVOLUCION
      this.saveLocalDevolucion()
    } else {
      this.router.navigate(['/mopa/firma', TypeBoton.DEVOLUCION])
    }

  }


  saveLocalDevolucion() {
    //obtner id de devolucion del store
    let devolucion: Devolucion = {
      fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
      id_corte_usuario: this.user.id_corte_usuario,
      id_usuario_retira: this.user.id_usuario, //operador
      tipo: 3, //'en transito'
      id_fondo: this.caseta?.fondo.id,
      devolucion_cantidad: this.devolucionStore.id_denominacion,
      id_cat_evento: CAT_EVENTO.DEVOLUCION
    };
    this.store.dispatch(postDevolucion({ devolucion }));
    this.store.dispatch(setStatus({ type_boton: TypeBoton.DEVOLUCION }));

    this.finished('Devolución');

  }

  finished(type: string) {

    this.toastr.success(`${type} guardado con éxito`, '', { positionClass: 'toast-bottom-right' });
    this.router.navigate(['/mopa/home']);
  }

  async notificacion(type: string, isCanceled = false) {

    this.ngxServiceLoader.start()
    try {
      let response = await this.pushNotService.sendNotification({ title: "MOPA", body: `${type}, Carril ${this.user.nom_carril}`, users: [this.user.id_jefe_turno], status: isCanceled ? STATUS_NOTIFICATION.CANCELADO : STATUS_NOTIFICATION.NOTIFICADO, type: TYPE_NOTIFICATION.DEVOLUCION })
      console.log(response);
      this.router.navigate(['/mopa/home']);
      this.toastr.success(`Notificando ${type}`, '', { positionClass: 'toast-bottom-right' });
      return true
      //change status
    } catch (error) {
      this.toastr.error('No se encontro conexión a Red', 'Red inestable',  { positionClass: 'toast-bottom-right' });
      return false
    } finally {
      this.ngxServiceLoader.stop()

    }
  }

  async cancelAction() {
    let result = false
    result = await this.notificacion('Acción cancelada Devolucion', true)
    if (result) {
      this.router.navigate(['/mopa/home']);
      //change status
      this.store.dispatch(changeStatus({ type_boton: TypeBoton.DEVOLUCION, status: StatusPanel.INITIAL }));
    }


  }
}
