<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase font-semibold text-base text-center text-gray-500 dark:text-gray-200">
    <div class="flex flex-col">
        <div class="flex justify-between items-center">
            <span></span>
            <span class="text-gray-500 dark:text-gray-50 py-2 font-bold">Usuarios</span>
            <button (click)="openModalCreate()" type="button" class="bg-gray-50 inline-flex items-center dark:bg-transparent rounded my-1 p-2 text-xs text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 hover:border-blue-600 uppercase">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
                </svg>
                <span class="ml-2">Nuevo Usuario</span>
            </button>
        </div>
        <hr class="mb-2 dark:border-gray-500">

        <!-- <div class="flex items-center mb-3">
            <span class="border-l border-t border-b rounded-l border-gray-300 dark:border-gray-600 p-1.5">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </span>
            <input type="text" class="w-full border rounded-r border-gray-300 placeholder-gray-500 p-2 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 text-left" placeholder="Buscar...">
        </div>

        <ul class="bg-white dark:bg-gray-800/50 rounded border border-gray-200 text-sm text-justify">
            <li *ngFor="let user of users" (click)="openModalEdit(user)" class="flex justify-between items-center px-4 py-2 border-b border-gray-200 cursor-pointer">
                <span>{{user.nombre}} {{user.apellido_materno}}-{{user.tipoUsuario.rol}}</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
            </li>
        </ul> -->
    
        <!--DATA TABLE -->
        <div *ngIf="dataSource != null;else cargandoData">
            <ngx-datatable
            class="material"
            [rows]="dataSource.data"
            [columns]="columns"
            [loadingIndicator]="loadingIndicator"
            [columnMode]="'force'" 
            [headerHeight]="50"
            [footerHeight]="80"
            [rowHeight]="'auto'"
            [externalPaging]="true"
            [count]="dataSource.meta.total"
            [offset]="dataSource.meta.current_page - 1"
            [limit]="dataSource.meta.per_page"
            (page)="setPage($event)">
            
            <!-- Nombre -->
              <ngx-datatable-column name="Nombre"  prop="nombre">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                  {{ value }}
                </ng-template>
              </ngx-datatable-column>
            <!-- Apellido Paterno -->
              <ngx-datatable-column name="Apellido" prop="apellido_paterno">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                   {{ value }}
                </ng-template>
              </ngx-datatable-column>
              <!-- Rol -->
              <ngx-datatable-column name="Rol" prop="tipoUsuario.rol">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                    {{ value }}
                 </ng-template>
              </ngx-datatable-column>
                <!-- Editar -->
                <ngx-datatable-column name="Editar" prop="id">
                    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                        <div class="place-items-center 2 border-b border-gray-200 cursor-pointer">
                            <svg  (click)="openModalEdit(row)"   xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                            </svg>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
          </ngx-datatable> 
        </div>
        <ng-template #cargandoData>
             Cargando Información
        </ng-template>
      

        <form [formGroup]="formSaveUser">
            <div *ngIf="modalOpen" class="modal-component-container fixed inset-0 overflow-y-auto">
                <div class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>

                    <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>

                    <div class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
                        <div class="modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

                            <div class="modal-wrapper-flex sm:flex sm:items-start">

                                <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">
                                    <div class="mt-0">
                                        <label for="nombre" class="block text-xs font-medium text-gray-700">Nombres(s)</label>
                                        <input type="text" formControlName="nombre"  name="nombre" id="nombre" class="block w-full px-3 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                    </div>

                                    <div>
                                        <label for="apellido_paterno" class="block text-xs font-medium text-gray-700">Apellido Paterno</label>
                                        <input type="text" formControlName="apellido_paterno" name="apellido_paterno" id="apellido_paterno" class="block w-full px-3 py-2 mt-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                    </div>

                                    <div>
                                        <label for="apellido_materno" class="block text-xs font-medium text-gray-700">Apellido Materno</label>
                                        <input type="text" formControlName="apellido_materno"   name="apellido_materno" id="apellido_materno" class="block w-full px-3 py-2 mt-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                    </div>

                                    <div>
                                        <label for="usuario" class="block text-xs font-medium text-gray-700">Usuario</label>
                                        <input type="text" formControlName="usuario"  name="usuario" id="usuario" class="block w-full px-3 py-2 mt-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                    </div>

                                    <div>
                                        <label for="numero_empleado" class="block text-xs font-medium text-gray-700">Numero de empleado</label>
                                        <input type="number" formControlName="num_empleado"  name="numero_empleado" id="numero_empleado" class="block w-full px-3 py-2 mt-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                    </div>

                                    <div>
                                        <label for="rol" class="block text-xs font-medium text-gray-700">Rol</label>
                                        <select id="rol" (change)="valideMultiAuxiliares($event)" formControlName="id_rol"  name="rol" class="appearance-none block w-full px-3 py-2 mt-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                            <option *ngFor="let tuser of typeUser" value="{{tuser.id}}" >{{tuser.rol}}</option>
                                        </select>
                                    </div>

                                    <div>
                                        <label for="id_caseta" class="block text-xs font-medium text-gray-700">Caseta Trocal</label>
                                        <ng-select  (change)="getAuxiliares($event,'save')" formControlName="casetas" class="custom">
                                          <ng-option *ngFor="let troncal of troncales" [value]="troncal.id">{{troncal.nombre}}
                                          </ng-option>
                                        </ng-select>
                                    </div>

                                    <div>
                                        <label for="id_caseta" class="block text-xs font-medium text-gray-700">Caseta Auxiliar</label>
                                        <ng-select formControlName="auxiliares"  [items]="auxiliares"
                                                bindLabel="nombre"
                                                bindValue="id"
                                                [multiple]="multiple"
                                                [groupBy]="groupingHelper">
                                        </ng-select>
                                    </div>

                                    <div>
                                        <label for="password" class="block text-xs font-medium text-gray-700">Password</label>
                                        <input (keyup)="validatePassword($event)" minlength="4" type="text" formControlName="password"  name="password" id="password" class="block w-full px-3 py-2 mt-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                    </div>

                                    <div>
                                      <label for="passwordR" class="block text-xs font-medium text-gray-700">Repetir Password</label>
                                      <input (keyup)="validatePasswordR($event)" type="password" formControlName="passwordR"  name="passwordR" id="passwordR" class="block w-full px-3 py-2 mt-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                      <p class="text-[10px] text-blue-600 text-center"
                                      *ngIf="campoNoValido('passwordR')">
                                          Las contraseñas deben ser iguales !
                                    </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-actions bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button [disabled]="!formSaveUser.valid" (click)="guardarUsuario()"  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-blue-700 font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Guardar</button>
                            <button (click)="cerrarModal()" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Cancelar</button>
                        </div>

                    </div>
                </div>
            </div>
        </form>

        <form [formGroup]="formEdidtUser">
            <div *ngIf="modalOpenEdit" class="modal-component-container fixed inset-0 overflow-y-auto">
                <div class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>

                    <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>

                    <div class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
                        <div class="modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

                            <div class="modal-wrapper-flex sm:flex sm:items-start">

                                <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">

                                    <div>
                                        <input type="text" formControlName="id" name="id" id="id" class="hidden w-full px-3 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                    </div>

                                    <div class="mt-0">
                                        <label for="nombre" class="block text-xs font-medium text-gray-700">Nombres(s)</label>
                                        <input type="text" formControlName="nombre"  name="nombre" id="nombre" class="block w-full px-3 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                    </div>

                                    <div>
                                        <label for="apellido_paterno" class="block text-xs font-medium text-gray-700">Apellido Paterno</label>
                                        <input type="text" formControlName="apellido_paterno" name="apellido_paterno" id="apellido_paterno" class="block w-full px-3 py-2 mt-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                    </div>

                                    <div>
                                        <label for="apellido_materno" class="block text-xs font-medium text-gray-700">Apellido Materno</label>
                                        <input type="text" formControlName="apellido_materno" name="apellido_materno" id="apellido_materno" class="block w-full px-3 py-2 mt-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                    </div>

                                    <div>
                                        <label for="usuario" class="block text-xs font-medium text-gray-700">Usuario</label>
                                        <input type="text" formControlName="usuario"  name="usuario" id="usuario" class="block w-full px-3 py-2 mt-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                    </div>

                                    <div>
                                        <label for="numero_empleado" class="block text-xs font-medium text-gray-700">Numero de empleado</label>
                                        <input type="number" formControlName="num_empleado"  name="numero_empleado" id="numero_empleado" class="block w-full px-3 py-2 mt-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                    </div>

                                    <div>
                                        <label for="estado" class="block text-xs font-medium text-gray-700">Estado</label>
                                        <select id="estado" formControlName="estado"  name="estado" class="appearance-none block w-full px-3 py-2 mt-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                            <option value="0">Inactivo</option>
                                            <option value="1">Activo</option>
                                        </select>
                                    </div>
                                    
                                    <div>
                                        <label for="rol" class="block text-xs font-medium text-gray-700">Rol</label>
                                        <select id="rol" formControlName="id_rol"  name="rol" class="appearance-none block w-full px-3 py-2 mt-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                            <option *ngFor="let tuser of typeUser" value="{{tuser.id}}" >{{tuser.rol}}</option>
                                        </select>
                                    </div>

                                    <div>
                                        <label for="id_caseta" class="block text-xs font-medium text-gray-700">Caseta</label>
                                        <ng-select (change)="getAuxiliares($event,'edit')"  formControlName="casetas" class="custom">
                                          <ng-option *ngFor="let caseta of troncales" [value]="caseta.id">{{caseta.nombre}}
                                          </ng-option>
                                        </ng-select>
                                    </div>

                                    <div>
                                        <label for="id_caseta_auxiliar" class="block text-xs font-medium text-gray-700">Caseta Auxiliar</label>
                                        <ng-select formControlName="auxiliares"  [items]="auxiliares"
                                                bindLabel="nombre"
                                                bindValue="id"
                                                [multiple]="multiple">
                                        </ng-select>
                                    </div>

                                  

                                    <div>
                                        <label for="password" class="block text-xs font-medium text-gray-700">Password</label>
                                        <input type="password" formControlName="password"  name="password id="password class="block w-full px-3 py-2 mt-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                    </div>

                                    <div>
                                      <label for="password" class="block text-xs font-medium text-gray-700">Repetir Password</label>
                                      <input type="password" formControlName="passwordR"  name="password id="password class="block w-full px-3 py-2 mt-1 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                  </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-actions bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button [disabled]="!formEdidtUser.valid" (click)="editarUsuario()" id="close-modal" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-blue-700 font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Editar</button>
                            <button (click)="cerrarModal()" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Cancelar</button>
                        </div>

                    </div>
                </div>
            </div>
        </form>

    </div>
</div>
