
const BASE_API = 'https://api.op.sogak.com.mx' //'https://api.mopadev.sogak.com.mx'
const BASE_API_PUSH = 'https://api.pushmopadev.sogak.com.mx'
export const environment = {
  production: false,
  ENDPOINTS:{
    SUBTIPO: BASE_API + '/api/subtipo-cruce',
    EVENTO: BASE_API + '/api/evento',
    CASETA: BASE_API + '/api/caseta',
    CATOBSERVACION: BASE_API + '/api/cat-observacion',
    CORTEUSUARIO: BASE_API + '/api/corte',
    DEVOLUCION: BASE_API + '/api/devolucion',
    FICHADEPOSITO: BASE_API + '/api/getFileDesposito',
    RETIRO: BASE_API + '/api/retiro',
    TIPOVEHICULO: BASE_API + '/api/tipo-vehiculo',
    MORRALLA: BASE_API + '/api/morralla',
    TIPO_CRUCE: BASE_API + '/api/tipo-cruce',
    USUARIO: BASE_API + '/api/user',
    TIPOUSUARIO: BASE_API + '/api/type-user',
    LOGIN: BASE_API + '/api/login',
    ROLLO: BASE_API + '/api/rollo',
    PUSH_NOTIFICATION : BASE_API_PUSH +'/api/push',
    FONDO: BASE_API + '/api/fondo',
    NOTIFICACIONES_PUSH: BASE_API + '/api/notificaciones-push',
    LOGUSUARIO: BASE_API + '/api/log-usuario',
    CORTEACTUAL: BASE_API + '/api/cortes-actuales',
    INSTALACION: BASE_API + '/api/get-cortes-by-caseta',
    CORTEUSERS: BASE_API + '/api/get-cortes-by-usuario',
    CHANGEPASSWORD: BASE_API + '/api/update-passwort-user',
    CARRILCLOSED: BASE_API +  '/api/carril/closed',
    CAT_MENU_PERMISOS : BASE_API + '/api/menu-permisos',
    FORMACION: BASE_API + '/api/formacion',
    APERTURACARRIL: BASE_API + '/api/apertura-carril',
    USUARIO_GENERA_FALTANTE: BASE_API + '/api/get-usuario-by-corte',
    FALTANTE: BASE_API + '/api/guardar-faltante',
    MORRALLABYJT: BASE_API + '/api/morralla-by-jt/',
    USUARIO_FALTANTE: BASE_API + '/api/get-usuario-faltante',
    LOG_INICIO: BASE_API + '/api/get-log-by-usuario',
    FALTANTETOTAL: BASE_API + '/api/get-faltantes',
    CONTROLFOLIO: BASE_API + '/api/control-folio',
    SAVEMORRALLACARRIL: BASE_API + '/api/saveMorralla',
    MORRALLATOTAL: BASE_API + '/api/getMorrallaTotal',
    PAGOMORRALLA: BASE_API + '/api/pago-morralla',
    FONDO_PDF: BASE_API + '/api/get-fondo-carril',
    PDF: BASE_API + '/api/createReportePDF',
    PRELIQUIDACION: BASE_API + '/api/getPreliquidacionPDF',
    ACTAADMINISTRATIVAPDF : BASE_API + '/api/get-actaPDF',
    BITACORA: BASE_API + '/api/bitacora',
    CAT_EVENTO: BASE_API + '/api/cat-evento',
    HEALTH: BASE_API + '/api/health',
    DENOMINACION: BASE_API + '/api/denominacion',
    PDFDEVOLUCIONFONDO: BASE_API + '/api/createFondoPDF',
    CATBITACORA: BASE_API + '/api/cat-bitacora',
    CONFIGURACION: BASE_API + '/api/configuracion',

  },
  PUSH:{
    VAPID_PUBLIC_KEY : 'BMfIlHKKrOQYy_W__omjJnUWA15nuXd2u7caeqLYzpQ4kEBOF75PCwJ6lsCLgT01S8RCXtKE8IosrbtXvSgbMB4'
  },
  WS: 'https://ws.sogak.com.mx'
};
