import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface FormatoData {
  id_corte_usuario: number,
  tipo: number
}

@Injectable({
  providedIn: 'root'
})
export class FormatoService {

  private sendData: BehaviorSubject<FormatoData> = new BehaviorSubject<FormatoData>({
    id_corte_usuario: 0,
    tipo: 0
  });

  get obtenerData() {
    return this.sendData.asObservable();
  }

  set modificarData(data: FormatoData) {
    this.sendData.next(data);
  }

  constructor() { }

}
