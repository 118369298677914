import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-switch-theme',
  templateUrl: './switch-theme.component.html',
  styleUrls: ['./switch-theme.component.scss']
})
export class SwitchThemeComponent implements OnInit {

  isDarkEnable = true;

  constructor() { }

  ngOnInit(): void {
    // Check Current Theme
    if (localStorage.getItem('colorMode') === 'dark') {
      document.documentElement.classList.add('dark');
      this.isDarkEnable = true;
    } else if (localStorage.getItem('colorMode') === 'light') {
      document.documentElement.classList.remove('dark');
      this.isDarkEnable = false;
    }
  }

  changeTheme(btnMode) {
    if (btnMode === 1) {
      localStorage.setItem('colorMode', 'light');
      document.documentElement.classList.remove('dark');
      this.isDarkEnable = false;
    } else {
      localStorage.setItem('colorMode', 'dark');
      document.documentElement.classList.add('dark');
      this.isDarkEnable = true;
    }
  }

}
