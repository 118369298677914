import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CorteService } from 'src/app/services/corte.service';
import { FormatoData, FormatoService } from 'src/app/services/formato.service';
import { RolloService } from 'src/app/services/rollo.service';

@Component({
  selector: 'app-formato-corte',
  templateUrl: './formato-corte.component.html',
  styleUrls: ['./formato-corte.component.scss']
})
export class FormatoCorteComponent implements OnInit {

  corte: any[];
  rollos: any[];
  aforoTotal: number = 0;
  aforoFinal: number = 0;
  fondoCobrador: number = 0;
  eludido: number = 0;
  exentoResidente: number = 0;
  boletoRecibido: number = 0;
  folioTotal: number = 0;
  noEmitidos: number = 0;
  cancelados: number = 0;
  efectivo: any[];
  editFolioModal: boolean = false;
  data$: Observable<FormatoData>;
  id_rollo: number = 0;
  finalise = new Subject();

  foliosForm = new FormGroup({
    folioInicial: new FormControl('', [Validators.required, Validators.maxLength(8)]),
    folioFinal: new FormControl('', [Validators.required, Validators.maxLength(8)]),
  });

  constructor(
    private formatoService: FormatoService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private toastr: ToastrService,
    private rolloService: RolloService,
    private corteService: CorteService
  ) { 
    this.data$ = formatoService.obtenerData;
  }

  ngOnInit(): void {

    this.data$.pipe(takeUntil(this.finalise)).subscribe( data => {
      if ( data.tipo === 1 ) {
        this.corteService.getCorteCorte( data.id_corte_usuario ).subscribe( corte => {
          console.log( corte );
          this.rollos = corte['rollo_consumo'];
          this.efectivo = corte['efectivo'];

          // fondo cobrador
          this.fondoCobrador = corte['fondo_cobrador'];

          // folios consumidos
          for (let rollo of this.rollos) {
            this.folioTotal += ((rollo.folio_final - rollo.folio_inicial) + 1);
          }
          this.noEmitidos = corte['folio_consumido'][0].noemitido;
          this.cancelados = corte['folio_consumido'][0].cancelado;
          this.aforoTotal = this.folioTotal + (this.noEmitidos - this.cancelados);

          // concentrado de aforo
          this.exentoResidente = corte['aforo'].exentoResidente;
          this.eludido = corte['aforo'].eludido;
          this.boletoRecibido = corte['aforo'].boletoRecibido;
          this.aforoFinal = this.aforoTotal + this.exentoResidente + this.eludido + this.boletoRecibido;


        });
      }
      this.finalise.next();
      this.finalise.complete();
    });
    
  }

  getRolloId(id_rollo: number) {
    this.id_rollo = id_rollo;
    console.log( 'Rollo ID', id_rollo );
  }

  updateFolios() {
    if (!this.foliosForm.valid) { return this.toastr.error('Por favor, ingrese los folios', 'Error', { positionClass: 'toast-bottom-right' } ) }

    let data = {
      id: this.id_rollo,
      folio_inicial: Number(this.foliosForm.value.folioInicial),
      folio_final: Number(this.foliosForm.value.folioFinal)
    }

    this.ngxUiLoaderService.start();

    this.rolloService.updateFolios( data ).subscribe( data => {

      this.toastr.success('Se actualizaron los folios del corte', '', {positionClass: 'toast-bottom-right'});
      this.editFolioModal= false;

      window.location.reload()

      this.ngxUiLoaderService.stop();

    });
    this.ngxUiLoaderService.stop();

    // console.log( data, "DATA ACTUALIZADA" );
  }

}
