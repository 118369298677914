<div class="flex items-center min-h-screen p-6 bg-gray-100 dark:bg-gray-900">
    <div class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
      <div class="flex flex-col overflow-y-auto md:flex-row">
        <div class="flex items-center justify-center p-6 sm:p-12 w-full">
          <div class="w-full">

            <div class="flex justify-between">

                <div>
                    <img aria-hidden="true" class="mx-auto h-6 w-auto dark:hidden" src="/assets/images/sogak-logo-light.svg" alt="SOGAK"/>
                    <img aria-hidden="true" class="hidden mx-auto h-6 w-auto dark:block" src="/assets/images/sogak-logo-dark.svg" alt="SOGAK"/>
                </div>

                <app-switch-theme></app-switch-theme>

            </div>

            <hr class="mt-4 mb-4 border-gray-200 dark:border-gray-700"/>

            <div class="uppercase sm:w-3/4 md:w-1/2 mx-auto">
              <h1 class="mt-0 mb-6 text-base text-center font-semibold dark:text-gray-200">Capturar fondo de auxiliar</h1>
              <!-- <app-teclado-numerico placeholder="Ingrese monto" [value]="monto | number" type="text" (change)="montoFondo($event)"></app-teclado-numerico> -->
              <app-teclado-numerico placeholder="Ingrese monto" [value]="monto" (change)="onChange($event)"></app-teclado-numerico>

              <button *ngIf="botonConfirmar" class="bg-gray-50 mt-4 w-full uppercase dark:bg-transparent rounded h-12 text-base py-1 text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 dark:border-gray-500 drop-shadow-lg hover:border-blue-600 dark:hover:border-blue-600" (click)="showHome()">Confirmar</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
