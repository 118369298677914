import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserService } from 'src/app/services/user.service';
import { AppState } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/selectors/user.selectors';

@Component({
  selector: 'app-cambio-password',
  templateUrl: './cambio-password.component.html',
  styleUrls: ['./cambio-password.component.scss']
})
export class CambioPasswordComponent implements OnInit {
  formPasswordUser: FormGroup;
  user: any;
  passCheck:boolean = false;
  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private store: Store<AppState>,
    private ngxUiLoaderService: NgxUiLoaderService,

  ) { }

  ngOnInit(): void {
    this.createForms();
    this.store.select(selectUser).subscribe(user => this.user = user);
    console.log(this.user);

  }
  createForms() {
    this.formPasswordUser = this.formBuilder.group({
      password: ['', [Validators.required,Validators.min(4), Validators.pattern("^[0-9]*$")]],
      passwordR: ['',[Validators.required,Validators.pattern("^[0-9]*$")]]
    },{
      validators: [ this.camposIguales('password','passwordR')]
    });
}


  updatePassword() {
    // console.log("entro");
    // if (this.formPasswordUser.value.password == this.formPasswordUser.value.passwordR) {
      this.ngxUiLoaderService.start();
      this.userService.updatePasswordUser(this.user.id, this.formPasswordUser.value).
        subscribe(user => {
          this.toastr.success('Usuario creado con éxito', '', { positionClass: 'toast-bottom-right' })
        })
      this.ngxUiLoaderService.stop();

    // } else {
    //   this.toastr.error('El Password no coincide', '', { positionClass: 'toast-bottom-right' })
    // }
  }
   
  campoNoValido(campo:string){
    return this.formPasswordUser.get(campo)?.invalid
    && this.formPasswordUser.get(campo)?.touched;
  }

  camposIguales(password:string, passwordR:string){
    return(formGroup:AbstractControl): ValidationErrors | null => {
      console.log(formGroup);
      const pass1 = formGroup.get(password)?.value;
      const passR = formGroup.get(passwordR)?.value;
      if (pass1 !== passR) {
        formGroup.get(passwordR)?.setErrors({ noIguales: true})
        return { noIguales:true }
      }
      formGroup.get(passwordR)?.setErrors(null);
      return null;
    }
  }

  validatePasswordR() {
    if (this.campoNoValido('passwordR')) {
      this.toastr.error('Las contraseñas no coinciden', '', { positionClass: 'toast-bottom-right' })
    }else{
      this.toastr.success('Contraseñas correctas', '', { positionClass: 'toast-bottom-right' })
    }
  }

  validatePassword(string) {//Solo numeros
    let consecutivos = false;
    let duplicado = false;
    let numeros = [...string.target.value];//convertir a array
    if (numeros.length > 1) {
      duplicado = this.validarRepetitivos(string); //validar repetitivos maximo 3;
      if (duplicado) {
        this.toastr.error('Repite máximo 3 veces el mismo digito', '', { positionClass: 'toast-bottom-right' });
        this.formPasswordUser.controls['password'].setValue(string.target.value.slice(0, -1)); //eliminar el ultimo digito de input
      }else{
        if (numeros.length % 2 != 0) {
          consecutivos = this.validarConsecutivos(string);
          if (consecutivos) {
            this.toastr.error('No se aceptan numeros consecutivos', '', { positionClass: 'toast-bottom-right' })
            this.formPasswordUser.controls['password'].setValue(string.target.value.slice(0, -1)); //eliminar el ultimo digito de input
          }
        }
      }
    }  
  }


  validarConsecutivos(passValue){
    let numeros = [...passValue.target.value];//convertir a array
    let antepenultimo = numeros[numeros.length -3];
    let penultimo = numeros[numeros.length - 2];   
    let lastNum = numeros[numeros.length - 1]; 
    let totalpenultimo =  antepenultimo - penultimo; 
    let total = penultimo - lastNum;
      if ((totalpenultimo == 1 || totalpenultimo == -1) && (total == 1 || total == -1)) { //validar consecutivos
        return  true;
      }else{
        return false;
      }
  }
   
  validarRepetitivos(password){
    let numeros = [...password.target.value];//convertir a array
    let digitoActual =  numeros[numeros.length - 1]; ;
    let duplicadoArray = [];
    if (numeros.indexOf(digitoActual) != -1) { //buscamos el ultimo valor
      for (let i = 0; i < numeros.length; i++) {
        if (numeros[i] == numeros[i + 1] && numeros[i] == digitoActual) {
          duplicadoArray.push(numeros[i]);
        }
      }
    }
    return duplicadoArray.length >= 3 ? true : false;
  }

}
