import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CorteUsuario, Retiro } from 'src/app/models';
import { FondoDenominacion } from 'src/app/models/dash';
import { AsignarFondoService } from 'src/app/services/asignar-fondo.service';
import { CorteService } from 'src/app/services/corte.service';
import { RetiroService } from 'src/app/services/retiro.service';
import { AppState } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { TYPE_ROL } from 'src/app/utils/enum';

@Component({
  selector: 'app-ultimo-efectivo',
  templateUrl: './ultimo-efectivo.component.html',
  styleUrls: ['./ultimo-efectivo.component.scss']
})
export class UltimoEfectivoComponent implements OnInit {

  denominaciones: FondoDenominacion[] = [];
  denominacionFormato: FondoDenominacion[] = [];
  montoFinal: number = 0;
  monto = [];

  cortes: any
  cortesPagados: any
  user: any;
  formSaveMonto: FormGroup;
  formUpdateMonto: FormGroup;
  subtotales: number[] = [];
  hiddenMontos = true;

  @ViewChild('inputUR') inputUR!: ElementRef<HTMLInputElement>;

  constructor(
    private asignarFondo: AsignarFondoService,
    private corteService: CorteService,
    private retiroService: RetiroService,
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private ngxUiLoaderService: NgxUiLoaderService,
    public asignarFondoService: AsignarFondoService,

  ) { }

  ngOnInit(): void {
    this.store.select(selectUser).subscribe(user => this.user = user);
    // obtener fondo para conjunto de casetas
    this.asignarFondoService.getFondoDenominacion(this.user.caseta_usuario[0].id).then(() => {
      this.denominacionFormato = this.asignarFondoService.fondoDenominacion.slice(0, 10).reverse();
    });

    this.getData();
    this.createForms()
  }

  // TODO: REVISAR
  // inputFormat() {
  //   let cantidad = this.inputUR.nativeElement.value;
  //   cantidad = cantidad.replace(/[^0-9]/g, '');
  //   console.log(cantidad);
  //   if (cantidad != "") {
  //     let amountArr = cantidad.split('.');
  //     amountArr[0] = (parseInt(amountArr[0], 10)).toLocaleString();
  //     cantidad = amountArr.join('.');
  //   }
  //   this.inputUR.nativeElement.value = cantidad;
  // }

  getData() {
    this.ngxUiLoaderService.start()
    if (this.user.tipoUsuario.id == TYPE_ROL.CARRIL) {
      //cortes que no tienen ultimo efectivo
      this.corteService.getCortesUser(this.user.id)
        .subscribe(cortes => {
          this.cortes = cortes;
        })
        //cortes que tienen ultimo efectivo
        this.corteService.getCortesPagados(this.user.id)
        .subscribe(cortes => {
          this.cortesPagados = cortes;
        })
    } else {
      this.corteService.getCorteUsuarioFromJT(this.user.id)
        .subscribe(cortes => {
          this.cortes = cortes;
        })
    }
    this.ngxUiLoaderService.stop()

  }

  createForms() {
    this.formSaveMonto = this.formBuilder.group({
      id_corte_usuario: ['', Validators.required],
      monto: ['', Validators.required],
      //montoConfirmado: ['', Validators.required],

    });

    this.formUpdateMonto = this.formBuilder.group({
      id_retiro: ['', Validators.required],
      monto: ['', Validators.required],

    });
  }

  navSection(s: number) {
    switch (s) {
      case 1:
        this.router.navigate(['dashboard/cobrador/ultimo-efectivo']);
        break;
      case 2:
        this.router.navigate(['dashboard/cobrador/cambio-password']);
        break;
      default:
        break;
    }
  }

  saveMonto() {

    if (this.formSaveMonto.value.monto == 0 || !this.formSaveMonto.value.monto || this.formSaveMonto.value.monto == null || this.formSaveMonto.value.id_corte_usuario == 0) {
      return this.toastr.error(`Seleccione el Corte y Monto`, '', { positionClass: 'toast-bottom-right' });
    }

    // if (this.formSaveMonto.value.monto != this.formSaveMonto.value.montoConfirmado) {
    //   return  this.toastr.error(`Las cantidades no coinciden`, '', { positionClass: 'toast-bottom-right' });
    // }
    this.ngxUiLoaderService.start();
    let retiro: Retiro = {
      fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
      id_corte_usuario: this.formSaveMonto.value.id_corte_usuario,
      id_usuario_retira: this.user.id,
      id_denominacion: null,
      monto: this.formSaveMonto.value.monto
    };
    this.retiroService.saveRetiro(retiro).
      subscribe(data => {
        this.ngxUiLoaderService.stop();
        this.getData();
        this.finished('Retiro')

      })
  }
  updateMonto() {
    console.log(this.formUpdateMonto.value.monto  ,this.formUpdateMonto.value.id_retiro );

    if (!this.formUpdateMonto.value.monto || !this.formUpdateMonto.value.id_retiro ) {
      return this.toastr.error(`Seleccione el Corte y Monto`, '', { positionClass: 'toast-bottom-right' });
    }

    this.ngxUiLoaderService.start();
    let retiro: any = {
      id_retiro: this.formUpdateMonto.value.id_retiro,
      monto: this.formUpdateMonto.value.monto
    };
    this.retiroService.updateMontoRetiro(retiro).
      subscribe(data => {
        this.ngxUiLoaderService.stop();
        this.getData();
        this.finished('Monto')

      })
  }

  finished(type: string) {
    this.toastr.success(`${type} guardado con éxito`, '', { positionClass: 'toast-bottom-right' });
    this.formSaveMonto.reset();
    this.formUpdateMonto.reset();
    this.monto = []
    this.montoFinal = 0;
    this.subtotales = []
    this.resetForm();
    this.router.navigate(['/dashboard/ultimo-efectivo']);
  }

  montoTotal(event: any, denominacion) {
    this.montoFinal = 0;
    let cantidad = Number(event.target.value);
    let subTotal = cantidad * (denominacion.cantidad);
    this.subtotales[denominacion.id] = subTotal;

    // total
    for (let total in this.subtotales) {
      if (this.subtotales[total]) {
        this.montoFinal += Number(this.subtotales[total]);
        this.formSaveMonto.patchValue({ monto: this.montoFinal })
        this.formUpdateMonto.patchValue({ monto: this.montoFinal })
      }
    }


    this.insertmonto(cantidad, denominacion)
  }

  insertmonto(cantidad: number, denominacion: any) {
    let index = this.monto.findIndex(m => m.id_denominacion === denominacion.id);
    index === -1 ? this.monto.push({
      id_denominacion: denominacion.id,
      cantidad: cantidad,
    }) : this.monto[index].cantidad = cantidad;

    console.log(this.monto);
  }

  updateMontosCortes(){
    this.formSaveMonto.reset();
    this.formUpdateMonto.reset();
    this.hiddenMontos ? this.hiddenMontos=false : this.hiddenMontos=true;
  }

  resetForm() {
    this.formSaveMonto.reset({id_corte_usuario: '', monto: ''});
    this.formUpdateMonto.reset({id_retiro: '', monto: ''});
    let inputs = document.querySelectorAll('.montoUF');
    inputs.forEach( input => {
      ( input as HTMLInputElement ).value = "";
    });
  }

}
