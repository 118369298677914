import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.scss']
})
export class ConfiguracionComponent implements OnInit {
  openTab = 1;
  dataTransportistas:any
  constructor(
    ) { }

  ngOnInit(): void {

  }

  toggleTabs($tabNumber: number){
    this.openTab = $tabNumber;
    if ($tabNumber==2) {
      this.consultarTransportistas()
    }
  }
  consultarTransportistas() {


  }

}
