<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 h-fit uppercase text-lg text-gray-500 dark:text-gray-200">
    <div class="m-2">
        <div class="flex flex-col">

            <div class="flex text-base text-center font-semibold">
                <div class="flex flex-col flex-1">

                    <div class="flex justify-between items-center px-4 mb-2">
                        <p class="mb-2">Retiros Recientes</p>

                        <button id="imprimirReciente" (click)="openModal(1)" type="button" class="bg-gray-50 inline-flex items-center dark:bg-transparent rounded p-2 text-xs text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 uppercase">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                            </svg>
                            <span class="ml-2">Imprimir</span>
                        </button>
                    </div>

                    <hr class="mb-2 mx-4 dark:border-gray-500">

                    <div class="flex-1 text-center font-bold text-xs">
                        <div class="px-4 overflow-y-auto">

                            <table class="w-full">

                                <thead>
                                    <tr class="border-b-2 border-gray-200 dark:border-gray-600 bg-gray-100 dark:bg-gray-900/50 uppercase font-semibold text-xs text-center text-gray-600 dark:text-gray-300">
                                        <th class="px-2 py-3">Carril</th>
                                        <th class="px-2 py-3">Hora</th>
                                        <th class="px-2 py-3">Importe</th>
                                        <th class="px-2 py-3">Cobrador</th>
                                        <th class="px-2 py-3">Eliminar</th>
                                    </tr>
                                </thead>

                                <tbody *ngIf="retirosParciales.length > 0; else noRetirosRecientes" class="border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-900/50 text-gray-900 dark:text-gray-300 text-sm text-center">
                                    <tr *ngFor="let retiroParcial of retirosParciales" class="border-b border-gray-200 dark:border-gray-600">
                                        <td class="px-2 py-3">{{ retiroParcial.carril }}</td>
                                        <td class="px-2 py-3">{{ retiroParcial.fecha_hora }}</td>
                                        <td class="px-2 py-3">${{ retiroParcial.importe | number }}</td>
                                        <td class="px-2 py-3">{{ retiroParcial.cobrador }}</td>
                                        <th class="px-2 py-3">

                                            <button (click)="deleteModal = true; getRetiroId(retiroParcial.id_retiro)" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md p-1.5 mt-3 bg-white hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </button>

                                        </th>
                                    </tr>
                                </tbody>

                                <ng-template #noRetirosRecientes>
                                    <tbody class="font-semibold text-xs">
                                        <td colspan="4" class="py-4">
                                            Sin impresiones recientes
                                        </td>
                                    </tbody>
                                </ng-template>
                            </table>

                        </div>
                    </div>

                </div>

                <div class="flex flex-col flex-1">

                    <div class="flex justify-between items-center px-4 mb-2">
                        <p class="mb-2">Historial de retiros parciales</p>

                        <div>
                            <button (click)="consultarRonda()" type="button" class="bg-gray-50 inline-flex items-center dark:bg-transparent rounded p-2 text-xs text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 uppercase">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                </svg>
                                <span class="ml-2">Consultar</span>
                            </button>

                            <button (click)="openModal(2)" type="button" class="bg-gray-50 inline-flex ml-2 items-center dark:bg-transparent rounded p-2 text-xs text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 uppercase">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                                </svg>
                                <span class="ml-2">Reimpresion</span>
                            </button>
                        </div>

                    </div>

                    <hr class="mb-2 mx-4 dark:border-gray-500">

                    <div [formGroup]="retiroForm" class="flex justify-between items-center px-4 mb-2 text-xs">

                        <div class="flex items-center">
                            <span class="sr-only">Fecha:</span>
                            <input (change)="retiroFormReset(1)" formControlName="fecha" type="date" class="block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            placeholder="Seleccionar fecha" />
                        </div>

                        <div class="flex items-center">
                            <span class="sr-only">Turno:</span>
                            <select (change)="retiroFormReset(2)" formControlName="turno" class="appearance-none block p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                <option value="" selected disabled>Seleccionar turno</option>
                                <option *ngFor="let turno of turnos" value="{{ turno.id }}">{{ turno.turno }}</option>
                            </select>
                        </div>

                        <div class="flex items-center">
                            <span class="sr-only">Ronda:</span>
                            <select formControlName="ronda" class="appearance-none block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                                <option value="" selected disabled>Seleccionar ronda</option>
                                <option *ngFor="let ronda of rondas" value="{{ ronda.ronda }}">{{ ronda.ronda }}</option>
                            </select>
                        </div>

                    </div>

                    <hr class="mb-2 mx-4 dark:border-gray-500">

                    <div class="flex-1 text-center font-bold text-xs">
                        <div class="px-4 overflow-y-auto">

                            <table class="w-full">

                                <thead>
                                    <tr class="border-b-2 border-gray-200 dark:border-gray-600 bg-gray-100 dark:bg-gray-900/50 uppercase font-semibold text-xs text-center text-gray-600 dark:text-gray-300">
                                        <th class="px-2 py-3">Carril</th>
                                        <th class="px-2 py-3">Hora</th>
                                        <th class="px-2 py-3">Importe</th>
                                        <th class="px-2 py-3">Cobrador</th>
                                    </tr>
                                </thead>

                                <tbody *ngIf="retirosReimpresion.length > 0; else noReimpresion" class="border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-900/50 text-gray-900 dark:text-gray-300 text-sm text-center">
                                    <tr *ngFor="let reImpresion of retirosReimpresion" class="border-b border-gray-200 dark:border-gray-600">
                                        <td class="px-2 py-3">{{ reImpresion.carril }}</td>
                                        <td class="px-2 py-3">{{ reImpresion.fecha_hora }}</td>
                                        <td class="px-2 py-3">${{ reImpresion.importe | number }}</td>
                                        <td class="px-2 py-3">{{ reImpresion.cobrador }}</td>
                                    </tr>
                                </tbody>

                                <ng-template #noReimpresion>
                                    <tbody class="font-semibold text-xs">
                                        <td colspan="4" class="py-4">
                                            Configure una busqueda para retiros
                                        </td>
                                    </tbody>
                                </ng-template>
                            </table>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>


<div *ngIf="isOpenModal" class="modal-component-container fixed inset-0 overflow-y-auto text-gray-500">
  <div class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>
      <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>
      <div class="modal-container inline-block align-bottom bg-white text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
          <div class="modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="modal-wrapper-flex sm:flex sm:items-start">
                  <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">
                      <div class="flex items-center justify-between">

                        <span>&nbsp;</span>

                        <button (click)="openModal()" class=" oculto-impresion w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md p-1.5 mt-3 bg-white hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>

                      </div>

                      <hr class="oculto-impresion">
                      <center>
                      <app-print-retiro></app-print-retiro>
                      </center>
                      <hr class="mx-auto pt-4 w-44">
                      <div class="float-right">
                      <!-- <button  (click)="imprimirPDF(true)" type="button" class="bg-gray-50 inline-flex items-center dark:bg-transparent rounded p-2 text-xs text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 uppercase">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                          </svg>
                          <span class="ml-2">Descargar</span>
                      </button> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div *ngIf="deleteModal" class="modal-component-container fixed inset-0 overflow-y-auto">
    <div
      class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>
  
      <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>
  
      <div
        class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
        <div class="modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
  
          <div class="modal-wrapper-flex sm:flex sm:items-start">
  
            <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">
  
              <div class="flex items-center justify-between">
                <div class="flex items-center rounded py-2">
                  <span class="pl-2">¿Estás seguro de eliminar este la retiro?</span>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="modal-actions bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button (click)="deleteModal = false"
                  class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Cerrar
          </button>
          <button (click)="deleteRetiro()"
                  class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Aceptar
          </button>
        </div>
  
      </div>
    </div>
  </div>
