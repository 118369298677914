<div
  class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase font-semibold text-base text-center text-gray-500 dark:text-gray-200">
  <div class="flex flex-col">

    <nav
      class="flex py-1 px-2 text-gray-700 text-xs bg-gray-50 rounded border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <ol class="inline-flex items-center space-x-1">
        <li class="inline-flex items-center">
          <span class="font-bold dark:text-gray-400">Nuevo registro</span>
        </li>
        <li>
          <div class="flex items-center">
            <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"></path>
            </svg>
            <a routerLink="/dashboard/bitacora"
              class="ml-1 inline-flex items-center font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
              Búsqueda
            </a>
          </div>
        </li>
      </ol>
    </nav>


    <div class="flex justify-between items-center my-1">
      <span></span>
      <span class="flex-1 text-gray-500 dark:text-gray-50 py-2 font-bold">BITÁCORA NUEVO REGISTRO</span>
      <span></span>
    </div>

    <hr class="dark:border-gray-500 mb-4">

    <div class="grid-col-1 justify-between">
      <div class="grid grid-cols-3 md:gap-6">
        <div *ngFor="let padres of catPadres" class="rounded bg-gray-100 dark:bg-gray-700 p-2 font-medium"><label
            for="1" (click)="showBitadoraForm(padres.id)"
            class="block cursor-pointer select-none rounded p-2 text-center peer-checked:bg-blue-500 peer-checked:font-semibold peer-checked:text-white">{{padres.nombre}}</label>
        </div>

      </div>
      <br>
      <div class="flex flex-1 flex-col">
        <div  class="flex w-full justify-center mb-4">
            <div class="w-3/4 rounded bg-gray-100 dark:bg-gray-700 p-2 font-medium gap-6">
            <div *ngFor="let tipos of catTipos">
              <label for="1" (click)="showBCategorias(tipos.tipo)"
                class="block cursor-pointer select-none rounded p-2 text-center peer-checked:bg-blue-500 peer-checked:font-semibold peer-checked:text-white">{{tipos.tipo}}</label>
            </div>
          </div>

        </div>
      </div>


      <div class="flex-1">
        <!-- categorias -->
        <form [formGroup]="bitacoraForm">
          <div class="space-y-2">

            <div class="flex justify-center">
              <select formControlName="id_cat_bitacora"
                class="appearance-none w-3/4 block p-2 text-sm font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                <option value="" selected disabled>Categoria</option>
                <option *ngFor="let cat of categorias" value="{{cat.id}}">{{cat.nombre}}</option>

              </select>
            </div>
            <div *ngIf="bitacoraForm.value.id_cat_bitacora">
              <div class="flex w-3/4 mx-auto items-center space-x-2 mb-2">

                <select formControlName="id_caseta" (change)="getCaseta($event)"
                  class="flex-1 appearance-none w-3/4 block p-2 text-sm font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                  <option value="" selected disabled>Seleccionar caseta</option>
                  <option *ngFor="let caseta of casetas" value="{{caseta.id}}">{{caseta.nombre}}</option>
                </select>

                <select formControlName="id_carril" *ngIf="bitacoraForm.value.id_cat_bitacora !=  14"
                  class="flex-1 cursor-pointer ppearance-none w-3/4 block p-2 text-sm font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                  <option value="" selected disabled>Seleccionar carril</option>
                  <option *ngFor="let carril of carriles" value="{{carril.id}}">{{carril.nombre}}</option>
                </select>

                <select formControlName="id_turno"
                  class="flex-1 cursor-pointer ppearance-none w-3/4 block p-2 text-sm font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                  <option value="" selected disabled>Seleccionar turno</option>
                  <option value="1">Primero</option>
                  <option value="2">Segundo</option>
                  <option value="3">Tercero</option>
                </select>
              </div>

              <div class="flex justify-center" *ngIf="bitacoraForm.value.id_cat_bitacora ==  15 || bitacoraForm.value.id_cat_bitacora ==  16 || bitacoraForm.value.id_cat_bitacora ==  17">
                <select formControlName="id_cobrador"
                  class="appearance-none w-3/4 block p-2 text-sm font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                  <option value="" selected disabled>Cobrador</option>
                  <option *ngFor="let user of users" value="{{user.id}}">{{user.nombre}} {{user.apellido_paterno}}
                    {{user.apellido_materno}}</option>
                </select>
              </div>

              <div class="flex justify-center"  *ngIf="bitacoraForm.value.id_cat_bitacora ==  15">
                <select formControlName="id_suplente"
                  class="appearance-none w-3/4 block p-2 text-sm font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                  <option value="" selected disabled>Suplente</option>
                  <option *ngFor="let user of users" value="{{user.id}}">{{user.nombre}} {{user.apellido_paterno}}
                    {{user.apellido_materno}}</option>
                </select>
              </div>


              <div class="flex justify-center">
                <textarea formControlName="observacion"
                  class="resize-y w-3/4 text-sm p-2 font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  rows="3" placeholder="Observaciones"></textarea>
              </div>
              <br>
              <div class="flex justify-center">
                <select formControlName="estatus"
                  class="appearance-none w-3/4 block p-2 text-sm font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                  <option value="" selected disabled>Estatus</option>
                  <option value="1">Inicial</option>
                  <option value="2">Proceso</option>
                  <option value="3">Cerrada</option>
                  <option value="4">Validado</option>
                </select>
              </div>


            </div>
            <div *ngIf="bitacoraForm.valid">
              <button (click)="saveEvento()" type="button"
                class="w-3/4 bg-gray-50 dark:bg-transparent rounded py-3 text-xs text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 uppercase">Guardar
                registro</button>
            </div>
          </div>
        </form>
      </div>

    </div>

  </div>
</div>

