import {createReducer, on} from '@ngrx/store';
import {InputState} from '../../core/models/input.state';
import {addInputAction, changeInputAction, clearInputAction} from '../actions/input.action';


export const initialState:InputState = {inputs:[]};


export const inputReducer = createReducer(
  initialState,
  on(addInputAction, (state,{input}) => {
    let inputState : InputState = { inputs : [...state.inputs]}
    //Change others to false
    inputState.inputs = inputState.inputs.map(i => ({ ...i, active: false}))

    //add input
    inputState.inputs = [...inputState.inputs, input]
    return {...inputState};
  }),
  on(changeInputAction, (state,{input, index}) => {
    let inputState : InputState = { inputs : [...state.inputs]}
    //Change others to false
    inputState.inputs = inputState.inputs.map(i => ({ ...i, active: false}))
    //Edit input
    inputState.inputs[index] = input
    return inputState;
  }),
  on(clearInputAction ,(state)=>{
    return initialState;
})
);
