import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BitacoraService } from 'src/app/services/bitacora.service';
import { CasetaService } from 'src/app/services/caseta.service';
import { UserService } from 'src/app/services/user.service';
import { AppState } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { catFormTipo } from 'src/app/utils';

@Component({
  selector: 'app-bitacora-pendiente',
  templateUrl: './bitacora-pendiente.component.html',
  styleUrls: ['./bitacora-pendiente.component.scss']
})
export class BitacoraPendienteComponent implements OnInit {

  fechaActual: any = moment().format('YYYY-MM-DD');
  showBitacoraCat: string = '';
  categoriaForm: FormGroup;
  bitacoraForm: FormGroup;
  bitacora
  catBitacoras: any;
  catPadres: any
  catHijos: any
  catTipos = []
  categorias = []
  casetas : any
  casetastemp : any
  usuario: any
  carriles : any
  users : any
  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private bitacoraService: BitacoraService,
    private casetaService : CasetaService,
    private userService : UserService,
    private store: Store<AppState>,
  ) { }

  ngOnInit(): void {
    this.getData()
    this.createForm();
  }

  showBitadoraForm(id) {
    this.catHijos = this.catBitacoras.filter(hijos => hijos.id_padre == id)
    console.log(this.catHijos);
    this.getTipos()
    this.showBitacoraCat = this.bitacoraForm.value.bitacoraTipo;
    let setting = catFormTipo(this.showBitacoraCat, this.fechaActual);
    this.categoriaForm = this.formBuilder.group(setting);
    console.log(this.showBitacoraCat);
  }

  createForm(){
    this.bitacoraForm = new FormGroup({
      id_cat_bitacora: new FormControl('', Validators.required),
      id_caseta: new FormControl(2, Validators.required),
      id_carril: new FormControl('', ),
      id_turno: new FormControl('', Validators.required),
      id_cobrador: new FormControl('', ),
      id_suplente: new FormControl('', ),
      observacion: new FormControl('', Validators.required),
      estatus: new FormControl(1, Validators.required),
      id_usuario: new FormControl(this.usuario.id, Validators.required)
    });
  }
  saveEvento() {
    if (!this.bitacoraForm.valid) { return this.toastr.error(`Llene todos los campos`, 'Error', { positionClass: 'toast-bottom-right' }) }
    this.bitacoraService.saveBitacora(this.bitacoraForm.value)
    .subscribe(data=>{
      this.createForm();
      return this.toastr.success(`Se ha guardado la nota correctamente`, 'Petición', { positionClass: 'toast-bottom-right' })


    })

  }

  getData() {
        // obtener casetas de usuario
        this.store.select(selectUser).subscribe( usuario => this.usuario = usuario);
    this.bitacoraService.getCatBitacora()
      .subscribe(cat => {
        this.catBitacoras = cat;
        this.catPadres = this.catBitacoras.filter(padres => padres.id_padre == null)
      })

      this.casetaService.getCasetas()
      .subscribe(data=>{
        this.casetastemp = data;
        this.casetas = data;

        this.casetas = this.casetas.filter(cas => cas.tipo == 1)
        this.casetastemp = this.casetastemp.filter(cas => cas.id == 2)
        this.carriles = this.casetastemp[0].carriles;
        this.userService.getUsers(2, 22)
        .subscribe(data=>{
          this.users = data
          console.log(this.users);

        })
      })

  }

  getTipos() {
    this.catTipos = []
    this.categorias = []

    for (let i = 0; i < this.catHijos.length; i++) {
      let index = this.catTipos.findIndex(m => m.tipo === this.catHijos[i].tipo);
      index === -1 ? this.catTipos.push({
        tipo: this.catHijos[i].tipo,
      }): null ;

    }
      console.log(this.catTipos);
  }

  showBCategorias(tipo){
    this.categorias = this.catHijos.filter(tip => tip.tipo == tipo)
  }

  getCaseta(e){
    console.log(e.target.value);
    this.casetastemp = this.casetas
    this.casetastemp = this.casetastemp.filter(cas => cas.id == e.target.value)
    this.carriles = this.casetastemp[0].carriles;

    // this.carriles = this.casetastemp.filter(car => car[0].carriles == e.target.value)

    // console.log(this.carriles);

    this.userService.getUsers(e.target.value, 22)
    .subscribe(data=>{
      this.users = data
      console.log(this.users);

    })
  }

}
