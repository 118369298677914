<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 h-fit uppercase text-lg text-gray-500 dark:text-gray-200">
    <div class="flex flex-col text-center mb-2">
        <h2 class="text-gray-500 dark:text-gray-50 font-bold text-base">Firma jefe de turno (numero de empleado)</h2>
        <hr class="mb-2 dark:border-gray-500">

        <div class="w-1/3 mx-auto mb-2">
            <label for="password" class="block sr-only">Firma jefe de turno</label>

            <div class="mt-1 relative rounded-md shadow-sm">

                <div class="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z" clip-rule="evenodd" />
                    </svg>
                </div>

                <input type="password" [(ngModel)]="password" name="password" id="password" autocomplete="off" class="border rounded border-gray-300 placeholder-gray-500 block w-full py-3 pl-8 pr-12 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray" placeholder="Contrasena" required>

            </div>
        </div>

        <button type="submit" (click)="showAsignar()" class="w-1/3 mx-auto bg-blue-600 p-4 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <img class="h-5 mx-auto" src="/assets/images/btn_send.svg" alt="">
        </button>

    </div>
</div>
