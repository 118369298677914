<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 font-bold text-base text-center uppercase text-gray-500 dark:text-gray-200">
    <div class="m-2">

        <div class="flex flex-col">

            <span class="dark:text-gray-50 py-2">Preparacion de fondos para cabina</span>
            <hr class="mb-2 dark:border-gray-500">

            <div class="flex justify-between text-xs mb-2">

                <div class="flex items-center">
                    <span class="font-semibold">Jefe de turno: </span>
                    <select (change)="getJefeTurno($event)" class="appearance-none block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                        <option selected disabled>Seleccionar jefe de turno</option>
                        <option *ngFor="let user of users" value="{{ user.id }}">{{ user.nombre }} {{ user.apellido_paterno }} {{ user.apellido_materno }}</option>
                    </select>
                </div>

                <div class="flex items-center">
                    <span class="font-semibold mr-4">Turno:</span>
                    <span class="text-sm">{{ turno.name }}</span>
                </div>

            </div>

            <hr class="dark:border-gray-500">

            <!-- denominaciones -->
            <div class="flex flex-col bg-gray-200 dark:bg-gray-900/50 rounded p-2 font-semibold text-center my-2">
                <div class="grid grid-flow-col auto-cols-fr">
                    <span>Pre</span>
                    <span>Cabina</span>
                    <span *ngFor="let denominacion of denominacionFormato">${{ denominacion.cantidad | number }}</span>
                    <span>Total</span>
                </div>

                <div class="grid grid-flow-col auto-cols-fr text-green-600">
                    <span class="text-gray-500 dark:text-white">Asignacion</span>
                    <span></span>
                    <span *ngFor="let denominacion of denominacionFormato">{{ denominacion.pivot.cantidad }}</span>
                    <span></span>
                </div>
            </div>

            <!-- carriles -->
            <div class="mb-2 font-semibold">
                <hr class="my-2 dark:border-gray-500">

                <div *ngFor="let carril of carriles" class="grid grid-flow-col gap-1 auto-cols-fr my-2 items-center container_carriles">
                    <input (change)="onChangeCheckCarril(carril, $event)" class="mx-auto rounded container_carriles--input" type="checkbox" >
                    <span>{{ carril.nombre }}</span>
                    <input #input id="c{{ carril.id }}-{{ i }}" (change)="getResult(carril,denominacion,$event, input)" *ngFor="let denominacion of denominacionFormato; let i = index" type="number" class="text-center block py-1 text-base font-normal text-gray-700 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" min="0" max="5" value="{{ denominacionPerfil[i].pivot.cantidad }}" placeholder="0"/>
                    <span id="c{{ carril.id }}">$0</span>
                </div>

                <hr class="my-2 dark:border-gray-500">
            </div>

            <!-- footer -->
            <div class="flex justify-between items-center font-semibold text-sm text-gray-500 dark:text-gray-50">
                <div>
                    <button (click)="saveAsignarFondo()" type="button" class="bg-gray-50 dark:bg-transparent border border-gray-300 drop-shadow-lg hover:border-blue-600 rounded p-2 uppercase font-semibold">Guardar</button>
                </div>
                <div class="mr-8">
                    <span>Total Final</span>
                    <span id="totalFinal" class="pl-8">$0</span>
                </div>
            </div>

        </div>

    </div>
</div>
