import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ToastrService } from 'ngx-toastr';
import { Carril } from 'src/app/models';
import { CorteActual, Formacion, Turno, User } from 'src/app/models/dash';
import { CasetaService } from 'src/app/services/caseta.service';
import { CorteService } from 'src/app/services/corte.service';
import { UserService } from 'src/app/services/user.service';
import { getTurno } from 'src/app/utils';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import { AuthService } from 'src/app/services/auth.service';
import { loadedUser, changeAccion } from '../../state/actions/user.actions';
import { LogUsuarioService } from 'src/app/services/log-usuario.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { TipoUsuarioService } from 'src/app/services/tipo-usuario.service';
import { switchMap } from 'rxjs/operators';
import { FormacionService } from 'src/app/services/formacion.service';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-asignar-carril',
  templateUrl: './asignar-carril.component.html',
  styleUrls: ['./asignar-carril.component.scss']
})
export class AsignarCarrilComponent implements OnInit {

  id_caseta: number = null;
  usuario: any;
  turnoActual: Turno;
  id_rol: number = 22;
  cobradores: User[] = [];
  asignarCobrador: any [] = [];
  cortes: CorteActual[] = [];
  formaciones: Formacion[] = [];
  corteFormacionList = [];
  formacionItems = {};

  constructor(
    private router: Router,
    private config: NgSelectConfig,
    private cortesActuales: CorteService,
    private store: Store<AppState>,
    private user: UserService,
    private casetaService: CasetaService,
    private formacionService: FormacionService,
    private tipoUser: TipoUsuarioService,
    private logUsuario: LogUsuarioService,
    private toastr: ToastrService,
    private authService:AuthService,
    private ngxUiLoaderService : NgxUiLoaderService,
  ) {
    this.config.notFoundText = 'Cobrador no encontrado';
  }

  async ngOnInit() {

    this.ngxUiLoaderService.start();
    // Obtener turno actual
    this.turnoActual = getTurno();
    // Obtener el usuario
    this.store.select(selectUser).subscribe( usuario => {
      this.usuario = usuario;
      // Obtener caseta troncal
      let casetaTroncal = usuario.caseta_usuario.find(caseta => caseta.tipo === 1);
      this.id_caseta = casetaTroncal.id;
    });

    // obtener cortes para caseta
    // NEW FLUJO JT, OBTENER JT ENTRNATE
    await this.cortesActuales.getCorteActual(Number(localStorage.getItem('jt_entrante')), this.id_caseta).toPromise().then( cortesActuales => {
      this.cortes = cortesActuales;
    });

    // obtener formaciones
    await this.formacionService.getFormaciones().toPromise().then( formaciones => {
      this.formaciones = formaciones;
    });

    // obtener cobradores por caseta
    await this.casetaService.getCaseta( this.id_caseta ).toPromise().then( cobradores => {
      let personal = cobradores.jefesturno.concat(cobradores.cobradores);
      this.cobradores = personal;
    });

    // generar un arreglo de objetos con los cortes
    for (const corte of this.cortes) {
      this.corteFormacionList.push({
        id_corte_usuario: corte['id_corte'],
        formacion: [1],
        justificacion: ''
      });
    }

    this.ngxUiLoaderService.stop();
  }

  getCobrador( corte: number, event: any ) {
    if (event) {
      let index = this.asignarCobrador.findIndex( cc => cc.id === corte);
      index === -1 ? this.asignarCobrador.push({ id: corte, id_usuario: event.id }) : this.asignarCobrador[index].id_usuario = event.id;
    }
  }

  updateFormacionList(id_corte: number, id_formacion: number, e: any) {
    let index = this.corteFormacionList.findIndex( element => element.id_corte_usuario === id_corte );
    // agregar formacion checked
    if (e.target.checked) { this.corteFormacionList[index].formacion.push(id_formacion)}
    // eliminar formacion !checked
    if (!e.target.checked) {
      let indexFormacion = this.corteFormacionList[index].formacion.indexOf(id_formacion);
      indexFormacion !== -1 ? this.corteFormacionList[index].formacion.splice(indexFormacion, 1) : '';
    }
    // si formacion completa, retirar justificacion
    if ( this.corteFormacionList[index].formacion.length === 3 ) {
      this.corteFormacionList[index].justificacion = null;
      let input = (document.getElementById(`j-${id_corte}`) as HTMLInputElement);
      input.classList.add('hidden');
      input.value = "";
    }
    // si formacion incompleta, mostrar justificacion
    if (this.corteFormacionList[index].formacion.length < 3) { (document.getElementById(`j-${id_corte}`) as HTMLInputElement).classList.remove('hidden')}
    console.log( this.corteFormacionList );
  }

  justificacion(event: any, index: number) {
    let texto = event.target.value;
    this.corteFormacionList[index].justificacion = texto;
  }

  async asignarCarril() {
    // Si no existen cortes
    if (this.cortes.length === 0) {
      return this.toastr.error('No existen cortes para asignar cobrador', 'Error', { positionClass: 'toast-bottom-right' } );
    }
    // Si no estan asignados todos los cobradores
    if ( this.cortes.length != this.asignarCobrador.length ) {
      return this.toastr.error('Asigne todos los cobradores', 'Error', { positionClass: 'toast-bottom-right' } );
    }

    try{

      this.ngxUiLoaderService.start();
      this.store.dispatch( changeAccion({ id_cat_acciones: 2 }) );
      this.authService.login( this.usuario );
      // actualizo el log de usuario
      await this.logUsuario.updateLogUsuario({id_cat_acciones: 2}, this.usuario.id_log_usuario).toPromise();
      // guardo la asignacion de cobradores en la bd
      await this.cortesActuales.saveAsignarCarril( this.asignarCobrador ).toPromise();
      // guradar la formacion de los cobradores
      await this.formacionService.saveFormacion(this.corteFormacionList).toPromise();
      this.toastr.success('Asignacion de carril realizada correctamente', '', { positionClass:'toast-bottom-right' } );
      this.router.navigate(['dashboard/home']);
    }catch(err){
      console.log(err)
    }finally {
      this.ngxUiLoaderService.stop();
    }

  }

}
