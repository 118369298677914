<!--
<div>
  <h2>Último Folio</h2>
  <hr>
  <app-teclado-numerico placeholder="Folio" (confirmValue)="logut($event)" ></app-teclado-numerico>
</div> -->

<div class="p-2 border rounded border-solid border-gray-300 dark:border-gray-500 h-112 uppercase text-lg text-gray-500">
  <div class="flex flex-col text-center">
    <h2 class="text-gray-500 dark:text-gray-50 font-bold text-base">Último Folio</h2>
    <hr class="mt-1 mb-2">
    <div class="grid grid-cols-3">

      <div>

        <div class="flex items-center justify-center">
          <span class="text-lg pb-1.5 font-medium dark:text-gray-300 mr-4">Arqueo</span>
          <input class="w-4 h-4 mt-0.5 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox" (change)="isArqueo()">
        </div>

        <!-- <div class="mt-2 ">
          <button (click)="confirmModal=true" class="bg-blue-600 p-3 text-white font-bold uppercase border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Rollo Pendiente</button>
        </div> -->

        <!-- <div *ngIf="arqueo" class="mt-4 text-base">
          <div class="w-3/4 mx-auto">
            <label class="sr-only">Asignación de cabina</label>
            <ng-select class="custom" [items]="cobradores" bindLabel="nombre" bindValue="id" (change)="getCobrador($event)" notFoundText="No se han encontrado registros" placeholder="Asignar cobrador">
            </ng-select>
          </div>
        </div> -->

      </div>

      <div class="w-96">
        <app-teclado-numerico placeholder="Folio" (confirmValue)="logut($event)" ></app-teclado-numerico>
      </div>
    </div>
  </div>
</div>

<!-- <div *ngIf="confirmModal" class="modal-component-container fixed inset-0 overflow-y-auto">
  <div
    class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>

    <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>

    <div
      class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
      <div class="modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

        <div class="modal-wrapper-flex sm:flex sm:items-start">

          <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">

              <div class="py-2 text-center">
                  <span >¿{{getText()}}?</span>
              </div>

          </div>
        </div>
      </div>

      <div class="modal-actions bg-gray-50 px-4 py-3 sm:px-6 flex flex-col">
          <div class="flex justify-end">
              <button (click)="cancelarRolloPendiente()"
                      class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  Cancelar Rollo Pendiente
              </button>
              <button (click)="pendingRollo()"
                      class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  Asignar Rollo Pendiente
              </button>
          </div>

      </div>

    </div>
  </div>
</div> -->
