import { createSelector } from '@ngrx/store';
import {AppState} from '../app.state';
import {TipoVehiculoState} from '../../core/models/tipoVehiculo.state';

export const selectTipoVehiculoFeature = (state: AppState) => state.tipoVehiculo;

export const selectTipoVehiculo = createSelector(
  selectTipoVehiculoFeature,
  (state: TipoVehiculoState) => state.tipoVehiculo
);

