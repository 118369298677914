import { createSelector } from '@ngrx/store';
import {AppState} from '../app.state';
import {RolloState} from '../../core/models/rollo.state';

export const selectRolloFeature = (state: AppState) => state.rollo;

export const selectRollo = createSelector(
  selectRolloFeature,
  (state: RolloState) => state.rollo
);

export const selectEstatusRollo = createSelector(
  selectRolloFeature,
  (state: RolloState) => {
    return state.stateRollo
  }
)
