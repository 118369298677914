<!-- MORRALLA-->
<!-- <div class="main">
    <span>Morralla</span>
    <button #btn class="btn w-20"  (click)="isSelected(btn) ; morrallaArray(morralla.id)" *ngFor="let morralla of morallasDisponibles$ | async">${{morralla.cantidad}}</button>
    <button class="btn w-20" (click)="postRollo()">Solicitar Rollo</button>
    <button [disabled]="morralla.length == 0" class="btn w-20" (click)="saveAction()">/</button>
</div> -->

<div class="grid grid-cols-9 gap-4 border-solid border border-gray-300 dark:border-gray-400 rounded m-2 p-2 text-lg font-semibold uppercase text-gray-500 dark:text-white text-center items-center">
    <span>Morralla</span>
    <button #btn class="btn bg-gray-50 w-full text-xl uppercase dark:bg-transparent  rounded py-1 text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 dark:border-gray-400 drop-shadow-lg hover:border-blue-600" (click)="isSelected(btn) ; morrallaArray(morralla.id)" *ngFor="let morralla of morallasDisponibles$ | async">${{morralla.cantidad}}</button>
    <button *ngIf="morrallaStatus" #btnD class="btn bg-gray-50 w-full h-9 text-lg uppercase dark:bg-transparent rounded py-1 text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 dark:border-gray-400 drop-shadow-lg hover:border-blue-600" [disabled]="isDisabledBtn()" [class.dark:bg-gray-600] = "isDisabledBtn()" [class.bg-gray-300] ="isDisabledBtn()" [class.dark:bg-transparent] ="!isDisabledBtn()" (click)="saveAction()"><img class="hidden h-5 mx-auto dark:block" src="/assets/images/btn_send.svg" alt=""><img class="h-5 mx-auto dark:hidden" src="/assets/images/btn_send_dark.svg" alt=""></button>
    <button *ngIf="!morrallaStatus" #btnD class="btn bg-gray-50 w-full h-9 text-lg uppercase dark:bg-transparent rounded py-1 text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 dark:border-gray-400 drop-shadow-lg hover:border-blue-600" [disabled]="isOffline" [class.dark:bg-gray-600] = "isOffline" [class.bg-gray-300] ="isOffline" [class.dark:bg-transparent] ="!isOffline" (click)="goToFirma()"><img class="hidden h-5 mx-auto dark:block" src="/assets/images/key.svg" alt=""><img class="h-5 mx-auto dark:hidden" src="/assets/images/key_black.svg" alt=""></button>

</div>
