<!-- <div class="container">
    <app-container-header></app-container-header>
    <router-outlet></router-outlet>
</div> -->

<div class="flex">
    <app-container-header></app-container-header>
    <div class="w-full mx-2">
        <router-outlet></router-outlet>
    </div>
</div>