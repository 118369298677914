import { createSelector } from '@ngrx/store';
import {AppState} from '../app.state';
import {PanelIzquierdoState} from '../../core/models/panelIzquierdo.state';
import {PanelIzquieroRetiroState} from '../../core/models/panelIzquieroRetiro.state';

export const selectPanelIzquieroRetiroFeature = (state: AppState) => state.panelIzquierdoRetiro;

export const selectPanelIzquierdoRetiro = createSelector(
  selectPanelIzquieroRetiroFeature,
  (state: PanelIzquieroRetiroState) => state
);
