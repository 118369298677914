import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '../../state/app.state';
import { Store } from '@ngrx/store';
import { Observable, Subscription, timer } from 'rxjs';
import { Caseta, Usuario } from '../../models';
import { selectCaseta } from '../../state/selectors/caseta.selectors';
import { selectUser } from '../../state/selectors/user.selectors';
import { map, share } from 'rxjs/operators';
import { Router } from '@angular/router';
import { OfflineOnlineService } from 'src/app/services/offline-online.service';
import { MorrallaService } from 'src/app/services/morralla.service';
import { BtnRapidoService } from 'src/app/services/btn-rapido.service';
import { CruceService } from 'src/app/services/cruce.service';
import { DevolucionService } from 'src/app/services/Devolucion.service';
import { RetiroService } from 'src/app/services/retiro.service';
import { RolloService } from 'src/app/services/rollo.service';
import { ActionUserLocaldbService } from 'src/app/services/action-user-localdb.service';
import { ACTIONS_USER_DB } from '../../utils/enum';
import { PanelIzquierdoState } from 'src/app/core/models/panelIzquierdo.state';
import { selectPanelIzquierdo } from 'src/app/state/selectors/panelIzquierdo.selectors';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  isDarkEnable = true;

  time = new Date();
  intervalId;
  subscription: Subscription;

  caseta$: Observable<Caseta> = new Observable<Caseta>();
  caseta: Caseta = null;
  usuario: any = null;
  isOffline = false;
  morrallaCount: number = 0;
  btnRapidoCount: number = 0;
  crucesCount: number = 0;
  devolucionCount: number = 0;
  rolloCount: number = 0;
  retiroCount: number = 0;
  allSync: number = 0
  //user
  anyDevolutionLocalDB: boolean = false;
  stateDevolucionStore: PanelIzquierdoState;

  user: any = null;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private offlineOnlineService: OfflineOnlineService,
    private morrallaService: MorrallaService,
    private btnRapidoService: BtnRapidoService,
    private cruceService: CruceService,
    private devolucionService: DevolucionService,
    private retiroService: RetiroService,
    private rolloService: RolloService,
    private actionUserDB: ActionUserLocaldbService,
    private toastrService : ToastrService


  ) { }

  ngOnInit(): void {

    this.isConnection()

    //get caseta
    this.caseta$ = this.store.select(selectCaseta);
    this.caseta$.subscribe(caseta => {
      this.caseta = caseta;

    })
    //get user
    this.store.select(selectUser).subscribe(async usuario => {
      this.usuario = usuario

      //Get action devolution
      this.anyDevolutionLocalDB = await this.actionUserDB.existAction(this.usuario.id_corte_usuario, ACTIONS_USER_DB.DEVOLUCION)
      console.log(this.anyDevolutionLocalDB);

    })
        //Get state devolucion
        this.store.select(selectPanelIzquierdo).subscribe(stateDevolucionStore => {
          this.stateDevolucionStore = stateDevolucionStore;
          //this.class(this.btnDevolucion, this.stateDevolucionStore.status)
        });
    this.timeLive();

    // Check Current Theme
    if (localStorage.getItem('colorMode') === 'dark') {
      document.documentElement.classList.add('dark');
      this.isDarkEnable = true;
    } else if (localStorage.getItem('colorMode') === 'light') {
      document.documentElement.classList.remove('dark');
      this.isDarkEnable = false;
    }

  }

  timeLive() {
    // Using Basic Interval
    this.intervalId = setInterval(() => {
      this.time = new Date();
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  cerrarSesion() {
    //mandar component de numeros
    this.router.navigate(['/mopa/corte',]);
    console.log('ok');


  }

  // Switch Button Theme
  changeTheme(btnMode) {
    if (btnMode === 1) {
      localStorage.setItem('colorMode', 'light');
      document.documentElement.classList.remove('dark');
      this.isDarkEnable = false;
    } else {
      localStorage.setItem('colorMode', 'dark');
      document.documentElement.classList.add('dark');
      this.isDarkEnable = true;
    }
  }

  isConnection() {
    this.offlineOnlineService.connectionChanged.subscribe(
      estatus => {
        //this.toastrService.info(estatus ? "De nuevo en línea" : "Trabajando sin red"  ,'', {positionClass: 'toast-top-right'});
        this.isOffline = !estatus;
      }
    )
    this.getCountSync();

  }

  async getCountSync() {
    this.morrallaService.counterDocs$.subscribe(counter => {
      this.morrallaCount = counter;
    })
    this.btnRapidoService.counterDocsBtnR$.subscribe(counter => {
      this.btnRapidoCount = counter;
    })
    this.cruceService.counterDocs$.subscribe(counter => {
      this.crucesCount = counter;
    })
    this.devolucionService.counterDocs$.subscribe(counter => {
      this.devolucionCount = counter;
    })
    this.retiroService.counterDocs$.subscribe(counter => {
      this.retiroCount = counter;
    })
    this.rolloService.counterDocs$.subscribe(counter => {
      this.rolloCount = counter;
    })
    this.allSync = (this.morrallaCount + this.btnRapidoCount + this.crucesCount);
  }


  hasAnyDevolution(){
    //si no hay ninguna devolución no se muestra
    return this.stateDevolucionStore.countDevolucion > 0
  }
}
