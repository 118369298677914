import { Component, OnInit } from '@angular/core';
import {Carril, Caseta} from '../../models';
import {CasetaService} from '../../services/caseta.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-configuracion-carril',
  templateUrl: './configuracion-carril.component.html',
  styleUrls: ['./configuracion-carril.component.scss']
})
export class ConfiguracionCarrilComponent implements OnInit {
  casetas : Caseta[] = [];
  carriles : Carril[] = [];
  idCarril = 0;

  constructor(
    private casetaService: CasetaService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private toastr: ToastrService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.getData();

  }

  async getData(){
    this.ngxUiLoaderService.start();

    try{
      //Get casetas
      this.casetas = await  this.casetaService.getCasetas().toPromise();
    }catch (err){
      console.log(err);
    }finally {
      this.ngxUiLoaderService.stop();
    }

  }

  onChangeCaseta(ev){
    let idCaseta = ev.target.value;
    if(idCaseta){
      let caseta = this.casetas.find(caseta => caseta.id == idCaseta);
      this.carriles = caseta.carriles;
    }
  }

  saveCarril(){
      localStorage.setItem('id_carril', `${this.idCarril}`);
      this.toastr.success("Se ha guardado con éxito el carril", "Configuración carril", {positionClass: 'toast-bottom-right'})
  }

  logout(){
    this.authService.logout();
    this.router.navigate(['/login'])
  }


}
