import {createSelector} from '@ngrx/store';
import {InputState} from '../../core/models/input.state';
import {AppState} from '../app.state';
import {MorrallaStatusState} from '../../core/models/morralla-status.state';

export const selectMorrallaFeature = (state: AppState) => state.morrallaStatus;


export const selectMorrallaStatus = createSelector(
  selectMorrallaFeature,
  (state: MorrallaStatusState) => state
);
