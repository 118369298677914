import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../models/dash';
import { UserData } from '../models';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private EDNPOINT = environment.ENDPOINTS.USUARIO;
  private EDNPOINTPASSWORD = environment.ENDPOINTS.CHANGEPASSWORD;

  constructor(
    private http: HttpClient
  ) { }

  getUsers(id_caseta,id_rol) {
    return this.http.get<User[]>(this.EDNPOINT + `/arqueo/${id_caseta}/${id_rol}`);
  }

  getUsersAllByPage(page:number) {
    let param = new HttpParams;
    param = param.append('page',String(page));

    return this.http.get<UserData>(this.EDNPOINT, {params:param});
  }

  editUser(id: number, user: any) {
    return this.http.put<User>(this.EDNPOINT + `/${id}`, user).toPromise();
  }

  saveUser( user: any) {
    return this.http.post<User>(this.EDNPOINT, user).toPromise();
  }

  getUserAuxiliares(){
    return this.http.get<User[]>(`${this.EDNPOINT}/get/users-aux`);
  }

  updatePasswordUser(id : number, user: any) {
    return this.http.put<User[]>(`${this.EDNPOINTPASSWORD}/${id}`, user);
  }

  getUsersFaltanteTraslado(id_caseta: number) {
    return this.http.get<any[]>(this.EDNPOINT + `/get-users-by-faltantes/${ id_caseta }`);
  }

}
