import {Cruce} from '../../models';
import {createReducer, on} from '@ngrx/store';
import {CruceState} from '../../core/models/cruce.state';
import {addCruceClasificacion, addCruceCaravana, addCruceType, addCruceSubType, addCruceDinero, clearCruce, addCruceObservacion, addCruceCantidadEjes} from '../actions/cruce.actions';


export const initialState:CruceState = {loading: false, cruce: null, contCruce: 0};


export const cruceReducer = createReducer(
  initialState,
  on(addCruceType, (state,{id_type, childrens}) => {
    return {...initialState, id_type, childrens};
  }),
  on(addCruceSubType, (state,{namePadre, idPadre,nameChildren,idChildren}) => {
    return {...state, namePadre, idPadre, nameChildren, idChildren};
  }),
  on(addCruceClasificacion, (state,{clasificacion, id_clasificacion}) => {
    return {...state, clasificacion, id_clasificacion};
  }),
  on(addCruceObservacion, (state,{id_cat_observacion}) => {
    return {...state, id_cat_observacion};
  }),
  on(addCruceCantidadEjes, (state,{cantidad_ejes}) => {
    return {...state, cantidad_ejes};
  }),
  on(addCruceDinero, (state,{aventoDinero}) => {
    return {...state, aventoDinero};
  }),
  on(addCruceCaravana, (state,{caravana}) => {
    return {...state, caravana};
  }),
  on(clearCruce, (state,) => {
    return {...initialState};
  }),


);
