import {createAction, props} from '@ngrx/store';

export enum ActionsHistoryNotification {
  ADD_NOTIFICATION = '[ADD-NOTIFICATION] ADD',
}


export const addNotification = createAction(
  ActionsHistoryNotification.ADD_NOTIFICATION,
  props<{ notification: any}>()
);

