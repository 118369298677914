import {createAction, props} from '@ngrx/store';
import {Retiro} from '../../models';

export enum ActionsRetiro {
  LOAD_RETIRO = '[Retiro-GET] Load Retiro',
  LOADED_RETIRO = '[Retiro-GET] Loaded Retiro',
  POST_RETIRO = '[Retiro-POST] Post Retiro',
  POSTED_RETIRO = '[Retiro-POST] Posted success',
  addMonto = '[Retiro-ADD] Add monto',
  CLEAR_RETIRO = '[Retiro-CLEAR] Clear',
  ADD_RETIROS_PRINT = '[Retiro-ADD] Add monto',
  CLEAR_RETIRO_PRINT = '[Retiro-CLEAR] Clear'
}


export const loadRetiro = createAction(
  ActionsRetiro.LOAD_RETIRO
);

export const loadedRetiro = createAction(
  ActionsRetiro.LOADED_RETIRO,
  props<{ retiro: Retiro }>()
);

export const postRetiro = createAction(
  ActionsRetiro.POST_RETIRO,
  props<{ retiro: Retiro }>()

);

export const postedRetiro = createAction(
  ActionsRetiro.POSTED_RETIRO,
  props<{ retiro: Retiro }>()
);

export const addMonto = createAction(
  ActionsRetiro.addMonto,
  props<{monto? : number }>()
);

export const clearRetiro = createAction(
  ActionsRetiro.CLEAR_RETIRO
)
export const addRetirosPrint = createAction(
  ActionsRetiro.ADD_RETIROS_PRINT,
  props<{retiroPrint? : any }>()
);

export const clearRetiroPrint = createAction(
  ActionsRetiro.CLEAR_RETIRO_PRINT
)

