import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AppState} from '../../state/app.state';
import {Store} from '@ngrx/store';
import {selectInputs} from '../../state/selectors/input.selector';
import {changeInputAction} from '../../state/actions/input.action';
import {InputState} from '../../core/models/input.state';
import { OfflineOnlineService } from 'src/app/services/offline-online.service';

@Component({
  selector: 'app-teclado-numerico',
  templateUrl: './teclado-numerico.component.html',
  styleUrls: ['./teclado-numerico.component.scss']
})
export class TecladoNumericoComponent implements OnInit, OnChanges {
  @Output() change =  new EventEmitter<string>();
  @Output() confirmValue =  new EventEmitter<string>();
  @Input() placeholder: string;
  @Input() value: string;
  @Input() type: string = 'number';
  @Input() multipleInputs : boolean = false;
  text: string = '';
  isOffline : boolean = false;
  constructor(
    private store: Store<AppState>,
    private offlineOnlineService: OfflineOnlineService

  ) { }

  ngOnInit(): void {
    this.offlineOnlineService.connectionChanged.subscribe(estatus => {
      this.isOffline = !estatus;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {

    //Value Change
    if(changes['value'] && changes.value.currentValue ){
      this.text =  `${changes.value.currentValue}` ;
    }

  }

  async onPress(value: number){

    if(this.multipleInputs){
      let state: InputState = await this.getStateInput()
          let index = state.inputs.findIndex( i => i.active);
          if(index != -1){
            let input = {...state.inputs[index]};
            input.value = input.value.concat(`${value}`);
            //Change state
            this.store.dispatch(changeInputAction({input, index}));
          }
          return;
        }

    this.text = this.text + `${value}`;
    this.change.emit(this.text);
  }

  confirm(){
    this.confirmValue.emit(this.text);
  }

  async erase(){
    if(this.multipleInputs){
      let state: InputState = await this.getStateInput()
      let index = state.inputs.findIndex( i => i.active);
      if(index != -1){
        let input = {...state.inputs[index]};
        input.value = input.value.slice(0,-1);
        //Change state
        this.store.dispatch(changeInputAction({input, index}));
      }
      return;
    }

    this.text =  this.text.slice(0, -1)
    this.change.emit(this.text);
  }

  async clear(){

    if(this.multipleInputs){
      let state: InputState = await this.getStateInput()
      let index = state.inputs.findIndex( i => i.active);
      if(index != -1){
        let input = {...state.inputs[index]};
        input.value = ''
        //Change state
        this.store.dispatch(changeInputAction({input, index}));
      }
      return;
    }

    this.text = '';
    this.change.emit(this.text)
  }

  onKeyPress(e){
    return false;
  }

  getStateInput(){
    return new Promise<InputState>((resolve, reject)=>{
      this.store.select(selectInputs).subscribe(state => resolve(state))
    })
  }

  isDisabled(){
    if(this.multipleInputs){
      return false;
    }

    if(this.text){
      return false;
    }

    return true;
  }

}
