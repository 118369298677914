import { createSelector } from '@ngrx/store';
import {AppState} from '../app.state';
import {UsuarioState} from '../../core/models/user.state';

export const selectUserFeature = (state: AppState) => state.usuario;

export const selectUser = createSelector(
  selectUserFeature,
  (state: UsuarioState) => state.usuario
);

export const selectAccion = createSelector(
  selectUserFeature,
  (state: UsuarioState) => state.accion
);

export const selectCorteAuxiliar = createSelector(
  selectUserFeature,
  (state: UsuarioState) => state.id_corte_auxiliar
);


