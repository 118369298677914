import {createAction, props} from '@ngrx/store';


export enum PanelIzquierdoRetiroActions {
  changeStatus = '[PanelIzquierdoRetiro] CHANGE',
  addEvent = '[PanelIzquierdoRetiro] ADD',
  setStatus = '[PanelIzquierdoRetiro] SET',
  clearStatus = '[PanelIzquierdoRetiro] CLEAR',
}

export enum StatusPanel {
  INITIAL = 0,
  NOTIFICADO = 1,
  PROCESO = 2
}

export const changeStatusRetiro = createAction(
  PanelIzquierdoRetiroActions.changeStatus,
  props<{ status: StatusPanel }>()
);

export const addEventRetiro = createAction(
  PanelIzquierdoRetiroActions.addEvent,
  props<{status: StatusPanel, id_denominacion : number}>()
);

export const setStatusRetiro = createAction(
  PanelIzquierdoRetiroActions.setStatus
)

export const clearStatusRetiro = createAction(
  PanelIzquierdoRetiroActions.clearStatus
)
