import { Component, OnDestroy, OnInit } from '@angular/core';
import { RolloService } from '../../services/rollo.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import { selectUser } from '../../state/selectors/user.selectors';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { postRollo, savedStatusRollo } from 'src/app/state/actions/rollo.actions';
import { selectInputs } from '../../state/selectors/input.selector';
import { addInputAction, changeInputAction, clearInputAction } from '../../state/actions/input.action';
import { InputState } from '../../core/models/input.state';
import { PushNotificationService } from 'src/app/services/push-notification.service';
import { STATUS_NOTIFICATION, TYPE_NOTIFICATION, TYPE_PERMISO } from '../../utils/enum';
import { NotificacionesPushService } from '../../services/notificaciones-push.service';
import { selectPermisos } from 'src/app/state/selectors/caseta.selectors';
import { foundPermiso } from 'src/app/utils';
import { TypeBoton } from 'src/app/state/actions/panelIzquierdo.actions';
import { selectEstatusRollo } from 'src/app/state/selectors/rollo.selectors';
import { OfflineOnlineService } from 'src/app/services/offline-online.service';
import { longNumber } from '../../../app/utils/index';

@Component({
  selector: 'app-cambio-rollo',
  templateUrl: './cambio-rollo.component.html',
  styleUrls: ['./cambio-rollo.component.scss']
})
export class CambioRolloComponent implements OnInit, OnDestroy {

  rollo: any = {};
  usuario: any;
  inputFolioFinal: boolean = false;
  user: any = null;
  confirmModal: boolean = false;

  totalCollected: any;
  //permisos
  permisos = [];
  //rollo
  statusRollo: number = 0;
  confirmarRolloPendign: number = 0
  confirmModalRolloPending: boolean = false;

  //offline
  isOffline = false;
  constructor(
    private rolloService: RolloService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private router: Router,
    private pushNotService: PushNotificationService,
    private notificacionesService: NotificacionesPushService,
    private offlineOnlineService: OfflineOnlineService,
    private ngxServiceLoader: NgxUiLoaderService


  ) {
  }

  ngOnDestroy(): void {
    //Clear inputs
    this.store.dispatch(clearInputAction());
  }

  ngOnInit(): void {
    this.store.select(selectEstatusRollo).subscribe(status => this.statusRollo = status);
    // Permisos para retirar o devolucion por roles
    this.store.select(selectPermisos).subscribe(permiso => {
      this.permisos = permiso
      console.log(this.permisos);
    });
    //get rollo pending storage
    this.confirmarRolloPendign = Number(localStorage.getItem('rollo_pendiente'));

    //VALIDAR SI TIENE PERMISOS EL USUARIO DE HACER ROLLO
    this.validateRedirect();


    this.store.select(selectUser).subscribe(async (user) => {
      this.user = user;
    });
    //Get user in session
    this.store.select(selectUser).subscribe(user => this.usuario = user);

    //Get input value
    this.store.select(selectInputs).subscribe(state => {
      console.log(state);

      //Nuevo Folio
      let inputNewFolio = state.inputs.find(i => i.id === 'NUEVO_FOLIO');
      if (inputNewFolio) {
        this.rollo.nuevo_folio = inputNewFolio.value;
      }

      //Folio Final
      let inputFolio = state.inputs.find(i => i.id === 'FOLIO_FINAL');
      if (inputFolio) {
        this.rollo.folio_final = inputFolio.value;
      }
    });

  }

  async saveFolio(value: string) {
    this.ngxUiLoaderService.start();
    if (!(this.rollo.folio_inicial && this.rollo.folio_final && this.rollo.nuevo_folio)) {
      this.ngxUiLoaderService.stop();
      return this.toastr.info('Completa campos requeridos', 'Error de formulario', { positionClass: 'toast-bottom-right' });
    }

    if (!longNumber(this.rollo.folio_final.length)) {
      this.ngxUiLoaderService.stop();
      return this.toastr.error('El folio debe tener 8 digitos', 'Revisa el Folio', { positionClass: 'toast-bottom-right' });

    }

    //change status notification ATENDIDO
    let body = {
      id_corte: this.usuario.id_corte_usuario,
      tipo: TYPE_NOTIFICATION.ROLLO,
      estatus: STATUS_NOTIFICATION.ATENDIDO
    }
    try {
      let not = await this.notificacionesService.updateStateByCorte(body).toPromise();
    } catch (error) {
      this.ngxUiLoaderService.stop();

      return this.toastr.error('No se encontro conexión a Red', 'Red inestable', { positionClass: 'toast-bottom-right' });

    }


    let rollo = {
      folio_inicial: Number(this.rollo.nuevo_folio),//nuevo folio
      folio_final: Number(this.rollo.folio_final),
      id_corte_usuario: this.usuario.id_corte_usuario
    };


    this.store.dispatch(postRollo({ rollo }));
    this.store.dispatch(savedStatusRollo({ stateRollo: 0 }));
    localStorage.setItem('rollo_pendiente', '0');
    this.toastr.success('Se ha cargado el nuevo rollo', 'Aviso', { positionClass: 'toast-bottom-right' });
    this.router.navigate(['/mopa/home']);
    this.ngxUiLoaderService.stop();
    //se hace con redux
    // try {
    //   this.ngxUiLoaderService.start();
    //   this.rolloService.saveRollo(rollo).toPromise();
    //   this.store.dispatch(savedStatusRollo({ stateRollo: 0 }))

    //
    // } catch (err) {
    //   console.log(err);
    // } finally {
    //   this.ngxUiLoaderService.stop();
    // }
  }

  async cancelAction() {
    let result = false
    result = await this.notificacion('Acción cancelada', true, TYPE_NOTIFICATION.ROLLO);
    if (result) {
      this.store.dispatch(savedStatusRollo({ stateRollo: 0 }));
      this.router.navigate(['/mopa/home']);
    }
  }

  async onFocusIn(ev: any) {

    //Verificar que no exista en el arreglo
    let state = await this.getStateInput();
    let index = state.inputs.findIndex(i => i.id == 'FOLIO_FINAL');
    if (index != -1) {
      //Cambiar active in true
      this.store.dispatch(changeInputAction({ input: { ...state.inputs[index], active: true }, index }));
    } else {
      //añadir
      this.store.dispatch(addInputAction({ input: { id: 'FOLIO_FINAL', value: '', active: true } }));
    }

  }

  async onFocusNewFolio(ev: any) {

    //Verificar que no exista en el arreglo
    let state = await this.getStateInput();
    let index = state.inputs.findIndex(i => i.id == 'NUEVO_FOLIO');
    if (index != -1) {
      //Cambiar active in true
      this.store.dispatch(changeInputAction({ input: { ...state.inputs[index], active: true }, index }));
    } else {
      //añadir
      this.store.dispatch(addInputAction({ input: { id: 'NUEVO_FOLIO', value: '', active: true } }));
    }

  }


  onChangeTN(e: string) {
    console.log(e);
  }

  getStateInput() {
    return new Promise<InputState>((resolve, reject) => {
      this.store.select(selectInputs).subscribe(state => resolve(state));
    });
  }

  onKeyPress(event) {
    return false;
  }


  async notificacion(type: string, isCanceled = false, typeNotification: TYPE_NOTIFICATION) {
    try {
      this.ngxUiLoaderService.start()
      let response = await this.pushNotService.sendNotification({
        title: 'MOPA',
        body: `${type}, Carril ${this.user.nom_carril}`,
        users: [this.user.id_jefe_turno],
        status: isCanceled ? STATUS_NOTIFICATION.CANCELADO : STATUS_NOTIFICATION.NOTIFICADO,
        type: typeNotification

      })
      this.toastr.success(`Notificando ${type}`, '', { positionClass: 'toast-bottom-right' });
      return true;
    } catch (error) {
      this.toastr.error('No se encontro conexión a Red', 'Red inestable', { positionClass: 'toast-bottom-right' });
      return false;
    } finally {
      this.ngxUiLoaderService.stop()
    }

  }

  validateRedirect() {
    // validar que tenga un rollo pendiente
    // if (this.confirmarRolloPendign > 0 && !this.confirmModalRolloPending) {
    //   return this.confirmModalRolloPending = true;
    // }
    console.log(foundPermiso(TYPE_PERMISO.ROLLO, this.permisos));

    if (foundPermiso(TYPE_PERMISO.ROLLO, this.permisos).length > 0 || this.statusRollo == 3) {

      //Get folios
      this.rolloService.getUltimoFolio().subscribe(rollo => {
        this.rollo = rollo;
        this.rollo.folio_inicial = this.rollo.folio_inicial;
      })
    } else {
      this.router.navigate(['/mopa/firma', TypeBoton.ROLLO])
    }
  }


  // cancelActionRolloPendig() {
  //   this.router.navigate(['/mopa/home'])
  // }

  // async saveRolloPendig() {
  //   this.confirmModalRolloPending = false;
  //   this.ngxUiLoaderService.start()
  //   let data = {
  //     'id_corte_usuario_anterior': this.confirmarRolloPendign,
  //     'id_corte_usuario': this.user.id_corte_usuario
  //   }
  //   //update corte en control de folios, se reasigna el rollo aperturado por el jt
  //   try {
  //     let updateControl = await this.rolloService.updateCorteToControlFolio(data).toPromise()
  //     localStorage.setItem('rollo_pendiente', '0');
  //     this.toastr.success(`Rollo reasignado con éxito`, '', { positionClass: 'toast-bottom-right' });
  //     //Get folios
  //     this.rolloService.getUltimoFolio().subscribe(rollo => {
  //       this.rollo = rollo;
  //       this.rollo.folio_inicial = this.rollo.folio_inicial;

  //     })

  //   } catch (error) {
  //     this.toastr.error('No se encontro conexión a Red', 'Red inestable', { positionClass: 'toast-bottom-right' });
  //     this.router.navigate(['/mopa/home'])
  //     console.log(error);

  //   } finally {
  //     this.ngxUiLoaderService.stop()
  //   }


  // }
}
