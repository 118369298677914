import {createAction, props} from '@ngrx/store';
import {Cruce} from '../../models';

export enum CruceActions {
  addCruceType = '[Cruce-ADD] Type',
  addCruceSubtype = '[Cruce-ADD] Subtype',
  addCruceDinero = '[Cruce-ADD] Dinero',
  addCruceClasificacion = '[Cruce-ADD] Clasificacion',
  addCruceObservacion = '[Cruce-ADD] Observacion',
  addCruceCantidadEjes = '[Cruce-ADD] CantidadEjes',
  addCruceCaravana = '[Cruce-ADD] Caravana',
  clearCruce = '[Cruce-CLEAR] Clear',
  postCruce = '[Cruce-POST] post',
  postedCruce ='[Cruce-POSTED] posted'
}

export const addCruceType = createAction(
  CruceActions.addCruceType,
  props<{ id_type: number, childrens: any [] }>()
);

export const addCruceSubType = createAction(
  CruceActions.addCruceSubtype,
  props<{ namePadre: string, idPadre, nameChildren:string, idChildren: number, }>()
);

export const addCruceClasificacion = createAction(
  CruceActions.addCruceClasificacion,
  props<{ id_clasificacion?: number, clasificacion?: string, id_cat_observacion? : number }>()
);

export const addCruceObservacion = createAction(
  CruceActions.addCruceObservacion,
  props<{id_cat_observacion? : number }>()
);

export const addCruceCantidadEjes = createAction(
  CruceActions.addCruceCantidadEjes,
  props<{cantidad_ejes? : number }>()
);

export const addCruceDinero = createAction(
  CruceActions.addCruceDinero,
  props<{ aventoDinero: number }>()
);

export const addCruceCaravana = createAction(
  CruceActions.addCruceCaravana,
  props<{ caravana: number }>()
);

export const clearCruce = createAction(
  CruceActions.clearCruce
);

export const postCruce = createAction(
  CruceActions.postCruce,
  props<{ cruce: Cruce }>()

);

export const postedCruce = createAction(
  CruceActions.postedCruce,
  props<{ cruce: Cruce }>()
);
