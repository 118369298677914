import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY} from 'rxjs';
import {map, mergeMap, catchError, exhaustMap} from 'rxjs/operators';
import {RolloService} from '../../services/rollo.service';
import {Rollo} from'../../models/pouch.db';
import {ActionsRollo} from '../actions/rollo.actions';
import {RolloState} from '../../core/models/rollo.state';

@Injectable()
export class RolloEffects {

  rollo: Rollo;

  postRollo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionsRollo.POST_ROLLO),
      exhaustMap((action : RolloState) => this.rolloService.saveRolloLocal(action.rollo)
        .pipe(
          map(rollo => ({type: ActionsRollo.POSTED_ROLLO, rollo})),
          catchError(() => EMPTY)
        ))
    );
  });


  constructor(
    private actions$: Actions,
    private rolloService:RolloService
  ) {
  }
}
