import { Component, EventEmitter, OnInit, Output ,LOCALE_ID, Inject} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { Carril, Caseta, Turno } from 'src/app/models';
import { Turnos } from 'src/app/models/dash';
import { CasetaService } from 'src/app/services/caseta.service';
import { CorteService } from 'src/app/services/corte.service';
import { FormatoData, FormatoService } from 'src/app/services/formato.service';
import { TurnoService } from 'src/app/services/turno.service';
import { formatDate } from '@angular/common';
import { AppState } from 'src/app/state/app.state';
import { Store } from '@ngrx/store';
import { selectUser } from 'src/app/state/selectors/user.selectors';
@Component({
  selector: 'app-formato-header',
  templateUrl: './formato-header.component.html',
  styleUrls: ['./formato-header.component.scss']
})
export class FormatoHeaderComponent implements OnInit {

  casetas: Caseta[];
  carriles: Carril[];
  turnos: Turnos[];
  cortes: any[];
  tiposReporte:string[] = ['Corte-caja','Reporte-incidencias','Registro-exentos-residentes','Retiros-parciales'];
  id_corte_usuario: number;
  cobrador: string;
  jefeTurno: string;
  tipoFormato: number = null;
  base64Reporte = "";
  showModal = false;
  corteForm : any
  usuario: any;
  id_caseta : number
  cortesTable: any[];

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private router: Router,
    private toastr: ToastrService,
    private activeRoute: ActivatedRoute,
    private casetaService: CasetaService,
    private turnoService: TurnoService,
    private corteService: CorteService,
    private formatoService: FormatoService,
    private ngxUiLoaderService : NgxUiLoaderService,
    private store : Store<AppState>,

  ) { }

  ngOnInit(): void {

    this.store.select(selectUser).subscribe( usuario => {
      this.usuario = usuario;
      //console.log(this.usuario);

      let casetaTroncal = this.usuario.caseta_usuario.find((caseta: any) => caseta.tipo === 1);

      this.id_caseta = casetaTroncal.id;
    });

    this.corteService.getCorteFormatoHeader(this.id_caseta, String(formatDate(new Date(), 'yyyy-MM-dd', 'en'))).subscribe( cortes => {
      this.cortes = cortes;
      this.cortesTable = this.cortes;
    });

    this.router.navigate(['/dashboard/formato-resumen']);

    this.casetaService.getCasetas().subscribe( casetas => {
      this.casetas = casetas;
    });

    this.turnoService.getTurnos().subscribe( turnos => {
      this.turnos = turnos;
    });

    this.corteForm = new FormGroup({
      caseta: new FormControl(this.id_caseta, Validators.required),
      //carril: new FormControl('', Validators.required),
      //turno: new FormControl(this.usuario.id_turno, Validators.required),
      fecha: new FormControl(formatDate(new Date(), 'yyyy-MM-dd', 'en'), Validators.required),
      //corte: new FormControl('', Validators.required),
    });
  }

  getCaseta() {
    this.corteFormReset(1);

    this.casetaService.getCaseta( this.corteForm.value.caseta ).subscribe( caseta => {
      this.carriles = caseta.carriles;
    });
  }

  getFormato(event: any) {
    this.tipoFormato = event.target.value;
  }

  getCorte() {
    console.log( this.cortes );
    this.id_corte_usuario = this.corteForm.value.corte;
    let user = this.cortes.find(corte => corte.id_corte_usuario === Number(this.id_corte_usuario));
    this.cobrador = user.cobrador;
    this.jefeTurno = user.jefeturno;

    this.routeActive(2);
  }

  corteFormReset(tipo: number) {
    switch (tipo) {
      case 1:
        this.corteForm.controls.carril.reset('');
        this.corteForm.controls.turno.reset('');
        this.corteForm.controls.fecha.reset('');
        this.routeActive(1);
        break;
      case 2:
        this.corteForm.controls.turno.reset('');
        this.corteForm.controls.fecha.reset('');
        this.routeActive(1);
        break;
      case 3:
        this.corteForm.controls.fecha.reset('');
        this.routeActive(1);
        break;
      case 4:
        this.routeActive(1);
        // buscar cortes
        let dataForm = this.corteForm.value;
        // this.corteService.getCorteFormatoHeader(dataForm.caseta, dataForm.carril, dataForm.turno, dataForm.fecha).subscribe( cortes => {
        //   if (!cortes.length) {
        //     this.corteFormReset(1);
        //     return this.toastr.info(`No existen cortes para esta configuracion`, '', { positionClass: 'toast-bottom-right' } )
        //   }
        //   this.cortes = cortes;
        // });

        break;
      default:
        break;
    }
  }

  routeActive(t: number) {
    if ( this.activeRoute.children.length > 0) {
      this.router.navigate(['/dashboard/formato-resumen']);
    }
    (document.getElementById("formatoResumen") as HTMLSelectElement).selectedIndex = 0;
    this.tipoFormato = null;

    if (t === 1) {
      this.corteForm.controls.corte.reset('');
      this.cortes = [];
      this.cobrador = "";
      this.jefeTurno = "";
    }
  }

  enviarConsulta() {
    console.log( this.corteForm.value );
    if (!this.corteForm.valid ) {
      return this.toastr.info(`Seleccione todos los campos para mostrar la informacion`, '', { positionClass: 'toast-bottom-right' } )
    }
    this.corteService.getCorteFormatoHeader(this.corteForm.value.caseta,this.corteForm.value.fecha).subscribe( cortes => {
      this.cortes = cortes;
      this.cortesTable = this.cortes;

    });


    // this.formatoService.modificarData = {
    //   id_corte_usuario: Number(this.corteForm.value.corte),
    //   tipo: Number(this.tipoFormato),
    // };

    // let catFormatos = {
    //   1 : 'corte',
    //   2 : 'incidencia',
    //   3 : 'exento',
    //   4 : 'retiro',
    // }

    // this.router.navigate(['/dashboard/formato-resumen/' + catFormatos[this.tipoFormato]]);

  }

  async imprimirPDF(download, corte){
    this.ngxUiLoaderService.start();
    let { fecha ,turno } = this.corteForm.value;
    let data = {
      "id_corte": corte.id_corte_usuario,
      "fecha" : corte.fecha_asignacion,
      "turno" : corte.id_turno,
      //"formato": this.tipoFormato,
      "download": download, //bandera para imprimir o visualizar el PDF
    }
    await this.corteService.getDataReporte(data)
    .then((data:any) => {
        if (download) {
          let date = formatDate(new Date(), 'dd-MM-yyyy' ,this.locale);
          let downloadURL = window.URL.createObjectURL(data);
          let link = document.createElement('a');
          link.href = downloadURL;
          link.download = `concentrado_${corte.cobrador}_${date}.pdf`;
          link.click();
          this.ngxUiLoaderService.stop();
          this.toastr.success('Se ha descargado el documento con éxito', '', { positionClass: 'toast-bottom-right' } )
        }else{
          this.base64Reporte = data+'#toolbar=0'; //Eliminar cabecera de iframe
          this.openModal();//abrir el modal
          this.ngxUiLoaderService.stop();
        }
    }).catch((err)=>{
        console.log(err);
        this.toastr.error("Por favor inténtalo nuevamente","Se ha producido un error", { positionClass : 'toast-bottom-right'})
        this.ngxUiLoaderService.stop();
    });
  }

  imprimir(){

  }


  openModal(){
    this.showModal = !this.showModal;
  }

  getTurno(e){

    this.cortesTable = this.cortes.filter(corte => corte.id_turno == e.target.value);

  }

}
