import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FondoDenominacion } from 'src/app/models/dash';
import { AsignarFondoService } from 'src/app/services/asignar-fondo.service';
import { BitacoraService } from 'src/app/services/bitacora.service';
import { AppState } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/selectors/user.selectors';

@Component({
  selector: 'app-bitacora',
  templateUrl: './bitacora.component.html',
  styleUrls: ['./bitacora.component.scss']
})
export class BitacoraComponent implements OnInit {

  verDetalle: boolean = false;
  showAreaNota: boolean = false;
  bitacoras: any;
  bitacoraTemp: any;
  nota: string
  usuario: any
  catBitacoras: any
  catPadres: any
  catTipos = []
  catHijos: any
  tipo: any
  area: any
  fecha: any
  constructor(
    private router: Router,
    private toastr: ToastrService,
    public asignarFondo: AsignarFondoService,
    private store: Store<AppState>,
    private ngxUiLoaderService: NgxUiLoaderService,
    private bitacoraService: BitacoraService
  ) { }

  ngOnInit(): void {
    // Obtener el usuario
    this.store.select(selectUser).subscribe(usuario => {
      this.usuario = usuario;
    });
    this.getData()
  }

  getData() {
    this.bitacoraService.getBitacora()
      .subscribe(data => {
        this.bitacoras = data;
      })
    this.bitacoraService.getCatBitacora()
      .subscribe(cat => {
        this.catBitacoras = cat;
        this.catPadres = this.catBitacoras.filter(padres => padres.id_padre == null)
        console.log(this.catPadres);

      })
  }
  openModal(bitacora) {
    this.verDetalle = true;
    this.showAreaNota = false;
    this.bitacoraTemp = bitacora;

  }

  closeModal() {
    this.verDetalle = false;
    this.showAreaNota = false;
  }

  openSeguimiento() {
    this.showAreaNota = true;
  }

  saveSeguimiento() {
    let data = {
      nota: this.nota,
      id_usuario: this.usuario.id,
      id_bitacora: this.bitacoraTemp.id
    }
    this.bitacoraService.saveSeguimientoBitacora(data)
      .subscribe(data => {
        this.nota = ''
        this.verDetalle = false;
        this.showAreaNota = false;
        this.getData()
        this.toastr.success(`Nota agregada correctamente`, 'Correcto', { positionClass: 'toast-bottom-right' });

      })
  }

  getEstado(status) {
    switch (status) {
      case 1:
        return 'Inicial';
        break;
      case 2:
        return 'Proceso';

        break;
      case 3:
        return 'Cerrada';

        break;
      default:
        return 'Validado';

        break;
    }
  }
  getNota(e) {

    this.nota = e.target.value;

  }


  getChildren(e) {
    this.catTipos = []
    console.log(e.target.value);
    this.area = e.target.value
    this.catHijos = this.catBitacoras.filter(hijos => hijos.id_padre == e.target.value)
    console.log(this.catHijos);
    // this.getTipos()
    for (let i = 0; i < this.catHijos.length; i++) {
      let index = this.catTipos.findIndex(m => m.tipo === this.catHijos[i].tipo);
      index === -1 ? this.catTipos.push({
        tipo: this.catHijos[i].tipo,
      }) : null;
    }
  }

  getTipo(e) {
    this.tipo = e.target.value

  }

  busquedaBitacora() {
    if (this.tipo || this.area || this.fecha) {
      this.bitacoraService.busquedaBitacora({ tipo: this.tipo, id: this.area, fecha: this.fecha })
        .subscribe(data => {
          console.log(data);
          this.bitacoras = data;
        })
    }

  }

  getDate(e) {
    this.fecha = e.target.value
  }
}
