import { createSelector } from '@ngrx/store';
import {AppState} from '../app.state';
import {RetiroState} from '../../core/models/retiro.state';

export const selectRetiroFeature = (state: AppState) => state.retiro;

export const selectRetiro = createSelector(
  selectRetiroFeature,
  (state: RetiroState) => state.retiro
);

export const selectMontoRetiro = createSelector(
  selectRetiroFeature,
  (state: RetiroState) => {
    return state.monto
  }
)

export const selectRetiroPrint = createSelector(
  selectRetiroFeature,
  (state: RetiroState) => state.retiroPrint
);
