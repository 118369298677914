import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { Retiro } from '../models';
import { environment } from 'src/environments/environment';
import PouchDB from 'pouchdb-browser';
import {from, Observable, Subject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RetiroService {
  private db: any;
  counterDocs$ = new Subject<number>();
  actualCount = 0;
  private ENDPOINT = environment.ENDPOINTS.RETIRO;

  constructor(private http:HttpClient) {
    this.db = new PouchDB('db_retiro');
    this.getInfoLocal();
  }

  saveRetiro(retiro){
    return this.http.post<Retiro[]>(this.ENDPOINT,retiro);
  }

  printRetiro(retiro){
    return this.http.post<any[]>(this.ENDPOINT+'/print-retiros',retiro);
  }

  getRetiro(){
    return this.http.get<Retiro[]>(this.ENDPOINT);
  }

  getRetirosbyCortes(id_usuario : number){
    return this.http.get<any[]>(this.ENDPOINT +`/get-retiro-by-cortes/`+id_usuario);
  }

  updateMontoRetiro(retiro : any ){
    return this.http.put<Retiro[]>(this.ENDPOINT +`/`+retiro.id_retiro, retiro );
  }

  saveRetiroLocal(dataSchema: Retiro): Observable<Retiro> {
    let result: Promise<Retiro> = new Promise((resolve, reject) => {
      this.db.get(dataSchema._id)
        .then(async (doc: any) => {
          console.log('encontrado y actualizado')
          delete dataSchema._id;
          doc = Object.assign(doc, dataSchema);
          try{
            let result =  await this.db.put(doc);
            resolve(result);
          }catch(err){
            reject(err)
          }

        }).catch(async () => {
          try{
            let result = await this.db.post(dataSchema)
            resolve(result);
            console.log('se creo new register')
            this.actualCount = this.actualCount + 1;
            console.log(this.actualCount)
            this.counterDocs$.next(this.actualCount)
          }catch(err){
            reject(err);
          }

        });
    })

    return from(result);


  }

  public deleteRetiroLocal(id: number) {
    return this.db.get(id)
      .then(doc => {
        return this.db.remove(doc);
      })
      .then(
        _ => {
          this.actualCount = this.actualCount - 1;
          console.log(this.actualCount)
          this.counterDocs$.next(this.actualCount)
          return true;
        }
      )
      .catch(err => {
        console.log(err)
      })
  }
  public getInfoLocal() {
    this.db.info().then(data => {
      this.actualCount = data.doc_count;
      console.log(this.actualCount)
      this.counterDocs$.next(this.actualCount);
    });
  }

  public getAll(): Promise<Retiro[]> {
    return new Promise((resolve, reject) => {
      this.db.allDocs({
        include_docs: true
      })
        .then(data => {
          let eventsRetiro= data.rows.map(
            (row: any): Retiro => {

              return ({
                _id: row.doc._id,
                fecha_hora: row.doc.fecha_hora,
                id_corte_usuario: row.doc.id_corte_usuario,
                id_usuario_retira: row.doc.id_usuario_retira,
                id_denominacion:row.doc.id_denominacion,
                monto:row.doc.monto
              });

            }
          )
          resolve(eventsRetiro);
        }).catch(err => {
          reject(err)
        })
    })
  }

  getRetirosParcials(id_caseta: number, id_turno: number) {
    return this.http.get<any[]>(this.ENDPOINT + `/parcial/${ id_caseta }/${ id_turno }`);
  }

  updateRetiroImpresion( retiros: any ) {
    return this.http.post<any>(`${ this.ENDPOINT }/update-retiro-impresion`, retiros);
  }

  getRetiroRonda( id_caseta: number, fecha: any, id_turno: number ) {
    return this.http.get<any[]>(`${ this.ENDPOINT }/get-retiro-ronda/${id_caseta}/${fecha}/${id_turno}`);
  }

  getRonda( id_caseta: number, fecha: any, id_turno: number, ronda: number ) {
    return this.http.get<any>(`${ this.ENDPOINT }/get-ronda/${id_caseta}/${fecha}/${id_turno}/${ronda}`);
  }

  deleteRetiro(id_retiro: number) {
    return this.http.get<any>(`${ this.ENDPOINT }/delete-retiro-from-jt/${ id_retiro }`);
  }

}


