import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BtnRapido, Cruce } from 'src/app/models';
import { postBtnRapido } from 'src/app/state/actions/btnRapido.actions';
import { addCruceCantidadEjes, addCruceClasificacion, postCruce } from 'src/app/state/actions/cruce.actions';
import { AppState } from 'src/app/state/app.state';
import { selectBtnRapido } from 'src/app/state/selectors/btnRapido.selectors';
import { selectPermisos } from 'src/app/state/selectors/caseta.selectors';
import { selectCruce } from 'src/app/state/selectors/cruce.selectors';
import { selectTipoVehiculo } from 'src/app/state/selectors/tipoVehiculo.selectors';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { TYPE_CRUCE_PADRE, TYPE_PERMISO } from 'src/app/utils/enum';
import { finished } from 'stream';
import {foundPermiso} from '../../utils';

@Component({
  selector: 'app-ecc',
  templateUrl: './ecc.component.html',
  styleUrls: ['./ecc.component.scss']
})
export class EccComponent implements OnInit {
  eec = [
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
  ];
  tr = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
  ];
  tiposVehiculo = [
    { id: 1, tipo: 'Moto', icon: 'ic_moto.svg' },
    { id: 2, tipo: 'Auto', icon: 'ic_auto.svg' },
  ];
  cruce: any = null;
  user: any = null;
  btnTemp: any
  clasificacion: number
  btnRapido: any = null;
  //permisos
  permisos = [];
  constructor(private store: Store<AppState>,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.store.select(selectBtnRapido).subscribe(btnRapido => this.btnRapido = btnRapido);

    console.log(this.btnRapido);

    this.store.select(selectCruce).subscribe(cruce => this.cruce = cruce);
    console.log(this.cruce);
    this.store.select(selectUser).subscribe(user => this.user = user);

    // Permisos para retirar o devolucion por roles
    this.store.select(selectPermisos).subscribe(permiso => {
      this.permisos = permiso
    });
    this.foundPermiso(TYPE_PERMISO.TR)
  }

  saveEcc(id) {

    //validar si es t+r debe seleccionar moto o auto.
    if (this.cruce.id_clasificacion == 3 && !this.clasificacion) {
      this.toastr.info('Seleciona Moto o Auto', '', { positionClass: 'toast-bottom-right' })

    } else {
      //validar si viene de botones rapidos
      if (this.btnRapido.id_subtipo) {
        // Se guarda la info de btnRapido
        let BtnRapido: BtnRapido = {

          fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
          id_subtipo: this.btnRapido.id_subtipo,
          id_corte_usuario: this.user.id_corte_usuario,
          id_clasificacion: this.clasificacion ? this.clasificacion : 14,//carga 9
          id_cat_observacion: this.btnRapido.id_cat_observacion,
          cantidad_ejes: id
        }
        this.store.dispatch(postBtnRapido({ btnRapido: BtnRapido }))
        this.router.navigate(['/mopa',]);
        this.finished();
      } else {
        //mandar al componente avento dinero si es eludido
        if (this.cruce.idPadre == TYPE_CRUCE_PADRE.ELUDIDOS) {
          this.store.dispatch(addCruceClasificacion({ id_clasificacion: this.clasificacion ? this.clasificacion : 14 }))
          this.store.dispatch(addCruceCantidadEjes({ cantidad_ejes: id }))
          this.router.navigate(['/mopa/dinero-eludido']);
        } else {
          let cruce: Cruce = {
            fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
            id_corte_usuario: this.user.id_corte_usuario,
            id_subtipo: this.cruce.idChildren,
            id_clasificacion: this.clasificacion ? this.clasificacion : 14,//carga 9
            id_cat_observacion: this.cruce.id_cat_observacion,
            cantidad_ejes: id
          }
          this.store.dispatch(postCruce({ cruce }))
          this.finished()
        }
      }


    }

  }

  addClasificacion(id: number, tipo, btnClasificacion) {
    this.clasificacion = id;
    //estilo de botones
    this.isSelected(btnClasificacion)
    if (this.btnTemp) {
      this.isSelected(this.btnTemp)
    }
    this.btnTemp = btnClasificacion
    console.log(this.clasificacion);

  }

  returnPage() {
    this.router.navigate(['/mopa/subtipo/' + this.cruce.id_type])
  }

  isSelected(btn) {
    if (btn.classList.contains('selected')) {
      btn.classList.remove('selected');
      btn.classList.remove('dark:bg-blue-800');
      btn.classList.remove('bg-blue-800');
      btn.classList.remove('text-white');
      btn.classList.add('dark:bg-transparent');
    } else {
      btn.classList.add('selected');
      btn.classList.add('dark:bg-blue-800');
      btn.classList.add('bg-blue-800');
      btn.classList.add('text-white');
      btn.classList.remove('dark:bg-transparent');

    }
  }
  finished(){
    this.toastr.success('Cruce guardado con éxito', '', { positionClass: 'toast-bottom-right' })
    this.router.navigate(['/mopa'])
  }

  foundPermiso(id: number) {
    const permisos = foundPermiso(id, this.permisos);
    if (permisos.length > 0) {
      this.tr = [{ id: 1 }];
      console.log(this.tr);
    }
    return permisos
  }
}
