import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LogUsuario } from '../models/dash';


@Injectable({
  providedIn: 'root'
})
export class LogUsuarioService {

  private ENDPOINT = environment.ENDPOINTS.LOGUSUARIO;
  private ENDPOINTLOG = environment.ENDPOINTS.LOG_INICIO;

  constructor(
    private http: HttpClient
  ) { }

  getLogUsuarios() {
    return this.http.get(this.ENDPOINT);
  }

  saveLogUsuario( data: any ) {
    return this.http.post<LogUsuario>(this.ENDPOINT, data);
  }

  updateLogUsuario( data: any, id: number ) {
    return this.http.put<LogUsuario>(this.ENDPOINT+`/${id}`, data);
  }

  // BUG FIX
  // searchLogUsuario( hora_inicio: any, hora_fin: any, id_usuario: number) {
  //   return this.http.get(this.ENDPOINTLOG+`/${hora_inicio}/${hora_fin}/${id_usuario}`);
  // }
  searchLogUsuario( id_usuario: number ) {
    return this.http.get(this.ENDPOINTLOG+`/${id_usuario}`);
  }

  finalizarTurno( id_log_usuario: number) {
    let data = {
      id_log_usuario: id_log_usuario
    }
    return this.http.post<any>(this.ENDPOINT+`/finalizar-turno`, data);
  }

  getMontoCorte( id_corte_anterior: number ) {
    return this.http.get<LogUsuario>(this.ENDPOINT+`/monto-by-corte/${id_corte_anterior}`);
  }

}
