import {Caseta} from '../../models';
import {createReducer, on} from '@ngrx/store';
import {clearDevolution, loadDevolucion, loadedDevolucion, postDevolucion, postedDevolucion} from '../actions/devolucion.actions';
import {DevolucionState} from '../../core/models/devolucion.state';
import {sample} from 'rxjs/operators';


export const initialState:DevolucionState = {loading: false, devolucion: null, contDevolucion: 0};


export const devolucionReducer = createReducer(
  initialState,
  on(loadDevolucion, (state) => {
    return {...state, loading: true};
  }),
  on(loadedDevolucion, (state,{devolucion}) => {
    return {...state, loading: false, devolucion};
  }),
  on(postDevolucion, (state,{devolucion}) => {
    return {...state, loading: true,contDevolucion: 0};
  }),
  on(postedDevolucion, (state,{devolucion}) => {
    return {...state, loading: false, devolucion, contDevolucion:0};
  }),
  on(clearDevolution, (_)=>{
    return initialState
  })
);
