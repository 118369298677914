import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formato-resumen',
  templateUrl: './formato-resumen.component.html',
  styleUrls: ['./formato-resumen.component.scss']
})
export class FormatoResumenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
