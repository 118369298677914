import { Injectable } from '@angular/core';
import {interval, Subject} from 'rxjs';
import {HealthService} from './health.service';
import {filter, switchMap, take, timeout} from 'rxjs/operators';
declare const window: any;
@Injectable({
  providedIn: 'root'
})
export class OfflineOnlineService {


  private internalConnectionChanged = new Subject<boolean>();
  private  lastConnection = true;

  get connectionChanged() {
    return this.internalConnectionChanged.asObservable();
  }

  get isOnline() {
    return !!window.navigator.onLine;
  }

  constructor( private healthService: HealthService) {
    window.addEventListener('online', () => this.updateOnlineStatus());
    window.addEventListener('offline', () => this.updateOnlineStatus());
    this.polling();
  }

  private updateOnlineStatus() {
    this.internalConnectionChanged.next(window.navigator.onLine);
  }

  polling(){
    interval(5000).pipe(
      switchMap(()=> this.healthService.getConnection()),
        filter((data : any)=>{
          console.log(data);
          return data.status;
        }),
      take(1),
      timeout(10000)
    ).subscribe(result =>{
      this.polling();
      if(!this.lastConnection) {
        this.internalConnectionChanged.next(true);
        this.lastConnection = true;
      }

    }, error => {
      if(this.lastConnection) {
        this.internalConnectionChanged.next(false);
        this.lastConnection = false;
      }
      this.polling();

    });
  }


}
