import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Cruce, SubtipoCruce } from '../../models';
import { SubtipoCruceService } from '../../services/subtipo-cruce.service';
import { TipoCruceService } from '../../services/tipo-cruce.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import { selectCaseta } from '../../state/selectors/caseta.selectors';
import { selectCruce } from '../../state/selectors/cruce.selectors';
import { addCruceClasificacion, addCruceSubType, clearCruce } from '../../state/actions/cruce.actions';
import { TYPE_CRUCE, TYPE_CRUCE_PADRE } from '../../utils/enum';
import { CruceState } from '../../core/models/cruce.state';
import * as moment from 'moment';
import { postCruce } from '../../state/actions/cruce.actions';
import { ToastrService } from 'ngx-toastr';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { PushNotificationService } from 'src/app/services/push-notification.service';

@Component({
  selector: 'app-subtipo-cruce',
  templateUrl: './subtipo-cruce.component.html',
  styleUrls: ['./subtipo-cruce.component.scss']
})
export class SubtipoCruceComponent implements OnInit {
  idTipo: number = 0;
  subtipos: SubtipoCruce[] = [];
  cruce: any;
  user: any
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tipoCruceService: TipoCruceService,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private pushNotService: PushNotificationService

  ) {
    this.idTipo = Number(this.activatedRoute.snapshot.params['id']);
    console.log(this.idTipo);
  }

  ngOnInit(): void {

    this.store.select(selectCruce).subscribe(cruce => {
      this.cruce = cruce;
      console.log(this.cruce);

      this.subtipos = this.cruce.childrens;
      console.log(this.subtipos);

    });

    this.store.select(selectUser).subscribe(user => this.user = user);
  }

  goToClasificacion(subtipo: SubtipoCruce, padreSubtipo) {
    this.store.dispatch(addCruceSubType({ namePadre: padreSubtipo.nombre, idPadre: padreSubtipo.id, nameChildren: subtipo.nombre, idChildren: subtipo.id }));
    let cruce: Cruce = {
      fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
      id_corte_usuario: this.user.id_corte_usuario,
      id_subtipo: this.cruce.idChildren,
    }
    switch (this.cruce.id_type) {
      //Si es alarma no se captura clasificacion vehicular
      case TYPE_CRUCE.ALARMAS:
        this.notificacion('Falla')
        this.finishEvent('Falla')
        //Se guarda la info del cruce
        this.store.dispatch(postCruce({ cruce }))

        break;

      case TYPE_CRUCE.INCIDENCIAS:
        //Se guarda la info del cruce
        console.log(this.cruce.idChildren);
        switch (this.cruce.idPadre) {
          case TYPE_CRUCE_PADRE.EJES_LEVANTADOS:

            if (this.cruce.idChildren == 5) {//4 ejes = 5
              this.router.navigate(['/mopa/ecc'])
            } else {
              this.store.dispatch(postCruce({ cruce }))
              this.finishEvent('Cruce')
            }
            break;

          case TYPE_CRUCE_PADRE.ELUDIDOS:
            //eludido avienta dinero sin clasificacion vehicular
            if (this.cruce.idChildren == 74) {//avienta dinero
              console.log('entras perro o qie');

             return this.router.navigate(['/mopa/dinero-eludido']);
            }

            if (this.cruce.idChildren == 10) {//cruza por costado
              this.store.dispatch(addCruceClasificacion({ clasificacion: 'Moto', id_clasificacion: 1 }))
              let cruce: Cruce = {
                fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
                id_corte_usuario: this.user.id_corte_usuario,
                id_subtipo: this.cruce.idChildren,
                id_clasificacion: 1,//MOTO
              }
              this.store.dispatch(postCruce({ cruce }))
              this.finishEvent('Cruce')
            } else {
              this.router.navigate(['/mopa/clasificacion-vehicular']);
            }
            break;
          case TYPE_CRUCE_PADRE.NO_EMITIDOS:
          case TYPE_CRUCE_PADRE.CANCELADOS:
            this.router.navigate(['/mopa/clasificacion-vehicular']);
            break;

        }
        break;
      case TYPE_CRUCE.EXCENTOS:
        this.router.navigate(['/mopa/clasificacion-vehicular']);
        break;

    }


  }

  async notificacion(type: string) {
    this.toastr.success(`Notificando ${type}`, '', { positionClass: 'toast-bottom-right' });
    let response = await this.pushNotService.sendNotification({ title: "MOPA", body: `Solicitud de ${type}, Carril ${this.user.nom_carril}`, users: [this.user.id_jefe_turno] })
  }

  finishEvent(type: string) {
    this.toastr.success(`${type} guardada con éxito`, '', { positionClass: 'toast-bottom-right' })
    this.store.dispatch(clearCruce());
    this.router.navigate(['mopa/home']);
  }

}
