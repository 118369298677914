import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RolPermiso} from '../models';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatPermisosService {

  constructor(private http: HttpClient) { }

  getPermissionByRol(id_rol : number){
      return this.http.get<RolPermiso>(`${environment.ENDPOINTS.CAT_MENU_PERMISOS}/${id_rol}`);
  }
}
