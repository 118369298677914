import {createAction, props} from '@ngrx/store';
import {PanelIzquierdoState} from '../../core/models/panelIzquierdo.state';
import {BtnRapidoActions} from './btnRapido.actions';


export enum PanelIzquierdoActions {
  changeStatus = '[PanelIzquierdo] CHANGE',
  addEvent = '[PanelIzquierdo] ADD',
  setStatus = '[PanelIzquierdo] SET',
  clearStatus = '[PanelIzquierdo] Clear',
}

export enum TypeBoton {
  DEVOLUCION = 0,
  RETIRO = 1,
  MORRALLA = 2,
  ROLLO = 3
}

export enum StatusPanel {
  INITIAL = 0,
  NOTIFICADO = 1,
  PROCESO = 2
}

export const changeStatus = createAction(
  PanelIzquierdoActions.changeStatus,
  props<{ type_boton: TypeBoton, status: StatusPanel }>()
);

export const addEvent = createAction(
  PanelIzquierdoActions.addEvent,
  props<{type_boton: TypeBoton, status: StatusPanel, id_denominacion : number}>()
);

export const setStatus = createAction(
PanelIzquierdoActions.setStatus,
  props<{type_boton: TypeBoton}>()
)


export const clearStatusPanel = createAction(
  PanelIzquierdoActions.clearStatus,
)
