<div class="flex items-center min-h-screen p-6 bg-gray-100 dark:bg-gray-900">
  <div class="flex-1 h-full max-w-xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
    <div class="flex flex-col overflow-y-auto md:flex-row">
      <!-- <div class="h-32 md:h-auto md:w-1/2">
        <img
          aria-hidden="true"
          class="object-cover w-full h-full dark:hidden"
          src="/assets/images/login-light.svg"
          alt="MOPA"
        />
        <img
          aria-hidden="true"
          class="hidden object-cover w-full h-full dark:block"
          src="/assets/images/login-dark.svg"
          alt="MOPA"
        />
      </div> -->
      <div class="flex items-center justify-center p-6 sm:p-12 md:w-full">
        <div class="w-full">

          <div class="grid grid-cols-2">

            <div class="grid grid-rows-2 text-xs">
              <span class="text-xs uppercase font-medium text-gray-400 hover:text-gray-600">Nombre: {{userActual?.nom_user}}</span>
              <span class="text-xs capitalize font-medium text-sky-500 hover:text-sky-700">Turno: {{userActual?.turno}}</span>
            </div>

            <div class="flex justify-end">

              <app-switch-theme></app-switch-theme>

            </div>

          </div>

          <hr class="mt-4 mb-8 border-gray-200 dark:border-gray-700" />

          <img
            aria-hidden="true"
            class="mx-auto h-12 w-auto dark:hidden"
            src="/assets/images/sogak-logo-light.svg"
            alt="SOGAK"
          />
          <img
            aria-hidden="true"
            class="hidden mx-auto h-12 w-auto dark:block"
            src="/assets/images/sogak-logo-dark.svg"
            alt="SOGAK"
          />

          <div *ngIf="isCobrador; else notCobrador">
            <h1 class="mt-6 mb-6 text-xl text-center font-semibold text-gray-700 dark:text-gray-200">COBRADOR</h1>
            <app-teclado-numerico placeholder="Número de empleado" (change)="onChange($event)"  (confirmValue)="confirm($event)"></app-teclado-numerico>
          </div>

          <ng-template #notCobrador>

            <h1 class="mt-6 mb-6 text-xl text-center font-semibold text-gray-700 dark:text-gray-200">CONFIGURACIÓN</h1>

            <input (focus)="onFocusNumEmpleado($event)"  [value]="credentials.num_empleado" class="border rounded border-gray-300 placeholder-gray-500 px-3 py-2 block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray h-12 form-input ng-pristine ng-invalid ng-touched" placeholder="Número de empleado">
            <input (focus)="onFocusPassword($event)"  type="password" [value]="credentials.password" class="border rounded border-gray-300 placeholder-gray-500 px-3 py-2 block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray h-12 form-input ng-pristine ng-invalid ng-touched" placeholder="Password">
            <app-teclado-numerico [multipleInputs]="true" placeholder="Número de empleado" (change)="onChange($event)"  (confirmValue)="confirmJT($event)"></app-teclado-numerico>
          </ng-template>

          <input class="text-m mt-3" type="checkbox" id="cbox2" [(ngModel)]="isCobrador" >
          <label class="text-m mt-3 text-center font-semibold text-gray-700 dark:text-gray-200" for="cbox2"> Soy cobrador</label>

        </div>
      </div>
    </div>
  </div>
</div>
