import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FondoDenominacion } from 'src/app/models/dash';
import { AsignarFondoService } from 'src/app/services/asignar-fondo.service';
import { DevolucionService } from 'src/app/services/Devolucion.service';
import { AppState } from 'src/app/state/app.state';
import { getTurno } from '../../utils';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Turno } from 'src/app/models';
import { NotificacionesPushService } from 'src/app/services/notificaciones-push.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-devolucion',
  templateUrl: './devolucion.component.html',
  styleUrls: ['./devolucion.component.scss']
})
export class DevolucionComponent implements OnInit {

  denominacionFormato: FondoDenominacion[] = [];
  usuario: any;
  caseta_id: number = null;
  devolucionFinal: number = 0;
  fondo: number = 0;
  subtotales: number[] = [];
  devolucion = [];
  devolucionCarril = [];
  arrayIdDevolucion = [];

  // modal tickets
  ticketFondoModal = false;
  ticketEntregaModal = false;
  turno: Turno;
  fondoCarrilActual = [];
  fondoEntregaData = [];
  fondoCarrilSuma: number = 0;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private router: Router,
    private asignarFondoService: AsignarFondoService,
    private devolucionService: DevolucionService,
    private notificacionPushService: NotificacionesPushService,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
  ) { }

  ngOnInit(): void {

    // obtener turno actual
    this.turno = getTurno();

    // obtener casetas de usuario
    this.store.select(selectUser).subscribe(usuario => this.usuario = usuario);
    // obtener fondo para conjunto de casetas
    this.asignarFondoService.getFondoDenominacion( this.usuario.caseta_usuario[0].id ).then( () => {
      this.denominacionFormato = this.asignarFondoService.fondoDenominacion.slice(0, 10).reverse();
    });

  }

  getCaseta(caseta: any) {

    this.ngxUiLoaderService.start();

    this.caseta_id = Number(caseta.target.value);

    // this.turno = getTurno();

    this.notificacionPushService.getNotificacionCaseta(this.turno.id, this.usuario.id).subscribe( fondoCabina => {
      this.fondoCarrilActual = fondoCabina;
      for (let fc of fondoCabina) {
        this.fondoCarrilSuma += (fc.fondo ? fc.fondo : 0);
      }
    });

    this.devolucionService.getDevolucionCaseta(this.caseta_id).subscribe( devoluciones => {
      this.devolucionCarril = devoluciones;
    });

    this.devolucionService.getDevolucionTicket(this.caseta_id, this.turno.id).subscribe( devTicket => {
      this.fondoEntregaData = devTicket;
    });

    let data = {
      id_caseta: this.caseta_id,
      id_jefe_turno: this.usuario.id,
      id_turno: this.turno.id
    }

    this.devolucionService.fondoByCortes(data).subscribe(fondo => {
      console.log( fondo );
      if (fondo[0]['devoluciones'] && fondo[0]['fondo']) {
        this.fondo = fondo[0]['fondo'];
        this.arrayIdDevolucion = fondo[0]['devoluciones'].split(",");
      } else {
        this.toastr.info('No se han realizado devoluciones desde carril', '', { positionClass: 'toast-bottom-right' })
      }
      this.ngxUiLoaderService.stop()
    });
  }

  devolucionTotal(event: any, denominacion) {
    this.devolucionFinal = 0;
    let cantidad = Number(event.target.value);
    let subTotal = cantidad * (denominacion.cantidad);
    this.subtotales[denominacion.id] = subTotal;

    // total
    for (let total in this.subtotales) {
      if (this.subtotales[total]) {
        this.devolucionFinal += Number(this.subtotales[total]);
      }
    }

    this.insertDevolucion(cantidad, denominacion)
  }

  insertDevolucion(cantidad: number, denominacion: any) {
    let index = this.devolucion.findIndex(m => m.id_denominacion === denominacion.id);
    index === -1 ? this.devolucion.push({
      id_denominacion: denominacion.id,
      cantidad: cantidad,
    }) : this.devolucion[index].cantidad = cantidad;

    console.log(this.devolucion);
  }

  saveDevolucion() {
    console.log(this.devolucion);

    if (this.fondo != this.devolucionFinal) {
      return this.toastr.error('Los montos totales no coinciden', 'Error', { positionClass: 'toast-bottom-right' })
    }

    this.ngxUiLoaderService.start()
    let data = {
      devoluciones: this.devolucion,
      id_caseta: this.caseta_id,
      id_usuario_retira: this.usuario.id,
      arrayIdDevoluciones: this.arrayIdDevolucion

    }
    this.devolucionService.savefondoByCortes(data).
      subscribe(response => {
        console.log(response);
        //validar si ya se realizo la devolucion por caseta
        this.ngxUiLoaderService.stop()
        this.toastr.success('Se ha guardado con éxito la información', '', { positionClass: 'toast-bottom-right' })
        this.router.navigate(['/dashboard/fondo-operacion']);

      })


  }


  async imprimirPDF(){
    console.log(this.fondoCarrilActual);
    this.ngxUiLoaderService.start();
    
    let data = {
      "fechaActal": formatDate(new Date(), 'dd-MM-yyyy' ,this.locale),
      "caseta": this.caseta_id,
      "jT": this.usuario.id,
      "turno": this.turno.id
    }

    console.log(data);

    await this.notificacionPushService.createFondoPDF(data)
    .then((res:any) => {
      console.log(res);
          let downloadURL = window.URL.createObjectURL(res);
          let link = document.createElement('a');
          link.href = downloadURL;
          link.download = `comprobanteFondoInicial.pdf`;
          link.click();
          this.ngxUiLoaderService.stop();
          this.toastr.success('Se ha descargado el documento con éxito', '', { positionClass: 'toast-bottom-right' })      
    }).catch((err)=>{
        console.log(err);
        this.toastr.error("Por favor inténtalo nuevamente","Se ha producido un error", { positionClass : 'toast-bottom-right'})
        this.ngxUiLoaderService.stop();
    });
   }

  imprent() {
    window.print();
    this.toastr.success('Ticket impreso','', { positionClass: 'toast-bottom-right' } );
  }


}
