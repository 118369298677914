import {EventEmitter, Injectable} from '@angular/core';
import {Observable, Observer, Subject} from 'rxjs';
import {Socket} from 'ngx-socket-io';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService /*extends Socket */{

  outEvent : EventEmitter<any> = new EventEmitter<any>();
  callback : EventEmitter<any> = new EventEmitter<any>();


  constructor() {
    /*
    super({
      url: environment.WS,
      options:{
        query:{
          nameRoom: 'notification'
        }
      }
    });
    this.listen();
     */
  }

  listen =  () => {
    //this.ioSocket.on('event' , res => this.callback.emit(res))
  }


  emitEvent = (payload = {}) =>{
    //this.ioSocket.emit('event', payload);
  }

}

