import {createAction, props} from '@ngrx/store';
import {Devolucion} from '../../models';

export enum ActionsDevolucion {
  LOAD_DEVOLUCION = '[Devolucion-GET] Load Devolucion',
  LOADED_DEVOLUCION = '[Devolucion-GET] Loaded Devolucion',
  POST_DEVOLUCION = '[Devolucion-POST] Post Devolucion',
  POSTED_DEVOLUCION = '[Devolucion-POST] Posted success',
  CLEAR_DEVOLUCION = '[Devolucion-CLEAR] Clear'
}


export const loadDevolucion = createAction(
  ActionsDevolucion.LOAD_DEVOLUCION
);

export const loadedDevolucion = createAction(
  ActionsDevolucion.LOADED_DEVOLUCION,
  props<{ devolucion: Devolucion }>()
);

export const postDevolucion = createAction(
  ActionsDevolucion.POST_DEVOLUCION,
  props<{ devolucion: Devolucion }>()

);

export const postedDevolucion = createAction(
  ActionsDevolucion.POSTED_DEVOLUCION,
  props<{ devolucion: Devolucion }>()
);


export const clearDevolution = createAction(
  ActionsDevolucion.CLEAR_DEVOLUCION
)
