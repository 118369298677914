<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase font-semibold text-base text-center text-gray-500 dark:text-gray-200">

    <div class="flex flex-col">
        <div class="flex justify-between items-center">
            <span></span>
            <span class="text-gray-500 dark:text-gray-50 py-2 font-bold">Cambiar contrasena</span>
            <span></span>
        </div>
        <hr class="mb-2 dark:border-gray-500">

        <div class="flex flex-row justify-around">
            <div>
                <div class="text-left mt-4 space-y-4">
                  <form [formGroup]="formPasswordUser">

                    <div>
                        <label for="newPassword" class="block text-xs font-medium mb-1">Nueva contraseña</label>
                        <input type="text" (keyup)="validatePassword($event)" formControlName="password" id="newPassword" name="newPassword" class="w-full border rounded border-gray-300 placeholder-gray-500 p-2 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input">
                    </div>

                    <div>
                        <label for="newPassword" class="block text-xs font-medium mb-1">Confirmar nueva contraseña</label>
                        <input type="password"  formControlName="passwordR" id="newPassword" name="newPassword" class="w-full border rounded border-gray-300 placeholder-gray-500 p-2 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input">
                        <span class="form-text text-danger"
                        *ngIf="campoNoValido('passwordR')">
                            Las contraseñas deben ser iguales !
                        </span>
                    </div>

                    <button  [disabled]="!formPasswordUser.valid" class="flex items-center mt-2 p-2 bg-gray-50 text-xs uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 hover:border-blue-600" (click)="updatePassword()">

                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                        </svg>

                        <span class="ml-2">
                            Guardar cambios
                        </span>

                    </button>
              </form>

                </div>

            </div>
        </div>

    </div>
</div>
