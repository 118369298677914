import {createAction, props} from '@ngrx/store';
import {TipoVehiculo} from '../../models';

export enum TipoVehiculoActions {
  LOAD_TIPO_VEHICULO = '[TipoVehiculo-GET] Load TipoVehiculo',
  LOADED_TIPO_VEHICULO = '[TipoVehiculo-GET] Loaded success',

}
export const loadTipoVehiculo = createAction(
  TipoVehiculoActions.LOAD_TIPO_VEHICULO,
);


export const loadedTipoVehiculo = createAction(
  TipoVehiculoActions.LOADED_TIPO_VEHICULO,
  props<{ tipoVehiculo: TipoVehiculo }>()
);
