import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY} from 'rxjs';
import {map, mergeMap, catchError, exhaustMap} from 'rxjs/operators';
import {CruceService} from '../../services/cruce.service';
import {Cruce} from '../../models/pouch.db';
import {CruceActions} from '../actions/Cruce.actions';
import {CruceState} from '../../core/models/Cruce.state';

@Injectable()
export class CruceEffects {

  Cruce: Cruce;

  postCruce$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CruceActions.postCruce),
      exhaustMap((action : CruceState) => this.CruceService.saveCruceLocal(action.cruce)
        .pipe(
          map(Cruce => ({type: CruceActions.postedCruce, Cruce})),
        ))
    );
  });

  constructor(
    private actions$: Actions,
    private CruceService: CruceService
  ) {
  }
}
