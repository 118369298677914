import {createAction, props} from '@ngrx/store';
import {Usuario} from '../../models';


export const loadUser = createAction(
  '[User-GET] Load User'
);


export const loadedUser = createAction(
  '[User-GET] Loaded success',
  props<{ usuario: any }>()
);

export const getUsers = createAction(
  '[Users-GET] GET Users'
);
export const addUserEstado = createAction(
  '[Users-ADD] ADD accion',
  props<{estado? : number }>()
);

export const changeCaseta = createAction(
  '[User-PUT] PUT caseta',
  props<{ caseta_selected: number }>()
);

export const changeAccion = createAction(
  '[User-PUT] PUT Accion',
  props<{id_cat_acciones: number }>()
);

export const addCorteUserAuxiliar = createAction(
  '[Users-ADD] ADD auxiliar',
  props<{id_corte_auxiliar? : number }>()
);
