import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Turno } from 'src/app/models';
import { FondoDenominacion } from 'src/app/models/dash';
import { AsaltoService } from 'src/app/services/asalto.service';
import { AsignarFondoService } from 'src/app/services/asignar-fondo.service';
import { CasetaService } from 'src/app/services/caseta.service';
import { CorteService } from 'src/app/services/corte.service';
import { AppState } from 'src/app/state/app.state';
import * as moment from 'moment';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { getTurno } from 'src/app/utils';
import { CAT_EVENTO, CAT_FONDO_TIPO } from 'src/app/utils/enum';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-asalto-pago',
  templateUrl: './asalto-pago.component.html',
  styleUrls: ['./asalto-pago.component.scss']
})
export class AsaltoPagoComponent implements OnInit {

  denominacionFormato: FondoDenominacion[];
  id_caseta: number;
  subtotales: number[] = [];
  usuario: any;
  cobradores: any[];
  turno: Turno;
  tipoForm: number;
  asaltosList = [];
  totalAsalto: number = 0;
  totalPaga: number = 0;
  asaltoFondo = [];

  eventoForm = new FormGroup({
    tipoEvento: new FormControl('', Validators.required)
  });

  asaltoForm = new FormGroup({
    corteCobradorAsalto: new FormControl('', Validators.required),
    observacionAsalto: new FormControl('', Validators.required),
  });

  pagoForm = new FormGroup({
    totalPago: new FormControl('', Validators.required)
  });

  constructor(
    private asignarFondoService: AsignarFondoService,
    private store: Store<AppState>,
    private corteService: CorteService,
    private router: Router,
    private toastr: ToastrService,
    private asaltoService: AsaltoService,
    private casetaService: CasetaService,
    private ngxUiLoaderService: NgxUiLoaderService,
  ) { }

  ngOnInit(): void {
    // obtener turno actual
    this.turno = getTurno();
    // obtener casetas de usuario
    this.store.select(selectUser).subscribe( usuario => this.usuario = usuario);
    // obtener fondo para conjunto de casetas
    this.asignarFondoService.getFondoDenominacion( this.usuario.caseta_usuario[0].id ).then( () => {
      this.denominacionFormato = this.asignarFondoService.fondoDenominacion.slice(0, 10).reverse();
    });
  }

  getTipoEvento() {
    // valida caseta
    if ( !this.id_caseta ) {
      this.eventoForm.reset();
      return this.toastr.error('Por favor, seleccione una caseta', 'Error', { positionClass: 'toast-bottom-right' } )
    }

    this.tipoForm = Number(this.eventoForm.value.tipoEvento);

    switch (this.tipoForm) {
      case CAT_FONDO_TIPO.SALIENTE:
        this.corteService.getCorteTurnoUsuarios(this.id_caseta, this.turno.id).subscribe( usuarios => {
          this.cobradores = usuarios;
        });
        break;
      case CAT_FONDO_TIPO.ENTRANTE:
        this.asaltoService.getAsaltos(this.id_caseta).subscribe( asaltos => {
          this.asaltosList = asaltos;
        });
        break;
      default:
        break;
    }

    // reinicio total, array e inputs
    this.totalAsalto = 0;
    this.asaltoFondo = [];
    let start: number = 20;
    for (let i = 0; i < this.denominacionFormato.length; i++) {
      (document.getElementById(`a${ (start+ i) }`) as HTMLInputElement).value = '0';
      this.subtotales[(start + i)] = 0;
    }

  }

  getCaseta(caseta: any) {
    this.id_caseta = Number(caseta.target.value);
  }

  getPagoAsalto(event: any) {
    let asalto = this.asaltosList.find(e => e.id_devolucion === Number(event.target.value))
    this.totalPaga = asalto.total;
  }

  asaltoTotal(event: any, denominacion: FondoDenominacion, input:any) {
    this.totalAsalto = 0;

    let cantidad = Number(event.target.value);
    let subtotal = cantidad * denominacion.cantidad;
    this.subtotales[denominacion.id] = subtotal;

    // validacion individual en relacion al fondo
    if (this.tipoForm === CAT_FONDO_TIPO.SALIENTE && cantidad > denominacion.pivot.cantidad) {
      cantidad = 0;
      this.subtotales[denominacion.id] = 0;
      (document.getElementById(input.id) as HTMLInputElement).value = '0';
      this.toastr.error(`La cantidad ingresada en $${ denominacion.cantidad } es mayor a la disponible`, 'Error', { positionClass: 'toast-bottom-right' } );
    }

    for (let subtotal in this.subtotales) {
      if (this.subtotales[subtotal]) {
        this.totalAsalto += Number(this.subtotales[subtotal]);
      }
    }

    this.insertAsalto( cantidad, denominacion );
  }

  insertAsalto(cantidad: number, denominacion: FondoDenominacion){
    let index = this.asaltoFondo.findIndex( m => m.id_denominacion === denominacion.id);
    index === -1 ? this.asaltoFondo.push({
      id_denominacion: denominacion.id, 
      cantidad: cantidad, 
      denominacion: denominacion.cantidad
    }): this.asaltoFondo[index].cantidad = cantidad;
  }

  async saveAsalto() {
    if (!this.asaltoForm.valid && this.tipoForm === CAT_FONDO_TIPO.SALIENTE) {
      return this.toastr.error('Por favor, llene todos los campos', 'Error', { positionClass: 'toast-bottom-right' } );
    }

    if (!this.pagoForm.valid && this.tipoForm === CAT_FONDO_TIPO.ENTRANTE) {
      return this.toastr.error('Por favor, llene todos los campos', 'Error', { positionClass: 'toast-bottom-right'});
    }

    if ( this.asaltoFondo.length === 0 || this.totalAsalto === 0) { return this.toastr.error('Ingresa una cantidad $ para faltantes', 'Error', { positionClass: 'toast-bottom-right' } ) }

    if( this.tipoForm === CAT_FONDO_TIPO.ENTRANTE && this.totalAsalto != this.totalPaga) {
      return this.toastr.error('La cantidades deben coincidir para pagar el asalto', 'Error', { positionClass: 'toast-bottom-right' });
    }

    let data = {
      tipo: this.tipoForm,
      id_cat_evento: CAT_EVENTO.ASALTO,
      id_caseta: this.id_caseta,
      fondo_denominacion: this.asaltoFondo,
      turno: this.turno.id,
      asaltoForm: this.asaltoForm.value,
      id_devolucion: this.pagoForm.value.totalPago ? Number(this.pagoForm.value.totalPago) : null,
      id_usuario_retira: this.usuario.id
    }

    this.ngxUiLoaderService.start();

    if (this.tipoForm === CAT_FONDO_TIPO.SALIENTE) {
      this.asaltoService.saveAsalto( data ).subscribe( savedata => {
        //DESCARGAR PDF VALE ASALTO
        this.downloadFaltante().then(data => {
          this.toastr.success(`Se ha registrado un asalto`, 'Correcto', {positionClass: 'toast-bottom-right'})
          this.router.navigate(['/dashboard/fondo-operacion']);
          this.ngxUiLoaderService.stop();
        })
      });
    }

    if (this.tipoForm === CAT_FONDO_TIPO.ENTRANTE) {
      this.asaltoService.pagaAsalto( data ).subscribe( savedata => {
        console.log( savedata );
        this.toastr.success(`Se ha pagado un asalto`, 'Correcto', {positionClass: 'toast-bottom-right'})
        this.router.navigate(['/dashboard/fondo-operacion']);
        this.ngxUiLoaderService.stop();
      });
    }
    
  }

  downloadFaltante(){
    let usuario = this.cobradores.find( nombre => nombre.id_corte_usuario === Number(this.asaltoForm.value.corteCobradorAsalto));

    return new Promise( (resolve,reject) => {
    let data = {
      'fecha': moment().format(),
      'tipo': 1, //FALTANTE
      'diferencia' : Math.abs(this.totalAsalto),
      'concepto': 5, //FALTANTE ASALTO
      'jt': this.usuario.id,
      'caseta':this.id_caseta,
      'turno':this.turno.id,
      'nombreUserAsalto': usuario.usuario_nombre
    }
    //CONSUMO DE API
    this.corteService.getPDFPreliquidacion(data)
      .then(data => {
        let descarga = this.downloadFile(data);
        if (descarga) {
           this.toastr.success('Se ha descargado con éxito el PDF', '', { positionClass: 'toast-bottom-right' })
           resolve(descarga);
        }
    })
    .catch(err =>{
      this.ngxUiLoaderService.stop();
      return this.toastr.error('Algo salio mál, favor de volver a intenter la descarga del PDF', '', { positionClass: 'toast-bottom-right' } )  
    });
    });

  }

  downloadFile(data){
    const tipoPDF = 'ValeAsalto';
    let downloadURL = window.URL.createObjectURL(data);
    let link = document.createElement('a');
    link.href = downloadURL;
    link.download = `${tipoPDF}.pdf`;
    link.click();
    return true;
  }

}
