<!-- <h3 class="rollo__title">Cambio de rollo</h3>
<div class="rollo__inputs">

  <div class="form-group">
    <label class="rollo__inputs-label"> Inicial </label>
    <input type="number" min="0"/>
  </div>

  <div class="form-group">
    <label class="rollo__inputs-label"> Final </label>
    <input type="number" min="0"/>
  </div>

</div>

<app-teclado-numerico placeholder="Nuevo folio"></app-teclado-numerico> -->

<div class="p-2 border rounded border-solid border-gray-300 dark:border-gray-500 h-120 uppercase text-lg text-gray-500">
  <div class="flex flex-col text-center">
    <h3 class="text-gray-500 dark:text-gray-50 font-bold text-base">Cambio de rollo</h3>
    <hr class="mt-2 mb-2">

    <div class="grid grid-cols-3">

      <div class="col-span-2">
        <div class="w-1/2 mx-auto">
          <app-teclado-numerico (change)="onChangeTN($event)" [multipleInputs]="true" (confirmValue)="confirmModal = true" placeholder="Nuevo folio"></app-teclado-numerico>

          <div class="mt-2">
            <button (click)="cancelAction()" class="bg-blue-600 p-3 w-full text-white font-bold uppercase border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancelar</button>
          </div>
        </div>
      </div>

      <div class="w-96 flex flex-col mt-4 space-y-4">

        <div class="bg-gray-100 dark:bg-gray-900/50 py-6 rounded">

          <div class="form-group">
            <label class="block text-lg font-medium dark:text-gray-300 mr-2"> Folio Inicial </label>
            <input disabled [value]="rollo?.folio_inicial | number" class="w-3/4 border rounded border-gray-300 placeholder-gray-500 px-3 py-3 text-lg dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input" type="text" min="0" placeholder="Inicial"/>
          </div>

          <div class="form-group">
            <label class="block text-lg font-medium dark:text-gray-300 mr-2"> Folio Final </label>
            <input (focus)="onFocusIn($event)" (keypress)="onKeyPress($event)" [value]="rollo?.folio_final | number" class="w-3/4 border rounded border-gray-300 placeholder-gray-500 px-3 py-3 text-lg dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input" type="text" min="0" placeholder="Final" />
          </div>

        </div>

        <div class="form-group">
          <label class="block text-lg font-medium dark:text-gray-300 mr-2"> Nuevo Folio </label>
          <input (focus)="onFocusNewFolio($event)" (keypress)="onKeyPress($event)" [value]="rollo?.nuevo_folio | number" class="w-3/4 border rounded border-gray-300 placeholder-gray-500 px-3 py-3 text-lg dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input" type="text" min="0" placeholder="" />
        </div>
      </div>

    </div>

  </div>
</div>

<div *ngIf="confirmModal" class="modal-component-container fixed inset-0 overflow-y-auto">
  <div
    class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>

    <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>

    <div
      class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
      <div class="modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

        <div class="modal-wrapper-flex sm:flex sm:items-start">

          <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">

              <div class="py-2 text-center">
                  <span>¿Estás seguro de guardar el folio final?</span>
              </div>

          </div>
        </div>
      </div>

      <div class="modal-actions bg-gray-50 px-4 py-3 sm:px-6 flex flex-col">
          <div class="flex justify-end">
              <button (click)="confirmModal = false"
                      class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  Cerrar
              </button>
              <button (click)="saveFolio($event)"
                      class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  Aceptar
              </button>
          </div>

      </div>

    </div>
  </div>
</div>
<!-- <div *ngIf="confirmModalRolloPending" class="modal-component-container fixed inset-0 overflow-y-auto">
  <div
    class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>

    <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>

    <div
      class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
      <div class="modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

        <div class="modal-wrapper-flex sm:flex sm:items-start">

          <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">

              <div class="py-2 text-center">
                  <span>Rollo Pendiente</span>
                  <hr>
                  <span>¿Estás seguro de guardar el rollo pendiente?</span>
              </div>

          </div>
        </div>
      </div>

      <div class="modal-actions bg-gray-50 px-4 py-3 sm:px-6 flex flex-col">
          <div class="flex justify-end">
              <button (click)="cancelActionRolloPendig()"
                      class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  Cerrar
              </button>
              <button (click)="saveRolloPendig()"
                      class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  Aceptar
              </button>
          </div>

      </div>

    </div>
  </div>
</div> -->
