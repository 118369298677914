import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb-browser';
import {ACTIONS_USER_DB} from '../utils/enum';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ActionUserLocaldbService {

  db: any;
  constructor() {
    this.db = new PouchDB('db_actions_user');
  }

  async saveAction(doc){
    let result = await this.db.post({...doc, fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss')});
    return result;
  }

  async existAction(id_corte_usuario: number, action: ACTIONS_USER_DB) : Promise<boolean>{
    let result = await this.db.allDocs({
      include_docs: true
    })

    let register = result.rows.findIndex( r => r.doc.id_corte_usuario == id_corte_usuario && r.doc.action == action )
    return register != -1 ? true :  false ;
  }


}
