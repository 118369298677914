import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Morralla } from '../models';
import { environment } from 'src/environments/environment';
import PouchDB from 'pouchdb-browser';
import {  from, Observable, Subject } from 'rxjs';
import {TYPE_NOTIFICATION} from '../utils/enum';
import {selectHisNotification} from '../state/selectors/history.notification.selectors';
import {AppState} from '../state/app.state';
import {Store} from '@ngrx/store';
@Injectable({
  providedIn: 'root'
})
export class MorrallaService {
  private db: any;
  counterDocs$ = new Subject<number>();
  actualCount = 0;

  private ENDPOINT = environment.ENDPOINTS.MORRALLA;
  private ENDPOINTSAVEMORRALLA = environment.ENDPOINTS.SAVEMORRALLACARRIL;
  private ENDPOINTMORRALLAJF = environment.ENDPOINTS.MORRALLABYJT;
  private ENDPOINTMORRALLATOTAL = environment.ENDPOINTS.MORRALLATOTAL;
  private ENDPOINTPAGO = environment.ENDPOINTS.PAGOMORRALLA;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.db = new PouchDB('db_morralla');
    this.getInfoLocal();
  }

  async saveMoralla(moralla) {

    //Get last notification
    const lastNotification: any = await this.getLastHistoryNotification(TYPE_NOTIFICATION.MORRALLA);
    console.log("LAST -> " + JSON.stringify(lastNotification))

    return await this.http.post<Morralla[]>(this.ENDPOINT, {...moralla, id_notificacion_push : lastNotification.id}).toPromise()
  }

  getMorralla() {
    return this.http.get<Morralla[]>(this.ENDPOINT);
  }
  getMorrallaByJt(id_jefe_turno : Number, id_caseta : Number) {
    return this.http.get<any>(this.ENDPOINTMORRALLAJF+id_jefe_turno + '/' + id_caseta);
  }

  saveMorrallaCarril(data: any) {
    return this.http.post<any>(this.ENDPOINTSAVEMORRALLA, data);
  }

  getMorrallaTotal(id_jefe_turno, id_turno, id_caseta) {
    return this.http.get<any>(this.ENDPOINTMORRALLATOTAL+`/${ id_jefe_turno }/${ id_turno }/${ id_caseta }`);
  }

  pagoMorralla(data: any) {
    return this.http.post<any>(this.ENDPOINTPAGO, data);
  }

  saveMorrallaLocal  (dataSchema: Morralla) :  Observable<Morralla>  {
    console.log('no hay conexión a red');
    let result: Promise<Morralla> = new Promise(async (resolve, reject) => {


      this.db.get(dataSchema._id)
        .then(async (doc: any) => {
          console.log('encontrado y actualizado')
          delete dataSchema._id;
          doc = Object.assign(doc, dataSchema);
          try{
            let result =  await this.db.put(doc);
            resolve(result);
          }catch(err){
            reject(err)
          }

        }).catch(async () => {
          try{
            let result = await this.db.post(dataSchema)
            resolve(result);
            console.log('se creo new register')
            this.actualCount = this.actualCount + 1;
            console.log(this.actualCount)
            this.counterDocs$.next(this.actualCount)
          }catch(err){
            reject(err);
          }

        });
    })

    return from(result);
  }


  public deleteMorrallaLocal(id: string) {
    return this.db.get(id)
      .then(doc => {
        return this.db.remove(doc);
      })
      .then(
        _ => {
          this.actualCount = this.actualCount - 1;
          console.log(this.actualCount)
          this.counterDocs$.next(this.actualCount)
          return true;
        }
      )
      .catch(err => {
        console.log(err)
      })
  }
  public getInfoLocal() {
    this.db.info().then(data => {
      this.actualCount = data.doc_count;
      console.log(this.actualCount)
      this.counterDocs$.next(this.actualCount);
    });
  }


  public getAll(): Promise<Morralla[]> {
    return new Promise((resolve, reject) => {
      this.db.allDocs({
        include_docs: true
      })
        .then(data => {
          let eventsMorralla = data.rows.map(
            (row: any): Morralla => {

              return ({
                _id: row.doc._id,
                denominacion: row.doc.denominacion,
                fecha_hora: row.doc.fecha_hora,
                id_corte_usuario: row.doc.id_corte_usuario,
                id_notificacion_push: row.doc.id_notificacion_push
              });

            }
          )
          resolve(eventsMorralla);
        }).catch(err => {
          reject(err)
        })
    })
  }

  getLastHistoryNotification ( type : TYPE_NOTIFICATION ) {
    return new Promise((resolve)=>{
      this.store.select(selectHisNotification).subscribe(notifications =>{
        console.log(notifications)
        let last = notifications.find( n => n.type == type);
        if(!last){
          return resolve({});
        }
        return resolve(last);
      })
    })

  }
}
