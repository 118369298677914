<div class="col-span-1 flex flex-col space-y-2 border-solid border border-gray-300 dark:border-gray-500 rounded m-2 p-2 text-xs">

    <div>
        <img aria-hidden="true" class="mx-auto h-12 w-auto dark:hidden" src="/assets/images/sogak-logo-light.svg" alt="SOGAK"/>
        <img aria-hidden="true" class="hidden mx-auto h-12 w-auto dark:block" src="/assets/images/sogak-logo-dark.svg" alt="SOGAK"/>
        <div class="text-base font-semibold mb-2 uppercase text-gray-600 dark:text-white text-center">
            <span> {{rolPermiso?.rol}}</span>
        </div>
    </div>
    <aside *ngIf="showAside" class="bg-white dark:bg-gray-800">
        <div class="text-gray-500 dark:text-white">
            <ul>

                <!-- Home -->
                <li *ngIf="showHome">
                    <button class="inline-flex items-center w-full h-12 p-2 mb-3 font-semibold uppercase bg-gray-50 dark:bg-transparent rounded border-2 border-gray-300 dark:border-gray-500 drop-shadow-lg hover:border-blue-600 dark:hover:border-blue-600" (click)="showSection(0)">

                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                            <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
                          </svg>

                        <span class="ml-2 w-full">Inicio</span>
                    </button>
                </li>

                <!-- Permiso con Hijos -->
                <li *ngFor="let permiso of rolPermiso.menu_permisos">
                    <button *ngIf="permiso.visible_menu == 1" class="inline-flex items-center w-full h-12 p-2 mb-3 font-semibold uppercase bg-gray-50 dark:bg-transparent rounded border-2 border-gray-300 dark:border-gray-500 drop-shadow-lg hover:border-blue-600 dark:hover:border-blue-600" (click)=" permiso.url ? goToPage(permiso.url) : toggleMenuItem(permiso.id)">

                        <div [innerHTML]="sgv[permiso.id]" ></div>

                        <span class="ml-2 w-full">{{permiso.descripcion}}</span>
                    </button>

                    <ul *ngIf="itemOpen == permiso.id" class="p-2 space-y-2 mb-2 overflow-hidden text-xs font-medium text-gray-500 rounded-md shadow-inner bg-gray-100 dark:text-white dark:bg-gray-900/50 uppercase">

                        <li *ngFor="let children of permiso.childrens" class="px-2 py-1 transition-colors duration-150 hover:text-blue-600 dark:hover:text-blue-600">
                            <a class="w-full cursor-pointer" (click)="goToPage(children.url)">{{children.descripcion}}</a>
                            <hr class="mt-3">
                        </li>
                      <!--
                        <li *ngIf="showAsignarFondo" class="px-2 py-1 transition-colors duration-150 hover:text-blue-600 dark:hover:text-blue-600">
                            <a class="w-full cursor-pointer" (click)="showSection(2)">Asignar Fondo</a>
                            <hr class="mt-3">
                        </li>
                        -->

                    </ul>

                </li>

                <li>
                    <button (click)="logout()" class="inline-flex items-center w-full h-12 p-2 mb-3 font-semibold uppercase bg-gray-50 dark:bg-transparent rounded border-2 border-gray-300 dark:border-gray-500 drop-shadow-lg hover:border-red-600 dark:hover:border-red-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                        </svg>
                        <span class="ml-2 w-full">Salir</span>
                    </button>
                </li>

                <li>
                    <button *ngIf="finalizarBtn" (click)="finalizarConfirm = true" class="inline-flex items-center w-full h-12 p-2 mb-3 font-semibold uppercase bg-gray-50 dark:bg-transparent rounded border-2 border-gray-300 dark:border-gray-500 drop-shadow-lg hover:border-red-600 dark:hover:border-red-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-7">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                        </svg>
                        <span class="ml-2 w-full">Finalizar Turno</span>
                    </button>
                </li>

              </ul>

        </div>
    </aside>

</div>

<!-- confirmacion de cierre de turnos -->
<div *ngIf="finalizarConfirm" class="modal-component-container fixed inset-0 overflow-y-auto">
    <div
      class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>
  
      <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>
  
      <div
        class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
        <div class="modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
  
          <div class="modal-wrapper-flex sm:flex sm:items-start">
  
            <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">
  
              <!-- <div class="flex items-center justify-between">
                <div class="flex items-center rounded py-2"> -->
                  <span class="pl-2">¿Estás seguro de finalizar el turno?</span>
                  <div class="bg-white dark:bg-gray-900/50 rounded-md w-full">

                  <!-- </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
  
        <div class="modal-actions bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button (click)="finalizarConfirm = false"
                  class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Cerrar
          </button>
          <button (click)="finalizarTurno()"
                  class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Aceptar
          </button>
        </div>
  
      </div>
    </div>
  </div>