import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CorteUsuario } from 'src/app/models';
import { FondoDenominacion } from 'src/app/models/dash';
import { AsignarFondoService } from 'src/app/services/asignar-fondo.service';
import { CorteService } from 'src/app/services/corte.service';
import { FaltanteService } from 'src/app/services/faltante.service';
import { AppState } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/selectors/user.selectors';

@Component({
  selector: 'app-preliquidacion',
  templateUrl: './preliquidacion.component.html',
  styleUrls: ['./preliquidacion.component.scss']
})
export class PreliquidacionComponent implements OnInit {

  denominaciones: FondoDenominacion[] = [];
  confirmModal: boolean = false;
  cortesPreliquidados: any = [];
  corte: any = [];
  user: any;
  id_corte_usuario: number = 0;
  finalizarCorteModal: boolean = false;
  id_caseta: number = 0;
  subtotales: number[] = [];
  selectTag: boolean = false;
  totalConsumido: number = 0;
  diferenciaFinal: number = 0;
  faltanteEntregado: number = 0;
  faltanteBD = [];
  faltanteSuma: number = 0;
  faltantePagar: number = 0;
  observacion: string = "";
  totalAforo: number = 0;
  totalEfectivo: number = 0;
  resultadoSGP: number = 0;
  cobradorCorte: any = [];

  get denominacionFormato() {
    return this.denominaciones.slice(0, ).reverse();
  }

  constructor(
    private corteService: CorteService,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private faltanteService: FaltanteService,
    public asignarFondoService: AsignarFondoService,
    private ngxLoaderUtils: NgxUiLoaderService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.resultadoSGP = 0;
    //redux
    this.store.select(selectUser).subscribe(async (user) => {
      this.user = user;
    });
    this.getData(this.user.id);
    // obtener fondo
    this.asignarFondoService.getFondoDenominacion( this.user.caseta_usuario[0].id ).then( () => {
      this.denominaciones = this.asignarFondoService.fondoDenominacion;
    });
  }

  getData(id) {
    //NEW FLUJO JT, REVISAR
    this.corteService.getCortesPreliquidadoByJT(id)
      .subscribe(preliquidados => {
        console.log( 'CORTE PRELIQUIDADO', preliquidados );
        this.cortesPreliquidados = preliquidados;
      })
  }

  selectCorte(e) {
    this.id_corte_usuario = e.target.value;
    console.log( 'SELECT CORTE', this.id_corte_usuario );

    this.ngxLoaderUtils.start();
    this.corteService.getCortePreliquidadoByJT(this.id_corte_usuario)
      .subscribe(preliquidado => {

        console.log( 'PRELIQUIDADO', preliquidado );
        if (preliquidado.length === 0) {
          // finalizar corte
          this.finalizarCorteModal = true;
          this.ngxLoaderUtils.stop();
          // metodo de guardado de este tipo de cortes
          return console.log( 'CORTE VACIO' );;
        }

        this.diferenciaFinal = 0;
        this.resultadoSGP = 0;
        this.cobradorCorte = [];

        this.corte = preliquidado;

        let c = this.cortesPreliquidados.find( corte => corte.id === Number(this.id_corte_usuario));
        this.cobradorCorte.push({
          nombre: `${ c.usuario.nombre } ${ c.usuario.apellido_paterno } ${ c.usuario.apellido_materno }`,
          turno: c.turno.turno
        });
        this.cobradorCorte.push({
          totalRetiros: this.corte[0].totalRetiros,
          efectivoFinal: this.corte[0].efectivoFinal
        });
        this.cobradorCorte.push({
          totalFolio: this.corte[0].totalFolio,
          boletosNoEmitido: this.corte[0].boletosNoEmitido,
          boletosCancelado: this.corte[0].boletosCancelado,
          totalConsumido: Number(this.corte[0].totalFolio) + this.corte[0].boletosNoEmitido - this.corte[0].boletosCancelado
        });
        this.cobradorCorte.push({
          boletosRecibidos: this.corte[0].boletosRecibidos,
          boletosExe: this.corte[0].boletosExe,
          boletosEspecial: this.corte[0].boletosEspecial,
          boletosEludidos: this.corte[0].boletosEludidos,
          totalAforo: this.cobradorCorte[2].totalConsumido + this.corte[0].boletosRecibidos + this.corte[0].boletosExe + this.corte[0].boletosEspecial + this.corte[0].boletosEludidos
        });

        this.totalEfectivo = this.corte[0].totalRetiros + this.corte[0].efectivoFinal;

        this.ngxLoaderUtils.stop();
      })
  }

  savePreliquidacionCorte() {

    let faltanteReal: number = this.totalEfectivo - this.resultadoSGP - this.faltanteEntregado;
    console.log( faltanteReal );

    if (this.id_corte_usuario === 0) {
      return this.toastr.info(`Seleccione corte`, '', { positionClass: 'toast-bottom-right' });
    }

    if (this.resultadoSGP === 0) {
      return this.toastr.info(`Ingrese el resultado de SGP`, '', { positionClass: 'toast-bottom-right' });
    }

    if (!this.selectTag && this.diferenciaFinal < 0) {
      return this.toastr.info(`Seleccione la opcion de pago`, '', { positionClass: 'toast-bottom-right' });
    }

    if (faltanteReal < 0 && (this.faltanteSuma != Math.abs(faltanteReal))) {
      return this.toastr.info(`No se a capturado el faltante correspondiente`, '', { positionClass: 'toast-bottom-right' });
    }

    let data = {
      id_corte_usuario: this.corte[0].id,
      resultadoSGP: this.resultadoSGP,
      finalizar_corte: 0,
      faltante_entregado: Math.abs(this.faltanteEntregado),
      preliquidado_observacion: this.observacion,
    }

    this.ngxLoaderUtils.start();

    if (faltanteReal < 0) {
      let dataFaltante = {
        tipo: 0,
        id_cat_evento: 5,
        id_caseta: this.user.caseta_usuario[0].id,
        fondo_denominacion: this.faltanteBD,
        cobrador: {id_corte_usuario: this.corte[0].id}
      }

      this.faltanteService.saveFaltante( dataFaltante ).subscribe( savedata => {

        // descarga faltante automaticamente en la preliquidacion
        this.downloadPDFPreliquidacion('faltante');

        this.toastr.success(`Se ha generado el faltante`, 'Correcto', {positionClass: 'toast-bottom-right'});
      });
    }

    this.corteService.savePreliquidacionCorte(data)
      .subscribe(corte => {
        this.getData(this.user.id)
        this.confirmModal = false ;
        this.resultadoSGP = 0;
        this.cobradorCorte = [];
        this.diferenciaFinal = 0;
        this.totalEfectivo = 0;
        this.observacion = "";
        this.id_corte_usuario = 0; // corte se reinicia
        this.faltanteEntregado = 0;
        this.selectTag = false;
        (document.getElementById("corteCabina") as HTMLSelectElement).selectedIndex = 0;
        this.ngxLoaderUtils.stop();
        this.toastr.success(`Preliquidación guardada con éxito`, '', { positionClass: 'toast-bottom-right' })
        this.router.navigate(['/dashboard/preliquidacion']);
      })

  }

  finalizarCorte() {

    let data = {
      id_corte_usuario: this.id_corte_usuario,
      finalizar_corte: 1,
    }

    this.ngxLoaderUtils.start();

    this.corteService.savePreliquidacionCorte(data)
    .subscribe(corte => {
      this.getData(this.user.id)
      this.confirmModal = false ;
      this.resultadoSGP = 0;
      this.cobradorCorte = [];
      this.diferenciaFinal = 0;
      this.totalEfectivo = 0;
      this.observacion = "";
      this.id_corte_usuario = 0; // corte se reinicia
      this.faltanteEntregado = 0;
      this.selectTag = false;
      (document.getElementById("corteCabina") as HTMLSelectElement).selectedIndex = 0;
      this.finalizarCorteModal = false;
      this.ngxLoaderUtils.stop();
      this.toastr.success(`Preliquidación cerrada`, '', { positionClass: 'toast-bottom-right' })
      this.router.navigate(['/dashboard/preliquidacion']);
    })
  }

  getSGP(e){
    this.resultadoSGP = Number(e.target.value);
    this.diferenciaFinal = this.totalEfectivo - this.resultadoSGP;
  }

  getFE(e){
    this.faltanteEntregado = Number(e.target.value);
    this.selectTag = true;
  }

  getObservacion(e: any) {
    this.observacion = e.target.value;
  }

  faltanteVale(event: any, denominacion: any, input: any) {
    this.faltanteSuma = 0;

    let cantidad = Number(event.target.value);
    let subTotal = cantidad * (denominacion.cantidad);
    this.subtotales[denominacion.id] = subTotal;

    // validacion para no sacar mas dinero que se tiene
    if (cantidad > denominacion.pivot.cantidad) {
      cantidad = 0;
      this.subtotales[denominacion.id] = 0;
      (document.getElementById(input.id) as HTMLInputElement).value = '0';
      this.toastr.error(`La cantidad ingresada en $${ denominacion.cantidad } es mayor a la disponible`, 'Error', { positionClass: 'toast-bottom-right' } );
    }

    // capturado
    for (let total in this.subtotales){
      if(this.subtotales[total]){
        this.faltanteSuma += Number(this.subtotales[total]);
      }
    }

    this.insertFaltanteCapturado( cantidad, denominacion);
  }

  insertFaltanteCapturado(cantidad: number, denominacion: any){
    let index = this.faltanteBD.findIndex( m => m.id_denominacion === denominacion.id);
    index === -1 ? this.faltanteBD.push({
      id_denominacion: denominacion.id,
      cantidad: cantidad,
      denominacion: denominacion.cantidad
    }): this.faltanteBD[index].cantidad = cantidad;
  }


  downloadPDFPreliquidacion(tipoPDF){
      this.ngxLoaderUtils.start();
      let tipo = tipoPDF == "faltante" ? 1 : 2;
      let diferencia = tipo == 1 ? (this.totalEfectivo - this.resultadoSGP - this.faltanteEntregado) : this.totalEfectivo - this.resultadoSGP + this.faltanteEntregado;
      //generarData
      let data = {
        fecha : new Date(),
        tipo: tipo, //tipo reporte
        jt: this.user.id,
        diferencia : Math.abs(diferencia),
        id_corte_usuario: this.corte[0].id,
        concepto: 1,
        observacion: tipo == 2 ? this.observacion : ''
       };
      this.corteService.getPDFPreliquidacion(data)
      .then(data => {
        let descarga = this.downloadFile(data,tipoPDF);
        if (descarga) {
          this.ngxLoaderUtils.stop();
          return this.toastr.success('Se ha descargado con éxito el PDF', '', { positionClass: 'toast-bottom-right' } )
        }
      })
      .catch(err =>{
        this.ngxLoaderUtils.stop();
        return this.toastr.error('Algo salio mál, favor de volver a intenter la descarga del PDF', '', { positionClass: 'toast-bottom-right' } )
      });;
  }


  downloadFile(data,tipo){
    const tipoPDF = tipo == 'sobrante' ? 'Sobrante' : 'Faltante';
    let downloadURL = window.URL.createObjectURL(data);
    let link = document.createElement('a');
    link.href = downloadURL;
    link.download = `preliquidacion${tipoPDF}.pdf`;
    link.click();
    return true;
  }

  closeModal() {
    this.confirmModal = false;
    this.faltanteBD = [];
    this.subtotales = [];
    this.faltanteSuma = 0;
  }

  //NEW FLUJO: OBTENER INFORMACION DEPENDIENDO DEL USUARIO
  salienteJT(e){
    this.getData(e.target.checked ? localStorage.getItem('jt_saliente') :  this.user.id)

  }
}
