import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirmacion-firma',
  templateUrl: './confirmacion-firma.component.html',
  styleUrls: ['./confirmacion-firma.component.scss']
})
export class ConfirmacionFirmaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
