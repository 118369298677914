import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { BtnRapido } from '../models';
import { environment } from 'src/environments/environment';
import PouchDB from 'pouchdb-browser';
import {from, Observable, Subject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class BtnRapidoService {
  private db: any;
  counterDocsBtnR$ = new Subject<number>();
  actualCount = 0;
  private ENDPOINT = environment.ENDPOINTS.EVENTO;
  constructor(private http:HttpClient) {
    this.db = new PouchDB('db_btnRapido');
  }

  saveBtnRapido(BtnRapido){
    return this.http.post<BtnRapido[]>(this.ENDPOINT,BtnRapido);
  }

  getBtnRapido(){
    return this.http.get<BtnRapido[]>(this.ENDPOINT);
  }
  saveBtnRapidoLocal(dataSchema: BtnRapido): Observable<BtnRapido> {
    let result: Promise<BtnRapido> = new Promise((resolve, reject) => {
      this.db.get(dataSchema._id)
        .then(async (doc: any) => {
          console.log('encontrado y actualizado')
          delete dataSchema._id;
          doc = Object.assign(doc, dataSchema);
          try{
            let result =  await this.db.put(doc);
            resolve(result);
          }catch(err){
            reject(err)
          }

        }).catch(async () => {
          try{
            let result = await this.db.post(dataSchema)
            resolve(result);
            console.log('se creo new register')
            this.actualCount = this.actualCount + 1;
            console.log(this.actualCount)
            this.counterDocsBtnR$.next(this.actualCount)
          }catch(err){
            reject(err);
          }

        });
    })

    return from(result);


  }

  public deleteBtnRapidoLocal(id: number) {
    return this.db.get(id)
      .then(doc => {
        return this.db.remove(doc);
      })
      .then(
        _ => {
          this.actualCount = this.actualCount - 1;
          console.log(this.actualCount)
          this.counterDocsBtnR$.next(this.actualCount)
          return true;
        }
      )
      .catch(err => {
        console.log(err)
      })
  }
  public getInfoLocal() {
    this.db.info().then(data => {
      this.actualCount = data.doc_count;
      console.log(this.actualCount)
      this.counterDocsBtnR$.next(this.actualCount);
    });
  }


  public getAll(): Promise<BtnRapido[]> {
    return new Promise((resolve, reject) => {
      this.db.allDocs({
        include_docs: true
      })
        .then(data => {
          let eventsBtnRapido = data.rows.map(
            (row: any): BtnRapido => {

              return ({
                _id: row.doc._id,
                fecha_hora: row.doc.fecha_hora,
                id_corte_usuario: row.doc.id_corte_usuario,
                id_subtipo:row.doc.id_subtipo,
                id_clasificacion:row.doc.id_clasificacion,
                id_cat_observacion: row.doc.id_cat_observacion,
                cantidad_ejes: row.doc.cantidad_ejes


              });

            }
          )
          resolve(eventsBtnRapido);
        }).catch(err => {
          reject(err)
        })
    })
  }
}


