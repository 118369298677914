import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserData, Usuario } from 'src/app/models';
import { CasetaService } from 'src/app/services/caseta.service';
import { TipoUsuarioService } from 'src/app/services/tipo-usuario.service';
import { UserService } from '../../services/user.service';
import { element } from 'protractor';
import { map } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {
  dataSource: UserData = null;
  loadingIndicator : boolean = true;
  formEdidtUser: FormGroup;
  formSaveUser: FormGroup;
  modalOpen: boolean = false;
  modalOpenEdit: boolean = false;
  users: any;
  typeUser: any;
  casetas: any;
  troncales: any[] = [];
  auxiliares: any[] = [];
  multiple: boolean = true;
  constructor(private userService: UserService,
    private formBuilder: FormBuilder,
    private typeUserService: TipoUsuarioService,
    private casetaService: CasetaService,
    private toastr: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,

  ) {

  }

  ngOnInit(): void {

    this.getData();
    this.createForms();

  }

  getData() {
    this.userService.getUsersAllByPage(1).
    subscribe(usuarios => {
      // console.log(usuarios.data);
      this.dataSource = usuarios;
      this.users = usuarios.data;
    })
    this.typeUserService.getTepeUsesAll().
      subscribe(type => {
        this.typeUser = type;
      })
    this.casetaService.getCasetas().
      subscribe(caseta => {
        this.casetas = caseta;
        // console.log(this.casetas);
        this.getFilterCasetaTroncales();
      })
  }

  createForms() {
    this.formEdidtUser = this.formBuilder.group({
      nombre: ['', Validators.required],
      id: ['', Validators.required],
      apellido_paterno: ['', Validators.required],
      apellido_materno: ['', Validators.required],
      usuario: ['', Validators.required],
      num_empleado: ['', Validators.required],
      id_rol: ['', Validators.required],
      estado: ['', Validators.required],
      casetas: [[], Validators.required],
      auxiliares: [''],
      password: ['',[Validators.min(4), Validators.pattern("^[0-9]*$")]],
      passwordR: ['']
    });

    this.formSaveUser = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellido_paterno: ['', Validators.required],
      apellido_materno: ['', Validators.required],
      usuario: ['', Validators.required],
      num_empleado: ['', Validators.required],
      id_rol: ['', Validators.required],
      casetas: [[], Validators.required],
      auxiliares: [''],
      password: [''],
      passwordR: ['']

    },{
      validators: [ this.camposIguales('password','passwordR'), this.valideteRol()]
    });
  }

  openModalCreate() {
    this.modalOpen = true;
    // console.log(this.formSaveUser.valid);
  }

  openModalEdit(user: Usuario) {
    this.modalOpenEdit = true;
    this.cargarDatos(user);
  }

  cerrarModal() {
    this.modalOpen = false;
    this.modalOpenEdit = false;
    //set form
    this.formSaveUser.reset();
  }

  async cargarDatos(usuario) {
    // console.log(usuario)
    // console.log(usuario.caseta_usuario.filter(c => c.id_caseta_padre == 0).map(c => c.id)[0])
    this.formEdidtUser.controls['nombre'].setValue(usuario.nombre)
    this.formEdidtUser.controls['usuario'].setValue(usuario.usuario)
    this.formEdidtUser.controls['apellido_paterno'].setValue(usuario.apellido_paterno)
    this.formEdidtUser.controls['apellido_materno'].setValue(usuario.apellido_materno)
    this.formEdidtUser.controls['id_rol'].setValue(usuario.tipoUsuario.id)
    this.formEdidtUser.controls['num_empleado'].setValue(usuario.num_empleado)
    this.formEdidtUser.controls['estado'].setValue(usuario.estado)
    //casetas
    this.formEdidtUser.controls['casetas'].setValue(usuario.caseta_usuario.filter(c => c.id_caseta_padre == 0).map(c => c.id)[0])
    if (this.formEdidtUser.controls['casetas'].value != null) {
      await this.getAuxiliares(usuario.caseta_usuario.filter(c => c.id_caseta_padre == 0).map(c => c.id)[0],'edit')
      // console.log(this.auxiliares)
    }
    if (usuario.tipoUsuario.id != 10) { //Roles (1 trocal y N auxiliar)
      if (this.auxiliares.length > 0 && this.auxiliares[0].id != 0) {
        this.formEdidtUser.controls['auxiliares'].setValue(usuario.caseta_usuario.filter(c => c.id_caseta_padre != 0).map(c => c.id))
      }
      }else{ //Gestor operativo (1 trocal y 1 auxiliar)
      this.multiple = false;
      if (this.auxiliares.length > 0 && this.auxiliares[0].id != 0) {
      this.formEdidtUser.controls['auxiliares'].setValue(usuario.caseta_usuario.filter(c => c.id_caseta_padre != 0).map(c => c.id)[0])
      }
    }
    this.formEdidtUser.controls['id'].setValue(usuario.id)

  }

  guardarUsuario() {
    this.ngxUiLoaderService.start()
    this.userService.saveUser(this.formSaveUser.value).
      then((user) => {
        this.ngxUiLoaderService.stop()

         this.toastr.success('Usuario creado con éxito', '', { positionClass: 'toast-bottom-right' })
        this.getData()
        this.cerrarModal();
      }).catch(err => {
        this.ngxUiLoaderService.stop()
        this.toastr.error('Usuario existente', 'Favor de registrar otro usuario y/o número de empleado', { positionClass: 'toast-bottom-right' })
      });
  }

  editarUsuario() {
    this.ngxUiLoaderService.start()

    // console.log(this.formEdidtUser.value);
    this.userService.editUser(this.formEdidtUser.value.id, this.formEdidtUser.value).
      then(user => {
        this.ngxUiLoaderService.stop()

        this.toastr.success('Usuario modificado con éxito', '', { positionClass: 'toast-bottom-right' })
        this.getData()
        this.cerrarModal();
      }).catch(err => {
        this.ngxUiLoaderService.stop()

        this.toastr.error('Usuario existente', 'Favor de registrar otro usuario y/o número de empleado', { positionClass: 'toast-bottom-right' })
      });
  }

  campoNoValido(campo:string){
    return this.formSaveUser.get(campo)?.invalid
    && this.formSaveUser.get(campo)?.touched;
  }

  camposIguales(password:string, passwordR:string){
    return(formGroup:AbstractControl): ValidationErrors | null => {
      // console.log(formGroup);
      const pass1 = formGroup.get(password)?.value;
      const passR = formGroup.get(passwordR)?.value;
      if (pass1 !== passR) {
        formGroup.get(passwordR)?.setErrors({ noIguales: true})
        return { noIguales:true }
      }
      formGroup.get(passwordR)?.setErrors(null);
      return null;
    }
  }

  validatePasswordR(valuePassR) {
    let passValue = String(this.formSaveUser.value.password);
    let passwordArray = [...passValue];
    let passRArray = [...valuePassR.target.value];//convertir a array
    if (passwordArray.length == passRArray.length){
      if (this.formSaveUser.value.password == this.formSaveUser.value.passwordR) { //validar que sean iguales
        this.toastr.success('Contraseñas correctas', '', { positionClass: 'toast-bottom-right' })
      }else{
        this.toastr.error('Las contraseñas no coinciden', '', { positionClass: 'toast-bottom-right' })
      }
    }
  }

  validatePassword(string) {//Solo numeros
    let consecutivos = false;
    let duplicado = false;
    let numeros = [...string.target.value];//convertir a array
    if (numeros.length > 1) {
      duplicado = this.validarRepetitivos(string); //validar repetitivos maximo 3;
      if (duplicado) {
        this.toastr.error('Repite máximo 3 veces el mismo digito', '', { positionClass: 'toast-bottom-right' });
        this.formSaveUser.controls['password'].setValue(string.target.value.slice(0, -1)); //eliminar el ultimo digito de input
      }else{
        if (numeros.length % 2 != 0) {
          consecutivos = this.validarConsecutivos(string);
          if (consecutivos) {
            this.toastr.error('No se aceptan numeros consecutivos', '', { positionClass: 'toast-bottom-right' })
            this.formSaveUser.controls['password'].setValue(string.target.value.slice(0, -1)); //eliminar el ultimo digito de input
          }
        }
      }
    }
  }

  validarConsecutivos(passValue){
    let numeros = [...passValue.target.value];//convertir a array
    let antepenultimo = numeros[numeros.length -3];
    let penultimo = numeros[numeros.length - 2];
    let lastNum = numeros[numeros.length - 1];
    let totalpenultimo =  antepenultimo - penultimo;
    let total = penultimo - lastNum;
      if ((totalpenultimo == 1 || totalpenultimo == -1) && (total == 1 || total == -1)) { //validar consecutivos
        return  true;
      }else{
        return false;
      }
  }

  validarRepetitivos(password){
    let numeros = [...password.target.value];//convertir a array
    let digitoActual =  numeros[numeros.length - 1]; ;
    let duplicadoArray = [];
    if (numeros.indexOf(digitoActual) != -1) { //buscamos el ultimo valor
      for (let i = 0; i < numeros.length; i++) {
        if (numeros[i] == numeros[i + 1] && numeros[i] == digitoActual) {
          duplicadoArray.push(numeros[i]);
        }
      }
    }
    return duplicadoArray.length >= 3 ? true : false;
  }

  valideMultiAuxiliares(event){//rol
    if (event.target.value != 10) {
      this.multiple = true;
    }else{ //Gestor operativo (1 trocal y 1 auxiliar)
      this.multiple = false;
      //Agregar validate require para auxiliar

    }
  }

  valideteRol(){
    return(formGroup:AbstractControl): ValidationErrors | null => {
      // console.log(formGroup);
      const idRol = formGroup.get('id_rol')?.value;
      if (idRol == 10 && formGroup.get('auxiliares')?.value == null) { //Operador == Auxiliares requeridas
        formGroup.get('auxiliares')?.setErrors({ require: true})
        return { require:true }
      }
      formGroup.get('auxiliares')?.setErrors(null);
      return null;
    }
  }

  getFilterCasetaTroncales(){
   this.troncales = this.casetas.filter(c => c.tipo == 1);
  }

  getAuxiliares(idCaseta,form){
    return new Promise((resolve,reject) => {
      let formulario = form == 'save' ?  this.formSaveUser : this.formEdidtUser;
      if (formulario.value.casetas != undefined) {
        formulario.controls['auxiliares'].enable();
        this.casetaService.getAuxiliares(idCaseta).subscribe
          (casetas => {
        if (casetas[0]['error'] != undefined) { //si existe un error
           this.auxiliares = [];
           this.auxiliares.push({'id': 0, 'nombre': 'Sin auxiliares'});
           formulario.controls['auxiliares'].setValue([0]);
           formulario.controls['auxiliares'].disable();
           resolve(this.auxiliares);
        }else{
          formulario.controls['auxiliares'].reset();
          this.auxiliares = casetas;
          resolve(this.auxiliares);
        }
      });
      }else{
        this.auxiliares = [];
        reject(this.auxiliares);
      }
    })
  }

  groupingHelper(item){
    return item.padre;
  }

  //PAGINACION DATA TABLE
  onSelectRed(row) {
    console.log(row);
  }

  onSelectBlue(value) {
    console.log(value);
  }

  setPage(pageInfo) {
  //  console.log(pageInfo)
   let page = pageInfo.offset + 1;
   this.userService.getUsersAllByPage(page).
    subscribe(usuarios => {
      // console.log(usuarios.data);
      this.dataSource = usuarios;
      this.users = usuarios.data;
      this.loadingIndicator = false;
    })
  }


}
