import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import {Retiro} from 'src/app/models';
import {postRetiro} from 'src/app/state/actions/Retiro.actions';
import {AppState} from 'src/app/state/app.state';
import {selectMontoRetiro, selectRetiro} from 'src/app/state/selectors/retiro.selectors';
import {selectCorteAuxiliar, selectUser} from 'src/app/state/selectors/user.selectors';
import {selectInputs} from '../../state/selectors/input.selector';
import {loadedUser} from '../../state/actions/user.actions';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {LoginService} from '../../services/login.service';
import {addInputAction, changeInputAction, clearInputAction} from '../../state/actions/input.action';
import {InputState} from '../../core/models/input.state';

@Component({
  selector: 'app-u-efectivo-firma',
  templateUrl: './u-efectivo-firma.component.html',
  styleUrls: ['./u-efectivo-firma.component.scss']
})
export class UEfectivoFirmaComponent implements OnInit, OnDestroy {
  user: number;
  retiro: number;
  id_corte_auxiliar: number;

  form = {
    empleado: '',
    password: ''
  };

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private loginService: LoginService
  ) {
  }

  ngOnInit(): void {
//Get User
    this.store.select(selectCorteAuxiliar).subscribe(user => this.user = user);
    console.log(this.user);

//Get Retiro state
    this.store.select(selectMontoRetiro).subscribe(retiro => this.retiro = retiro);
    console.log(this.retiro);
    this.id_corte_auxiliar = Number(localStorage.getItem('id_corte_auxiliar'));

//Get input value
    this.store.select(selectInputs).subscribe(state => {
      //Folio Final
      let inputNumEmpleado = state.inputs.find(i => i.id === 'NUM_EMPLEADO');
      if (inputNumEmpleado) {
        this.form.empleado = inputNumEmpleado.value;
      }

      //Nuevo Folio
      let inputPassword = state.inputs.find(i => i.id === 'PASSWORD');
      if (inputPassword) {
        this.form.password = inputPassword.value;
      }

    });
  }


  ngOnDestroy() {
    //Clear inputs
    this.store.dispatch(clearInputAction());
  }
  cancelAction() {
    this.router.navigate(['/mopa/home']);
  }

  onChangeTN(e: string) {
    console.log(e);
  }

  async saveMonto(e) {
    if (this.id_corte_auxiliar == 0) {
      this.toastr.error(`No se tiene el corte anterior`, '', {positionClass: 'toast-bottom-right'});
    } else {
      console.log(e);

      //validate login
      //validate fields
      if (!(this.form.empleado && this.form.password)) {
        return this.toastr.error('Por favor captura todos los campos', 'Error', {positionClass: 'toast-bottom-right'});
      }

      //Login JT
      try {
        this.ngxUiLoaderService.start();
        const {empleado, password} = this.form;
        let user = await this.loginService.loginJT({empleado, password}).toPromise();

        if (!user) {
          return this.toastr.error('Usuario o contraseña incorrecta', 'Error', {positionClass: 'toast-bottom-right'});
        }

        //Login successfully

        let retiro: Retiro = {
          fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
          id_corte_usuario: this.id_corte_auxiliar,
          id_usuario_retira: this.id_corte_auxiliar,
          id_denominacion: null,
          monto: this.retiro
        };
        this.store.dispatch(postRetiro({retiro}));
        localStorage.setItem('id_corte_auxiliar', '0');
        this.finished('Retiro');

      } catch (err) {
        console.log(err);
      } finally {
        this.ngxUiLoaderService.stop();
      }

    }
  }

  async onFocusNumEmpleado(ev: any) {

    //Verificar que no exista en el arreglo
    let state = await this.getStateInput();
    let index = state.inputs.findIndex(i => i.id == 'NUM_EMPLEADO');
    if (index != -1) {
      //Cambiar active in true
      this.store.dispatch(changeInputAction({input: {...state.inputs[index], active: true}, index}));
    } else {
      //añadir
      this.store.dispatch(addInputAction({input: {id: 'NUM_EMPLEADO', value: '', active: true}}));
    }

  }

  async onFocusPassword(ev: any) {

    //Verificar que no exista en el arreglo
    let state = await this.getStateInput();
    let index = state.inputs.findIndex(i => i.id == 'PASSWORD');
    if (index != -1) {
      //Cambiar active in true
      this.store.dispatch(changeInputAction({input: {...state.inputs[index], active: true}, index}));
    } else {
      //añadir
      this.store.dispatch(addInputAction({input: {id: 'PASSWORD', value: '', active: true}}));
    }

  }

  getStateInput() {
    return new Promise<InputState>((resolve, reject) => {
      this.store.select(selectInputs).subscribe(state => resolve(state));
    });
  }

  finished(type: string) {

    this.toastr.success(`${type} guardado con éxito`, '', {positionClass: 'toast-bottom-right'});
    this.router.navigate(['/mopa/home']);

  }

}
