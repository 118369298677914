<!-- <div class="container__body">
    <div class="body__subtipo" *ngFor="let subtipo of subtipos">
        <span>{{subtipo.nombre}}</span>
        <div class="body__children">
            <button class="btn btn-image w-100" [style.background-image]="'url(/assets/images/'+  children.icon +')'" *ngFor="let children of subtipo.childrens" (click)="goToClasificacion(children, subtipo.nombre)">
              <span>{{children.nombre}}</span></button>
        </div>
    </div>
</div> -->

<div class="p-2 border rounded border-solid border-gray-300 dark:border-gray-500 h-112 uppercase text-lg text-gray-500">
     <div *ngIf="!subtipos" class="center">
      <img
        aria-hidden="true"
        class="mx-auto h-12 w-auto dark:hidden"
        src="/assets/images/sogak-logo-light.svg"
        alt="SOGAK"
      />
      <img
        aria-hidden="true"
        class="hidden mx-auto h-12 w-auto dark:block"
        src="/assets/images/sogak-logo-dark.svg"
        alt="SOGAK"
      />
      <div class="text-base font-semibold mb-2 uppercase text-gray-600 dark:text-white text-center">
        <span>inicio</span>
      </div>
    </div>
  <div class="grid grid-cols-4 gap-4">

    <div class="bg-gray-200 dark:bg-gray-900 rounded p-4 text-center w-68" *ngFor="let subtipo of subtipos">
      <span class="text-gray-500 dark:text-gray-50 font-bold text-base">{{subtipo.nombre}}</span>
      <hr class="mt-2 mb-4">
      <div class="grid grid-rows-4 grid-flow-col gap-4 overflow-x-auto pb-3">
        <button
          class="btn btn-image py-1 px-2 min-w-subtipoItem bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 dark:border-gray-400 drop-shadow-lg hover:border-blue-600"
          [style.background-image]="'url(/assets/images/'+  children.icon +')'"
          *ngFor="let children of subtipo.childrens"  (click)="goToClasificacion(children, subtipo)">
          <span class="text-xs">{{children.nombre}}</span>
        </button>
      </div>
    </div>
  </div>
</div>


