import {createReducer, on} from '@ngrx/store';
import {loadRollo,loadedRollo,postRollo,postedRollo,saveStatusRollo,savedStatusRollo} from '../actions/rollo.actions';
import {RolloState} from '../../core/models/rollo.state';


export const initialState:RolloState = {loading: false, rollo: null, stateRollo: 0};


export const rolloReducer = createReducer(
  initialState,
  on(loadRollo, (state) => {
    return {...state, loading: true};
  }),
  on(loadedRollo, (state,{rollo}) => {
    return {...state, loading: false, rollo};
  }),
  on(postRollo, (state,{rollo}) => {
    return {...state, loading: true};
  }),
  on(postedRollo, (state,{rollo}) => {
    return {...state, loading: false, rollo};
  }),
  on(savedStatusRollo, (state,{stateRollo}) => {
    return {...state, stateRollo};
  })
);
