import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BtnRapido, SubtipoCruce } from 'src/app/models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectBtnsMenu, selectDevolucion, selectPermisos } from '../../state/selectors/caseta.selectors';
import { AppState } from '../../state/app.state';
import { addBtnRapidoSubType, postBtnRapido } from '../../state/actions/btnRapido.actions';
import { ToastrService } from 'ngx-toastr';
import { clearCruce } from '../../state/actions/cruce.actions';
import * as moment from 'moment';

// Devolucion
import { Denominacion, Devolucion } from '../../models';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { selectPanelIzquierdo } from '../../state/selectors/panelIzquierdo.selectors';
import { PanelIzquierdoState } from '../../core/models/panelIzquierdo.state';
import { changeStatus, StatusPanel, TypeBoton } from '../../state/actions/panelIzquierdo.actions';
import { PanelIzquieroRetiroState } from '../../core/models/panelIzquieroRetiro.state';
import { selectPanelIzquierdoRetiro } from '../../state/selectors/panelIzquierdoRetiro.selectors';
import { changeStatusRetiro } from '../../state/actions/panelIzquierdoRetiro.actions';
import { selectEstatusRollo } from 'src/app/state/selectors/rollo.selectors';
import { savedStatusRollo } from 'src/app/state/actions/rollo.actions';
import { ActionUserLocaldbService } from '../../services/action-user-localdb.service';
import { ACTIONS_USER_DB, STATUS_NOTIFICATION, TYPE_NOTIFICATION } from '../../utils/enum';
import { PushNotificationService } from '../../services/push-notification.service';
import { OfflineOnlineService } from '../../services/offline-online.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @ViewChild('btnSDevolucion') btnDevolucion: ElementRef;
  btns$: Observable<SubtipoCruce[]> = new Observable<SubtipoCruce[]>();
  permisos = [];
  // Devolucion
  btnsDevolucion$: Observable<Denominacion[]> = new Observable<Denominacion[]>();
  devolucion: Devolucion;
  //state devolucion button
  stateDevolucion = 0;
  stateRetiro = 0;
  //user
  user: any = null;
  anyDevolutionLocalDB: boolean = false;

  stateDevolucionStore: PanelIzquierdoState;
  stateRetiroStore: PanelIzquieroRetiroState;

  //rollo
  statusRollo: number = 0;
  isOffline = false;
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private actionUserDB: ActionUserLocaldbService,
    private pushNotService: PushNotificationService,
    private offlineOnlineService: OfflineOnlineService,
    private ngiLoaderService: NgxUiLoaderService
  ) {
  }

  ngOnInit(): void {

    //Get state devolucion
    this.store.select(selectPanelIzquierdo).subscribe(stateDevolucionStore => {
      this.stateDevolucionStore = stateDevolucionStore;
      //this.class(this.btnDevolucion, this.stateDevolucionStore.status)
    });

    //Get state Retiro
    this.store.select(selectPanelIzquierdoRetiro).subscribe(selectPanelIzquierdoRetiro => this.stateRetiroStore = selectPanelIzquierdoRetiro);

    this.btns$ = this.store.select(selectBtnsMenu);

    // Devolucion
    this.btnsDevolucion$ = this.store.select(selectDevolucion);
    // Permisos para retirar o devolucion por roles
    this.store.select(selectPermisos).subscribe(permiso => {
      this.permisos = permiso
      console.log(this.permisos);
    });

    this.store.select(selectUser).subscribe(async (user) => {
      this.user = user;
      //Get action devolution
      this.anyDevolutionLocalDB = await this.actionUserDB.existAction(this.user.id_corte_usuario, ACTIONS_USER_DB.DEVOLUCION)

    });

    this.store.select(selectEstatusRollo).subscribe(status => this.statusRollo = status);
    console.log(this.statusRollo);

    this.offlineOnlineService.connectionChanged.subscribe(estatus => {
      this.isOffline = !estatus;
    });

    //validar status de rollo si se recarga la pagina
    if (Number(localStorage.getItem('rollo_pendiente')) > 0) {
      this.store.dispatch(savedStatusRollo({ stateRollo: 2 }))
    }
  }

  changeAction(btn) {
    //borrar state de cruce
    this.store.dispatch(clearCruce());
    this.store.dispatch(addBtnRapidoSubType({ id_subtipo: btn.id, nombreSubtipo: btn.nombre }));
    if (btn.id == 60) {//discrepancia
      this.toastr.info('Marca la opción real ', '', { positionClass: 'toast-bottom-right' });
    }
    //validar si el btn permite mostrar las clasificaciones
    if (btn.mostrar == 1) {
      this.goToClasificacion(btn);
    } else {
      //guardar evento
      let BtnRapido: BtnRapido = {
        fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
        id_subtipo: btn.id,
        id_corte_usuario: this.user.id_corte_usuario
      };
      this.store.dispatch(postBtnRapido({ btnRapido: BtnRapido }));
      this.toastr.success('Se registró el evento ' + btn.nombre, '', { positionClass: 'toast-bottom-right' });
    }
  }

  goToClasificacion(subtipo: SubtipoCruce) {
    this.router.navigate(['/mopa/clasificacion-vehicular']);
  }

  // Devolucion
  showDevolucion() {
    this.router.navigate(['/mopa/devolucion-denominacion']);
  }

  // Retiro
  showRetiro() {
    this.router.navigate(['/mopa/btn-retiro']);
  }


  async notificacion(type: TYPE_NOTIFICATION) {
    try {
      this.ngiLoaderService.start()
      let response = await this.pushNotService.sendNotification({ title: "MOPA", body: `Solicitud de ${type}, Carril ${this.user.nom_carril}`, users: [this.user.id_jefe_turno], type, status: STATUS_NOTIFICATION.NOTIFICADO })
      this.toastr.success(`Notificando ${type}`, '', { positionClass: 'toast-bottom-right' });
      return true;
    } catch (error) {
      this.toastr.error('No se encontro conexión a Red', 'Red inestable', { positionClass: 'toast-bottom-right' });
      return false;

    } finally {
      this.ngiLoaderService.stop()

    }

  }

  async actionsDevolucion(btn) {

    //Se hace con store redux
    switch (this.stateDevolucionStore.status) {
      case StatusPanel.INITIAL:
        //this.class(btn, StatusPanel.INITIAL);
        let result = false
        result = await this.notificacion(TYPE_NOTIFICATION.DEVOLUCION);
        if (result)
          //change status
          this.store.dispatch(changeStatus({ type_boton: TypeBoton.DEVOLUCION, status: StatusPanel.NOTIFICADO }));
        break;

      case StatusPanel.NOTIFICADO:
      case StatusPanel.PROCESO:
        //this.class(btn, StatusPanel.NOTIFICADO);
        this.showDevolucion();
        break;
    }

  }

  async actionsRetiro(btn) {
    //Se hace con store redux
    switch (this.stateRetiroStore.status) {
      case StatusPanel.INITIAL:
        let result = false

        result = await this.notificacion(TYPE_NOTIFICATION.RETIRO);
        if (result)
          //change status
          this.store.dispatch(changeStatusRetiro({ status: StatusPanel.NOTIFICADO }));
        break;

      case StatusPanel.NOTIFICADO:
      case StatusPanel.PROCESO:
        //this.class(btn, StatusPanel.NOTIFICADO);
        this.showRetiro();
        break;
    }

  }

  class(btn, status?: StatusPanel) {
    switch (status) {
      case StatusPanel.INITIAL:
        btn.classList.add('dark:bg-green-800');
        btn.classList.add('bg-green-800');
        btn.classList.add('hover:border-green-800');
        btn.classList.add('dark:hover:border-green-800');
        btn.classList.add('text-white');
        btn.classList.remove('dark:bg-transparent');
        break;
      case StatusPanel.NOTIFICADO:
        btn.classList.remove('dark:bg-green-800');
        btn.classList.remove('bg-green-800');
        btn.classList.remove('hover:border-green-800');
        btn.classList.remove('dark:hover:border-green-800');

        btn.classList.add('dark:bg-blue-600');
        btn.classList.add('bg-blue-600');
        btn.classList.add('hover:border-blue-600');
        btn.classList.add('dark:hover:border-blue-600');
        btn.classList.add('text-white');
        btn.classList.remove('dark:bg-transparent');
        break;

    }

  }

  getColorDevolucion(status: StatusPanel) {
    return status === this.stateDevolucionStore.status;
  }

  getColorRetiro(status: StatusPanel) {
    return status === this.stateRetiroStore.status;
  }

  hasAnyDevolution() {
    //this.actionUserDB.existAction(this.user.id_corte_usuario, ACTIONS_USER_DB.DEVOLUCION)
    //si no hay ninguna devolución no se muestra
    return this.stateDevolucionStore.countDevolucion > 0
  }

  //ROLLO

  async changeRollo(btn) {
    //validar show component rollo

    if (this.statusRollo == 1) {//1 SOLICITA
      this.statusRollo = 2
      this.store.dispatch(savedStatusRollo({ stateRollo: 2 }))
      this.router.navigate(['/mopa/cambio-rollo'])
    } else if (this.statusRollo == 2 || this.statusRollo == 3) {// 2 MANDA AL COMPONENTE DE ROLLO, // 3 confirmado por JT
      this.router.navigate(['/mopa/cambio-rollo'])
    } else {//NOTIFICA
      //notificar
      let result = false
      result = await this.notificacion(TYPE_NOTIFICATION.ROLLO)
      if (result) {
        this.store.dispatch(savedStatusRollo({ stateRollo: 1 }))
        this.statusRollo = 1
      }

    }
    console.log(this.statusRollo);
    //cambiar style
    this.styleButton(btn)
  }

  styleButton(btn) {
    var text = ''
    switch (this.statusRollo) {
      case 0:

        btn.classList.remove('dark:bg-blue-800');
        btn.classList.remove('bg-blue-800');
        btn.classList.remove('text-white');
        btn.classList.add('dark:bg-transparent');
        text = 'Rollo'
        break;
      case 1:
        btn.classList.add('dark:bg-green-800');
        btn.classList.add('bg-green-800');
        btn.classList.add('hover:border-green-800');
        btn.classList.add('dark:hover:border-green-800');
        btn.classList.add('text-white');
        btn.classList.add('text-sm');
        btn.classList.remove('dark:bg-transparent');
        text = 'ingresar rollo'

        break;
      case 2: case 3:
        btn.classList.remove('dark:bg-green-800');
        btn.classList.remove('bg-green-800');
        btn.classList.remove('hover:border-green-800');
        btn.classList.remove('dark:hover:border-green-800');
        btn.classList.remove('text-white');
        btn.classList.remove('text-sm');

        btn.classList.add('dark:bg-blue-800');
        btn.classList.add('bg-blue-800');
        btn.classList.add('hover:border-blue-800');
        btn.classList.add('dark:hover:border-blue-800');
        btn.classList.add('text-white');
        btn.classList.add('text-sm');
        btn.classList.add('disabled');
        btn.classList.remove('dark:bg-transparent');

        text = 'ingresar rollo'

        break;

    }
    return text;
  }

}
