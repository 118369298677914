import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { now } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {  Turno } from 'src/app/models/dash';
import { RetiroService } from 'src/app/services/retiro.service';
import { AppState } from 'src/app/state/app.state';
import { selectRetiroPrint } from 'src/app/state/selectors/retiro.selectors';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { EventEmitter } from 'stream';
import { getTurno } from '../../../app/utils/index';
@Component({
  selector: 'app-print-formato-final',
  templateUrl: './print-formato-final.component.html',
  styleUrls: ['./print-formato-final.component.scss']
})
export class PrintFormatoFinalComponent implements OnInit {
  user: any;
  dateTime = now();
  tipoImpresion: string = "[ IMPRESION ]";
  turnoActual: Turno;

  @Input () cortes: any;
  @Input () totales: any;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private toastr: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
  ) { }

  ngOnInit(): void {
    //redux
    this.store.select(selectUser).subscribe(async (user) => {
      this.user = user;
      console.log(this.user);

    });
    this.turnoActual=getTurno();

    console.log(this.turnoActual);

    console.log(this.cortes);
    console.log(this.totales);

  }


  imprent() {

    window.print();
    this.toastr.success('Se ha guardado con exito la información','', { positionClass: 'toast-bottom-right' } )

    // despues de imprir recarga
    window.location.reload()

    // this.retiroService.printRetiro(data)
    // .subscribe(print=>{
    //   console.log(print);

    // })
  }
}
