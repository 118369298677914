
<div class="min-h-screen p-6 bg-gray-100 dark:bg-gray-900">
    <div class="mx-auto bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <div class="grid grid-cols-8 grid-flow-row gap-2 mb-2">

            <app-aside-dashboard></app-aside-dashboard>

            <div class="col-span-7">

                <app-header-dashboard></app-header-dashboard>
                
                <router-outlet></router-outlet>

            </div>

        </div>
    </div>
</div>
  