import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CorteService } from 'src/app/services/corte.service';
import { FormatoData, FormatoService } from 'src/app/services/formato.service';

@Component({
  selector: 'app-formato-exento',
  templateUrl: './formato-exento.component.html',
  styleUrls: ['./formato-exento.component.scss']
})
export class FormatoExentoComponent implements OnInit {

  exentos: any[];
  data$: Observable<FormatoData>;
  finalise = new Subject();

  constructor(
    private formatoService: FormatoService,
    private toastr: ToastrService,
    private corteService: CorteService
  ) { 
    this.data$ = formatoService.obtenerData;
  }

  ngOnInit(): void {

    this.data$.pipe(takeUntil(this.finalise)).subscribe( data => {
      if ( data.tipo === 3 ) {
        this.corteService.getCorteExentos( data.id_corte_usuario ).subscribe( exentos => {
          this.exentos = exentos;
          console.log( this.exentos );
        });
      }
      this.finalise.next();
      this.finalise.complete();
    });

  }

}
