<div class="flex flex-wrap">
  <div class="w-full">
    <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center cursor-pointer">
        <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" (click)="toggleTabs(1)"
          [ngClass]="{' bg-white': openTab !== 1, ' active ': openTab === 1}">
          <i class="fas fa-space-shuttle text-base mr-1"></i> Iniciar sistema
        </a>
      </li>
      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center cursor-pointer">
        <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" (click)="toggleTabs(2)"
          [ngClass]="{' bg-white': openTab !== 2, ' active': openTab === 2}">
          <i class="fas fa-cog text-base mr-1"></i> Boletos
        </a>
      </li>
      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center cursor-pointer">
        <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" (click)="toggleTabs(3)"
          [ngClass]="{' bg-white': openTab !== 3, 'active ': openTab === 3}">
          <i class="fas fa-briefcase text-base mr-1"></i> Reporteria
        </a>
      </li>
    </ul>
    <div class="relative flex flex-col min-w-0 break-words bg-transparent w-full mb-6 shadow-lg rounded">
      <div class="px-4 py-5 flex-auto bg-transparent">
        <div class="tab-content tab-space">
          <div [ngClass]="{'hidden': openTab !== 1, 'block': openTab === 1}">
            <app-cuadrar-fondo></app-cuadrar-fondo>

          </div>
          <div [ngClass]="{'hidden': openTab !== 2, 'block': openTab === 2}">
            <!-- <app-boletaje [dataTransportistas]="dataTransportistas"></app-boletaje> -->

          </div>
          <div [ngClass]="{'hidden': openTab !== 3, 'block': openTab === 3}">
            <!-- <app-reportes></app-reportes> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
