import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppState} from '../../state/app.state';
import {Store} from '@ngrx/store';
import {CatPermisosService} from '../../services/cat-permisos.service';
import {RolPermiso} from '../../models';
import {DomSanitizer} from '@angular/platform-browser';
import {TYPE_ROL} from '../../utils/enum';
import { AuthService } from 'src/app/services/auth.service';
import { RetiroService } from 'src/app/services/retiro.service';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { LogUsuarioService } from 'src/app/services/log-usuario.service';

@Component({
  selector: 'app-aside-dashboard',
  templateUrl: './aside-dashboard.component.html',
  styleUrls: ['./aside-dashboard.component.scss']
})
export class AsideDashboardComponent implements OnInit {

  itemOpen = null;
  showHome: boolean = true;
  showAside: boolean = false;
  showAsignarCarril: boolean = true;
  showAsignarFondo: boolean = true;
  sgv : any[] = [];
  user: any;
  finalizarBtn: boolean = false;
  finalizarConfirm: boolean = false;
  rolPermiso: RolPermiso;
  nameRol : string = '';

  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store<AppState>,
    private menuPermisionService: CatPermisosService,
    private sanitizer: DomSanitizer,
    private logUsuarioService: LogUsuarioService
  ) {
  }

  ngOnInit() {
    //redux
    this.store.select(selectUser).subscribe(async (user) => {
      this.user = user;

    });

    this.store.select('usuario').subscribe(async (usuario : any) => {
      let id_rol = usuario?.usuario?.rol?.id || usuario?.usuario?.tipoUsuario?.id;
      this.nameRol =  usuario?.usuario?.rol?.rol || usuario?.usuario?.tipoUsuario?.rol;
      this.rolPermiso = await this.menuPermisionService.getPermissionByRol(id_rol).toPromise();
      this.createSgv();
      // console.log(this.rolPermiso);
      // Ya se confirmo fondo?
      if (usuario['usuario'].id_cat_acciones > 0) {
        this.showAside = false;
      }

      // Ya se asigno carril?
      if (usuario['usuario'].id_cat_acciones >= 1) {
        this.showAside = true;
        this.showAsignarCarril = false;
      }

      // Ya se asigno fondo para carril?
      if (usuario['usuario'].id_cat_acciones === 3) {
        this.showAsignarFondo = false;
      }

      if(id_rol === TYPE_ROL.CARRIL){
        this.showAside = true;
        this.showHome = false;
      }

      if (id_rol === TYPE_ROL.JEFE_TURNO) {
        this.finalizarBtn = true;
      }

    });
  }

  showSection(id: number) {
    let sections = {
      0: 'home',
    };
    this.router.navigate(['/dashboard/' + sections[id]]);
  }

  goToPage( route : string){
    if(route){
      this.router.navigate([route]);
    }
  }
  toggleMenuItem(number: number) {
    if (this.itemOpen == number) {
      return this.itemOpen = null;
    }
    return this.itemOpen = number;
  }

  logout(){
    this.authService.logout();
    this.router.navigate(['/login-mopa']);
  }

  async finalizarTurno() {
    await this.logUsuarioService.finalizarTurno(this.user.id_log_usuario).toPromise();
    console.log( this.user.id_log_usuario );
    //NEW FLUJO: GUADAR EL JT PARA PRELIQUIDAR EN EL SIGUIENTE TUNRO
    localStorage.setItem('jt_saliente', this.user.id)
    this.authService.logout();
    this.router.navigate(['/login-mopa']);
  }

  createSgv(){
    for(const permiso of this.rolPermiso.menu_permisos){
      this.sgv[permiso.id] = this.sanitizer.bypassSecurityTrustHtml(permiso.icon);
    }

  }



}


