import {createAction, props} from '@ngrx/store';
import {NotificationState} from '../../core/models/notification.state';


export enum MorrallaStatusActions {

  CHANGE_STATUS = '[MORRALLA STATUS] Change ',

}


export const changeStatusMorralla = createAction(
  MorrallaStatusActions.CHANGE_STATUS,
  props<{ status: boolean }>()
);
