
<div class="bg-white dark:bg-gray-900/50 rounded-md w-full">
    <div class="p-2 overflow-x-auto h-96">
        <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
            
            <table class="w-full text-center text-gray-900 dark:text-gray-300">

                <thead>
                    <tr class="border-b-2 border-gray-200 dark:border-gray-600 bg-gray-100 dark:bg-gray-900/50 text-gray-600 dark:text-gray-300">
                        <th class="px-2 py-3">Hora</th>
                        <th class="px-2 py-3">Concepto</th>
                        <th class="px-2 py-3">Tarifa</th>
                        <th class="px-2 py-3">Descripcion</th>
                    </tr>
                </thead>

                <tbody>

                    <tr *ngFor="let incidencia of incidencias" class="border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-900/50 ">

                        <td class="p-2">
                            <span>{{ incidencia.hora }}</span>
                        </td>

                        <td class="p-2">
                            <span>{{ incidencia.concepto || "Sin concepto" }}</span>
                        </td>

                        <td class="p-2">
                            <span>{{ incidencia.tarifa || ( incidencia.descripcion === 'Avienta dinero' ? incidencia.avento_dinero : "Sin tarifa" ) }}</span>
                        </td>

                        <td class="p-2">
                            <span>{{ incidencia.descripcion }}, ${{ incidencia.avento_dinero || "0" }}</span>
                        </td>

                    </tr>

                </tbody>
            </table>

        </div>
    </div>
</div>
