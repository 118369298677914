import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../services/login.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {ToastrService} from 'ngx-toastr';
import {State, Store} from '@ngrx/store';
import {AppState} from '../../state/app.state';
import {loadedUser} from '../../state/actions/user.actions';
import {AuthService} from '../../services/auth.service';
import { LogUsuarioService } from 'src/app/services/log-usuario.service';
import { Turno } from 'src/app/models/dash';
import { getTurno, rangeTurno } from 'src/app/utils';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import {TYPE_ROL} from '../../utils/enum';
import {WebsocketService} from '../../services/websocket.service';

@Component({
  selector: 'app-login-mopa',
  templateUrl: './login-mopa.component.html',
  styleUrls: ['./login-mopa.component.scss']
})
export class LoginMopaComponent implements OnInit {

  rol = 13;
  form: FormGroup;
  turno: Turno;

  constructor(
    private router: Router,
    private formBuilder : FormBuilder,
    private loginService: LoginService,
    private ngxUiLoaderService : NgxUiLoaderService,
    private toastrService: ToastrService,
    private store : Store<AppState>,
    private authService:AuthService,
    private logUsuario: LogUsuarioService,
  ) {
    this.form =  this.formBuilder.group({
      empleado : ['', Validators.required],
      password : ['', Validators.required]
    });
  }

  ngOnInit(): void {
    // obtener turno actual
    this.turno = getTurno();
  }

  async loginUser() {

    try{
      this.ngxUiLoaderService.start();
      let user : any = await this.loginService.loginJT(this.form.value).toPromise();
      let casetaTroncal = user.caseta_usuario.find( caseta => caseta.tipo === 1);

      if(!user){
          return this.toastrService.error("Inténtalo nuevamente","Empleado o contraseña inválida", { positionClass : 'toast-bottom-right'})
      }

      // BUG FIX
      // let rango = rangeTurno(this.turno.id);

      // BUG FIX
      // En base al log enviar a la seccion determinida de su proceso de trabajo
      // let usuarioLog = await this.logUsuario.searchLogUsuario(rango.hora_inicio, rango.hora_fin, user.id).toPromise();
      let usuarioLog = await this.logUsuario.searchLogUsuario(user.id).toPromise()
      if (Object.keys(usuarioLog).length > 0) {

        // si encuentra informacion del usuario en log, recupera la informacion, update en redux
        user.id_log_usuario = usuarioLog[0].id;
        user.id_cat_acciones = usuarioLog[0].id_cat_acciones;
        user.caseta_selected = null;
        this.store.dispatch(loadedUser({usuario: user}));
        this.authService.login(user);

        switch (user.tipoUsuario.id) {
          case TYPE_ROL.JEFE_TURNO:
            // seccion determinada por id_cat_acciones
            if (user.id_cat_acciones === 1) {
              this.router.navigate(['dashboard/firma']);
            } else if (user.id_cat_acciones === 2) {
              this.router.navigate(['dashboard/home']);
            } else {
              this.router.navigate(['dashboard/fondo-operacion']);
            }
            break;

          case TYPE_ROL.CARRIL:
            this.router.navigate(['dashboard/ultimo-efectivo']);
            // finaliza ese log porque es carril al ingresar
            this.logUsuario.finalizarTurno(user.id_log_usuario).toPromise()
            break;
        }

      }

      if (Object.keys(usuarioLog).length === 0) {

        // Generar registro en el log
        let log = await this.logUsuario.saveLogUsuario({ id_usuario: user.id, id_caseta: casetaTroncal.id }).toPromise();
        user.id_log_usuario = log.id;
        user.id_cat_acciones = null;
        user.caseta_selected = null;

        //Cargar en el state
        this.store.dispatch(loadedUser({usuario: user}))
        this.authService.login(user);

        //redirigir por rol
        switch (user.tipoUsuario.id) {
          case TYPE_ROL.JEFE_TURNO:
            this.router.navigate(['dashboard/fondo-operacion']);
            break;
          case TYPE_ROL.CARRIL:
            this.router.navigate(['dashboard/ultimo-efectivo']);
            // finaliza ese log porque es carril al ingresar
            this.logUsuario.finalizarTurno(user.id_log_usuario).toPromise()
            break;
        }

      }

    }catch(err){
      console.log(err)
    }finally {
      this.ngxUiLoaderService.stop();
    }

  }

  downloadCer() {
    const certificado = document.createElement('a');
    certificado.setAttribute('target', '_blank');
    certificado.setAttribute('href', '/assets/certificado/mopaCertificado.cer');
    certificado.setAttribute('download', `mopaCertificado.cer`);
    document.body.appendChild(certificado);
    certificado.click();
    certificado.remove();
  }

}
