import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Caseta, Devolucion, Retiro } from 'src/app/models';
import { postDevolucion } from 'src/app/state/actions/devolucion.actions';
import { postRetiro } from 'src/app/state/actions/Retiro.actions';
import { AppState } from 'src/app/state/app.state';
import { PanelIzquierdoState } from '../../core/models/panelIzquierdo.state';
import { selectUser } from '../../state/selectors/user.selectors';
import { selectPanelIzquierdo } from '../../state/selectors/panelIzquierdo.selectors';
import { changeStatus, setStatus, StatusPanel, TypeBoton } from '../../state/actions/panelIzquierdo.actions';
import { selectPanelIzquierdoRetiro } from '../../state/selectors/panelIzquierdoRetiro.selectors';
import { PanelIzquieroRetiroState } from '../../core/models/panelIzquieroRetiro.state';
import { changeStatusRetiro, setStatusRetiro } from '../../state/actions/panelIzquierdoRetiro.actions';
import { selectCaseta } from '../../state/selectors/caseta.selectors';
import { LoginService } from '../../services/login.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CAT_EVENTO, STATUS_NOTIFICATION, TYPE_NOTIFICATION } from 'src/app/utils/enum';
import { NotificacionesPushService } from '../../services/notificaciones-push.service';
import { changeStatusMorralla } from '../../state/actions/morralla-status.actions';
import { savedStatusRollo } from 'src/app/state/actions/rollo.actions';
import { PushNotificationService } from 'src/app/services/push-notification.service';
import { OfflineOnlineService } from 'src/app/services/offline-online.service';

@Component({
  selector: 'app-firma',
  templateUrl: './firma.component.html',
  styleUrls: ['./firma.component.scss']
})
export class FirmaComponent implements OnInit {

  devolucionStore: PanelIzquierdoState;
  retiroStore: PanelIzquieroRetiroState;
  caseta: Caseta;
  user: any;
  typeBoton: TypeBoton;
  isOffline: boolean = false;
  constructor(
    private store: Store<AppState>,
    private toastr: ToastrService,
    private router: Router,
    private activedRoute: ActivatedRoute,
    private loginService: LoginService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private notificacionesService: NotificacionesPushService,
    private pushNotService: PushNotificationService,
    private offlineOnlineService: OfflineOnlineService

  ) {
    //Get Action to save
    this.activedRoute.params.subscribe(params => {
      this.typeBoton = params.type ? Number(params.type) : null;
      console.log('Type Borón firma ', this.typeBoton);
    });
  }

  ngOnInit(): void {

    //Get Caseta
    this.store.select(selectCaseta).subscribe(caseta => this.caseta = caseta);

    //Get User
    this.store.select(selectUser).subscribe(user => this.user = user);

    //Get Devolucion state
    this.store.select(selectPanelIzquierdo).subscribe(devolucion => this.devolucionStore = devolucion);

    //Get Retiro state
    this.store.select(selectPanelIzquierdoRetiro).subscribe(retiro => this.retiroStore = retiro);

    this.getConnection();
  }


  async confirmValue(e: string) {


    // this.getConnection();
    // if (!this.isOffline) {
    //   this.toastr.error('No se encontro conexión a Red', 'Red inestable', { positionClass: 'toast-bottom-right' });

    // }
    try {
      //Save notification Atendida
      let body = {
        id_corte: this.user.id_corte_usuario,
        tipo: this.typeBoton == TypeBoton.DEVOLUCION ? TYPE_NOTIFICATION.DEVOLUCION : TYPE_NOTIFICATION.RETIRO,
        estatus: STATUS_NOTIFICATION.ATENDIDO
      };
      this.ngxUiLoaderService.start();
      let res = await this.notificacionesService.updateStateByCorte(body).toPromise()

      //validar la clave del jt

      let isValid = await this.loginService.validatePasswordJTLocal(e);
      if (!isValid) {
        return this.toastr.error('La firma que has ingresado no es válida', 'Firma inválida', { positionClass: 'toast-bottom-right' });
      }

      //action to save
      //validar si es retiro o devolucion

      switch (this.typeBoton) {
        case TypeBoton.DEVOLUCION:
          this.saveLocalDevolucion();
          break;

        case TypeBoton.RETIRO:
          this.postRetiro();
          break;

        case TypeBoton.MORRALLA:
          this.store.dispatch(changeStatusMorralla({ status: true }));
          this.router.navigate(['mopa/home'])
          break;

        case TypeBoton.ROLLO:
          this.store.dispatch(savedStatusRollo({ stateRollo: 3 }))//3 confirmado por jefe turno
          this.router.navigate(['mopa/cambio-rollo'])
          break;

        default:
          console.log('No hay configuración para este caso Firma.Component->confirmValue');

      }

    } catch (err) {
      console.log(err);
      return this.toastr.error('No se encontro conexión a Red', 'Red inestable', { positionClass: 'toast-bottom-right' })


    } finally {
      this.ngxUiLoaderService.stop();
    }


  }

  postRetiro() {
    let retiro: Retiro = {
      fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
      id_corte_usuario: this.user.id_corte_usuario,
      id_usuario_retira: this.user.id_jefe_turno, //jefe de turno
      id_denominacion: this.retiroStore.id_denominacion
    };
    this.store.dispatch(postRetiro({ retiro }));
    this.store.dispatch(setStatusRetiro());
    this.finished('Retiro');


  }

  saveLocalDevolucion() {
    //obtner id de devolucion del store
    let devolucion: Devolucion = {
      fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
      id_corte_usuario: this.user.id_corte_usuario,
      id_usuario_retira: this.user.id_jefe_turno, //jefe turno
      tipo: 3, //'en transito'
      id_fondo: this.caseta?.fondo.id,
      devolucion_cantidad: this.devolucionStore.id_denominacion,
      id_cat_evento: CAT_EVENTO.DEVOLUCION
    };
    this.store.dispatch(postDevolucion({ devolucion }));
    this.store.dispatch(setStatus({ type_boton: TypeBoton.DEVOLUCION }));

    this.finished('Devolución');


  }

  finished(type: string) {

    this.toastr.success(`${type} guardado con éxito`, '', { positionClass: 'toast-bottom-right' });
    this.router.navigate(['/mopa/home']);


  }

  async cancelAction() {
    this.router.navigate(['/mopa/home']);
    let result = false
    switch (this.typeBoton) {
      case TypeBoton.DEVOLUCION:
        // cambia notif en bd
        result = await this.notificacion('Acción cancelada Devolucion', true, TYPE_NOTIFICATION.DEVOLUCION);
        if (result)
          // cambia panel izq
          this.store.dispatch(changeStatus({ type_boton: TypeBoton.DEVOLUCION, status: StatusPanel.INITIAL }));
        break;
      case TypeBoton.ROLLO:
        result = await this.notificacion('Acción cancelada Rollo', true, TYPE_NOTIFICATION.ROLLO);
        if (result)

          this.store.dispatch(savedStatusRollo({ stateRollo: 0 }));
        break;
      case TypeBoton.RETIRO:
        result = await this.notificacion('Acción cancelada Retiro', true, TYPE_NOTIFICATION.RETIRO);
        if (result)

          this.store.dispatch(changeStatusRetiro({ status: StatusPanel.INITIAL }));
        break;
      case TypeBoton.MORRALLA:
        result = await this.notificacion('Acción cancelada Morralla', true, TYPE_NOTIFICATION.MORRALLA);
        if (result)

          this.store.dispatch(changeStatusMorralla({ status: true }));
      default:
        console.log('Event missing configuration ');
        break;
    }

    // if (this.typeBoton == TypeBoton.DEVOLUCION) {
    //   //change status
    //   this.store.dispatch(changeStatus({type_boton: TypeBoton.DEVOLUCION, status: StatusPanel.INITIAL}));
    // } else {
    //   this.store.dispatch(changeStatusRetiro({status: StatusPanel.INITIAL}));
    // }

  }

  async notificacion(type: string, isCanceled = false, typeNotification: TYPE_NOTIFICATION) {
    try {
      this.ngxUiLoaderService.start()
      let response = await this.pushNotService.sendNotification({
        title: "MOPA",
        body: `${type}, Carril ${this.user.nom_carril}`,
        users: [this.user.id_jefe_turno],
        status: isCanceled ? STATUS_NOTIFICATION.CANCELADO : STATUS_NOTIFICATION.NOTIFICADO,
        type: typeNotification
      })
      this.toastr.success(`Notificando ${type}`, '', { positionClass: 'toast-bottom-right' });
      return true;
    } catch (error) {
      this.toastr.error('No se encontro conexión a Red', 'Red inestable', { positionClass: 'toast-bottom-right' });
      return false;
    } finally{
      this.ngxUiLoaderService.stop()
    }


  }

  getConnection() {
    this.offlineOnlineService.connectionChanged.subscribe(estatus => {
      this.isOffline = !estatus;
    });
  }
}
