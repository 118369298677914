import {Component, OnDestroy, OnInit} from '@angular/core';
import {Caseta} from './models';
import {Morralla} from './models/pouch.db';
import {CasetaService} from './services/caseta.service';
import {ConnectionService} from './services/connection.service';
import {MorrallaService} from './services/morralla.service';
import {Store} from '@ngrx/store';
import {loadCaseta, loadedCaseta} from './state/actions/caseta.actions';
import {Observable} from 'rxjs';
import {selectLoading} from './state/selectors/caseta.selectors';
import {SwPush} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {PushNotificationService} from './services/push-notification.service';
import {AppState} from './state/app.state';
import {selectUser} from './state/selectors/user.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  actualUser : any = {};

  constructor(
    private swPush: SwPush,
    private pushService: PushNotificationService,
    private store: Store<AppState>
  ) {
    //Push
    //this.suscribeToNotifications();
  }

  ngOnInit() {
    this.store.select(selectUser).subscribe(user => {
      this.actualUser = user;
    });
  }

  async suscribeToNotifications() {
    let sub = await this.swPush.requestSubscription(
      {
        serverPublicKey: environment.PUSH.VAPID_PUBLIC_KEY
      }
    );

    console.log(sub);
    //Validate if exist user to register token

    if(!this.actualUser){
      console.log('User dont configure')
      return false;
    }

    const {id_usuario} = this.actualUser;
    //save token
    this.pushService.saveToken(sub, id_usuario).subscribe(
      data => {
        console.log(data);
      }, err => {
        console.log(err);
      }
    );

  }
}
