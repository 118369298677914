import {createAction, props} from '@ngrx/store';
import {Rollo} from '../../models';

export enum ActionsRollo {
  LOAD_ROLLO = '[Rollo-GET] Load Rollo',
  LOADED_ROLLO = '[Rollo-GET] Loaded Rollo',
  POST_ROLLO = '[Rollo-POST] Post Rollo',
  POSTED_ROLLO = '[Rollo-POST] Posted success',
  STATUS_ROLLO = '[Rollo-STATUS] status Rollo',
  STATUSF_ROLLO = '[Rollo-STATUSF] Statusf success',
}


export const loadRollo = createAction(
  ActionsRollo.LOAD_ROLLO
);

export const loadedRollo = createAction(
  ActionsRollo.LOADED_ROLLO,
  props<{ rollo: Rollo }>()
);

export const postRollo = createAction(
  ActionsRollo.POST_ROLLO,
  props<{ rollo: Rollo }>()

);

export const postedRollo = createAction(
  ActionsRollo.POSTED_ROLLO,
  props<{ rollo: Rollo }>()
);

export const saveStatusRollo = createAction(
  ActionsRollo.STATUS_ROLLO
);

export const savedStatusRollo = createAction(
  ActionsRollo.STATUSF_ROLLO,
  props<{ stateRollo : number }>()
);
