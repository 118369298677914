import {createReducer, on} from '@ngrx/store';
import {MorrallaStatusState} from '../../core/models/morralla-status.state';
import {changeStatusMorralla} from '../actions/morralla-status.actions';


export const initialState: MorrallaStatusState  = { status : true};

export const morrallaStatusReducer = createReducer(
  initialState,
  on(changeStatusMorralla, (state, {status}) => {
    return {...state, status};
  }));
