<div class="p-2 border rounded border-solid border-gray-300 dark:border-gray-500 h-128 uppercase text-lg text-gray-500">
  <div class="flex flex-col text-center">
    <h2 class="text-gray-500 dark:text-gray-50 font-bold text-base">Firma jefe de turno</h2>
    <hr class="mt-1 mb-2">

    <div class="grid grid-cols-3">
      <div class="col-start-2 w-96">
        <app-teclado-numerico type="password" placeholder="Numero de empleado" (confirmValue)="confirmValue($event)" min="0" max="1" ></app-teclado-numerico>
      </div>

      <div class="col-start-2 mt-2">
        <button (click)="cancelAction()" class="bg-blue-600 p-3 w-96 text-white font-bold uppercase border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancelar</button>
      </div>

    </div>

  </div>
</div>

