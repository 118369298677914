import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FondoDenominacion } from 'src/app/models/dash';
import { AsignarFondoService } from 'src/app/services/asignar-fondo.service';
import { AuthService } from 'src/app/services/auth.service';
import { DevolucionService } from 'src/app/services/Devolucion.service';
import { AppState } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/selectors/user.selectors';

@Component({
  selector: 'app-faltante-fondo',
  templateUrl: './faltante-fondo.component.html',
  styleUrls: ['./faltante-fondo.component.scss']
})
export class FaltanteFondoComponent implements OnInit {

  denominaciones: FondoDenominacion[] = [];
  usuario: any;
  id_fondo: number;
  id_caseta: number;
  subtotales: number[] = [];
  faltanteSeleccionado: any = {};
  faltantesPendientes = [];
  faltanteDeuda: number;
  faltanteIngresado: number;
  faltanteDenominacion = [];

  get denominacionFormato() {
    return this.denominaciones.slice(0, ).reverse();
  }

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private asignarFondoService: AsignarFondoService,
    private store : Store<AppState>,
    private authService:AuthService,
    private config: NgSelectConfig,
    private devolucionService: DevolucionService,
    private ngxUiLoaderService : NgxUiLoaderService,
  ) {
    this.config.notFoundText = 'Sin faltantes';
  }

  ngOnInit(): void {
    // obtener usuario, fondo, caseta troncal
    this.getUsuario();
    // obtener faltantes de fondo
    this.getFaltanteFondo();
    // obtener fondo del sistema
    this.getFondoOperacion();
  }

  getUsuario() {
    this.store.select(selectUser).subscribe( usuario => {
      this.usuario = usuario;
      this.id_fondo = this.usuario.caseta_usuario[0].id_fondo;
      let casetaTroncal = this.usuario.caseta_usuario.find((caseta: any) => caseta.tipo === 1);
      this.id_caseta = casetaTroncal.id;
    });
  }

  getFaltante(event: any) {
    event ? this.faltanteSeleccionado = event : this.faltanteSeleccionado = [];
    event ? this.faltanteDeuda = event.faltanteFondo : this.faltanteDeuda = null;
    console.log( this.faltanteSeleccionado );
  }

  getFaltanteFondo() {
    this.devolucionService.getFaltanteFondoByDev( this.id_fondo ).subscribe( faltantes => {
      this.faltantesPendientes = faltantes;
      console.log( this.faltantesPendientes );
    });
  }

  getFondoOperacion() {
    this.asignarFondoService.getFondos().subscribe( fondos => {
      let fondo = fondos.find((fondo: any) => fondo.id === this.id_fondo);
      this.denominaciones = fondo['fondoDenominacion'];
    });
  }

  faltanteTotal(event: any, denominacion) {
    this.faltanteIngresado = 0;
    let cantidad = Number(event.target.value);
    let subtotal = cantidad * (denominacion.cantidad);
    this.subtotales[denominacion.id] = subtotal;

    for (let subtotal in this.subtotales) {
      this.faltanteIngresado += Number(this.subtotales[subtotal]);
    }

    this.insertFaltanteFondo( cantidad, denominacion);
  }

  insertFaltanteFondo(cantidad: number, denominacion: any){
    let index = this.faltanteDenominacion.findIndex( m => m.id_denominacion === denominacion.id);
    index === -1 ? this.faltanteDenominacion.push({
      id_denominacion: denominacion.id,
      cantidad: cantidad,
    }): this.faltanteDenominacion[index].cantidad = cantidad;

    console.log( this.faltanteDenominacion );
  }

  saveFaltante() {
    if ( !Object.keys( this.faltanteSeleccionado ).length ) { return this.toastr.error('No se ha seleccionado el faltante de fondo', 'Error', { positionClass: 'toast-bottom-right' } )}

    if ( !this.faltanteDenominacion.length || this.faltanteIngresado === 0) { return this.toastr.error('Ingresa una cantidad $ para faltantes', 'Error', { positionClass: 'toast-bottom-right' } ) }

    if (this.faltanteDeuda != this.faltanteIngresado ) { return this.toastr.error('La cantidades deben coincidir para pagar el faltante', 'Error', { positionClass: 'toast-bottom-right' } )}


    let data = {
      tipo: 1,
      id_cat_evento: 8,
      id_caseta: this.id_caseta,
      id_fondo: this.id_fondo,
      id_usuario_retira: this.usuario.id,
      fondo_denominacion: this.faltanteDenominacion,
      id_devolucion: this.faltanteSeleccionado.id_devolucion,
    }

    this.ngxUiLoaderService.start();

    this.devolucionService.saveFaltanteFondo( data ).subscribe( faltanteFondo => {
      console.log( faltanteFondo );
      this.toastr.success(`Se ha registrado pago de faltante de fondo`, 'Correcto', {positionClass: 'toast-bottom-right'});

      this.router.navigate(['/dashboard/fondo-operacion']);
      this.ngxUiLoaderService.stop();
    });

    console.log( 'GUARDANDO' );

  }


}







//   denominaciones: FondoDenominacion[] = [];
//   usuario: any;
//   caseta_id: number = null;
//   tipo: number = null;
//   turno: Turno;
//   subtotales: number[] = [];
//   faltantes = [];
//   faltanteFinal: number = 0;
//   asignarCobrador = {};
//   cobradores: any = [];
//   id_corte_usuario: number = null;
//   usuarios_faltantes = [];
//   pagaFaltante: number = null;

//   tipoFaltanteForm = new FormGroup({
//     tipoFaltante: new FormControl('', Validators.required)
//   });

//   get denominacionFormato() {
//     return this.denominaciones.slice(0, ).reverse();
//   }

//   constructor(
//     private router: Router,
//     private toastr: ToastrService,
//     public asignarFondo: AsignarFondoService,
//     private store: Store<AppState>,
//     private config: NgSelectConfig,
//     private ngxUiLoaderService: NgxUiLoaderService,
//     private corteService: CorteService,
//     private faltanteService: FaltanteService,
//   ) {
//       this.config.notFoundText = 'Sin cobradores';
//   }

//   ngOnInit(): void {

//     // obtener turno actual
//     this.turno = getTurno();
//     // obtener casetas de usuario
//     this.store.select(selectUser).subscribe( usuario => this.usuario = usuario);
//     // obtener fondo para conjunto de casetas
//     this.asignarFondo.getFondoDenominacion( this.usuario.caseta_usuario[0].id ).then( () => {
//           this.denominaciones = this.asignarFondo.fondoDenominacion;
//         });
//   }

//   getCaseta( caseta: any ) {
//     this.caseta_id = Number(caseta.target.value);

//     // obtener usuarios con faltantes para la tabla
//     this.faltanteService.getUsuariosFaltante( this.caseta_id ).subscribe( usuarios_faltantes => {
//       this.usuarios_faltantes = usuarios_faltantes;
//       console.log( usuarios_faltantes );
//     });

//   }

//   getTipoFaltante() {
//     // valida caseta
//     if ( this.caseta_id === null ) { 
//       this.tipoFaltanteForm.reset();
//       return this.toastr.error('Por favor, seleccione una caseta', 'Error', { positionClass: 'toast-bottom-right' } ) 
//     }
//     // genera = tipo.saliente | paga tipo.entrante
//     this.tipoFaltanteForm.value.tipoFaltante === 'genera' ? this.tipo = 0: this.tipo = 1;
//     // obtener cobradores dependiendo del tipo
//     if ( this.tipo === 0) {
//       // no tienen faltantes
//       this.pagaFaltante = null;
//       this.asignarCobrador = {};
//       this.corteService.getUsersCortes(this.caseta_id, this.turno.id).subscribe( cobradores => {
//         this.cobradores = cobradores;
//       });
//     } else {
//       // tienen faltantes
//       this.asignarCobrador = {};
//       this.faltanteService.getUsuariosFaltante( this.caseta_id ).subscribe( usuarios_faltantes => {
//         this.cobradores = usuarios_faltantes;
//       });
//     }

//     // reinicio total, array e inputs
//     this.faltanteFinal = 0;
//     this.faltantes = [];
//     let start: number = 20;
//     for (let i = 0; i < this.denominacionFormato.length; i++) {
//       (document.getElementById(`f${ (start+ i) }`) as HTMLInputElement).value = '0';
//       this.subtotales[(start + i)] = 0;
//     }

//   }

//   // obtener cobradores con cortes activos en el turno y dia { caseta, {pagar: si tiene un registro en devolucion de tipo faltante, genera: traer cobradores activos} }
//   getCobrador(event: any) {
//     event ? this.asignarCobrador = event : this.asignarCobrador = {};
//     this.tipo === 1 && event ? this.pagaFaltante = event.faltante : this.pagaFaltante = null;
//   }

//   faltanteTotal(event: any, denominacion, input: any) {
//     this.faltanteFinal = 0;

//     let cantidad = Number(event.target.value);
//     let subTotal = cantidad * (denominacion.cantidad);
//     this.subtotales[denominacion.id] = subTotal;

//     // validacion individual en relacion al fondo
//     if (this.tipo === 0 && cantidad > denominacion.pivot.cantidad) {
//       cantidad = 0;
//       this.subtotales[denominacion.id] = 0;
//       (document.getElementById(input.id) as HTMLInputElement).value = '0';
//       this.toastr.error(`La cantidad ingresada en $${ denominacion.cantidad } es mayor a la disponible`, 'Error', { positionClass: 'toast-bottom-right' } );
//     }

//     // total
//     for (let total in this.subtotales){
//       if(this.subtotales[total]){
//         this.faltanteFinal += Number(this.subtotales[total]);
//       }
//     }
    
//     this.insertFaltante( cantidad, denominacion)
//   }

//   insertFaltante(cantidad: number, denominacion: any){
//     let index = this.faltantes.findIndex( m => m.id_denominacion === denominacion.id);
//     index === -1 ? this.faltantes.push({
//       id_denominacion: denominacion.id, 
//       cantidad: cantidad, 
//       denominacion: denominacion.cantidad
//     }): this.faltantes[index].cantidad = cantidad;

//     console.log( this.faltantes );
//   }

//   saveFaltante() {
//     // valida caseta
//     if ( this.caseta_id === null ) { return this.toastr.error('Por favor, seleccione una caseta', 'Error', { positionClass: 'toast-bottom-right' } ) }
//     // valida tipo
//     if ( this.tipo === null ) { return this.toastr.error('Elija generar o pagar faltante', 'Error', { positionClass: 'toast-bottom-right' } ) }
//     // valida cobrador
//     if( Object.keys( this.asignarCobrador ).length === 0 ) { return this.toastr.error('Seleccione un cobrador por favor', 'Error', { positionClass: 'toast-bottom-right' } ) }
//     // valida cantidad x denominacion
//     if ( this.faltantes.length === 0 || this.faltanteFinal === 0) { return this.toastr.error('Ingresa una cantidad $ para faltantes', 'Error', { positionClass: 'toast-bottom-right' } ) }

//     // valida pago de faltantate
//     if( this.tipo === 1) {
//       if (this.pagaFaltante != this.faltanteFinal ) {
//         return this.toastr.error('La cantidades deben coincidir para pagar el faltante', 'Error', { positionClass: 'toast-bottom-right' } )
//       }
//     }

//     let data = {
//       tipo: this.tipo,
//       id_cat_evento: 5,
//       id_caseta: this.caseta_id,
//       fondo_denominacion: this.faltantes,
//       cobrador: this.asignarCobrador
//     }

//     this.ngxUiLoaderService.start();

//     this.faltanteService.saveFaltante( data ).subscribe( savedata => {
//       console.log( savedata );
//       if (this.tipo === 0) {
//         this.toastr.success(`Se ha generado el faltante`, 'Correcto', {positionClass: 'toast-bottom-right'});
//       } else {
//         this.toastr.success(`Se ha pagado el faltante`, 'Correcto', {positionClass: 'toast-bottom-right'});
//       }
//       this.router.navigate(['/dashboard/fondo-operacion']);
//       this.ngxUiLoaderService.stop();
//     });

//     console.log( 'GUARDANDO' );

//   }

// }
