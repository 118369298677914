import {Component, Input, OnInit} from '@angular/core';
import {Denominacion, Devolucion, Retiro, Usuario} from '../../models';
import {TYPE_DENOMINACION} from '../../utils/enum';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/app.state';
import {selectBtnsRetiro, selectDevolucion} from '../../state/selectors/caseta.selectors';
import {postDevolucion, postedDevolucion} from '../../state/actions/devolucion.actions';
import {postedRetiro, postRetiro} from '../../state/actions/retiro.actions';

import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {loadedCaseta} from 'src/app/state/actions/caseta.actions';
import {selectUser} from '../../state/selectors/user.selectors';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss']
})
export class AsideComponent implements OnInit {
  btnsDevolucion$: Observable<Denominacion[]> = new Observable<Denominacion[]>();
  btnsRetiro$: Observable<Denominacion[]> = new Observable<Denominacion[]>();
  devolucion: Devolucion;
  actualUser : any;

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.btnsRetiro$ = this.store.select(selectBtnsRetiro);
    this.btnsDevolucion$ = this.store.select(selectDevolucion);
    this.store.select(selectUser).subscribe(user=>{
      this.actualUser = user;
      console.log(this.actualUser)
    })
  }

  postDevolucion(id) {
    this.router.navigate(['/mopa/devolucion-denominacion']);
  }

  postRetiro(id) {
    let retiro: Retiro = {
      fecha_hora: moment().format('YYYY-MM-DD HH:mm:SS'),
      id_corte_usuario: this.actualUser.id_corte_usuario,
      id_usuario_retira: this.actualUser.id_usuario,
      id_denominacion: id
    };
    this.store.dispatch(postRetiro({retiro}));

  }
}
