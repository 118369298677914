import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Usuario} from '../models';
import {environment} from 'src/environments/environment';
import PouchDB from 'pouchdb-browser';
//import { Usuario } from '../models/pouch.db';
import {Observable, from} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {State, Store} from '@ngrx/store';
import {AppState} from '../state/app.state';
import {selectUser} from '../state/selectors/user.selectors';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private db: any;
  temp = {};
  private ENDPOINT = environment.ENDPOINTS.USUARIO;
  private ENDPOINT_LOGIN = environment.ENDPOINTS.LOGIN;

  constructor(private http: HttpClient,
              private toastr : ToastrService,
              private store:Store<AppState>
  ) {
    this.db = new PouchDB('db_user');

  }

  getUsers() {
    return this.http.get<Usuario[]>(this.ENDPOINT);
  }

  saveUsuarioLocal(dataSchema: Usuario): Observable<Usuario> {
    console.log('no hay conexión a red');
    let result = new Promise((resolve, reject) => {
      this.db.get(dataSchema._id)
        .then(async (doc: any) => {
          console.log('encontrado y actualizado');
          delete dataSchema._id;
          doc = Object.assign(doc, dataSchema);
          try {
            let result = await this.db.put(doc);
            resolve(result);
          } catch (err) {
            reject(err);
          }

        }).catch(async () => {
        try {
          let result = await this.db.post(dataSchema);
          resolve(result);
          console.log('se creo new register');
        } catch (err) {
          reject(err);
        }
      });
    });

    return from(result);
  };

  public deleteMorrallaLocal(id: string) {
    return this.db.get(id)
      .then(doc => {
        return this.db.remove(doc);
      })
      .then(
        _ => {
          return true;
        }
      )
      .catch(err => {
        console.log(err);
      });
  }

  public getInfoLocal() {
    this.db.info().then(data => {
    });
  }


  public getAll(): Promise<Usuario[]> {
    return new Promise((resolve, reject) => {
      this.db.allDocs({
        include_docs: true
      })
        .then(data => {
          let eventsMorralla = data.rows.map(
            (row: any): Usuario => {

              return ({
                _id: row.doc._id,
                name: row.doc.name,
                password: row.doc.password,
                usuario: row.doc.usuario
              });

            }
          );
          resolve(eventsMorralla);
        }).catch(err => {
        reject(err);
      });
    });
  }


  login() {
    setTimeout(() => {
      return true;
    }, 3000);
  }

  getActualUser(idTurno: number) {
    let idCarril = localStorage.getItem('id_carril');
    if (!idCarril) {
      this.toastr.error('No se ha configurado la cabina', '' , {positionClass: 'toast-bottom-right' })
      return new Observable<Object>();
    }
    return this.http.get(`${this.ENDPOINT_LOGIN}/actual-user?id_carril=${idCarril}&id_turno=${idTurno}`);
  }

  validatePasswordJT(password: string, id_user: number){
    return this.http.post<{isValid: boolean}>(`${this.ENDPOINT_LOGIN}/validate-password`, {password, id_user})
  }

  loginJT (form ){
    return this.http.post(`${this.ENDPOINT_LOGIN}/jt`,form);
  }

  validatePasswordJTLocal(password: string){
    return new Promise<boolean>((resolve,reject)=>{
       this.store.select(selectUser).subscribe((user: any) =>{
        if(!user){
          return resolve(false);
        }

        if(!user.firma_jefe){
          return  resolve(false)
        }

         return resolve(user.firma_jefe == password)
      })
    })

  }
}
