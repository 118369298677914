import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RolloService } from '../../services/rollo.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import { selectUser } from '../../state/selectors/user.selectors';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { runInThisContext } from 'vm';
import { UserService } from 'src/app/services/user.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TYPE_ROL } from '../../../app/utils/enum';
import { addCorteUserAuxiliar } from 'src/app/state/actions/user.actions';
import { selectCaseta } from 'src/app/state/selectors/caseta.selectors';
import { Caseta } from 'src/app/models';
import { longNumber } from '../../../app/utils/index';
import { selectEstatusRollo } from 'src/app/state/selectors/rollo.selectors';

@Component({
  selector: 'app-corte',
  templateUrl: './corte.component.html',
  styleUrls: ['./corte.component.scss']
})
export class CorteComponent implements OnInit {
  arqueo: boolean = false;
  arqueoUser: number = 0;
  user: any;
  cobradores = [];
  caseta : Caseta;
  confirmModal = false;
  textMensaje='Estás seguro de asignar el rollo pendiente al siguiente corte'
  statusRollo: number = 0;

  constructor(
    private router: Router,
    private rolloService: RolloService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private store: Store<AppState>,
    private authService: AuthService,
    private toastr: ToastrService,
    private serviceUser : UserService
  ) {
  }

  ngOnInit(): void {
    //Get user
    this.store.select(selectUser).subscribe(user => this.user = user);
    console.log(this.user);

    this.serviceUser.getUsers(this.user.id_caseta, TYPE_ROL.CARRIL).subscribe(data=>{
      this.cobradores = data;
      console.log(data);
    })

    this.store.select(selectCaseta).subscribe(caseta=>this.caseta=caseta);
    //recuperar el state de rollo
    this.store.select(selectEstatusRollo).subscribe(status => this.statusRollo = status);
  }

  async logut(e: string) {
      //guardar estado de rollo si recarga
      if (this.statusRollo == 2 || this.statusRollo == 3) {// 2 MANDA AL COMPONENTE DE ROLLO, // 3 confirmado por JT
          //guardar el corte actual del usuario
          localStorage.setItem('rollo_pendiente', this.user.id_corte_usuario);
      }
      //validar que se cumpla con longitud de 8 digitos el folio
        if(!longNumber(Number(e.length))){
          return this.toastr.error('El folio debe tener 8 digitos', 'Revisa el Folio',  { positionClass: 'toast-bottom-right' });
        }
      //petición para guardar último folio
      try {
        this.ngxUiLoaderService.start();
        let res = await this.rolloService.saveUltimoFolio({ ultimo_folio: e, id_corte_usuario: this.user.id_corte_usuario, arqueo: this.arqueo }).toPromise();
        console.log(res)
        //validar si es una auxiliar guardae el id de corte usuario en storage
        if (this.caseta.tipo === 0) {
          localStorage.setItem('id_corte_auxiliar', this.user.id_corte_usuario);
          //guardar id_corte anterior para el historial de confirmacion en el fondo
          localStorage.setItem('id_corte_anterior', this.user.id_corte_usuario);
          //this.store.dispatch(addCorteUserAuxiliar(this.user.id_corte_usuario))

        }
        //redirect to login
        this.authService.logout();
        this.router.navigate(['/login']);
        this.toastr.success('Corte cerrado con éxito', '',  { positionClass: 'toast-bottom-right' });

      } catch (err) {
        this.toastr.error('No se encontro conexión a Red', 'Red inestable',  { positionClass: 'toast-bottom-right' });
        this.ngxUiLoaderService.stop();
        console.log(err);
      } finally {
        this.ngxUiLoaderService.stop();
      }


  }
  isArqueo() {
    this.arqueoUser=0
    this.arqueo = this.arqueo ? false : true;
  }

  getCobrador(user) {
    this.arqueoUser = user.id;

  }

  // pendingRollo(){
  //   //guardar el corte actual del usuario
  //   localStorage.setItem('rollo_pendiente', this.user.id_corte_usuario);
  //   this.confirmModal = false;

  //   this.toastr.info('Se ha agregado un rollo pendiente','Rollo pendiente',{ positionClass: 'toast-bottom-right' })
  // }

  // cancelarRolloPendiente(){
  //   //cancelar  el corte actual del usuario
  //   this.confirmModal = false;
  //   localStorage.setItem('rollo_pendiente', '0');
  //   this.toastr.info('Se ha cancelado el rollo pendiente','Rollo pendiente',{ positionClass: 'toast-bottom-right' })

  // }

  // getText(){
  //  return Number(localStorage.getItem('rollo_pendiente'))==0?'Estás seguro de asignar el rollo pendiente al siguiente corte':'Estás seguro de cancelar el rollo pendiente al siguiente corte'

  // }
}
