<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase font-semibold text-base text-center text-gray-500 dark:text-gray-200">
    <div class="flex flex-col">

        <div class="flex justify-between items-center">
            <div class="flex flex-1 text-sm items-center">
                <span class="font-semibold">Caseta:</span>
                <select (change)="getCaseta($event)" class="appearance-none block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                    <option selected disabled>Seleccionar caseta</option>
                    <option *ngFor="let caseta of usuario.caseta_usuario" value="{{ caseta.id }}">{{ caseta.nombre }}</option>
                </select>
            </div>
            <span class="flex-1 text-gray-500 dark:text-gray-50 py-2 font-bold">Faltantes y pago de faltantes</span>
            <span class="flex-1"></span>
        </div>

        <hr class="dark:border-gray-500 mb-4">

        <div class="flex justify-between">

            <div class="flex flex-1 flex-col">
                
                <div class="flex w-full justify-center mb-4">
                    <form [formGroup]="tipoFaltanteForm" (change)="getMovimiento()" class="w-3/4 rounded bg-gray-100 dark:bg-gray-700 p-2 font-medium">
                        <div>
                            <input type="radio" name="tipoFaltante" formControlName="tipoFaltante" value="genera" id="1" class="peer hidden" />
                            <label for="1" class="block cursor-pointer select-none rounded p-2 text-center peer-checked:bg-blue-500 peer-checked:font-semibold peer-checked:text-white">Generar</label>
                        </div>
                        <hr class="border-gray-300 dark:border-gray-500">
                        <div>
                            <input type="radio" name="tipoFaltante" formControlName="tipoFaltante" value="paga" id="2" class="peer hidden" />
                            <label for="2" class="block cursor-pointer select-none rounded p-2 text-center peer-checked:bg-blue-500 peer-checked:font-semibold peer-checked:text-white">Pagar</label>
                        </div>
                    </form>
                </div>

                <div *ngIf="id_caseta && ( tipoMov === 0 || tipoMov === 1 )" class="flex w-full justify-center mb-4">
                    <select id="tipoFaltanteCat" (change)="getTipoFaltante($event)" class="appearance-none w-3/4 p-1 text-sm font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                        <option selected disabled>Seleccionar tipo faltante</option>
                        <option *ngFor="let catf of cat_faltante" value="{{ catf.id }}">{{ catf.nombre }}</option>
                    </select>
                </div>

                <div class="flex w-full justify-center mb-4">
                    <div class="w-3/4">
                        <label class="sr-only">Seleccionar personal</label>
                        <ng-select class="custom text-xs" 
                                   placeholder="Seleccionar personal" 
                                   [items]="personal"
                                   bindLabel="nombre_usuario" 
                                   bindValue="id"
                                   (change)="getCobrador($event)">
                        </ng-select>
                    </div>
                </div>


                <div *ngIf="pagaFaltante" class="flex justify-center text-blue-600 dark:text-blue-50 mb-4">
                    <div class="w-3/4 bg-gray-100 dark:bg-gray-700 rounded text-lg font-bold p-2">
                        <span>Pagar: $ </span>
                        <span>{{ pagaFaltante | number }}</span>
                    </div>
                </div>

                <div class="flex justify-center text-blue-600 dark:text-blue-50 mb-4">
                    <div class="w-3/4 bg-gray-100 dark:bg-gray-700 rounded text-lg font-bold p-2">
                        <span>Total: $ </span>
                        <span>{{ faltanteFinal | number }}</span>
                    </div>
                </div>

                <div class="flex justify-center mb-4">
                    <div class="w-3/4">
                        <button (click)="saveFaltante()" class="btn p-2 w-full bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600">Guardar</button>
                    </div>
                </div>

            </div>

            <div class="flex flex-1 flex-col">

                <div class="flex w-full mb-4">
                    <span class="flex-1">Denominacion</span>
                    <span class="flex-1">Cantidad</span>
                    <span class="flex-1">Total</span>
                </div>

                <div *ngFor="let denominacion of denominacionFormato" class="flex space-x-2 mb-2">
                    <input class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 font-bold text-base dark:border-gray-600 dark:bg-slate-800 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" disabled value="{{ denominacion.cantidad | number }}" type="text">

                    <input #input id="f{{ denominacion.id }}" (change)="faltanteTotal($event, denominacion, input)" class="flex-1 w-6 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" type="number" min="0">

                    <input [value]="subtotales[denominacion.id] || 0 | number" class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-slate-800 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" disabled type="text">
                </div>

            </div>

            <div class="flex-1 text-center font-bold text-xs">
                <div class="px-4 overflow-y-auto">

                    <table class="border-collapse w-full border border-slate-400 dark:border-slate-500 bg-white dark:bg-slate-800 text-sm shadow-sm">
                        <thead class="bg-slate-50 dark:bg-slate-700">
                            <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">Usuario</th>
                            <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">Fecha</th>
                            <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">Cantidad</th>
                        </thead>

                        <tbody *ngIf="faltante_historial.length > 0; else noFaltantes" class="font-semibold text-xs">
                            <tr *ngFor="let fh of faltante_historial">
                                <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">{{ fh.nombre_usuario }}</td>
                                <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">{{ fh.fecha_hora }}</td>
                                <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">${{ fh.faltante | number }}</td>
                            </tr>
                        </tbody>

                        <ng-template #noFaltantes>
                            <tbody class="font-semibold text-xs">
                                <td colspan="3" class="py-4">
                                    Sin faltantes, seleccione una caseta para obtener informacion
                                </td>
                            </tbody>
                        </ng-template>

                    </table>

                </div>
            </div>

        </div>

    </div>
</div>