<div
  class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase text-base text-center font-semibold text-gray-500 dark:text-gray-200">
  <div class="m-2">
    <div class="flex flex-col">

      <span class="text-gray-500 dark:text-gray-50 font-bold">Morralla para cobrador</span>
      <hr class="mb-2 dark:border-gray-500">

      <div class="text-xs mb-2">
        <div class="flex items-center">
          <span class="font-semibold">Caseta:</span>
          <select (change)="getCaseta($event)"
                  class="appearance-none block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
            <option selected disabled>Seleccionar caseta</option>
            <option *ngFor="let caseta of usuario.caseta_usuario" value="{{ caseta.id }}">{{ caseta.nombre }}</option>
          </select>
        </div>
      </div>

      <hr class="dark:border-gray-500 mb-4">

      <div class="grid grid-cols-11 grid-flow-row gap-1 bg-gray-200 dark:bg-gray-900/50 rounded p-2 mb-2 divide-x divide-gray-500">
        <span class="font-bold">Cabina</span>
        <span *ngFor="let denominacion of denominacionFormato">${{ denominacion.cantidad | number }}</span>
        <span class="font-bold">Total</span>
      </div>

      <div *ngFor="let morralla of morrallas" class="grid grid-cols-11 grid-flow-row gap-1 items-center bg-gray-100 dark:bg-gray-700/50 rounded p-2 mb-2">
        <hr class="col-span-11 my-2 dark:border-gray-500">
        <span>{{morralla.nombre}}</span>

        <input #input id="c{{ morralla.id_carril }}-{{ morralla.id_notificacion }}-{{ m }}"
               *ngFor="let denominacion of denominacionFormato; let m = index"
               (change)="getResult(morralla.id_carril, denominacion, $event, morralla.id_corte_usuario, morralla.id_notificacion, input)"
               type="number"
               class="text-center block py-1 font-normal text-gray-700 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"/>

        <span id="c{{ morralla.id_carril }}">$0</span>

        <hr class="col-span-11 my-2 dark:border-gray-500">
      </div>

      <div
        class="grid grid-cols-11 grid-flow-row gap-1 bg-gray-200 dark:bg-gray-900/50 rounded p-2 mb-2 divide-x divide-gray-500">
        <span class="font-bold">Cantidad</span>
        <span *ngFor="let df of denominacionFormato; let ca = index" id="ca{{ ca }}">0</span>
        <span id="caTotal" class="font-bold">0</span>
      </div>

      <div
        class="grid grid-cols-11 grid-flow-row gap-1 bg-gray-200 dark:bg-gray-900/50 rounded p-2 mb-2 divide-x divide-gray-500">
        <span class="font-bold">C/Disponible</span>
        <span *ngFor="let df of denominacionFormato" class="text-green-600">{{ df.pivot.cantidad }}</span>
        <span class="font-bold">-</span>
      </div>

      <div
        class="grid grid-cols-11 grid-flow-row gap-1 bg-gray-200 dark:bg-gray-900/50 rounded p-2 mb-2 divide-x divide-gray-500">
        <span class="font-bold">Monto</span>
        <span *ngFor="let df of denominacionFormato; let mo = index" id="mo{{ mo }}">$0</span>
        <span id="moTotal" class="font-bold">$0</span>
      </div>

      <!-- footer -->
      <div class="w-full">
        <button type="button"
                class="float-right bg-gray-50 dark:bg-transparent rounded py-4 px-2 text-xs text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 uppercase"
                (click)="saveMorralla()">Guardar solicitud
        </button>
      </div>

    </div>

    <hr class="col-span-9 my-4 dark:border-gray-500">

    <div class="flex flex-col">
      <span class="text-gray-500 dark:text-gray-50 font-bold">Rollo para cobrador</span>
      <hr class="mb-2 dark:border-gray-500">

      <div
        class="grid grid-cols-3 grid-flow-row gap-1 bg-gray-200 dark:bg-gray-900/50 rounded p-2 mb-2 divide-x divide-gray-500">
        <span class="font-bold">Cabina</span>
        <span class="font-bold">Folio inicial</span>
        <span class="font-bold">Folio final</span>
      </div>

      <div *ngFor="let rollo of rollos; let r = index"
           class="grid grid-cols-3 grid-flow-row gap-1 items-center bg-gray-100 dark:bg-gray-700/50 rounded p-2 mb-2">
        <hr class="col-span-3 my-2 dark:border-gray-500">

        <span>{{ rollo.nombre }}</span>

        <input #inputFI id="fi-{{ rollo.id_notificacion_push }}"
               (change)="getFolios(rollo.id_notificacion_push, $event, inputFI, 1)" type="number"
               class="text-center block py-1 font-normal text-gray-700 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"/>

        <input #inputFF id="ff-{{ rollo.id_notificacion_push }}"
               (change)="getFolios(rollo.id_notificacion_push, $event, inputFF, 2)" type="number"
               class="text-center block py-1 font-normal text-gray-700 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"/>

        <hr class="col-span-3 my-2 dark:border-gray-500">
      </div>

      <div class="w-full">
        <button type="button"
                class="float-right bg-gray-50 dark:bg-transparent rounded py-4 px-2 text-xs text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 uppercase"
                (click)="confirmModal = true">Guardar rollo
        </button>
      </div>

    </div>

  </div>
</div>

<div *ngIf="confirmModal" class="modal-component-container fixed inset-0 overflow-y-auto">
  <div
    class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>

    <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>

    <div
      class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
      <div class="modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

        <div class="modal-wrapper-flex sm:flex sm:items-start">

          <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">

            <div class="flex items-center justify-between">
              <div class="flex items-center rounded py-2">
                <span class="pl-2">¿Estás seguro de guardar los folios ingresados ?</span>
                <div class="bg-white dark:bg-gray-900/50 rounded-md w-full">
                  <div class="p-2 overflow-x-auto h-96">
                      <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">

                          <table class="w-full text-center text-gray-900 dark:text-gray-300">

                              <thead>
                                  <tr class="border-b-2 border-gray-200 dark:border-gray-600 bg-gray-100 dark:bg-gray-900/50 text-gray-600 dark:text-gray-300">
                                      <th class="px-2 py-3">Cabina</th>
                                      <th class="px-2 py-3">Folio inicial</th>
                                      <th class="px-2 py-3">Folio final</th>
                                  </tr>
                              </thead>

                              <tbody class="font-semibold">

                                  <tr *ngFor=" let data of arrayRollos" class="border-b border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-900/50 ">

                                      <td   class="p-2">
                                          <span>{{data.nombreCabina}}</span>
                                      </td>

                                      <td class="p-2">
                                          <span>{{data.folio_inicial}}</span>
                                      </td>

                                      <td class="p-2">
                                          <span>{{data.folio_final}}</span>
                                      </td>

                                  </tr>

                              </tbody>
                          </table>

                      </div>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-actions bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button (click)="confirmModal = false"
                class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          Cerrar
        </button>
        <button (click)="saveRollo()"
                class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          Aceptar
        </button>
      </div>

    </div>
  </div>
</div>


