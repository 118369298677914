import {Usuario} from '../../models';
import {createReducer, on} from '@ngrx/store';
import { loadUser, loadedUser, changeCaseta, changeAccion, addUserEstado, addCorteUserAuxiliar } from '../actions/user.actions';
import {UsuarioState} from '../../core/models/user.state';


export const initialState:UsuarioState = {loading: false, usuario: null, accion: null, id_corte_auxiliar: 0};


export const usuarioReducer = createReducer(
  initialState,
  on(loadUser, (state) => {
    return {...state, loading: true};
  }),
  on(loadedUser, (state, {usuario}) => {
    return {...state, loading: false, usuario};
  }),
  on(changeCaseta, (state, {caseta_selected}) => {
    return {
      ...state,
      usuario: {
        ...state.usuario, caseta_selected
      }
    };
  }),
  on(changeAccion, (state, {id_cat_acciones}) => {
    return {
      ...state,
      usuario: {
        ...state.usuario, id_cat_acciones
      }
    };
  }),
  on(addUserEstado, (state,{estado}) => {
    return {...state, estado};
  }),
  on(addCorteUserAuxiliar, (state,{id_corte_auxiliar}) => {
    return {...state, id_corte_auxiliar};
  }),
);
