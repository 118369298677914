import { createSelector } from '@ngrx/store';
import {AppState} from '../app.state';
import {InputState} from '../../core/models/input.state';

export const selectInputFeature = (state: AppState) => state.input;

export const selectInputs = createSelector(
  selectInputFeature,
  (state: InputState) => state
);
