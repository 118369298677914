import { createSelector } from '@ngrx/store';
import {AppState} from '../app.state';
import {CasetaState} from '../../core/models/caseta.state';
import {TYPE_BTNS, TYPE_DENOMINACION, TYPE_ROL} from '../../utils/enum';

export const selectCasetaFeature = (state: AppState) => state.caseta;

export const selectCaseta = createSelector(
  selectCasetaFeature,
  (state: CasetaState) => state.caseta
);

export const selectBtnsMenu = createSelector(
  selectCasetaFeature,
  (state: CasetaState) => {
    return state.caseta?.btnTipos.find(btn => btn.id === TYPE_BTNS.MENU)?.subtipoCruces
  }
);


export const selectMorralla = createSelector(
  selectCasetaFeature,
  (state: CasetaState) => {
    return state.caseta?.btnMorralla.filter(d => d.tipo === TYPE_DENOMINACION.MORRALLA)
  }
);

export const selectBtnsRetiro = createSelector(
  selectCasetaFeature,
  (state: CasetaState) => {
    return state.caseta?.btnMorralla.filter(d => d.tipo === TYPE_DENOMINACION.RETIRO)
  }
);

export const selectDevolucion = createSelector(
  selectCasetaFeature,
  (state: CasetaState) => {
    return state.caseta?.btnMorralla.filter(d => d.tipo === TYPE_DENOMINACION.DEVOLUCION)
  }
);

export const selectLoading = createSelector(
  selectCasetaFeature,
  (state: CasetaState) => state.loading
);

export const selectPermisos = createSelector(
  selectCasetaFeature,
  (state: CasetaState) => {
    return state.caseta?.permisos.filter(d => d.pivot.id_rol === TYPE_ROL.CARRIL)
    //
  }
);
