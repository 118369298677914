<form>
  <label class="block text-sm">
    <span class="text-gray-700 dark:text-gray-400 sr-only">Número de empleado</span>
    <input
      *ngIf="!multipleInputs"
      id="password"
      name="password"
      [type]="type"
      min="1"
      max="99999999"
      autocomplete="off"
      required
      [(ngModel)]="text"
      (keypress)="onKeyPress($event)"
      class="border rounded border-gray-300 placeholder-gray-500 px-3 py-2 block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray h-12 form-input"
     [placeholder]="placeholder"
    />
  </label>

  <div class="grid grid-cols-3 grid-flow-row gap-2 mt-6 text-white text-xl">
    <button type="button" (click)="onPress(7)" class="bg-blue-600 p-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">7</button>
    <button type="button" (click)="onPress(8)" class="bg-blue-600 p-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">8</button>
    <button type="button" (click)="onPress(9)" class="bg-blue-600 p-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">9</button>
    <button type="button" (click)="onPress(4)" class="bg-blue-600 p-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">4</button>
    <button type="button" (click)="onPress(5)" class="bg-blue-600 p-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">5</button>
    <button type="button" (click)="onPress(6)"class="bg-blue-600 p-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">6</button>
    <button type="button" (click)="onPress(1)" class="bg-blue-600 p-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">1</button>
    <button type="button" (click)="onPress(2)" class="bg-blue-600 p-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">2</button>
    <button type="button" (click)="onPress(3)" class="bg-blue-600 p-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">3</button>
    <button type="button" (click)="onPress(0)" class="bg-blue-600 p-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">0</button>
    <button type="button" (click)="erase()" class="col-span-2 bg-blue-600 p-3 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      <img class="h-6 mx-auto" src="/assets/images/btn_backspace.svg" alt="">
    </button>
    <button type="button" (click)="clear()" class="bg-blue-600 p-4 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      <img class="h-5 mx-auto" src="/assets/images/btn_erase.svg" alt="">
    </button>
    <button type="submit" [disabled]="isDisabled()" (click)="confirm()" class="col-span-2 bg-blue-600 p-4 font-bold border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" >
      <img class="h-5 mx-auto" src="/assets/images/btn_send.svg" alt="">
    </button>
  </div>

</form>
