import {Caseta} from '../models';
import {ActionReducerMap} from '@ngrx/store';
import {casetaReducer} from './reducers/caseta.reducers';
import {CasetaState} from '../core/models/caseta.state';
import {UsuarioState} from '../core/models/user.state';
import {usuarioReducer} from './reducers/user.reducer';
import {devolucionReducer} from './reducers/devolucion.reducers';
import { DevolucionState } from '../core/models/devolucion.state';
import {CruceState} from '../core/models/cruce.state';
import {cruceReducer} from './reducers/cruce.reducers';
import { RetiroState } from '../core/models/retiro.state';
import {retiroReducer} from './reducers/retiro.reducers';
import { BtnRapidoState } from '../core/models/btnRapido.state';
import {btnRapidoReducer} from './reducers/btnRapido.reducers';
import  {TipoVehiculoState } from '../core/models/tipoVehiculo.state';
import {tipoVehiculoReducer} from './reducers/tipoVehiculo.reducers';
import { rolloReducer } from './reducers/rollo.reducers';
import { RolloState } from '../core/models/rollo.state';
import {PanelIzquierdoState} from '../core/models/panelIzquierdo.state';
import {panelIzquierdoReducer} from './reducers/panelIzquierdo.reducers';
import {panelIzquierdoRetiroReducer} from './reducers/panelIzquierdoRetiro.reducers';
import {PanelIzquieroRetiroState} from '../core/models/panelIzquieroRetiro.state';
import {InputState} from '../core/models/input.state';
import {inputReducer} from './reducers/input.reducers';
import {NotificationState} from '../core/models/notification.state';
import {notificationReducer} from './reducers/notification.reducers';
import {HistoryNotification} from '../core/models/history.notification';
import {historyNotificationsReducer} from './reducers/history.notification.reducer';
import {morrallaStatusReducer} from './reducers/morralla-status.reducers';
import {MorrallaStatusState} from '../core/models/morralla-status.state';


export interface AppState{
  caseta: CasetaState,
  usuario: UsuarioState,
  devolucion: DevolucionState,
  retiro: RetiroState,
  cruce:CruceState
  btnRapido:BtnRapidoState,
  tipoVehiculo:TipoVehiculoState,
  rollo:RolloState,
  panelIzquierdo: PanelIzquierdoState,
  panelIzquierdoRetiro : PanelIzquieroRetiroState,
  input: InputState,
  notification: NotificationState
  history_notification: HistoryNotification,
  morrallaStatus: MorrallaStatusState
}


export const ROOT_REDUCERS : ActionReducerMap<AppState> = {
  caseta: casetaReducer,
  usuario: usuarioReducer,
  devolucion: devolucionReducer,
  cruce:cruceReducer,
  retiro:retiroReducer,
  btnRapido:btnRapidoReducer,
  tipoVehiculo:tipoVehiculoReducer,
  rollo:rolloReducer,
  panelIzquierdo: panelIzquierdoReducer,
  panelIzquierdoRetiro: panelIzquierdoRetiroReducer,
  input: inputReducer,
  notification: notificationReducer,
  history_notification: historyNotificationsReducer,
  morrallaStatus: morrallaStatusReducer
}
