import { Component, OnInit } from '@angular/core';
import { BtnRapidoService } from 'src/app/services/btn-rapido.service';
import { MorrallaService } from 'src/app/services/morralla.service';
import { OfflineOnlineService } from 'src/app/services/offline-online.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  isOffline = true;
  morrallaCount : number = 0;
  btnRapidoCount : number = 0;
  constructor(
    private offlineOnlineService:OfflineOnlineService,
    private morrallaService: MorrallaService,
    private btnRapidoService: BtnRapidoService
  ) { }

  ngOnInit() {
    this.offlineOnlineService.connectionChanged.subscribe(
      estatus=>{
        this.isOffline = !estatus;
      }
    )
      this.getCountSync();

  }

  async getCountSync(){
    // this.morrallaService.counterDocs$.subscribe(counter =>{
    //   this.morrallaCount = counter;
    // })
    // this.btnRapidoService.counterDocs$.subscribe(counter =>{
    //   this.btnRapidoCount = counter;
    // })
  }
}
