import {Caseta} from '../../models';
import {createReducer, on} from '@ngrx/store';
import {loadCaseta, loadedCaseta} from '../actions/caseta.actions';
import {CasetaState} from '../../core/models/caseta.state';


export const initialState:CasetaState = {loading: false, caseta: null};


export const casetaReducer = createReducer(
  initialState,
  on(loadCaseta, (state) => {
    return {...state, loading: true};
  }),
  on(loadedCaseta, (state,{caseta}) => {
    return {...state, loading: false, caseta};
  })
);
