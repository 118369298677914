<!-- BTN CINTA LATERAL IZQ -->
<!-- <div class="menu">
    <div class="container-image"></div>
    <button class="btn w-60"  (click)="changeAction(btn)" *ngFor="let btn of btns$ | async">
       {{btn.nombre}}
    </button>
</div> -->
<div class="grid grid-flow-row gap-4 border-solid	border border-gray-300 dark:border-gray-500 rounded m-2 p-2">

  <div>
    <img
      aria-hidden="true"
      class="mx-auto h-12 w-auto dark:hidden"
      src="/assets/images/sogak-logo-light.svg"
      alt="SOGAK"
    />
    <img
      aria-hidden="true"
      class="hidden mx-auto h-12 w-auto dark:block"
      src="/assets/images/sogak-logo-dark.svg"
      alt="SOGAK"
    />
    <div class="text-base font-semibold mb-2 uppercase text-gray-600 dark:text-white text-center">
      <span>carril</span>
    </div>
  </div>


  <!-- Devolucion de Aside -->
  <div *ngIf="!anyDevolutionLocalDB && !hasAnyDevolution()">
    <button #btnSDevolucion *ngFor="let denominacion of btnsDevolucion$ | async"
            [class.bg-green-800]="getColorDevolucion(1)"
            [class.dark:bg-green-800]="getColorDevolucion(1)"
            [class.hover:border-green-800]="getColorDevolucion(1)"
            [class.dark:hover:border-green-800]="getColorDevolucion(1)"
            [class.text-white]="getColorDevolucion(1)"
            [class.dark:bg-gray-600]="isOffline"
            [class.bg-gray-300]="isOffline"
            [class.dark:bg-transparent]="!isOffline"

            [class.bg-blue-600]="getColorDevolucion(2)"
            [class.dark:bg-blue-600]="getColorDevolucion(2)"
            [class.hover:border-blue-600]="getColorDevolucion(2)"
            [class.dark:hover:border-blue-600]="getColorDevolucion(2)"
            [class.text-white]="getColorDevolucion(2)"
            [class.bg-gray-50]="getColorDevolucion(3)"
            [disabled]="isOffline"
            [style.cursor]='isOffline ? "not-allowed" :  "pointer"'

            class="w-full h-14 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 dark:border-gray-400 drop-shadow-lg hover:border-blue-600"
            (click)="actionsDevolucion(btnSDevolucion)">Devolucion
    </button>
  </div>

  <button #btnSRetiro *ngIf="hasAnyDevolution()"
          [class.bg-green-800]="getColorRetiro(1)"
          [class.dark:bg-green-800]="getColorRetiro(1)"
          [class.hover:border-green-800]="getColorRetiro(1)"
          [class.dark:hover:border-green-800]="getColorRetiro(1)"
          [class.text-white]="getColorRetiro(1)"


          [class.dark:bg-gray-600]="isOffline"
          [class.bg-gray-300]="isOffline"
          [class.dark:bg-transparent]="!isOffline"
          [class.bg-blue-600]="getColorRetiro(2)"
          [class.dark:bg-blue-600]="getColorRetiro(2)"
          [class.hover:border-blue-600]="getColorRetiro(2)"
          [class.dark:hover:border-blue-600]="getColorRetiro(2)"
          [class.text-white]="getColorRetiro(2)"
          [class.bg-gray-50]="getColorRetiro(3)"
          [disabled]="isOffline"
          [style.cursor]='isOffline ? "not-allowed" :  "pointer"'
          class="bg-gray-50 w-full h-14 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 dark:border-gray-400 drop-shadow-lg hover:border-blue-600"
          (click)="actionsRetiro(btnSRetiro)">
    Retiro
  </button>

  <button #btnRollo
          class="btn bg-gray-50 w-full h-14 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 dark:border-gray-400 drop-shadow-lg hover:border-blue-600"
          [disabled]="isOffline" [style.cursor]='isOffline ? "not-allowed" :  "pointer"'
          [class.dark:bg-gray-600]="isOffline"
          [class.bg-gray-300]="isOffline"
          [class.dark:bg-transparent]="!isOffline" (click)="changeRollo(btnRollo)">{{styleButton(btnRollo)}}</button>

  <button
    class="bg-gray-50 w-full h-14 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 dark:border-gray-400 drop-shadow-lg hover:border-blue-600"
    (click)="changeAction(btn)" *ngFor="let btn of btns$ | async">
    {{btn.nombre}}
  </button>
</div>
