import { getLocaleDateFormat } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CorteService } from 'src/app/services/corte.service';
import { RetiroService } from 'src/app/services/retiro.service';
import { AppState } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/selectors/user.selectors';

@Component({
  selector: 'app-formato-final',
  templateUrl: './formato-final.component.html',
  styleUrls: ['./formato-final.component.scss']
})
export class FormatoFinalComponent implements OnInit {
  user: any = null;
  dataFormatoFinal : any = [];
  confirmModal: boolean = false;
  confirmModalPrint: boolean = false;
  cortes = [];
  totales = {
    nombre: 'Totales',
    retiroTotal: 0,
    efectivoFinal: 0,
    faltanteTotal: 0,
    sobranteTotal: 0,
    efectivoTotal: 0
  };

  constructor(
    private RetiroService: RetiroService,
    private CorteService: CorteService,
    private store: Store<AppState>,
    private router: Router,
    private toastr: ToastrService,
    private ngxUiLoaderService : NgxUiLoaderService,

  ) { }

  ngOnInit(): void {
    //redux
    this.store.select(selectUser).subscribe(async (user) => {
      this.user = user;

    });
    this.getData(this.user.id);
  }

  getData(id){
    this.ngxUiLoaderService.start();
    //NEW FLUJO JT, REVISAR
    this.RetiroService.getRetirosbyCortes(id)
    .subscribe(cortes =>{

      console.log( 'CORTES', cortes );

      for (let corte  of cortes) {
        // TODO: REVISAR
        let base: number =  corte.totalRetiros + corte.efectivoFinal - corte.resultado_SGP; // diferencia {negativa: faltante, positiva: sobrante}

        // let tempFaltante: number = base + corte.faltante_entregado;

        // let tempSobrante: number = base;
        let tempEF: number = !corte.efectivoFinal ? 0 : corte.efectivoFinal; // si no existe efectivo final es 0
        let tempFT: number = base < 0 ? Math.abs(base) : 0;
        let tempST: number = base > 0 ? base : 0;

        this.totales.retiroTotal += corte.totalRetiros;
        this.totales.efectivoFinal += tempEF;
        this.totales.faltanteTotal += tempFT;
        this.totales.sobranteTotal +=  tempST;
        this.totales.efectivoTotal += corte.totalRetiros + tempEF;

        this.cortes.push( {
          cabina: corte.nombre,
          totalRetiros: corte.totalRetiros,
          efectivoFinal: tempEF,
          Faltante: tempFT,
          Sobrante: tempST,
        });
      }
      this.dataFormatoFinal = cortes;
      this.ngxUiLoaderService.stop();

    })
  }


  setStatusCortes(){
    this.ngxUiLoaderService.start();
    let data = {
      cortes : this.dataFormatoFinal
    }
    this.CorteService.saveStatusCortes(data)
    .subscribe(data=>{
      this.ngxUiLoaderService.stop();
      this.confirmModalPrint = true;
      this.confirmModal = false;
      //this.router.navigate(['dashboard/home'])
    })

  }

    //NEW FLUJO: OBTENER INFORMACION DEPENDIENDO DEL USUARIO
    salienteJT(e){
      this.getData(e.target.checked ? localStorage.getItem('jt_saliente') :  this.user.id)

    }
}
