import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY} from 'rxjs';
import {map, mergeMap, catchError, exhaustMap} from 'rxjs/operators';
import {BtnRapidoService} from '../../services/btn-rapido.service';
import {BtnRapido} from'../../models/pouch.db';
import {BtnRapidoActions} from '../actions/btnRapido.actions';
import {BtnRapidoState} from '../../core/models/BtnRapido.state';

@Injectable()
export class BtnRapidoEffects {

  BtnRapido: BtnRapido;

  postBtnRapido$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BtnRapidoActions.postBtnRapido),
      exhaustMap((action : BtnRapidoState) => this.BtnRapidoService.saveBtnRapidoLocal(action.btnRapido)
        .pipe(
          map(BtnRapido => ({type: BtnRapidoActions.postedBtnRapido, BtnRapido})),
          catchError(() => EMPTY)
        ))
    );
  });

  constructor(
    private actions$: Actions,
    private BtnRapidoService: BtnRapidoService
  ) {
  }
}
