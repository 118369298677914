import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Retiro, Turno, Turnos } from 'src/app/models/dash';
import { RetiroService } from 'src/app/services/retiro.service';
import { TurnoService } from 'src/app/services/turno.service';
import { addRetirosPrint } from 'src/app/state/actions/Retiro.actions';
import { AppState } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { getTurno } from 'src/app/utils';

@Component({
  selector: 'app-retiro',
  templateUrl: './retiro.component.html',
  styleUrls: ['./retiro.component.scss']
})
export class RetiroComponent implements OnInit {

  retirosParciales = [];
  retirosReimpresion = [];
  id_retiro: number = 0;
  usuario: any;
  id_caseta: number;
  turno: Turno;
  turnos: Turnos[];
  isOpenModal: boolean = false;
  deleteModal: boolean = false;
  rondas = [];

  retiroForm = new FormGroup({
    fecha: new FormControl('', Validators.required),
    turno: new FormControl('', Validators.required),
    ronda: new FormControl('', Validators.required),
  });

  constructor(
    private store: Store<AppState>,
    private retiroService: RetiroService,
    private turnoService: TurnoService,
    private toastr: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
  ) { }

  ngOnInit(): void {
    this.turno = getTurno();
    this.getUsuario();
    this.getRetirosParciales();
    
    this.turnoService.getTurnos().subscribe( turnos => {
      this.turnos = turnos;
    });
  }

  getUsuario() {
    this.store.select(selectUser).subscribe( usuario => {
      this.usuario = usuario;
      let casetaTroncal = this.usuario.caseta_usuario.find((caseta: any) => caseta.tipo === 1);
      this.id_caseta = casetaTroncal.id;
    });
  }

  getRetirosParciales() {
    this.retiroService.getRetirosParcials( this.id_caseta, this.turno.id ).subscribe( retirosParciales => {
      this.retirosParciales = retirosParciales;
      console.log( "RETIROS PARCIALES", this.retirosParciales );
      // si no hay retiros deshabilito boton de impresion
      if (!this.retirosParciales.length) {
        (document.getElementById("imprimirReciente") as HTMLButtonElement).disabled = true;
        (document.getElementById("imprimirReciente") as HTMLButtonElement).classList.add('bg-gray-300', 'dark:bg-gray-600');
      }
      console.log( this.retirosParciales );
    });
  }

  openModal(tipo: number){
    this.isOpenModal = !this.isOpenModal;
    if (tipo === 1) {
      this.retiroFormReset(1);
      this.retirosReimpresion = [];
      this.store.dispatch(addRetirosPrint({'retiroPrint':this.retirosParciales}))
    }

    if (tipo === 2) {
      if (!this.retirosReimpresion.length) {
        this.isOpenModal = false;
        return this.toastr.info(`No hay retiros para reimprimir`, '', { positionClass: 'toast-bottom-right' } );
      }
      this.store.dispatch(addRetirosPrint({'retiroPrint':this.retirosReimpresion}))
    }
  }

  consultarRonda() {
    if (!this.retiroForm.valid) {
      return this.toastr.info(`Seleccione todos los campos para mostrar la informacion`, '', { positionClass: 'toast-bottom-right' } );
    }

    this.retiroService.getRonda( this.id_caseta, this.retiroForm.value.fecha, Number(this.retiroForm.value.turno), Number(this.retiroForm.value.ronda)).subscribe( retiroRondas => {
      this.retirosReimpresion = retiroRondas;
    });

  }

  retiroFormReset(tipo: number) {
    switch (tipo) {
      case 1:
        this.retiroForm.controls.turno.reset('');
        this.retiroForm.controls.ronda.reset('');
        this.rondas = [];
        break;
      case 2:
        this.retiroForm.controls.ronda.reset('');
        this.rondas = [];
        // buscar rondas
        let dataForm = this.retiroForm.value;
        console.log( dataForm );

        this.retiroService.getRetiroRonda( this.id_caseta, dataForm.fecha, dataForm.turno ).subscribe( rondas => {
          console.log( rondas );
          if (!rondas.length) {
            this.retiroFormReset(1);
            this.retirosReimpresion = [];
            return this.toastr.info(`No existen rondas para esta configuracion`, '', { positionClass: 'toast-bottom-right'} );
          }
          this.rondas = rondas;
        });

        break;
      default:
        break;
    }
  }

  getRetiroId(id_retiro) {
    this.id_retiro = id_retiro;
    console.log( 'ELIMINADO', id_retiro );
  }

  deleteRetiro() {
    this.ngxUiLoaderService.start();
    this.retiroService.deleteRetiro( this.id_retiro ).subscribe( data => {
      this.toastr.success('Se ha eliminado el retiro', '', {positionClass: 'toast-bottom-right'});
      this.deleteModal= false;
      window.location.reload()
      this.ngxUiLoaderService.stop();
    });
    this.ngxUiLoaderService.stop();
  }

}
