

<div class="flex flex-col justify-center text-black font-normal text-center text-xl uppercase w-44">

  <img aria-hidden="true" class="mx-auto h-12 w-auto mb-2" src="/assets/images/sogak-logo-light.svg" alt="SOGAK"/>
  <span class="text-base">{{dateTime | date:'dd/MM/yyyy'}}</span>
  <span class="text-base">{{ tipoImpresion }}</span>
  <span class="text-base">Efectivo Total</span>
  <span>TURNO: {{ turnoActual?.name }}</span>
  <span>JT: {{ user.nombre }}</span>
  <span>{{ user.apellido_paterno }} {{ user.apellido_materno }}</span>

  <table class="table-fixed text-justify w-max mx-auto mt-4">
      <thead class="border-y-2 border-black">
        <tr>
          <th>Cabina</th>
          <th class="w-4">&nbsp;</th>
          <th>Monto</th>
        </tr>
      </thead>
      <tbody *ngIf="cortes.length > 0">
          <tr *ngFor="let corte of cortes" class="border-b-2 border-black">
              <td>{{ corte?.cabina }}</td>
              <th class="w-4">&nbsp;</th>
              <td>${{ corte?.efectivoFinal + corte?.Faltante }}</td>
          </tr>
          <tr>
              <td>Total: </td>
              <th class="w-4">&nbsp;</th>
              <td>${{ totales?.efectivoFinal + totales?.faltanteTotal}}</td>
          </tr>
      </tbody>
  </table>

  <button (click)="imprent()" class="oculto-impresion inline-flex items-center w-full h-12 p-2 mt-4 font-semibold uppercase bg-gray-50 rounded border-2 border-gray-300 drop-shadow-lg hover:border-red-600">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
        </svg>

      <span class="ml-2 w-full">Imprimir</span>
  </button>
</div>
