import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Devolucion} from '../models';
import {environment} from 'src/environments/environment';
import PouchDB from 'pouchdb-browser';
import {from, Observable, Subject} from 'rxjs';
import {ActionUserLocaldbService} from './action-user-localdb.service';
import {ACTIONS_USER_DB} from '../utils/enum';

@Injectable({
  providedIn: 'root'
})
export class DevolucionService {
  private db: any;
  private ENDPOINT = environment.ENDPOINTS.DEVOLUCION;
  private FICHAPOINT = environment.ENDPOINTS.FICHADEPOSITO;
  private ENDPOINT_FO = environment.ENDPOINTS.FONDO_PDF;
  private ENDPOINT_AUX = environment.ENDPOINTS.DENOMINACION;

  counterDocs$ = new Subject<number>();
  actualCount = 0;

  constructor(private http: HttpClient, private actionUserDB: ActionUserLocaldbService) {
    this.db = new PouchDB('db_devolucion');
    this.getInfoLocal();
  }

  saveDevolucion(devolucion: Devolucion) {

    return this.http.post<Devolucion[]>(this.ENDPOINT, devolucion);

  }

  fondoByCortes(devolucion : any) {

    return this.http.post<Devolucion[]>(this.ENDPOINT+'/get-fondo-cortes', devolucion);

  }

  savefondoByCortes(devolucion : any) {

    return this.http.post<any>(this.ENDPOINT+'/save-fondo-cortes', devolucion);

  }

  saveTrasladoValores(devolucion : any) {

    return this.http.post<any>(this.ENDPOINT+'/save-traslado-valores', devolucion);

  }

  fileFichaDeposito(monto : any) {
    const httpOptions = {responseType: 'blob' as 'json'};
    return this.http.get<any>(this.FICHAPOINT+`/${monto}`, httpOptions);

  }

  saveCambioEfectivo(cambio: any) {
    return this.http.post<any>(this.ENDPOINT+'/save-cambio-efectivo', cambio);
  }

  // caja de seguridad
  getFondoTrasladoValores(devolucion : any) {
    return this.http.post<any>(this.ENDPOINT+'/get-fondo-traslados/valores', devolucion);
  }

  // traslados
  getTrasladoTransito(devolucion: any) {
    return this.http.post<any>(this.ENDPOINT+'/get-traslados-transito/valores', devolucion);
  }


  getTraslados(id_caseta) {
    return this.http.get<any[]>(this.ENDPOINT+'/get-traslados/'+id_caseta);
  }

  getDevolucionTicket(id_caseta, id_turno) {
    return this.http.get<any[]>(this.ENDPOINT+`/get-devolucion-ticket/${ id_caseta }/${ id_turno }`);
  }

  getDevolucion() {
    return this.http.get<Devolucion[]>(this.ENDPOINT);
  }

  getFondoOperacionPDF(id_caseta: number, id_turno: number, id_jefe_turno: number, id_tipo: number, observacion: string) {
    const httpOptions = {responseType: 'blob' as 'json'};
    return this.http.get<any>(this.ENDPOINT_FO+`/${id_caseta}/${id_turno}/${id_jefe_turno}/${id_tipo}/${observacion}`, httpOptions);
  }

  getDevolucionCaseta(id_caseta: number) {
    return this.http.get<any[]>(`${ this.ENDPOINT }/get-devolucion-caseta/${ id_caseta }`);
  }

  getFondoTurnoSiguiente(id_caseta: number, id_tuno: number) {
    return this.http.get<any>(`${ this.ENDPOINT }/get-fondo-turno-siguiente/${ id_caseta }/${ id_tuno }`);
  }

  saveFaltanteFondo( data: any ) {
    return this.http.post<any>(this.ENDPOINT+'/save-faltante-fondo', data);
  }

  getFaltanteFondoTotal( id_fondo: number ) {
    return this.http.get<any>(`${ this.ENDPOINT }/get-faltante-fondo/${ id_fondo }`);
  }

  getFaltanteFondoByDev( id_fondo: number ) {
    return this.http.get<any[]>(`${ this.ENDPOINT }/get-faltante-fondo-dev/${ id_fondo }`);
  }

  saveDevolucionLocal(dataSchema: Devolucion): Observable<Devolucion> {
    console.log('no hay conexión a red');
    let result: Promise<Devolucion> = new Promise((resolve, reject) => {
      this.db.get(dataSchema._id)
        .then(async (doc: any) => {
          console.log('encontrado y actualizado');
          delete dataSchema._id;
          doc = Object.assign(doc, dataSchema);
          try {
            let result = await this.db.put(doc);
            resolve(result);
          } catch (err) {
            reject(err);
          }

        }).catch(async () => {
        try {
          let result = await this.db.post(dataSchema);
          resolve(result);
          console.log('se creo new register');
          this.actualCount = this.actualCount + 1;
          console.log(this.actualCount);
          this.counterDocs$.next(this.actualCount);

          //Save action
          let resultAction = await this.actionUserDB.saveAction({id_corte_usuario: dataSchema.id_corte_usuario, action: ACTIONS_USER_DB.DEVOLUCION});
          console.log(resultAction);
        } catch (err) {
          reject(err);
        }
      });
    });

    return from(result);
  };

  public deleteDevolucionLocal(id: number) {
    return this.db.get(id)
      .then(doc => {
        return this.db.remove(doc);
      })
      .then(
        _ => {
          this.actualCount = this.actualCount - 1;
          console.log(this.actualCount);
          this.counterDocs$.next(this.actualCount);
          return true;
        }
      )
      .catch(err => {
        console.log(err);
      });
  }

  public getInfoLocal() {
    this.db.info().then(data => {
      this.actualCount = data.doc_count;
      console.log(this.actualCount);
      this.counterDocs$.next(this.actualCount);
    });
  }


  public getAll(): Promise<Devolucion[]> {
    return new Promise((resolve, reject) => {
      this.db.allDocs({
        include_docs: true
      })
        .then(data => {
          let eventsDevolucion = data.rows.map(
            (row: any): Devolucion => {

              return ({
                _id: row.doc._id,
                fecha_hora: row.doc.fecha_hora,
                id_corte_usuario: row.doc.id_corte_usuario,
                id_usuario_retira: row.doc.id_usuario_retira,
                devolucion_cantidad: row.doc.devolucion_cantidad,
                id_fondo: row.doc.id_fondo,
                tipo: row.doc.tipo,
                id_cat_evento: row.doc.id_cat_evento,
              });

            }
          );
          resolve(eventsDevolucion);
        }).catch(err => {
        reject(err);
      });
    });
  }

  getUsuarioDeudoresFaltante( id_caseta: number, id_cat_evento: number ) {
    return this.http.get<any[]>(this.ENDPOINT + `/get-usuarios-deudores-faltante/${id_caseta}/${id_cat_evento}`)
  }

  saveFaltanteGeneral( data: any ) {
    return this.http.post<any>(this.ENDPOINT + '/save-faltante-general', data)
  }

  getUsuariosDeudoresHistorial( id_caseta: number ) {
    return this.http.get<any[]>(this.ENDPOINT + `/get-usuarios-deudores-faltante-historial/${ id_caseta }`);
  }

  getDenominacionAuxiliarMonto() {
    return this.http.get<any[]>(this.ENDPOINT_AUX);
  }

}
