import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AsaltoService {

  private ENDPOINT = environment.ENDPOINTS.DEVOLUCION;

  constructor(
    private http: HttpClient
  ) { }

  saveAsalto(data: any) {
    return this.http.post<any>(`${this.ENDPOINT}/save-asalto`, data);
  }

  pagaAsalto(data: any) {
    return this.http.post<any>(`${this.ENDPOINT}/pago-asalto`, data);
  }

  getAsaltos(id_caseta: number) {
    return this.http.get<any[]>(`${ this.ENDPOINT }/get-asaltos/${ id_caseta }`);
  }

  getAsaltoTotal(id_fondo: number) {
    return this.http.get<any>(`${ this.ENDPOINT }/get-asalto-total/${ id_fondo }`);
  }

}
