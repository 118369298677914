import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FondoDenominacion } from 'src/app/models/dash';
import { AsignarFondoService } from 'src/app/services/asignar-fondo.service';
import { DevolucionService } from 'src/app/services/Devolucion.service';
import { AppState } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/selectors/user.selectors';

@Component({
  selector: 'app-cambio',
  templateUrl: './cambio.component.html',
  styleUrls: ['./cambio.component.scss']
})
export class CambioComponent implements OnInit {

  caseta_id: number = null;
  usuario: any;
  entradaCambio = [];
  salidaCambio = [];
  entradaFinal: number = 0;
  salidaFinal: number = 0;
  entradaSubtotal: number[] = [];
  salidaSubtotal: number[] = [];
  denominaciones: FondoDenominacion[] = [];

  get denominacionFormato() {
    return this.denominaciones.slice(0, ).reverse();
  }

  constructor(
    private router: Router,
    public asignarFondo: AsignarFondoService,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private devolucionService: DevolucionService,
    private ngxUiLoaderService: NgxUiLoaderService,

  ) { }

  ngOnInit(): void {

    // obtener casetas de usuario
    this.store.select(selectUser).subscribe( usuario => this.usuario = usuario);
    // obtener fondo para conjunto de casetas
    this.asignarFondo.getFondoDenominacion( this.usuario.caseta_usuario[0].id ).then( () => {
      this.denominaciones = this.asignarFondo.fondoDenominacion;
    });

  }

  getCaseta(caseta: any) {
    this.caseta_id = Number(caseta.target.value);
  }

  cambioTotal(event: any, denominacion, tipo, input) {

    let cantidad = Number(event.target.value);
    let subTotal = cantidad * (denominacion.cantidad);

    if (tipo === 1) {
      this.entradaFinal = 0;
      this.entradaSubtotal[denominacion.id] = subTotal;
      // total
      for (let total in this.entradaSubtotal){
        if(this.entradaSubtotal[total]){
          this.entradaFinal += Number(this.entradaSubtotal[total]);
        }
      }
    } else {

      this.salidaFinal = 0;
      this.salidaSubtotal[denominacion.id] = subTotal;

      // valida la cantidad de salida
      if ( cantidad > denominacion.pivot.cantidad ) {
        // reiniciar cantidad
        cantidad = 0;
        // reinicio el subtotal
        this.salidaSubtotal[denominacion.id] = 0;
        // reinicio el input
        (document.getElementById(input.id) as HTMLInputElement).value = '0';
        this.toastr.error(`La cantidad ingresa en $${ denominacion.cantidad } es mayor a la disponible`, 'Error', { positionClass: 'toast-bottom-right' } ); 
      }

      // total
      for (let total in this.salidaSubtotal){
        if(this.salidaSubtotal[total]){
          this.salidaFinal += Number(this.salidaSubtotal[total]);
        }
      }

    }

    this.insertCambio( cantidad, denominacion, tipo);
  }

  insertCambio(cantidad: number, denominacion: any, tipo: number){
    if (tipo === 1) {
      let index = this.entradaCambio.findIndex( m => m.id_denominacion === denominacion.id);
      index === -1 ? this.entradaCambio.push({
        id_denominacion: denominacion.id,
        cantidad: cantidad,
      }): this.entradaCambio[index].cantidad = cantidad;
    } else {
      let index = this.salidaCambio.findIndex( m => m.id_denominacion === denominacion.id);
      index === -1 ? this.salidaCambio.push({
        id_denominacion: denominacion.id,
        cantidad: cantidad,
      }): this.salidaCambio[index].cantidad = cantidad;
    }
  }

  saveCambio() {
    // valida caseta
    if ( this.caseta_id === null ) { return this.toastr.error('Por favor, seleccione una caseta', 'Error', { positionClass: 'toast-bottom-right' } ) }
    // valida entrada
    if ( this.entradaCambio.length === 0) { return this.toastr.error('Ingresa $ para la entrada del cambio', 'Error', { positionClass: 'toast-bottom-right' } ) }
    // valida salida
    if ( this.salidaCambio.length === 0) { return this.toastr.error('Ingresa $ para la salida del cambio', 'Error', { positionClass: 'toast-bottom-right' } ) }
    // valida que la entrada y salida coincidan
    if (this.entradaFinal != this.salidaFinal ) { return this.toastr.error('La entrada y salida debe coincidir para realizar el cambio', 'Error', { positionClass: 'toast-bottom-right' } ) }

    // guardar data
    let salida = {
      tipo: 0,
      id_cat_evento: 3,
      id_caseta: this.caseta_id,
      fondo_denominacion: this.salidaCambio,
      id_usuario_retira : this.usuario.id
    }

    let entrada = {
      tipo: 1,
      id_cat_evento: 3,
      id_caseta: this.caseta_id,
      fondo_denominacion: this.entradaCambio,
      id_usuario_retira : this.usuario.id
    }
    this.ngxUiLoaderService.start();
    this.devolucionService.saveCambioEfectivo({entrada, salida})
    .subscribe(devolucion=>{
      this.router.navigate(['dashboard/fondo-operacion']);
      this.ngxUiLoaderService.stop();
      return this.toastr.success('Se ha generado el cambio de efectivo con éxito', '', { positionClass: 'toast-bottom-right' } )
    })
    console.log( 'CAMBIO', {entrada, salida} );

  }


}
