import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY} from 'rxjs';
import {map, mergeMap, catchError, exhaustMap} from 'rxjs/operators';
import {MorrallaService} from '../../services/morralla.service';
import {Morralla} from'../../models/pouch.db';
import {MorrallaActions} from '../actions/Morralla.actions';
import {MorrallaState} from '../../core/models/morralla.state';

@Injectable()
export class MorrallaEffects {

  morralla: Morralla;

  postMorralla$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MorrallaActions.postMorralla),
      exhaustMap((action : MorrallaState) => this.MorrallaService.saveMorrallaLocal(action.morralla)
        .pipe(
          map(Morralla => ({type: MorrallaActions.postedMorralla, Morralla})),
          catchError(() => EMPTY)
        ))
    );
  });

  constructor(
    private actions$: Actions,
    private MorrallaService: MorrallaService
  ) {
  }
}
