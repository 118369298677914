import { Validators } from '@angular/forms';
import * as moment from 'moment';
import {TYPE_NOTIFICATION, TYPE_PERMISO} from './enum';

//Función que regresa el turno con base en la hora del sistema
export const getTurno = ()  => {
  let actual  = moment();

  if(actual.isBetween(customDate(0,0,0), customDate(7,59,59))){
    return { id: 1, name: 'NOCTURNO'}
  }

  if(actual.isBetween(customDate(8,0,0), customDate(15,59,59))){
    return { id: 2, name: 'MATUTINO'}
  }

  if(actual.isBetween(customDate(16,0,0), customDate(23,59,59))){
    return { id: 3, name: 'VESPERTINO'}
  }

  return {id:0 , name: 'Sin agignar'};

}

export const getNextTurno = ()  => {
  let actual  = moment();

  if(actual.isBetween(customDate(0,0,0), customDate(7,59,59))){
    return { id: 2, name: 'MATUTINO'}
  }

  if(actual.isBetween(customDate(8,0,0), customDate(15,59,59))){
    return { id: 3, name: 'VESPERTINO'}
  }

  if(actual.isBetween(customDate(16,0,0), customDate(23,59,59))){
    return { id: 1, name: 'NOCTURNO'}
  }

  return {id:0 , name: 'Sin agignar'};

}

// BUG FIX, buscar si es necesario eliminarlo o comentarlo
export const rangeTurno = ( turno_id: number ) => {
  switch (turno_id) {
    case 1:
      return { hora_inicio: '00:00:00', hora_fin: '07:59:59'};
    case 2:
      return { hora_inicio: '08:00:00', hora_fin: '15:59:59'};
    case 3:
      return { hora_inicio: '16:00:00', hora_fin: '23:59:59'};
    default:
      return null;
  }
}

export const catFormTipo = ( tipo: string, fechaActual: string ) => {

  const base: Record<string, any> = {
    catFecha: [fechaActual, Validators.required],
    catTipo: ['', Validators.required],
    catCaseta: ['', Validators.required],
    catCarril: ['', Validators.required],
    catTurno: ['', Validators.required],
    catObservacion: ['', Validators.required],
  }

  switch (tipo) {
    case 'falta':
      base.catCobrador = ['', Validators.required];
      base.catSuplente = ['', Validators.required];
      base.catMotivo = ['', Validators.required];
      return base;
    case 'queja':
      base.catMotivo = ['', Validators.required];
      return base;
    case 'sobrante':
      base.catCobrador = ['', Validators.required];
      base.catCantidad = ['', Validators.required];
      return base;
    case 'otro':
      return {
        catNombre: ['', Validators.required],
        catDescripcion: ['', Validators.required],
      }
    case 'motivo':
      return {
        catCategoria: ['', Validators.required],
        catNombre: ['', Validators.required],
        catDescripcion: ['', Validators.required],
      }
    case 'general':
      return  {
        catEvento: ['', Validators.required],
        catFecha: [fechaActual, Validators.required],
        catArea: ['', Validators.required],
        catTipo: ['', Validators.required],
        catObservacion: ['', Validators.required],
      }
    default:
      return base;
  }
}

const customDate = (h: number, m: number, s: number) =>{
  let date = moment();
  date.set('hours',h );
  date.set('minutes',m);
  date.set('seconds',s );

  return date;
}

export const foundPermiso = (idPermiso : TYPE_PERMISO, permisos : any []  = [] ) => {
  return permisos.filter(item => item.pivot.id_cat_permiso == idPermiso)
}


export const getLastHistoryNotification = ( type : TYPE_NOTIFICATION ) => {

}

export const longNumber = ( number: number ) => {
      if (number==8) {
        return true;
      }
      return false;

}
