<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 h-fit uppercase text-lg text-gray-500 dark:text-gray-200">
    <div class="m-2">
        <div class="flex flex-col text-xs font-semibold space-y-3">

            <app-formato-header></app-formato-header>

            <hr class="dark:border-gray-500">

            <router-outlet></router-outlet>

        </div>
    </div>
</div>

