<div class="p-2 border rounded border-solid border-gray-300 dark:border-gray-500 text-lg text-gray-500 h-104">

  <div class="flex flex-col text-center uppercase">

    <span class="text-gray-500 dark:text-gray-50 font-bold text-base">Elija cantidad de ejes excedentes</span>
    <hr class="mt-2 mb-4">

    <div class="grid grid-cols-6 grid-flow-row gap-4">

      <div class="col-start-3 col-span-2 grid grid-cols-3 grid-flow-row gap-4" *ngIf="this.cruce.id_clasificacion==3">
        <button #btnClasificacion *ngFor="let btn of tr"
          class="btn py-1 px-2 h-14 bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600"
          (click)="saveEcc(btn.id)">
          <span>{{btn?.id}}</span>
        </button>
      </div>

      <div class="col-start-3 col-span-2 grid grid-cols-3 grid-flow-row gap-4" *ngIf="this.cruce.id_clasificacion==15 || this.cruce.idChildren==5">
        <button #btnClasificacion *ngFor="let btn of eec"
          class="btn py-1 px-2 h-14 bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600"
          (click)="saveEcc(btn.id)">
          <span>{{btn?.id}}</span>
        </button>
      </div>

      <div *ngIf="cruce.id_clasificacion==3">
        <button #btnClasificacion *ngFor="let btn of tiposVehiculo "
          (click)="addClasificacion(btn.id,btn.tipo,btnClasificacion)"
          class="btn btn-image py-1 px-2 w-44 mb-4 bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 dark:border-gray-400 drop-shadow-lg hover:border-blue-600"
          [style.background-image]="'url(/assets/images/'+ btn.icon +'' + ')'">
          <span>{{btn?.tipo}}</span>
        </button>
      </div>

      <button (click)="returnPage()"
        class="btn w-60 col-start-3 h-14 py-1 px-2 bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 float-right">Regresar</button>

    </div>

  </div>


</div>

<!-- <div class="grid grid-cols-6 grid-flow-row gap-4 mt-20"> -->

<!-- <div class="col-start-3 col-span-2 grid grid-cols-3 grid-flow-row gap-4">
      <button #btnClasificacion *ngFor="let btn of eec"
      class="btn btn-image py-1 px-2 bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600" (click)="saveEcc(btn.id)">
        <span>{{btn?.id}}</span>
      </button>
    </div> -->

<!-- <button #btnClasificacion *ngFor="let btn of eec"
            class="btn btn-image w-20 py-1 px-2 bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600" (click)="saveEcc(btn.id)">
      <span>{{btn?.id}}</span>
    </button> -->

<!-- <div class="col-start-3 mt-4">
      <button (click)="returnPage()" class="btn w-44 py-1 px-2 bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 float-right">Regresar</button>
    </div> -->

<!-- </div> -->

<!-- </div> -->
