import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConfiguracionService } from 'src/app/services/configuracion.service';
import { AppState } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/selectors/user.selectors';

@Component({
  selector: 'app-cuadrar-fondo',
  templateUrl: './cuadrar-fondo.component.html',
  styleUrls: ['./cuadrar-fondo.component.scss']
})
export class CuadrarFondoComponent implements OnInit {
  user: any;
  fondo: any;
  morrallas = [];
  usuario: any;
  devolucionIds: any;
  caseta_id: number = null;
  subtotales: number[] = [];
  morrallaFinal: number = 0;
  constructor(
    private store: Store<AppState>,
    private configuracionService: ConfiguracionService) { }

  ngOnInit(): void {

    this.store.select(selectUser).subscribe(async (user) => {
      this.user = user;
    });
    this.getFondo()

  }

  getFondo() {
    this.configuracionService.getFondoCaseta(this.user)
      .subscribe(fondo => {
        this.fondo = fondo;
        for (let total of this.fondo) {
          this.subtotales[total.id] = total.cantidad * total.denominacion;
          this.morrallaFinal += total.cantidad * total.denominacion
        }
        console.log(fondo);

      })
  }

  morrallaTotal(event: any, denominacion) {
    this.morrallaFinal = 0;
    let cantidad = Number(event.target.value);
    let subTotal = cantidad * (denominacion.denominacion);
    this.subtotales[denominacion.id] = subTotal;

    // total
    for (let total in this.subtotales) {
      if (this.subtotales[total]) {
        this.morrallaFinal += Number(this.subtotales[total]);

      }
    }
    console.log(this.subtotales);

    this.insertMorralla(cantidad, denominacion)
  }

  insertMorralla(cantidad: number, denominacion: any) {
    let index = this.morrallas.findIndex(m => m.id_denominacion === denominacion.id_denominacion);
    index === -1 ? this.morrallas.push({
      id: denominacion.id,
      id_denominacion: denominacion.id_denominacion,
      id_fondo: denominacion.id_fondo,
      cantidad: cantidad

    }) : this.morrallas[index].cantidad = cantidad;

    console.log(this.morrallas);
  }

  guardar() {
    if (this.morrallas.length == 0) {
      return console.log('ingrese las cantidades por denomminación del fondo');

    }
    let data = {
      caseta: this.user.caseta_usuario[0]['id'],
      fondo: this.morrallas
    }
    console.log(data);
    this.configuracionService.updateFondo(data)
      .subscribe(fondo => {
        this.getFondo()
        console.log('Fondo actualizado');

      })
  }




}
