<!-- <p class="title">Retiro</p>
<button *ngFor="let denominacion of btnsRetiro$ | async" class="btn" (click)="postRetiro(denominacion.id)">${{denominacion.cantidad}}</button> -->

<div class="p-2 border rounded border-solid border-gray-300 dark:border-gray-500 h-120 uppercase text-lg text-gray-500">
    <div class="flex flex-col text-center">

        <p class="title text-gray-500 dark:text-gray-50 font-bold text-base">Retiro</p>
        <hr class="mt-2 mb-4">

        <div class="grid grid-cols-3">
            <div class="col-start-2 w-96">
                <div class="grid grid-cols-4 grid-flow-row gap-4">
                    <button *ngFor="let denominacion of btnsRetiro$ | async" class="btn p-3 bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 dark:border-gray-400 drop-shadow-lg hover:border-blue-600 dark:hover:border-blue-600" (click)="showFirma(denominacion.id)">${{denominacion.cantidad}}</button>
                </div>
            </div>

            <div class="col-start-2 w-96 mt-4">
                <button (click)="cancelAction()" class="btn w-full p-3 bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600">Cancelar</button>
            </div>
        </div>

    </div>
</div>
