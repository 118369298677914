import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { Caseta } from '../models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CasetaService {
  private ENDPOINT = environment.ENDPOINTS.CASETA;
  constructor(private http:HttpClient) { }

  getCasetas(){
    return this.http.get<Caseta[]>(this.ENDPOINT);
  }
  getCaseta(id: number){
    return this.http.get<Caseta>(this.ENDPOINT + `/${id}`);
  }
  
  getAuxiliares(id: number){
    return this.http.get<any[]>(`${this.ENDPOINT}/get/auxiliares/${id}`);
  }

}
