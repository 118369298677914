import {Caseta} from '../../models';
import {createReducer, on} from '@ngrx/store';
import {loadTipoVehiculo, loadedTipoVehiculo} from '../actions/tipoVehiculo.actions';
import {TipoVehiculoState} from '../../core/models/tipoVehiculo.state';


export const initialState:TipoVehiculoState = {loading: false, tipoVehiculo: null};


export const tipoVehiculoReducer = createReducer(
  initialState,
  on(loadTipoVehiculo, (state) => {
    return {...state, loading: true};
  }),
  on(loadedTipoVehiculo, (state,{tipoVehiculo}) => {
    return {...state, loading: false, tipoVehiculo};
  })
);
