import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Cruce } from '../models';
import { environment } from 'src/environments/environment';
import PouchDB from 'pouchdb-browser';
import {  from, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CruceService {
  private db: any;
  counterDocs$ = new Subject<number>();
  actualCount = 0;

  private ENDPOINT = environment.ENDPOINTS.EVENTO;
  constructor(private http: HttpClient) {
    this.db = new PouchDB('db_cruce');
    this.getInfoLocal();
  }

  saveCruce(cruce) {
    return this.http.post<Cruce[]>(this.ENDPOINT, cruce);
  }

  getCruce() {
    return this.http.get<Cruce[]>(this.ENDPOINT);
  }

  saveCruceLocal  (dataSchema: Cruce) :  Observable<Cruce>  {
    console.log('no hay conexión a red');
    let result : Promise<Cruce> = new Promise((resolve, reject) => {
      this.db.get(dataSchema._id)
        .then(async (doc: any) => {
          console.log('encontrado y actualizado')
          delete dataSchema._id;
          doc = Object.assign(doc, dataSchema);
          try{
            let result =  await this.db.put(doc);
            resolve(result);
          }catch(err){
            reject(err)
          }

        }).catch(async () => {
          try{
            let result = await this.db.post(dataSchema)
            resolve(result);
            console.log('se creo new register')
            this.actualCount = this.actualCount + 1;
            console.log(this.actualCount)
            this.counterDocs$.next(this.actualCount)
          }catch(err){
            reject(err);
          }

        });
    })

    return from(result);
  }


  public deleteCruceLocal(id: string) {
    return this.db.get(id)
      .then(doc => {
        return this.db.remove(doc);
      })
      .then(
        _ => {
          this.actualCount = this.actualCount - 1;
          console.log(this.actualCount)
          this.counterDocs$.next(this.actualCount)
          return true;
        }
      )
      .catch(err => {
        console.log(err)
      })
  }
  public getInfoLocal() {
    this.db.info().then(data => {
      this.actualCount = data.doc_count;
      console.log(this.actualCount)
      this.counterDocs$.next(this.actualCount);
    });
  }


  public getAll(): Promise<Cruce[]> {
    return new Promise((resolve, reject) => {
      this.db.allDocs({
        include_docs: true
      })
        .then(data => {
          let eventsCruce = data.rows.map(
            (row: any): Cruce => {

              return ({
                _id: row.doc._id,
                aventoDinero: row.doc.aventoDinero,
                id_cat_observacion: row.doc.id_cat_observacion,
                id_subtipo: row.doc.id_subtipo,
                fecha_hora: row.doc.fecha_hora,
                id_corte_usuario: row.doc.id_corte_usuario,
                id_clasificacion: row.doc.id_clasificacion,
                cantidad_ejes: row.doc.cantidad_ejes

              });

            }
          )
          resolve(eventsCruce);
        }).catch(err => {
          reject(err)
        })
    })
  }
}
