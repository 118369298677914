import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY} from 'rxjs';
import {map, mergeMap, catchError, exhaustMap} from 'rxjs/operators';
import {RetiroService} from '../../services/retiro.service';
import {Retiro} from'../../models/pouch.db';
import {ActionsRetiro} from '../actions/retiro.actions';
import {RetiroState} from '../../core/models/retiro.state';

@Injectable()
export class RetiroEffects {

  retiro: Retiro;

  postRetiro$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActionsRetiro.POST_RETIRO),
      exhaustMap((action : RetiroState) => this.retiroService.saveRetiroLocal(action.retiro)
        .pipe(
          map(retiro => ({type: ActionsRetiro.POSTED_RETIRO, retiro})),
          catchError(() => EMPTY)
        ))
    );
  });

  constructor(
    private actions$: Actions,
    private retiroService: RetiroService
  ) {
  }
}
