import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {Caseta, Devolucion, Retiro, Rollo} from '../../models';
import {Observable} from 'rxjs';
import {CasetaService} from '../../services/caseta.service';
import {MorrallaService} from '../../services/morralla.service';
import {Store} from '@ngrx/store';
import {selectLoading} from '../../state/selectors/caseta.selectors';
import {loadCaseta} from '../../state/actions/caseta.actions';
import {Morralla, BtnRapido, Cruce} from '../../models';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {BtnRapidoService} from 'src/app/services/btn-rapido.service';
import {CruceService} from 'src/app/services/cruce.service';
import {selectUser} from 'src/app/state/selectors/user.selectors';
import {DevolucionService} from 'src/app/services/Devolucion.service';
import {RetiroService} from 'src/app/services/retiro.service';
import {RolloService} from 'src/app/services/rollo.service';
import {WebsocketService} from '../../services/websocket.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-mopa-layout',
  templateUrl: './mopa-layout.component.html',
  styleUrls: ['./mopa-layout.component.scss']
})
export class MopaLayoutComponent implements OnInit, OnDestroy {
  polling: any;
  SECONDS_TO_SYNC = 10;

  title = 'mopa';
  caseta: Caseta;
  user: any = null;
  loading$: Observable<boolean> = new Observable<boolean>();
  notificationsWS$ : EventEmitter<any>;

  constructor(
    private casetaService: CasetaService,
    private morallaService: MorrallaService,
    private btnRapidoService: BtnRapidoService,
    private cruceService: CruceService,
    private devolucionService: DevolucionService,
    private retiroService: RetiroService,
    private rolloService: RolloService,
    private store: Store<any>,
    private ngxService: NgxUiLoaderService,
    private socketService: WebsocketService,
    private toastrService: ToastrService
  ) {
    //this.notificationsWS$ = this.socketService.callback;

    /*
    this.notificationsWS$.subscribe(data => {
      this.toastrService.info(data.message)
    });
     */

  }

  ngOnInit() {
    console.log("Entry mopa layout");
    this.loading$ = this.store.select(selectLoading);
    this.loading$.subscribe(loading => {
      console.log(loading);
      loading ? this.ngxService.start() : this.ngxService.stop();

    });
    this.store.select(selectUser).subscribe(user => this.user = user);
    this.store.dispatch(loadCaseta());
    this.syncDataMorralla();
    this.pollData();

  }

  pollData() {
    this.polling = setInterval(() => {
      this.syncDataMorralla();
      this.syncDatabtnRapido();
      this.syncDataCruce();
      this.syncDataDevolucion();
      this.syncDataRetiro();
      this.syncDataRollo();
    }, this.SECONDS_TO_SYNC * 1000);
  }

  ngOnDestroy() {
    clearInterval(this.polling);

    //clear notifications
    //this.notificationsWS$.unsubscribe();
  }

  async syncDataMorralla() {
    const docs: Morralla[] = await this.morallaService.getAll();
    console.log(docs);

    //Sync data morralla
    for (const doc of docs) {
      this.morallaService.saveMoralla(doc).then(async (data) => {
        let eliminado = await this.morallaService.deleteMorrallaLocal(doc._id);
      }, err => {
        console.log('Error al guardar online morralla');
      });
    }

  }

  async syncDatabtnRapido() {
    const docs: BtnRapido[] = await this.btnRapidoService.getAll();
    console.log(docs);

    //Sync data btnRapido
    for (const doc of docs) {
      this.btnRapidoService.saveBtnRapido(doc).subscribe(async (data) => {
        let eliminado = await this.btnRapidoService.deleteBtnRapidoLocal(doc._id);
      }, err => {
        console.log('Error al guardar online btnRapido');
        console.log(err);

      });
    }

  }

  async syncDataCruce() {
    const docs: Cruce[] = await this.cruceService.getAll();
    console.log(docs);

    //Sync data Cruce
    for (const doc of docs) {
      this.cruceService.saveCruce(doc).subscribe(async (data) => {
        let eliminado = await this.cruceService.deleteCruceLocal(doc._id);
      }, err => {
        console.log('Error al guardar online Cruce');
        console.log(err);

      });
    }

  }

  async syncDataDevolucion() {
    const docs: Devolucion[] = await this.devolucionService.getAll();
    console.log(docs);

    //Sync data Devolucion
    for (const doc of docs) {
      this.devolucionService.saveDevolucion(doc).subscribe(async (data) => {
        let eliminado = await this.devolucionService.deleteDevolucionLocal(doc._id);
      }, err => {
        console.log('Error al guardar online Devolucion');
        console.log(err);

      });
    }

  }

  async syncDataRetiro() {
    const docs: Retiro[] = await this.retiroService.getAll();
    console.log(docs);

    //Sync data retiro
    for (const doc of docs) {
      this.retiroService.saveRetiro(doc).subscribe(async (data) => {
        let eliminado = await this.retiroService.deleteRetiroLocal(doc._id);
      }, err => {
        console.log('Error al guardar online Retiro');
        console.log(err);

      });
    }

  }

  async syncDataRollo() {
    const docs: Rollo[] = await this.rolloService.getAll();
    console.log(docs);

    //Sync data rollo
    for (const doc of docs) {
      this.rolloService.saveRollo(doc).subscribe(async (data) => {
        let eliminado = await this.rolloService.deleteRolloLocal(doc._id);
      }, err => {
        console.log('Error al guardar online Rollo');
        console.log(err);

      });
    }

  }

}
