import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppState} from '../../state/app.state';
import {Store} from '@ngrx/store';
import {selectUser} from '../../state/selectors/user.selectors';
import {RolloService} from '../../services/rollo.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import { CasetaService } from '../../services/caseta.service';
import { longNumber } from '../../../app/utils/index';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-validacion-folio',
  templateUrl: './validacion-folio.component.html',
  styleUrls: ['./validacion-folio.component.scss']
})
export class ValidacionFolioComponent implements OnInit {

  user: any = {};
  folio: string ;
  tipoCaseta: number = null;
  rollo: any = [];
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private rollaService: RolloService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private casetaService: CasetaService,
    private toastr: ToastrService,


  ) {
  }

  ngOnInit(): void {
    this.ngxUiLoaderService.start();

    //Get último Folio
    this.rollaService.getUltimoFolio().subscribe((rollo: any) => {
      console.log(rollo);
      this.rollo = rollo
      this.folio = rollo.after_final ?? '1';
      console.log(this.folio);
      this.ngxUiLoaderService.stop();
      this.validateFolioCorte()
    }, err=>{
      console.log(err);
      this.ngxUiLoaderService.stop();
    });
      //validar si ya tiene guardo folio en el corte



    this.store.select(selectUser).subscribe(user => {
      this.user = user;

      // obtener caseta para saber su tipo
      this.casetaService.getCaseta( this.user.id_caseta ).subscribe(caseta => {
        this.tipoCaseta = caseta.tipo;
      });
    });

  }

  async confirm(value: string) {
    this.ngxUiLoaderService.start();
    //Evento para guardar el folio
    if(!value){
      return;
    }

    if (!longNumber(value.length)) {
      this.ngxUiLoaderService.stop();
      return this.toastr.error('El folio debe tener 8 digitos', 'Revisa el Folio', { positionClass: 'toast-bottom-right' });

    }
      //Se registra el nuevo rollo
      let rollo = {
        folio_inicial : value,
        id_corte_usuario : this.user.id_corte_usuario,
        rollo: 1
      }
      try{
        let res = await this.rollaService.saveRollo(rollo).toPromise();
        //Redirect to confirmar fondo or home
        switch (this.tipoCaseta) {
          case 0:
            this.router.navigate(['/confirmar-fondo']);
            break;
          case 1:
            this.router.navigate(['/mopa']);
            console.log(res);
          default:
            break;
        }
      }catch(err){
        console.log(err);
      }finally {
        this.ngxUiLoaderService.stop();
      }


  }

  validateFolioCorte(){
    if(this.rollo.folio_final==null && this.rollo.folio_inicial!=0 && this.rollo.folio_inicial && this.rollo.id_corte_usuario==this.user.id_corte_usuario){
      this.router.navigate(['/mopa']);
    }else{
      this.router.navigate(['/validacion-folio']);

    }
  }

}
