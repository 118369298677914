<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase font-semibold text-base text-center text-gray-500 dark:text-gray-200">
  <div class="flex flex-col">

      <div class="flex justify-between items-center">
          <span class="flex-1 text-gray-500 dark:text-gray-50 py-2 font-bold">Fondo en caseta</span>
          <span class="flex-1"></span>
      </div>

      <hr class="dark:border-gray-500 mb-4">

      <div class="flex justify-between">



          <div class="flex flex-1 flex-col">

              <div class="flex w-full mb-4">
                  <span class="flex-1">Denominacion</span>
                  <span class="flex-1">Cantidad</span>
                  <span class="flex-1">Total</span>
              </div>

              <div *ngFor="let fon of fondo" class="flex space-x-2 mb-2">
                  <input class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 font-bold text-base dark:border-gray-600 dark:bg-slate-800 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" disabled value="{{ fon.denominacion | number }}" type="text">

                  <input (change)="morrallaTotal($event, fon)" class="flex-1 w-6 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" type="number" min="0" value="{{ fon.cantidad | number }}">

                  <input [value]="subtotales[fon.id]  | number" class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-slate-800 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" disabled type="text">

                </div>
                <input [value]="morrallaFinal  | number" class="flex-1 w-2 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-slate-800 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" disabled type="text">
              <button (click)="guardar()" type="button" class="bg-gray-50 inline-flex items-center dark:bg-transparent rounded my-1 p-2 text-xs text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 hover:border-blue-600 uppercase">
                  <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
                  </svg> -->
                  <span class="ml-2">Guardar</span>
              </button>
          </div>

          <div class="flex-1 text-center font-bold text-xs">
              <div class="px-4 overflow-y-auto">

              </div>
          </div>

      </div>

  </div>
</div>
