import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../models/dash';

@Injectable({
  providedIn: 'root'
})
export class TipoUsuarioService {
  private EDNPOINT = environment.ENDPOINTS.TIPOUSUARIO;

  constructor(
    private http: HttpClient
  ) { }

  getTypeUser( id_rol ) {
    return this.http.get<User[]>(this.EDNPOINT+`/${ id_rol }`);
  }
  getTepeUsesAll(  ) {
    return this.http.get<User[]>(this.EDNPOINT);
  }
}
