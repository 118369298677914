import {BtnRapido} from '../../models';
import {createReducer, on} from '@ngrx/store';
import {BtnRapidoState} from '../../core/models/btnRapido.state';
import {addBtnRapidoClasificacion, addBtnRapidoSubType, clearBtnRapido, postBtnRapido, postedBtnRapido, addBtnRapidoObservacion} from '../actions/btnRapido.actions';


export const initialState:BtnRapidoState = { loading: false, btnRapido: null, contBtnRapido: 0};


export const btnRapidoReducer = createReducer(
  initialState,
  on(addBtnRapidoSubType, (state,{id_subtipo, nombreSubtipo}) => {
    return {...state, id_subtipo, nombreSubtipo};
  }),
  on(addBtnRapidoClasificacion, (state,{clasificacion, id_clasificacion}) => {
    return {...state, clasificacion, id_clasificacion};
  }),
  on(addBtnRapidoObservacion, (state,{ id_cat_observacion}) => {
    return {...state,  id_cat_observacion};
  }),
  on(clearBtnRapido, (state,) => {
    return {...initialState};
  }),
  on(postBtnRapido, (state,{btnRapido}) => {
    return {...state, loading: true, contBtnRapido :0,btnRapido};
  }),
  on(postedBtnRapido, (state,{BtnRapido}) => {
    return {...state, loading: false,BtnRapido, contBtnRapido:0};
  })


);
