import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FondoDenominacion, Turno } from 'src/app/models/dash';
import { AsignarFondoService } from 'src/app/services/asignar-fondo.service';
import { CorteService } from 'src/app/services/corte.service';
import { DevolucionService } from 'src/app/services/Devolucion.service';
import { EventoService } from 'src/app/services/evento.service';
import { FaltanteService } from 'src/app/services/faltante.service';
import { UserService } from 'src/app/services/user.service';
import { AppState } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { getTurno } from 'src/app/utils';
import * as moment from 'moment';
import { PromiseType } from 'protractor/built/plugins';

@Component({
  selector: 'app-faltante-pago',
  templateUrl: './faltante-pago.component.html',
  styleUrls: ['./faltante-pago.component.scss']
})
export class FaltantePagoComponent implements OnInit {

  denominaciones: FondoDenominacion[] = [];
  usuario: any;
  id_fondo: number;
  id_caseta: number;
  tipoMov: number = null;
  id_cat_evento: number;
  personal: any = [];
  cat_faltante = [];
  turno: Turno;
  faltanteFinal: number = 0;
  subtotales: number[] = [];
  faltantesBD = [];
  asignarPersonal = {};
  pagaFaltante: number = null;

  faltante_historial = [];

  tipoFaltanteForm = new FormGroup({
    tipoFaltante: new FormControl('', Validators.required)
  });

  get denominacionFormato() {
    return this.denominaciones.slice(0, ).reverse();
  }

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private asignarFondoService: AsignarFondoService,
    private store: Store<AppState>,
    private config: NgSelectConfig,
    private devolucionService: DevolucionService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private corteService: CorteService,
    private userService: UserService,
    private eventoService: EventoService,
    private faltanteService: FaltanteService,
  ) {
      this.config.notFoundText = 'Sin personal';
  }

  ngOnInit(): void {
    this.turno = getTurno();
    this.getUser();
    this.getFondo();

  }

  getUser() {
    this.store.select(selectUser).subscribe( usuario => {
      this.usuario = usuario;
      this.id_fondo = this.usuario.caseta_usuario[0].id_fondo;
    });
  }

  getFondo() {
    this.asignarFondoService.getFondos().subscribe( fondos => {
      let fondo = fondos.find((fondo: any) => fondo.id === this.id_fondo);
      this.denominaciones = fondo['fondoDenominacion'];
    });
  }

  getCaseta( caseta: any ) {
    this.id_caseta = Number(caseta.target.value);

    this.devolucionService.getUsuariosDeudoresHistorial( this.id_caseta ).subscribe( historial => {
      this.faltante_historial = historial;
    });

  }

  getMovimiento() {
    if ( !this.id_caseta ) {
      this.tipoFaltanteForm.reset();
      return this.toastr.error('Por favor, seleccione una caseta', 'Error', { positionClass: 'toast-bottom-right' } );
    }

    if (this.cat_faltante.length) {
      (document.getElementById("tipoFaltanteCat") as HTMLSelectElement).selectedIndex = 0;
    }

    this.personal = [];
    this.cat_faltante = [];
    this.asignarPersonal = {};
    this.tipoFaltanteForm.value.tipoFaltante === 'genera' ? this.tipoMov = 0: this.tipoMov = 1;
    
    // cat por tipoMov
    this.eventoService.getCatEvento().subscribe( catFaltante => {
      if ( !this.tipoMov ) {
        this.eventoService.getCatEvento().subscribe( catFaltante => {
          this.cat_faltante = catFaltante.filter(item => item.id != 5);
        });
      }

      if ( this.tipoMov === 1 ) {
        this.eventoService.getCatEvento().subscribe( catFaltante => {
          this.cat_faltante = catFaltante;
        });
      }

    });

  }

  getTipoFaltante(event: any) {
    this.personal = [];
    this.id_cat_evento = Number(event.target.value);

    // saliente 
    if ( this.tipoMov === 0) {
      switch (this.id_cat_evento) {
        case 5:
          this.personal = [];
          this.asignarPersonal = {};
          break;
        case 9:
          this.personal = [];
          this.asignarPersonal = {};
          this.corteService.getUsersCortes(this.id_caseta, this.turno.id).subscribe( personal => {
            this.personal = personal;
          });
          break;
        case 10:
          this.personal = [];
          this.asignarPersonal = {};
          this.userService.getUsersFaltanteTraslado( this.id_caseta ).subscribe( personal => {
            this.personal = personal;
          });
          break;
        default:
          break;
      }  
      console.log(this.personal);
      console.log(this.id_cat_evento);    
    }

    // para pagar
    if ( this.tipoMov === 1 ) {
      switch (this.id_cat_evento) {
        case 5:
          this.personal = [];
          this.asignarPersonal = {};
          this.faltanteService.getUsuariosFaltante( this.id_caseta ).subscribe( personal => {
            this.personal = personal.filter(item => item.id_cat_evento === 5);
          });
          break;
        case 9:
          this.personal = [];
          this.asignarPersonal = {};
          this.faltanteService.getUsuariosFaltante( this.id_caseta ).subscribe( personal => {
            this.personal = personal.filter(item => item.id_cat_evento === 9);
          });
          break;
        case 10:
          this.personal = [];
          this.asignarPersonal = {};
          this.devolucionService.getUsuarioDeudoresFaltante( this.id_caseta, this.id_cat_evento ).subscribe( personal => {
            this.personal = personal;
          });
          break;
        default:
          break;
      }
    }

    // // reinicio total, array e inputs
    // this.faltanteFinal = 0;
    // this.faltantes = [];
    // let start: number = 20;
    // for (let i = 0; i < this.denominacionFormato.length; i++) {
    //   (document.getElementById(`f${ (start+ i) }`) as HTMLInputElement).value = '0';
    //   this.subtotales[(start + i)] = 0;
    // }

  }

  getCobrador(event: any) {
    console.log( event );
    event ? this.asignarPersonal = event : this.asignarPersonal = {};
    console.log( this.asignarPersonal );
    this.tipoMov === 1 && event ? this.pagaFaltante = event.faltante : this.pagaFaltante = null;
  }

  faltanteTotal(event: any, denominacion, input: any) {
    this.faltanteFinal = 0;
    let cantidad = Number(event.target.value);
    let subTotal = cantidad * (denominacion.cantidad);
    this.subtotales[denominacion.id] = subTotal;

    // validacion para no sacar mas del fondo
    if (this.tipoMov === 0 && cantidad > denominacion.pivot.cantidad) {
      cantidad = 0;
      this.subtotales[denominacion.id] = 0;
      (document.getElementById(input.id) as HTMLInputElement).value = '0';
      this.toastr.error(`La cantidad ingresada en $${ denominacion.cantidad } es mayor a la disponible`, 'Error', { positionClass: 'toast-bottom-right' } );
    }

    // total
    for (let total in this.subtotales){
      if(this.subtotales[total]){
        this.faltanteFinal += Number(this.subtotales[total]);
      }
    }
    
    this.insertFaltante( cantidad, denominacion );
  }

  insertFaltante(cantidad: number, denominacion: any){
    let index = this.faltantesBD.findIndex( m => m.id_denominacion === denominacion.id);
    index === -1 ? this.faltantesBD.push({
      id_denominacion: denominacion.id, 
      cantidad: cantidad, 
      denominacion: denominacion.cantidad
    }): this.faltantesBD[index].cantidad = cantidad;

    console.log( this.faltantesBD );
  }

  async saveFaltante() {

    if ( !this.id_caseta ) { return this.toastr.error('Por favor, seleccione una caseta', 'Error', { positionClass: 'toast-bottom-right' } ) }
    if ( this.tipoMov === null ) { return this.toastr.error('Elija generar o pagar faltante', 'Error', { positionClass: 'toast-bottom-right' } ) }
    if ( !this.id_cat_evento ) { return this.toastr.error('Seleccion el tipo de faltante', 'Error', { positionClass: 'toast-bottom-right' } ) }
    if( Object.keys( this.asignarPersonal ).length === 0 ) { return this.toastr.error('Seleccione personal por favor', 'Error', { positionClass: 'toast-bottom-right' } ) }
    if ( !this.faltantesBD.length || this.faltanteFinal === 0) { return this.toastr.error('Ingresa una cantidad $ para faltantes', 'Error', { positionClass: 'toast-bottom-right' } ) }

    if ( this.tipoMov === 1 && (this.pagaFaltante != this.faltanteFinal) ) {
      return this.toastr.error('La cantidades deben coincidir para pagar el faltante', 'Error', { positionClass: 'toast-bottom-right' } )
    }

    let data: any = {
      tipo: this.tipoMov,
      id_cat_evento: this.id_cat_evento,
      id_caseta: this.id_caseta,
      fondo_denominacion: this.faltantesBD,
      cobrador: this.asignarPersonal
    }

    console.log( data );

    this.ngxUiLoaderService.start();

    if ( this.id_cat_evento === 5 || this.id_cat_evento === 9 ) {
      // genera y paga faltante 5 9
      this.faltanteService.saveFaltante( data ).subscribe( faltanteProcesado => {
        console.log( faltanteProcesado );

        if ( this.tipoMov === 0 ) {
          //DESCARGA DE FORMATO PDF 
        this.downloadFaltante(4) //FONDO POR ANALISTA
        .then(download => {
            if (download) {
              this.toastr.success(`Se ha generado el faltante`, 'Correcto', {positionClass: 'toast-bottom-right'});
              this.router.navigate(['/dashboard/fondo-operacion']);
              this.ngxUiLoaderService.stop();
            }else{
              this.toastr.error('Algo salio mál, favor de volver a intenter la descarga del PDF', '', { positionClass: 'toast-bottom-right' } )
            }
        }) 
        } else {
          this.toastr.success(`Se ha pagado el faltante`, 'Correcto', {positionClass: 'toast-bottom-right'});
          this.router.navigate(['/dashboard/fondo-operacion']);
          this.ngxUiLoaderService.stop();
        }
      });
    }

    if ( this.id_cat_evento === 10 ) {
      // genera y paga faltante 10
      this.devolucionService.saveFaltanteGeneral( data ).subscribe( faltanteGeneral => {
        console.log( faltanteGeneral );
        if ( this.tipoMov === 0 ) {
            //DESCARGA DE FORMATO PDF
            this.downloadFaltante(3)//TRANSLADO
            .then(download => {
              if (download) {
                this.toastr.success(`Se ha generado el faltante`, 'Correcto', {positionClass: 'toast-bottom-right'});
                this.router.navigate(['/dashboard/fondo-operacion']);
                this.ngxUiLoaderService.stop();
              }
          });
        } else {
          this.toastr.success(`Se ha pagado el faltante`, 'Correcto', {positionClass: 'toast-bottom-right'});
          this.router.navigate(['/dashboard/fondo-operacion']);
          this.ngxUiLoaderService.stop();
        }
      });
    }

    console.log( 'GUARDANDO' );

  }


  //DESCARGA DE PDF
  downloadFaltante(concepto){

    if (concepto===4) { // analista
      this.asignarPersonal = {
        id: this.asignarPersonal['id_usuario']
      }
    }

    return new Promise( (resolve,reject) => {
      let data = {
        'fecha': moment().format(),
        'caseta': this.id_caseta,
        'tipo': 1, //FALTANTE
        'diferencia': Math.abs(this.faltanteFinal),
        'concepto': concepto, //FALTANTE ANALISTA/TRANSLADO,
        'acepta': this.asignarPersonal,
        'jt': this.usuario.id
      }
      console.log(data);
      this.ngxUiLoaderService.start();
      //CONSUMO DE API
      let descarga:boolean;
      this.corteService.getPDFPreliquidacion(data)
        .then(data => {
          descarga = this.downloadFile(data,concepto);
          resolve(descarga);
        })
        .catch(err =>{
          reject(err);
          return this.toastr.error('Algo salio mál, favor de volver a intenter la descarga del PDF', '', { positionClass: 'toast-bottom-right' } )  
        });
      
    });
  }

 downloadFile(data,tipo){
    const tipoPDF = tipo === 4 ? 'ValeAnalista' : 'ValeTraslado';
    let downloadURL = window.URL.createObjectURL(data);
    let link = document.createElement('a');
    link.href = downloadURL;
    link.download = `${tipoPDF}.pdf`;
    link.click();
    return true;
  }



}
