<div class="flex items-center min-h-screen p-6 bg-gray-100 dark:bg-gray-900">
  <div class="flex-1 h-full max-w-xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
    <div class="flex flex-col overflow-y-auto md:flex-row">
      <!-- <div class="h-32 md:h-auto md:w-1/2">
        <img
          aria-hidden="true"
          class="object-cover w-full h-full dark:hidden"
          src="/assets/images/login-light.svg"
          alt="MOPA"
        />
        <img
          aria-hidden="true"
          class="hidden object-cover w-full h-full dark:block"
          src="/assets/images/login-dark.svg"
          alt="MOPA"
        />
      </div> -->
      <div class="flex items-center justify-center p-6 sm:p-12 md:w-full">
        <div class="w-full">

          <div class="grid grid-cols-2">

            <div class="grid grid-rows-2 text-xs">
              <span class="text-xs uppercase font-medium text-gray-400 hover:text-gray-600">Nombre: {{user?.nom_user}} </span>
              <span class="text-xs capitalize font-medium text-sky-500 hover:text-sky-700">Turno: {{user?.turno}} </span>
            </div>

            <div class="flex justify-end">

              <app-switch-theme></app-switch-theme>

            </div>

          </div>

          <hr class="mt-4 mb-8 border-gray-200 dark:border-gray-700" />

          <img
            aria-hidden="true"
            class="mx-auto h-12 w-auto dark:hidden"
            src="/assets/images/sogak-logo-light.svg"
            alt="SOGAK"
          />
          <img
            aria-hidden="true"
            class="hidden mx-auto h-12 w-auto dark:block"
            src="/assets/images/sogak-logo-dark.svg"
            alt="SOGAK"
          />

          <h1 class="mt-6 mb-6 text-xl text-center font-semibold text-gray-700 dark:text-gray-200">Folio saliente</h1>


          <app-teclado-numerico placeholder="Folio" [value]="folio" (confirmValue)="confirm($event)"></app-teclado-numerico>

        </div>
      </div>
    </div>
  </div>
</div>
