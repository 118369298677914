import { createSelector } from '@ngrx/store';
import {AppState} from '../app.state';
import {CruceState} from '../../core/models/cruce.state';

export const selectCruceFeature = (state: AppState) => state.cruce;

export const selectCruce = createSelector(
  selectCruceFeature,
  (state: CruceState) => state
);
export const selectSubTypeCruce = createSelector(
  selectCruceFeature,
  (state: CruceState) => state.cruce
);


