import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Formacion } from '../models/dash';

@Injectable({
  providedIn: 'root'
})
export class FormacionService {

  private ENDPOINT = environment.ENDPOINTS.FORMACION;

  constructor(
    private http: HttpClient
  ) { }

  getFormaciones() {
    return this.http.get<Formacion[]>(this.ENDPOINT);
  }

  saveFormacion(form){
    return this.http.post<Formacion[]>(this.ENDPOINT , form);
  }

}
