import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { timeStamp } from 'console';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { Carril, Caseta, Fondo } from 'src/app/models';
import { FondoDenominacion, Formacion, Turno, User } from 'src/app/models/dash';
import { AsignarFondoService } from 'src/app/services/asignar-fondo.service';
import { CarrilService } from 'src/app/services/carril.service';
import { CasetaService } from 'src/app/services/caseta.service';
import { CorteService } from 'src/app/services/corte.service';
import { FormacionService } from 'src/app/services/formacion.service';
import { AppState } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { getTurno } from 'src/app/utils';

@Component({
  selector: 'app-aperturar-carril',
  templateUrl: './aperturar-carril.component.html',
  styleUrls: ['./aperturar-carril.component.scss']
})
export class AperturarCarrilComponent implements OnInit {

  denominaciones: FondoDenominacion[] = [];
  usuario: any;
  caseta_id: number = null;
  carriles: Carril[] = [];
  hasCarrilsClosed: boolean = false;
  cobradores: User[] = [];
  fondoPerfil: FondoDenominacion[] = [];
  fondoBase: FondoDenominacion[] = [];
  turno: Turno;
  fondoByCarril = [];
  fondoByCarrilSuma = [];
  formaciones: Formacion[] = [];
  carrilFormacionList = [];
  asignarCobrador = [];
  inactiveCheck: any[] = [];
  inactiveFormacion: any[] = [];

  get denominacionFormato() {
    return this.denominaciones.slice(0, 8).reverse();
  }

  constructor(
    private asignarFondo: AsignarFondoService,
    private store: Store<AppState>,
    private caseta: CasetaService,
    private carril: CarrilService,
    private formacionService: FormacionService,
    private config: NgSelectConfig,
    private toastr: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private corteService: CorteService,
    private router: Router,
  ) {
    this.config.notFoundText = 'Sin cobradores';
  }

  ngOnInit():void {
    this.ngxUiLoaderService.start();

    // obtener turno actual
    this.turno = getTurno();
    // obtener casetas de usuario
    this.store.select(selectUser).subscribe( usuario => this.usuario = usuario);
    // fondo para casetas (todas comparten el mismo fondo) disponibles y fondo base para comparar
    this.asignarFondo.getFondoDenominacion( this.usuario.caseta_usuario[0].id ).then( () => {
      this.denominaciones = this.asignarFondo.fondoDenominacion;
    });

    this.ngxUiLoaderService.stop();
  }

  async getCaseta( event: any) {
    // this.ngxUiLoaderService.start();
    // this.ngxUiLoaderService.stop();

    console.log( 'IDCASETA', event.target.value );

    // refrescar el fondo cada vez que se cambie de caseta
    this.asignarFondo.getFondoDenominacion( this.usuario.caseta_usuario[0].id ).then( () => {
      this.denominaciones = this.asignarFondo.fondoDenominacion;
    });

    // instancia nueva para fondo base
    this.asignarFondo.getFondoDenominacion( this.usuario.caseta_usuario[0].id ).then( () => {
      this.fondoBase = this.asignarFondo.fondoDenominacion;
      this.fondoBase = this.fondoBase.slice(0, 8).reverse()
    });

    // obtener caseta
    this.caseta_id = Number(event.target.value);
    // obtener carriles
    let carrilsClosed = await this.carril.getCarrilClosed( this.caseta_id, this.turno.id ).toPromise();
    // mostrar dependiendo de carriles cerrados
    if ( carrilsClosed.length > 0 ) {
      // obtener formacion
      await this.formacionService.getFormaciones().toPromise().then( formaciones => {
        this.formaciones = formaciones;
      });

      // obtener fondo perfil por caseta y cobradores por caseta
      await this.caseta.getCaseta( this.caseta_id ).toPromise().then( caseta => {
        // obtener fondo
        this.fondoPerfil = caseta.perfil.reverse();
        // obtener cobradores
        let personal = caseta.jefesturno.concat(caseta.cobradores);
        this.cobradores = personal;

        // asigno despues para tener cargados los perfiles antes del render de carriles
        this.carriles = carrilsClosed;

        // console.log( 'COBRADORES', this.cobradores );
        // console.log( 'PERFILFONDO', this.fondoPerfil );
      });

      // mostrar carriles cerrados
      this.hasCarrilsClosed = true;
      // limpiar antes de calcular
      document.getElementById('totalFinal').innerText = `$0`;

      // sumar carriles
      setTimeout(() => {
        console.log( 'Guardar matriz' );

        // limpiar para cada cambio
        this.fondoByCarril = [];
        this.carrilFormacionList = [];

        for (let carril of this.carriles) {
          let i = 0;
          for (let denominacion of this.denominacionFormato) {
            this.insertMorralla(carril, denominacion, this.fondoPerfil[i].pivot.cantidad, `c${carril.id}-${i}`);
            i++;
          }

          // crear objeto de formacion por carril
          this.carrilFormacionList.push({
            id_carril: carril.id,
            formacion: [1],
            justificacion: ''
          });

        }

        // verificamos columnas
        this.sumaColumna();

      }, 1000);

    } else {
      this.hasCarrilsClosed = false;
      this.carriles = [];
      this.fondoPerfil = [];
      this.cobradores = [];
      this.formaciones = [];
      this.fondoByCarril = [];
      this.carrilFormacionList = [];

      // reiniciar el total cuando no hay carriles cerrados
      document.getElementById('totalFinal').innerText = `$0`;

    }

  }


  getResult(carril: Carril, denominacionPerfil: FondoDenominacion, cantidad, input) {
    let carrilObj = carril;
    let denominacionPerfilObj = denominacionPerfil;
    let inputCantidad = Number(cantidad.target.value);
    let inputId = input.id;

    // insertar cambio en la matriz
    this.modificarFondoByDenominacion(carrilObj, denominacionPerfilObj, inputCantidad);
    // validar cambio en la matriz
    this.sumaColumnaByDenominacion( denominacionPerfilObj, inputId, carrilObj, inputCantidad );

  }

  // sumar filas
  sumaFilaCarril(carril) {
    let subtotal: number = 0;
    for (let item in this.fondoByCarril) {
      if (this.fondoByCarril[item].id_carril === carril) {
        subtotal += (this.fondoByCarril[item].cantidad) * (this.fondoByCarril[item].denominacion);
      }
    }

    // console.log( 'SUMAFILACARRIL', subtotal );
    document.getElementById(`c${carril}`).innerText = `$${subtotal}`;
    this.sumaFinal();
  }

  // sumar columnas inicial, REFACTORIZAR SI ES NECESARIO o separar en funciones
  sumaColumna() {

    // objeto de fondo base
    let columnaBase = [];
    let columnaSuma = [];

    // arrays comparativa
    for (let fb in this.fondoBase) {
      columnaBase.push({
        id: this.fondoBase[fb].id,
        denominacion: this.fondoBase[fb].cantidad,
        cantidad: this.fondoBase[fb].pivot.cantidad
      });

      columnaSuma.push({
        id: this.fondoPerfil[fb].id,
        denominacion: this.fondoPerfil[fb].cantidad,
        cantidad: (this.fondoPerfil[fb].pivot.cantidad * this.carriles.length)
      });
    }

    for (let cb in columnaBase) {
      if ( columnaSuma[cb].cantidad > columnaBase[cb].cantidad ) {
        this.toastr.error(`El valor ingresado es mayor al disponible en $${ columnaSuma[cb].denominacion }`, 'Error', {positionClass: 'toast-bottom-right'});

        // reiniciar a cero la denominacion excedida en el array de suma
        columnaSuma[cb].cantidad = 0;

        // si el valor calculado es mayor enviar a cero la denominacion en la matriz y el input en el html
        for (let fbc in this.fondoByCarril) {
          if (this.fondoByCarril[fbc].id_denominacion === columnaSuma[cb].id) {
            this.fondoByCarril[fbc].cantidad = 0;
            (document.getElementById(this.fondoByCarril[fbc].id_input) as HTMLInputElement).value = '0';
          }
        }

      }

      // modificar el fondo disponible de acuerdo a la suma de columna despues de las validaciones
      this.denominacionFormato[cb].pivot.cantidad = (this.denominacionFormato[cb].pivot.cantidad - columnaSuma[cb].cantidad);

    }

    // sumar filas despues de verificar columnas
    for (let carril of this.carriles) {
      this.sumaFilaCarril(carril.id);
    }

    // console.log( 'FONDOBYCARRIL', this.fondoByCarril );


  }

  // suma columna por denominacion y compara
  sumaColumnaByDenominacion(denominacion, inputId, carril, inputCantidad) {
    let sumaColumna: number = 0;
    let denominacionDisponible: number = 0;
    let sumaColumnaFinal: number = 0;
    let denominacionIndex: number = 0;

    for (let i in this.fondoByCarril) {
      if (this.fondoByCarril[i].id_denominacion === denominacion.id) {
        sumaColumna += this.fondoByCarril[i].cantidad;
      }
    }

    // buscar la cantidad total de fondo a comparar
    let c: number = 0
    for (let fb of this.fondoBase) {
      if (fb.id === denominacion.id) {
        denominacionDisponible = fb.pivot.cantidad;
        denominacionIndex = c;
      }
      c++;
    }

    if (denominacionDisponible >= sumaColumna) {
      // console.log( 'SE PUEDE MODIFICAR' );
      // se mantiene el input modificado, se calcula el resto de fondo sobrante, se suma filas y total
      for (let fbc of this.fondoByCarril) {
        if (fbc.id_denominacion === denominacion.id) {
          sumaColumnaFinal += fbc.cantidad;
        }
      }
      this.denominacionFormato[denominacionIndex].pivot.cantidad = (this.fondoBase[denominacionIndex].pivot.cantidad - sumaColumnaFinal);

      // recalcular las filas y el total
      this.sumaFilaCarril(carril.id);

    } else {
      // console.log( 'NO SE PUEDE MODIFICAR' );
      // se reiniciar el input modificado a cero, se calcula el resto de fondo sobrante, se suma filas y total

      // reiniciar valor en la matriz y modificar input a 0
      this.modificarFondoByDenominacion(carril, denominacion, 0);
      (document.getElementById(inputId) as HTMLInputElement).value = '0';
      this.toastr.error(`El valor es mayor al disponible en $${ denominacion.cantidad }`, 'Error', {positionClass: 'toast-bottom-right'});

      // recalcular el fondo
      for (let fbc of this.fondoByCarril) {
        if (fbc.id_denominacion === denominacion.id) {
          sumaColumnaFinal += fbc.cantidad;
        }
      }
      this.denominacionFormato[denominacionIndex].pivot.cantidad = (this.fondoBase[denominacionIndex].pivot.cantidad - sumaColumnaFinal);

      // recalcular las filas y el total
      this.sumaFilaCarril(carril.id);

    }
  }

  modificarFondoByDenominacion(carril: Carril, denominacion: FondoDenominacion, cantidad: number) {

    // modificar valor en matriz
    let itemIndex = this.fondoByCarril.findIndex(item => item.id_denominacion === denominacion.id && item.id_carril === carril.id);
    this.fondoByCarril[itemIndex].cantidad = cantidad;

    // console.log( 'FONDOCARRILMODIFICADO', this.fondoByCarril );

  }

  // total final
  sumaFinal() {
    let total: number = 0;
    for (let i = 0; i < this.carriles.length; i++) {
      total += Number((document.getElementById(`c${this.carriles[i].id}`) as HTMLInputElement).innerText.substring(1));
    }
    document.getElementById('totalFinal').innerText = `$${total}`;
  }

  getCobrador(carril: number, cobrador: any) {
    if (cobrador) {
      let index = this.asignarCobrador.findIndex( cc => cc.id_carril === carril);
      index === -1 ? this.asignarCobrador.push({ id_carril: carril, id_usuario: cobrador.id, id_turno: this.turno.id, id_jefe_turno: this.usuario.id }) : this.asignarCobrador[index].id_usuario = cobrador.id;
      // revisar formacion duplicada
      this.corteService.getCobradorFormacion(this.caseta_id, cobrador.id, this.turno.id).subscribe( cobradorFormacion => {
        if (cobradorFormacion.length > 0) {
          this.inactiveFormacion.push(carril);
          this.toastr.info('La formacion para este cobrador sera descartada', 'Formacion evaluada', { positionClass: 'toast-bottom-right' });
        } else {
          let index = this.inactiveFormacion.indexOf(carril);
          if (index > -1) {
            this.inactiveFormacion.splice(index, 1);
          }
        }
        // console.log( 'FORMACIONEVALUADA', this.inactiveFormacion );
      });
      // console.log( 'COBRADOR', this.asignarCobrador );
    } else {
      let index = this.asignarCobrador.findIndex( cc => cc.id_carril === carril);
      this.asignarCobrador.splice(index, 1);

      let indexF = this.inactiveFormacion.indexOf(carril);
      if (indexF > -1) {
        this.inactiveFormacion.splice(indexF, 1);
      }
    }
  }

  insertMorralla(carril: Carril, denominacion: FondoDenominacion, cantidad: number, id_input: string) {
    let itemIndex = this.fondoByCarril.findIndex(item => item.id_denominacion === denominacion.id && item.id_carril === carril.id);
    itemIndex === -1 ? this.fondoByCarril.push({
      id_carril: carril.id,
      id_denominacion: denominacion.id,
      cantidad: cantidad,
      denominacion: denominacion.cantidad,
      id_input: id_input,
    }) : this.fondoByCarril[itemIndex].cantidad = cantidad;

    // console.log( 'FONDOPARACARRIL', this.fondoByCarril );
  }

  justificacion(event, index) {
    let texto = event.target.value;
    this.carrilFormacionList[index].justificacion = texto;
  }

  updateFormacionList(id_carril: number, id_formacion: number, e: any) {

    let index = this.carrilFormacionList.findIndex( element => element.id_carril === id_carril );

    // actualiza la formacion por carril
    if (e.target.checked) { this.carrilFormacionList[index].formacion.push(id_formacion)}
    if (!e.target.checked) {
      let indexFormacion = this.carrilFormacionList[index].formacion.indexOf(id_formacion);
      indexFormacion !== -1 ? this.carrilFormacionList[index].formacion.splice(indexFormacion, 1) : '';
    }

    // verifica si la formacion esta completa, si es asi oculta el input y limpio la justificacion
    if ( this.carrilFormacionList[index].formacion.length === 3 ) {
      this.carrilFormacionList[index].justificacion = null;
      let input = (document.getElementById(`j-${id_carril}`) as HTMLInputElement);
      input.classList.add('hidden');
      input.value = "";
    }

    // mostrar formacion en carril si la formacion esta incompleta
    if (this.carrilFormacionList[index].formacion.length < 3) { (document.getElementById(`j-${id_carril}`) as HTMLInputElement).classList.remove('hidden')}

    // console.log( this.carrilFormacionList, this.fondoByCarril );
  }

  //Check carriles
  onChangeCheckCarril(carril: Carril, evt) {

    let isActive = evt.target.checked;
    //get inactive carrils
    isActive ? this.inactiveCheck.push(carril.id) : this.inactiveCheck = [...this.inactiveCheck.filter(v => v != carril.id)];
    // set calculs to 0
    let index = 0;
    let indexDF = 0;
    for (let fbc in this.fondoByCarril) {

      // reiniciar los inputs html a 0 y deshabilitar, los ceros permancen en el array limpiar en backend con el id_carril
      let input: any = document.getElementById(`c${carril.id}-${index}`);
      // oculta los selectores de cobrador, su valor permanece seleccionado limpiar en el backend con el id_carril
      let select: any = document.getElementById(`co${carril.id}`);
      // oculta los checkbox de formacion, su valor permanece seleccionado limpiar en el backend con el id_carril
      let check: any = document.getElementById(`fo${carril.id}`);
      // oculta la justiciaciones
      let texto: any = document.getElementById(`j-${carril.id}`);

      if (input) {
        if (isActive) { //disabled and set 0
          input.value = 0;
          input.disabled = true;

          select.classList.add('hidden');
          check.classList.add('hidden');
          texto.classList.add('hidden');

        } else {  //active
          input.removeAttribute('disabled');

          select.classList.remove('hidden');
          check.classList.remove('hidden');
          texto.classList.remove('hidden');

        }
      }

      // sumar a la matriz disponible los valores a reiniciar a 0
      // reiniciar los inputs del carril en la matriz a 0
      if (this.fondoByCarril[fbc].id_carril === carril.id) {
        this.denominacionFormato[indexDF].pivot.cantidad += this.fondoByCarril[fbc].cantidad;
        this.fondoByCarril[fbc].cantidad = 0;
        indexDF++;
      }

      index++;
    }

    // recalcular filas y total
    this.sumaFilaCarril(carril.id);

    // console.log( 'FONDO', this.fondoByCarril );
    // console.log( 'ASIGNARCOBRADOR', this.asignarCobrador );
    // console.log( 'FORMACION', this.carrilFormacionList );
    // console.log( 'CARRILESCERRADOS', this.carriles );
    // console.log( 'CARRILESINACTIVOS', this.inactiveCheck );
  }

  aperturarCarril() {
    let cantidadActivos = this.carriles.length - this.inactiveCheck.length;
    let cantidadActual = 0;

    for (let ac of this.asignarCobrador) {
      let carril = this.inactiveCheck.indexOf(ac.id_carril);
      if (carril === -1) {
        cantidadActual += 1;
      }
    }

    // Si no estan asignados todos los cobradores
    if (cantidadActivos != cantidadActual) {
      return this.toastr.error('Asigne todos los cobradores', 'Error', { positionClass: 'toast-bottom-right' } );
    }

    // elimina la formacion de cobradores evaluados
    this.carrilFormacionList = this.carrilFormacionList.filter(ifo => !this.inactiveFormacion.includes(ifo.id_carril));
    // console.log( 'CARRILES INACTIVOS SAVE', this.inactiveFormacion );
    // console.log( 'CARRILES FINALES', this.carrilFormacionList );

    let data = {
      formacion : this.carrilFormacionList.filter(cf => !this.inactiveCheck.includes(cf.id_carril)),
      fondo_denominacion : this.fondoByCarril.filter(fd => !this.inactiveCheck.includes(fd.id_carril)),
      asignarCobrador : this.asignarCobrador.filter(ac => !this.inactiveCheck.includes(ac.id_carril)),
    }

    this.ngxUiLoaderService.start();
    this.asignarFondo.saveAperturaCarrril(data).
    subscribe(data=>{
      console.log(data);
      this.toastr.success(`Se han guardado las aperturas de carriles`, 'Correcto', {positionClass: 'toast-bottom-right'});
      this.router.navigate(['/dashboard/home']);
      this.ngxUiLoaderService.stop();
    });
  }

}
