<!--
<div class="container">
    <div class="container__header">
        <button class="btn w-100" [ngClass]="isSelected(1) ? 'selected' : '' "  (click)="goToSubtipo(1)">Incidencias</button>
        <button class="btn w-100" [ngClass]="isSelected(2) ? 'selected' : '' " (click)="goToSubtipo(2)">Exentos</button>
        <button class="btn w-100" [ngClass]="isSelected(3) ? 'selected' : '' " (click)="goToSubtipo(3)">Alarmas</button>
    </div>
</div> -->

<div class="flex flex-col gap-4 ml-2 mr-2">
    <button class="btn bg-gray-50 dark:bg-transparent rounded py-2 px-2 w-full text-gray-500 dark:text-gray-50 text-normal font-semibold border-2 border-gray-300 dark:border-gray-400 drop-shadow-lg hover:border-blue-600 uppercase" [ngClass]="isSelected(1) ? 'selected dark:bg-blue-800 bg-blue-800 text-white' : '' "  (click)="goToSubtipo(1)">Incidencias</button>
    <button class="btn bg-gray-50 dark:bg-transparent rounded py-2 px-2 w-full text-gray-500 dark:text-gray-50 text-normal font-semibold border-2 border-gray-300 dark:border-gray-400 drop-shadow-lg hover:border-blue-600 uppercase" [ngClass]="isSelected(2) ? 'selected dark:bg-blue-800 bg-blue-800 text-white' : '' " (click)="goToSubtipo(2)">Exentos</button>
    <button class="btn bg-gray-50 dark:bg-transparent rounded py-2 px-2 w-full text-gray-500 dark:text-gray-50 text-normal font-semibold border-2 border-gray-300 dark:border-gray-400 drop-shadow-lg hover:border-blue-600 uppercase" [ngClass]="isSelected(3) ? 'selected dark:bg-blue-800 bg-blue-800 text-white' : '' " (click)="goToSubtipo(3)">Fallas</button>
    <button #btnUA *ngIf="isAuxiliar()" class="btn bg-gray-50 dark:bg-transparent rounded py-2 px-2 w-full text-gray-500 dark:text-gray-50 text-sm font-semibold border-2 border-gray-300 dark:border-gray-400 drop-shadow-lg hover:border-blue-600 uppercase" (click)="doUltimoEfectivo(btnUA)">Ultimo efectivo</button>
</div>


