<div class="flex items-center min-h-screen p-6 bg-gray-100 dark:bg-gray-900">
  <div class="flex-1 h-full max-w-xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
    <div class="flex flex-col overflow-y-auto md:flex-row">
      <!-- <div class="h-32 md:h-auto md:w-1/2">
        <img
          aria-hidden="true"
          class="object-cover w-full h-full dark:hidden"
          src="/assets/images/login-light.svg"
          alt="MOPA"
        />
        <img
          aria-hidden="true"
          class="hidden object-cover w-full h-full dark:block"
          src="/assets/images/login-dark.svg"
          alt="MOPA"
        />
      </div> -->
      <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
        <div class="w-full">

          <div class="grid grid-cols-2">

            <div class="flex justify-end">

              <app-switch-theme></app-switch-theme>

            </div>

          </div>

          <hr class="mt-4 mb-8 border-gray-200 dark:border-gray-700" />

          <img
            aria-hidden="true"
            class="mx-auto h-12 w-auto dark:hidden"
            src="/assets/images/sogak-logo-light.svg"
            alt="SOGAK"
          />
          <img
            aria-hidden="true"
            class="hidden mx-auto h-12 w-auto dark:block"
            src="/assets/images/sogak-logo-dark.svg"
            alt="SOGAK"
          />

          <select (change)="onChangeCaseta($event)" class="w-full p-3 mt-3 appearance-none block  text-m font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" name="" id="">
            <option [value]="0">Selecciona una caseta</option>
            <option *ngFor="let caseta of casetas" [value]="caseta.id">{{caseta.nombre}}</option>
          </select>

          <select  [(ngModel)]="idCarril" class="w-full mt-3 appearance-none block p-3 text-m font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" name="" id="">
            <option [value]="0">Selecciona un carril</option>
            <option *ngFor="let carril of carriles" [value]="carril.id">{{carril.nombre}}</option>
          </select>

          <button [disabled]="idCarril == 0" type="button" class="w-full text-m mt-3 text-white col-span-1 bg-blue-600 p-2 rounded font-bold hover:bg-blue-700  border-2  border-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" (click)="saveCarril()">GUARDAR</button>
          <button (click)="logout()" class="mt-2 bg-red-600 w-full text-base uppercase dark:bg-red-600 rounded py-2 text-white dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-red-600">Salir</button>

        </div>
      </div>
    </div>
  </div>
</div>
