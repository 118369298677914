import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FondoDenominacion, Turno } from 'src/app/models/dash';
import { AsignarFondoService } from 'src/app/services/asignar-fondo.service';
import { ControlRolioService } from 'src/app/services/control-rolio.service';
import { MorrallaService } from 'src/app/services/morralla.service';
import { NotificacionesPushService } from 'src/app/services/notificaciones-push.service';
import { AppState } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { getTurno } from 'src/app/utils';

@Component({
  selector: 'app-morralla-carril',
  templateUrl: './morralla-carril.component.html',
  styleUrls: ['./morralla-carril.component.scss']
})
export class MorrallaCarrilComponent implements OnInit {

  denominaciones: FondoDenominacion[] = [];
  usuario: any;
  caseta_id: number = null;
  morrallas = null;
  arrayMorralla: any = [];
  turno: Turno;
  rollos = null;
  arrayRollos: any = [];
  confirmModal : boolean =  false;


  get denominacionFormato() {
    return this.denominaciones.slice(0, 9).reverse();
  }

  constructor(
    private router: Router,
    public asignarFondo: AsignarFondoService,
    public morrallaService: MorrallaService,
    public notificacionPushService: NotificacionesPushService,
    public controlFolioService: ControlRolioService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private store: Store<AppState>,
    private toastr: ToastrService,
  ) { }

  async ngOnInit() {
    // obtener turno actual
    this.turno = getTurno();
    // obtener casetas de usuario
    this.store.select(selectUser).subscribe(usuario => this.usuario = usuario);
    // fondo casetas (todas comparten el mismo fondo)
    await this.asignarFondo.getFondoDenominacion(this.usuario.caseta_usuario[0].id);
    this.denominaciones = this.asignarFondo.fondoDenominacion;

    console.log('DENOMINACIONES', this.denominacionFormato);
  }

  getCaseta(caseta: any) {
    this.caseta_id = Number(caseta.target.value);

    // busca solicitudes de morralla
    this.morrallaService.getMorrallaByJt(this.usuario.id, this.caseta_id).toPromise().then(morrallas => {
      this.morrallas = morrallas;
      // UPDATE Denominaciones morrralla
      let cantidadMorralla = [200, 200, 200, 100, 25, 20, 10, 0, 0];
      // si hay solicitud de morralla por carril
      if (this.morrallas.length > 0) {

        for (let morralla of morrallas) {
          let inputMorralla = new Array(cantidadMorralla.length).fill(0);
          let morrallaList = JSON.parse(morralla.denominaciones);

          // agregar cantidad donde coincida
          for (let ml of morrallaList) {
            inputMorralla[(ml - 1)] = cantidadMorralla[(ml - 1)];
          }
          // invertir vector para coincidir con inputs
          inputMorralla = inputMorralla.reverse();

          // mostrar valores en input html
          setTimeout(() => {
            let c = 0;
            for (let im of inputMorralla) {
              let id_input = `c${morralla.id_carril}-${morralla.id_notificacion}-${c}`;
              (document.getElementById(id_input) as HTMLInputElement).value = `${im}`;
              this.insertMorralla(morralla.id_carril, this.denominacionFormato[c], im, morralla.id_corte_usuario, morralla.id_notificacion, id_input);
              c++;
            }
            this.sumaCarrilFila(morralla.id_carril);
          }, 1000);

        }

        setTimeout(() => {
          this.sumaDenominacionColumna();
        }, 1500);


      } else {
        this.arrayMorralla = [];
        this.morrallas = null;
      }
    });

    // busca solicitudes de rollo
    this.notificacionPushService.getNotificacionRollo(this.caseta_id, this.turno.id, this.usuario.id).toPromise().then(rollos => {
      this.rollos = rollos;
      console.log('ROLLOS', this.rollos.length);

      if (this.rollos.length > 0) {
        // rollos solicitados
        for (let rollo of this.rollos) {
          this.arrayRollos.push({
            nombreCabina: rollo.nombre,
            id_corte_usuario: rollo.id_corte_usuario,
            id_notificacion_push: rollo.id_notificacion_push,
            id_jefe_turno: this.usuario.id,
            folio_inicial: null,
            folio_final: null
          });
        }
      } else {
        this.arrayRollos = [];
        this.rollos = null;
      }

    });

  }

  insertMorralla(carril, denominacion, cantidad, id_corte_usuario, id_notificacion, id_input) {
    let itemIndex = this.arrayMorralla.findIndex(item => item.id_denominacion === denominacion.id && item.id_carril === carril);
    itemIndex === -1 ? this.arrayMorralla.push({
      id_carril: carril,
      id_corte_usuario: id_corte_usuario,
      id_notificacion_push: id_notificacion,
      id_denominacion: denominacion.id,
      cantidad: cantidad,
      denominacion: denominacion.cantidad,
      id_input: id_input,
    }) : this.arrayMorralla[itemIndex].cantidad = cantidad;
    // console.log( 'MORRALLAFINAL', this.arrayMorralla );
  }

  sumaCarrilFila(carril: number) {
    let subtotal: number = 0;
    for (let item in this.arrayMorralla) {
      if (this.arrayMorralla[item].id_carril === carril) {
        subtotal += (this.arrayMorralla[item].cantidad) * (this.arrayMorralla[item].denominacion);
      }
    }
    document.getElementById(`c${carril}`).innerText = `$${subtotal}`;
  }

  sumaDenominacionColumna() {
    let sumaColumna = new Array(9).fill(0);
    let sumaValor = new Array(9).fill(0);

    // dividir array
    let carril = [];
    let inicio = 0;
    let fin = 9;
    for (let m of this.morrallas) {
      carril.push(this.arrayMorralla.slice(inicio, fin));
      inicio = fin;
      fin += 9;
    }

    // sumar cantidades
    for (let i = 0; i < carril.length; i++) {
      for (let j = 0; j < sumaColumna.length; j++) {
        sumaColumna[j] += carril[i][j].cantidad;
        sumaValor[j] += (carril[i][j].cantidad * carril[i][j].denominacion);
      }
    }


    // mostrar cantidad en el html
    for (let i = 0; i < sumaColumna.length; i++) {

      // valida la cantidad en base al fondo
      if (sumaColumna[i] > this.denominacionFormato[i].pivot.cantidad) {
        // reinicia el HTML si es mayor al disponible
        this.toastr.error(`El valor ingresado es mayor en la siguiente denominacion $${this.denominacionFormato[i].cantidad}`, 'Error', { positionClass: 'toast-bottom-right' });
        document.getElementById(`ca${i}`).innerText = `0`;
        document.getElementById(`mo${i}`).innerText = `$0`;

        // reinicia la sumaColumna y Valor
        sumaColumna[i] = 0;
        sumaValor[i] = 0;

        // obtengo el id con exceso de cantidad
        let id_denominacion = this.denominacionFormato[i].id;
        // reinicio la matriz que va a base de datos y reinicio los inputs correspondientes en el html
        for (let am in this.arrayMorralla) {
          if (this.arrayMorralla[am].id_denominacion === id_denominacion) {
            this.arrayMorralla[am].cantidad = 0;
            (document.getElementById(this.arrayMorralla[am].id_input) as HTMLInputElement).value = `0`;
          }
        }

        // reiniciar la suma de la fila
       for (let mas of this.morrallas) {
         this.sumaCarrilFila(mas.id_carril);
       }


      } else {
        document.getElementById(`ca${i}`).innerText = `${sumaColumna[i]}`;
        document.getElementById(`mo${i}`).innerText = `$${sumaValor[i]}`;
      }

    }

    // mostrar totales total en html
    let cantidadTotal = sumaColumna.reduce((a, b) => a + b, 0);
    let valorTotal = sumaValor.reduce((a, b) => a + b, 0);
    document.getElementById(`caTotal`).innerText = `${cantidadTotal}`;
    document.getElementById(`moTotal`).innerText = `$${valorTotal}`;

  }

  // optimizar ya que hace un recalculo total
  getResult(id_carril, denominacion, event, id_corte_usuario, id_notificacion, input) {
    let inputCantidad = Number(event.target.value);
    let inputId = input.id;

    this.insertMorralla(id_carril, denominacion, inputCantidad, id_corte_usuario, id_notificacion, inputId);

    // actualizar monto horizontal
    this.sumaCarrilFila(id_carril);
    // actualizar monto vertical
    this.sumaDenominacionColumna();
  }

  // recuperar folios
  getFolios(id_notificacion_push, event, input, tipo) {
    let folio = Number(event.target.value);
    let inputId = input.id;

    console.log('FOLIOS', id_notificacion_push, folio, inputId);

    // buscar el index y asignar folio
    let itemIndex = this.arrayRollos.findIndex(item => item.id_notificacion_push === id_notificacion_push);
    if (tipo === 1) {
      this.arrayRollos[itemIndex].folio_inicial = folio;
    } else {
      this.arrayRollos[itemIndex].folio_final = folio;
    }

  }

  async saveMorralla() {
    // valida que se eliga una caseta con notificaciones
    if (this.morrallas === null) { return this.toastr.error('Sin notificaciones de morralla', 'Error', { positionClass: 'toast-bottom-right' }) }

    this.ngxUiLoaderService.start()

    // formato final
    let data = [];
    for (let am of this.arrayMorralla) {
      let i = data.findIndex(item => item.id_corte_usuario === am.id_corte_usuario);
      i === -1 ? data.push({
        id_corte_usuario: am.id_corte_usuario,
        id_notificacion_push: am.id_notificacion_push,
        denominaciones: [{
          id_denominacion: am.id_denominacion,
          cantidad: am.cantidad,
        }]
      }) : data[i].denominaciones.push({
        id_denominacion: am.id_denominacion,
        cantidad: am.cantidad,
      });
    }

    let resp = await this.morrallaService.saveMorrallaCarril(data).toPromise();
    console.log( 'RESP', resp );
    this.toastr.success('Solicitud de morralla guardada correctamente', '', { positionClass: 'toast-bottom-right' });
    this.router.navigate(['/dashboard/fondo-operacion']);
    this.ngxUiLoaderService.stop()

    console.log('DATA', data);
    console.log('GUARDANDO MORRALLA');
  }

  async saveRollo() {
      console.log(this.arrayRollos);
      const allTrue  = this.arrayRollos.every(obj => obj.folio_inicial != null && obj.folio_final != null)
       console.log(allTrue);

      if(!allTrue){
       return  this.toastr.error('Ingrese todos los folios', '', { positionClass: 'toast-bottom-right' })

       }
    this.ngxUiLoaderService.start()
    let data = {
      folios : this.arrayRollos
    }
    await this.controlFolioService.saveControlFolio(data).toPromise()
    this.toastr.success('Información guardada con éxito', '', { positionClass: 'toast-bottom-right' })
    this.router.navigate(['/dashboard/home']);
    this.ngxUiLoaderService.stop()

  }

  validInputs() {
    return 1;
  }

}
