import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Carril, Denominacion} from 'src/app/models';
import {FondoDenominacion, Turno, Turnos, User} from 'src/app/models/dash';
import {AsignarFondoService} from 'src/app/services/asignar-fondo.service';
import {TurnoService} from 'src/app/services/turno.service';
import {UserService} from 'src/app/services/user.service';
import {CasetaService} from 'src/app/services/caseta.service';
import {ToastrService} from 'ngx-toastr';
import {getNextTurno, getTurno} from '../../utils/index';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Store} from '@ngrx/store';
import {AppState} from 'src/app/state/app.state';
import {loadedUser} from 'src/app/state/actions/user.actions';
import {AuthService} from 'src/app/services/auth.service';
import {LogUsuarioService} from 'src/app/services/log-usuario.service';
import {selectUser} from '../../state/selectors/user.selectors';
import {changeAccion} from '../../state/actions/user.actions';
import {CorteService} from '../../services/corte.service';
import {TipoUsuarioService} from 'src/app/services/tipo-usuario.service';
import { CAT_EVENTO, TYPE_ROL } from 'src/app/utils/enum';

@Component({
  selector: 'app-asignar-fondo',
  templateUrl: './asignar-fondo.component.html',
  styleUrls: ['./asignar-fondo.component.scss']
})
export class AsignarFondoComponent implements OnInit {

  turno: Turno;
  id_caseta: number;
  install: boolean = true;
  id_cat_acciones: number;
  routeNav: string;
  id_jefeTurno: number;
  users: User[];
  usuario: any;
  carriles: Carril[];
  denominacionFormato: FondoDenominacion[];
  denominacionBase: FondoDenominacion[];
  denominacionPerfil: FondoDenominacion[];
  inactiveCheck: any[] = [];
  fondoByCarril = [];

  constructor(
    private router: Router,
    private asignarFondoService: AsignarFondoService,
    private userService: UserService,
    private tipoUserService: TipoUsuarioService,
    private casetaService: CasetaService,
    private store: Store<AppState>,
    private authService: AuthService,
    private logUsuarioService: LogUsuarioService,
    private toastr: ToastrService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private corteService: CorteService,
  ) {

  }

  async ngOnInit() {

    this.ngxUiLoaderService.start();

    this.turno = getNextTurno();

    this.store.select(selectUser).subscribe( usuario => {
      this.usuario = usuario;
      let casetaTroncal = usuario.caseta_usuario.find(caseta => caseta.tipo === 1);
      this.id_caseta = casetaTroncal.id;
      // si asigno fondo antes regresa al home
      if (this.usuario.id_cat_acciones === 3) {
        this.router.navigate(['dashboard/home']);
      }
    });

    this.corteService.checkInstalacion(this.id_caseta).subscribe(install => {
      if (!install) {
        this.install = false;
        this.turno = getTurno();
        this.id_cat_acciones = 1;
        this.routeNav = 'dashboard/asignar-carril';
      } else {
        this.id_cat_acciones = 3;
        this.routeNav = 'dashboard/firma';
      }
      // console.log( 'CAT', this.id_cat_acciones, install, this.routeNav );
    });

    // matriz
    this.asignarFondoService.getFondoDenominacion( this.id_caseta ).then( () => {
      this.denominacionFormato = this.asignarFondoService.fondoDenominacion.slice(0, 8).reverse();
    });

    // matriz base
    this.asignarFondoService.getFondoDenominacion( this.id_caseta ).then( () => {
      this.denominacionBase = this.asignarFondoService.fondoDenominacion.slice(0, 8).reverse();
    });

    await this.casetaService.getCaseta( this.id_caseta ).toPromise().then( caseta => {
      this.denominacionPerfil = caseta.perfil.reverse();
      this.users = caseta['jefesturno'];
      this.carriles = caseta.carriles;
    });

    // sumar carriles
    setTimeout(() => {
      for (let carril of this.carriles) {
        let i = 0;
        for (let denominacion of this.denominacionFormato) {
          this.insertMorralla(carril, denominacion, this.denominacionPerfil[i].pivot.cantidad, `c${carril.id}-${i}`);
          i++;
        }
      }
      // verificamos columnas
      this.sumaColumna();
    }, 1000);

    this.ngxUiLoaderService.stop();
  }

  getJefeTurno(event): void {
    this.id_jefeTurno = event.target.value;
  }

  getResult(carril: Carril, denominacionPerfil: FondoDenominacion, cantidad, input) {
    let carrilObj = carril;
    let denominacionPerfilObj = denominacionPerfil;
    let inputCantidad = Number(cantidad.target.value);
    let inputId = input.id;
    // insertar cambio en la matriz
    this.modificarFondoByDenominacion(carrilObj, denominacionPerfilObj, inputCantidad);
    // validar cambio en la matriz
    this.sumaColumnaByDenominacion(denominacionPerfilObj, inputId, carrilObj);
  }

  insertMorralla(carril: Carril, denominacion: FondoDenominacion, cantidad: number, id_input: string) {
    let itemIndex = this.fondoByCarril.findIndex(item => item.id_denominacion === denominacion.id && item.id_carril === carril.id);
    itemIndex === -1 ? this.fondoByCarril.push({
      id_carril: carril.id,
      id_denominacion: denominacion.id,
      cantidad: cantidad,
      denominacion: denominacion.cantidad,
      id_input: id_input,
    }) : this.fondoByCarril[itemIndex].cantidad = cantidad;
    // console.log( 'FONDOPARACARRIL', this.fondoByCarril );
  }

  modificarFondoByDenominacion(carril: Carril, denominacion: FondoDenominacion, cantidad: number) {
    // modificar valor en matriz
    let itemIndex = this.fondoByCarril.findIndex(item => item.id_denominacion === denominacion.id && item.id_carril === carril.id);
    this.fondoByCarril[itemIndex].cantidad = cantidad;
    // console.log( 'FONDOCARRILMODIFICADO', this.fondoByCarril );
  }

  // suma columna por denominacion y compara
  sumaColumnaByDenominacion(denominacion, inputId, carril) {
    let sumaColumna: number = 0;
    let denominacionDisponible: number = 0;
    let sumaColumnaFinal: number = 0;
    let denominacionIndex: number = 0;

    for (let i in this.fondoByCarril) {
      if (this.fondoByCarril[i].id_denominacion === denominacion.id) {
        sumaColumna += this.fondoByCarril[i].cantidad;
      }
    }
    // buscar la cantidad total de fondo a comparar
    let c: number = 0
    for (let fb of this.denominacionBase) {
      if (fb.id === denominacion.id) {
        denominacionDisponible = fb.pivot.cantidad;
        denominacionIndex = c;
      }
      c++;
    }
    if (denominacionDisponible >= sumaColumna) {
      // console.log( 'SE PUEDE MODIFICAR' );
      // se mantiene el input modificado, se calcula el resto de fondo sobrante, se suma filas y total
      for (let fbc of this.fondoByCarril) {
        if (fbc.id_denominacion === denominacion.id) {
          sumaColumnaFinal += fbc.cantidad;
        }
      }
      this.denominacionFormato[denominacionIndex].pivot.cantidad = (this.denominacionBase[denominacionIndex].pivot.cantidad - sumaColumnaFinal);
      // recalcular las filas y el total
      this.sumaFilaCarril(carril.id);
    } else {
      // console.log( 'NO SE PUEDE MODIFICAR' );
      // se reiniciar el input modificado a cero, se calcula el resto de fondo sobrante, se suma filas y total
      // reiniciar valor en la matriz y modificar input a 0
      this.modificarFondoByDenominacion(carril, denominacion, 0);
      (document.getElementById(inputId) as HTMLInputElement).value = '0';
      this.toastr.error(`El valor es mayor al disponible en $${ denominacion.cantidad }`, 'Error', {positionClass: 'toast-bottom-right'});
      // recalcular el fondo
      for (let fbc of this.fondoByCarril) {
        if (fbc.id_denominacion === denominacion.id) {
          sumaColumnaFinal += fbc.cantidad;
        }
      }
      this.denominacionFormato[denominacionIndex].pivot.cantidad = (this.denominacionBase[denominacionIndex].pivot.cantidad - sumaColumnaFinal);
      // recalcular las filas y el total
      this.sumaFilaCarril(carril.id);
    }
  }

  sumaColumna() {
    // objeto de fondo base
    let columnaBase = [];
    let columnaSuma = [];

    // arrays comparativa
    for (let fb in this.denominacionBase) {
      columnaBase.push({
        id: this.denominacionBase[fb].id,
        denominacion: this.denominacionBase[fb].cantidad,
        cantidad: this.denominacionBase[fb].pivot.cantidad
      });

      columnaSuma.push({
        id: this.denominacionPerfil[fb].id,
        denominacion: this.denominacionPerfil[fb].cantidad,
        cantidad: (this.denominacionPerfil[fb].pivot.cantidad * this.carriles.length)
      });
    }

    for (let cb in columnaBase) {
      if ( columnaSuma[cb].cantidad > columnaBase[cb].cantidad ) {
        if (this.usuario.id_cat_acciones < 3) {
          this.toastr.error(`El valor ingresado es mayor al disponible en $${ columnaSuma[cb].denominacion }`, 'Error', {positionClass: 'toast-bottom-right'});
        }
        // reiniciar a cero la denominacion excedida en el array de suma
        columnaSuma[cb].cantidad = 0;
        // si el valor calculado es mayor enviar a cero la denominacion en la matriz y el input en el html
        for (let fbc in this.fondoByCarril) {
          if (this.fondoByCarril[fbc].id_denominacion === columnaSuma[cb].id) {
            this.fondoByCarril[fbc].cantidad = 0;
            (document.getElementById(this.fondoByCarril[fbc].id_input) as HTMLInputElement).value = '0';
          }
        }
      }
      // modificar el fondo disponible de acuerdo a la suma de columna despues de las validaciones
      this.denominacionFormato[cb].pivot.cantidad = (this.denominacionFormato[cb].pivot.cantidad - columnaSuma[cb].cantidad);
    }
    // sumar filas despues de verificar columnas
    for (let carril of this.carriles) {
      this.sumaFilaCarril(carril.id);
    }
    // console.log( 'FONDOBYCARRIL', this.fondoByCarril );
  }

  sumaFilaCarril(carril: number) {
    let subtotal: number = 0;
    for (let item in this.fondoByCarril) {
      if (this.fondoByCarril[item].id_carril === carril) {
        subtotal += (this.fondoByCarril[item].cantidad) * (this.fondoByCarril[item].denominacion);
      }
    }
    document.getElementById(`c${carril}`).innerText = `$${subtotal}`;
    this.sumaFinal();
  }

  // total final
  sumaFinal() {
    let total: number = 0;
    for (let i = 0; i < this.carriles.length; i++) {
      total += Number((document.getElementById(`c${this.carriles[i].id}`) as HTMLInputElement).innerText.substring(1));
    }
    document.getElementById('totalFinal').innerText = `$${total}`;
  }

  // Check carriles
  onChangeCheckCarril(carril: Carril, evt) {
    let isActive = evt.target.checked;
    //get inactive carrils
    isActive ? this.inactiveCheck.push(carril.id) : this.inactiveCheck = [...this.inactiveCheck.filter(v => v != carril.id)];
    // set calculs to 0
    let index = 0;
    let indexDF = 0;
    for (let fbc in this.fondoByCarril) {
      // reiniciar los inputs html a 0 y deshabilitar, los ceros permancen en el array limpiar en backend con el id_carril
      let input: any = document.getElementById(`c${carril.id}-${index}`);
      if (input) {
        if (isActive) { //disabled and set 0
          input.value = 0;
          input.disabled = true;
        } else {  // active
          input.removeAttribute('disabled');
        }
      }

      // sumar a la matriz disponible los valores a reiniciar a 0
      // reiniciar los inputs del carril en la matriz a 0
      if (this.fondoByCarril[fbc].id_carril === carril.id) {
        this.denominacionFormato[indexDF].pivot.cantidad += this.fondoByCarril[fbc].cantidad;
        this.fondoByCarril[fbc].cantidad = 0;
        indexDF++;
      }
      index++;
    }
    // recalcular filas y total
    this.sumaFilaCarril(carril.id);
    // console.log( 'FONDO', this.fondoByCarril );
    // console.log( 'CARRILESINACTIVOS', this.inactiveCheck );
  }

  async saveAsignarFondo() {
    if (!this.id_jefeTurno) { return this.toastr.error('Seleccione el jefe de turno', 'Error', {positionClass: 'toast-bottom-right'})}
    //NEW FLUJO JT, SE GUARDA EN LOCAL STORAGE EL ID DEL JT ENTRANTE
    localStorage.setItem('jt_entrante', String(this.id_jefeTurno));

    this.ngxUiLoaderService.start();

    let data = {
      id_jefe_turno: this.id_jefeTurno,
      carriles: this.carriles.filter(car => !this.inactiveCheck.includes(car.id)),
      fondo_denominacion: this.fondoByCarril.filter( dev => !this.inactiveCheck.includes(dev.id_carril)),
      id_turno: this.turno.id,
      id_cat_evento : CAT_EVENTO.FONDO
    };

    this.store.dispatch(changeAccion({id_cat_acciones: this.id_cat_acciones}));
    this.authService.login(this.usuario);

    // actualizo el log de usuario
    await this.logUsuarioService.updateLogUsuario({id_cat_acciones: this.id_cat_acciones}, this.usuario.id_log_usuario).toPromise();
    // envio fondo
    await this.asignarFondoService.saveAsignacionFondo(data).toPromise();
    this.toastr.success('Asignacion de fondo realizada correctamente', '', {positionClass: 'toast-bottom-right'});
    this.router.navigate([this.routeNav]);

    this.ngxUiLoaderService.stop();
  }
}

