<div class="flex justify-between border border-solid rounded border-gray-300 dark:border-gray-500 p-2 m-2 text-base font-semibold uppercase text-gray-600 dark:text-white items-center">
    <span>{{ casetaTroncal }}</span>
    <span>{{ turnoActual.name }}</span>
    <!-- <span>{{ (user | async)?.nombre }}</span> -->
    <span>{{ jefe_turno.nombre}}</span>
    <span>{{ time | date: 'dd/MM/YYYY' }}</span>
    <span>{{ time | date: 'hh:mm:ss a' }}</span>

    <button class="ml-2 border rounded px-3 py-1 shadow-sm shadow-sky-500">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
        </svg>
    </button>

    <app-switch-theme></app-switch-theme>
</div>

<!-- modal -->
<div *ngIf="tareaModal" class="modal-component-container fixed inset-0 overflow-y-auto">
    <div class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>

        <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>

        <div class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
            <div class="modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

                <div class="modal-wrapper-flex sm:flex sm:items-start">

                    <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">

                        <div class="flex items-center justify-between">
                            <div class="flex items-center rounded py-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
                                </svg>
                                <span class="pl-2">Tareas</span>
                            </div>
                            <div class="flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                                </svg>
                                <span class="pl-2">{{ tareaHoy }}</span>
                            </div>
                        </div>

                        <hr>

                        <!-- lista de pendientes -->
                        <div class="space-y-3">

                            <div class="flex flex-col container max-w-md mx-auto w-full items-center justify-center bg-white rounded-lg shadow">
                                <ul class="flex flex-col divide-y w-full">

                                    <li *ngIf="notificacionMorralla" class="flex flex-row">
                                        <div class="hover:bg-gray-50 flex flex-1 items-center p-4">

                                            <div class="flex-1 pl-1">
                                                <div class="font-medium">MORRALLA</div>
                                                <div class="text-gray-600 text-sm">Tienes solicitud de morralla, verifica tu panel de inicio</div>
                                            </div>

                                            <div class="flex flex-row justify-center">
                                                <a routerLink="/dashboard/morralla-carril" class="w-10 text-right flex justify-end select-none cursor-pointer">
                                                    <svg width="20" fill="currentColor" height="20" class="hover:text-gray-800 text-gray-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
                                                    </svg>
                                                </a>
                                            </div>

                                        </div>
                                    </li>

                                    <li *ngIf="notificacionRetiro" class="flex flex-row">
                                        <div class="hover:bg-gray-50 flex flex-1 items-center p-4">

                                            <div class="flex-1 pl-1">
                                                <div class="font-medium">RETIRO</div>
                                                <div class="text-gray-600 text-sm">Tienes solicitud de retiro, verifica tu panel de inicio</div>
                                            </div>

                                            <div class="flex flex-row justify-center">
                                                <a routerLink="/dashboard/home" class="w-10 text-right flex justify-end select-none cursor-pointer">
                                                    <svg width="20" fill="currentColor" height="20" class="hover:text-gray-800 text-gray-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
                                                    </svg>
                                                </a>
                                            </div>

                                        </div>
                                    </li>

                                    <li *ngIf="notificacionDevolucion" class="flex flex-row">
                                        <div class="hover:bg-gray-50 flex flex-1 items-center p-4">

                                            <div class="flex-1 pl-1">
                                                <div class="font-medium">DEVOLUCION</div>
                                                <div class="text-gray-600 text-sm">Tienes solicitud de devolucion, verifica tu panel de inicio</div>
                                            </div>

                                            <div class="flex flex-row justify-center">
                                                <a routerLink="/dashboard/home" class="w-10 text-right flex justify-end select-none cursor-pointer">
                                                    <svg width="20" fill="currentColor" height="20" class="hover:text-gray-800 text-gray-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
                                                    </svg>
                                                </a>
                                            </div>

                                        </div>
                                    </li>

                                    <li *ngIf="notificacionRollo" class="flex flex-row">
                                        <div class="hover:bg-gray-50 flex flex-1 items-center p-4">

                                            <div class="flex-1 pl-1">
                                                <div class="font-medium">ROLLO</div>
                                                <div class="text-gray-600 text-sm">Tienes solicitud de rollo, verifica tu panel de inicio</div>
                                            </div>

                                            <div class="flex flex-row justify-center">
                                                <a routerLink="/dashboard/morralla-carril" class="w-10 text-right flex justify-end select-none cursor-pointer">
                                                    <svg width="20" fill="currentColor" height="20" class="hover:text-gray-800 text-gray-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
                                                    </svg>
                                                </a>
                                            </div>

                                        </div>
                                    </li>

                                    <li *ngIf="notificacionFalla" class="flex flex-row">
                                        <div class="hover:bg-gray-50 flex flex-1 items-center p-4">

                                            <div class="flex-1 pl-1">
                                                <div class="font-medium">FALLA</div>
                                                <div class="text-gray-600 text-sm">Tienes notificacion de falla, verifica tu panel de inicio</div>
                                            </div>

                                            <div class="flex flex-row justify-center">
                                                <a routerLink="/dashboard/home" class="w-10 text-right flex justify-end select-none cursor-pointer">
                                                    <svg width="20" fill="currentColor" height="20" class="hover:text-gray-800 text-gray-500" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
                                                    </svg>
                                                </a>
                                            </div>

                                        </div>
                                    </li>


                                </ul>
                            </div>


                        </div>

                        <hr>

                    </div>
                </div>
            </div>

            <div class="modal-actions bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button (click)="closeModal()" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="pr-2 w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg> Cerrar
                </button>
            </div>

        </div>
    </div>
</div>

