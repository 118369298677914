import { Component, OnInit } from '@angular/core';
import { Denominacion, LocalStorageKeys } from '../../models';
import { Morralla } from '../../models/pouch.db';
//import { Rollo } from '../../models/pouch.db';
import { MorrallaService } from '../../services/morralla.service';
import { STATUS_NOTIFICATION, TYPE_NOTIFICATION } from '../../utils/enum';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AppState } from '../../state/app.state';
import { Store } from '@ngrx/store';
import { selectMorralla } from '../../state/selectors/caseta.selectors';
import { RolloService } from 'src/app/services/rollo.service';
import { postMorralla } from '../../state/actions/morralla.actions';
import { Router } from '@angular/router';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { PushNotificationService } from 'src/app/services/push-notification.service';
import { selectHisNotification } from '../../state/selectors/history.notification.selectors';
import { changeStatusMorralla } from '../../state/actions/morralla-status.actions';
import { selectMorrallaStatus } from '../../state/selectors/morralla-status.selectors';
import { MorrallaStatusState } from '../../core/models/morralla-status.state';
import { TypeBoton } from '../../state/actions/panelIzquierdo.actions';
import { OfflineOnlineService } from '../../services/offline-online.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  morralla = [];
  test = true;
  //user
  user: any = null;
  morallasDisponibles$: Observable<Denominacion[]> = new Observable<Denominacion[]>();
  morrallaStatus: boolean = true;
  isOffline = false;

  constructor(
    private morallaService: MorrallaService,
    private store: Store<AppState>,
    private rolloService: RolloService,
    private toastr: ToastrService,
    private router: Router,
    private pushNotService: PushNotificationService,
    private offlineOnlineService: OfflineOnlineService,
    private ngxServiceLoader: NgxUiLoaderService


  ) { }

  ngOnInit(): void {
    this.morallasDisponibles$ = this.store.select(selectMorralla);
    //this.store.select(selectEstatusRollo).subscribe(status => this.statusRollo = status);
    this.store.select(selectUser).subscribe(user => this.user = user);
    this.store.select(selectMorrallaStatus).subscribe(morrallaStatus => {
      this.morrallaStatus = morrallaStatus.status;
    });

    this.offlineOnlineService.connectionChanged.subscribe(estatus => {
      this.isOffline = !estatus;
    });

  }


  async saveAction() {
    if (this.morralla.length == 0) return;

    if (this.isOffline) return;


    let morralla: Morralla = {
      denominacion: this.morralla,
      id_corte_usuario: this.user.id_corte_usuario,
      fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss')
    }
    let result = false
    result = await this.notificacion(TYPE_NOTIFICATION.MORRALLA)
    if (result) {
      this.store.dispatch(postMorralla({ morralla }))
      this.toastr.success('Se registró el evento', '', { positionClass: 'toast-bottom-right' })

      this.clearForm();

      //solicitar firma de JT
      this.store.dispatch(changeStatusMorralla({ status: false }));
    }

  }

  async notificacion(type: TYPE_NOTIFICATION) {
    this.ngxServiceLoader.start()
    try {
      let response = await this.pushNotService.sendNotification({ title: "MOPA", body: `Solicitud de ${type}, Carril ${this.user.nom_carril}`, users: [this.user.id_jefe_turno], type, status: STATUS_NOTIFICATION.NOTIFICADO })
      this.toastr.success(`Notificando ${type}`, '', { positionClass: 'toast-bottom-right' });
      return true
    } catch (error) {
      this.toastr.error('No se encontro conexion a Red', 'Red inestable',  { positionClass: 'toast-bottom-right' });
      return false
    } finally {
      this.ngxServiceLoader.stop()

    }

  }
  morrallaArray(monto) {

    const found = this.morralla.find(element => element == monto)
    if (found) {
      console.log('fond' + found);

      this.morralla.forEach((element, index) => {
        if (element == monto)
          this.morralla.splice(index, 1);
      });
    } else {
      this.morralla.push(monto)
    }
    console.log(this.morralla);
  }

  isSelected(btn) {
    if (btn.classList.contains('selected')) {
      btn.classList.remove('selected');
      btn.classList.remove('dark:bg-blue-800');
      btn.classList.remove('bg-blue-800');
      btn.classList.remove('text-white');
      btn.classList.add('dark:bg-transparent');
    } else {
      btn.classList.add('selected');
      btn.classList.add('dark:bg-blue-800');
      btn.classList.add('bg-blue-800');
      btn.classList.add('text-white');
      btn.classList.remove('dark:bg-transparent');

    }
  }

  isDisabled(btnD, boolean) {
    if (boolean) {
      btnD.classList.add('dark:bg-gray-600');
      btnD.classList.add('bg-gray-300');
      btnD.classList.remove('dark:bg-transparent');
      btnD.setAttribute("disabled", "");
    } else {
      btnD.classList.remove('dark:bg-gray-600');
      btnD.classList.remove('bg-gray-300');
      btnD.classList.add('dark:bg-transparent');
      btnD.removeAttribute("disabled");
    }
  }

  clearForm() {
    this.morralla = [];
    let btns = document.querySelectorAll('.selected');

    for (let i = 0; i < btns.length; i++) {
      btns[i].classList.remove('selected');

      btns[i].classList.remove('dark:bg-blue-800');
      btns[i].classList.remove('bg-blue-800');
      btns[i].classList.remove('text-white');
      btns[i].classList.add('dark:bg-transparent');
    }


  }

  goToFirma() {
    this.router.navigate(['mopa/firma', TypeBoton.MORRALLA])
  }

  isDisabledBtn() {
    return this.isOffline || this.morralla.length == 0;
  }




}
