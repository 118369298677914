<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase font-semibold text-base text-center text-gray-500 dark:text-gray-200">
    <div class="flex flex-col">

        <div class="flex justify-between items-center">
            <div class="flex flex-1 text-sm items-center">
                <span class="font-semibold">Caseta:</span>
                <select (change)="getCaseta($event)" class="appearance-none block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                    <option selected disabled>Seleccionar caseta</option>
                    <option *ngFor="let caseta of usuario.caseta_usuario" value="{{ caseta.id }}">{{ caseta.nombre }}</option>
                </select>
            </div>
            <span class="flex-1 text-gray-500 dark:text-gray-50 py-2 font-bold">Devolucion de fondos</span>
            <span class="flex-1"></span>
        </div>

        <hr class="dark:border-gray-500 mb-4">

        <div class="flex justify-between">

            <div class="flex flex-1 flex-col">

                <div class="flex justify-center text-blue-600 dark:text-blue-50 mb-4">
                    <div class="w-3/4 bg-gray-100 dark:bg-gray-700 rounded text-lg font-bold p-2">
                        <span class="text-lg text-blue-700 dark:text-white">Ingresado: ${{ devolucionFinal | number }}</span>
                    </div>
                </div>

                <div class="flex justify-center text-blue-600 dark:text-blue-50 mb-4">
                    <div class="w-3/4 bg-gray-100 dark:bg-gray-700 rounded text-lg font-bold p-2">
                        <span class="text-lg text-blue-700 dark:text-white">Devolucion: ${{ fondo | number }}</span>
                    </div>
                </div>

                <div class="flex justify-center mb-4">
                    <div class="w-3/4">
                        <button *ngIf="caseta_id && fondo > 0" (click)="saveDevolucion()" class="btn p-2 w-full bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600">Guardar</button>
                    </div>
                </div>

            </div>

            <div class="flex flex-1 flex-col">

                <div class="flex w-full mb-4">
                    <span class="flex-1">Denominacion</span>
                    <span class="flex-1">Cantidad</span>
                    <span class="flex-1">Total</span>
                </div>

                <div *ngFor="let denominacion of denominacionFormato" class="flex space-x-2 mb-2">
                    <input class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 font-bold text-base dark:border-gray-600 dark:bg-slate-800 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" disabled value="{{ denominacion.cantidad | number }}" type="text">

                    <input (change)="devolucionTotal($event, denominacion)" class="flex-1 w-6 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" type="number" min="0">

                    <input [value]="subtotales[denominacion.id] || 0 | number" class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-slate-800 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" disabled type="text">
                </div>

            </div>

            <div class="flex-1 text-center font-bold text-xs">
                <div class="px-4 overflow-y-auto">

                    <table class="border-collapse w-full border border-slate-400 dark:border-slate-500 bg-white dark:bg-slate-800 text-sm shadow-sm">
                        <thead class="bg-slate-50 dark:bg-slate-700">
                            <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">Carril</th>
                            <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">Fecha Hora</th>
                            <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">Cantidad</th>
                        </thead>

                        <tbody *ngIf="devolucionCarril.length > 0; else noDevoluciones" class="font-semibold text-xs">
                            <tr *ngFor="let devcarril of devolucionCarril">
                                <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">{{ devcarril.nombre }}</td>
                                <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">{{ devcarril.fecha_hora }}</td>
                                <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">${{ devcarril.total | number }}</td>
                            </tr>
                        </tbody>

                        <ng-template #noDevoluciones>
                            <tbody class="font-semibold text-xs">
                                <td colspan="3" class="py-4">
                                    Sin devoluciones de carril, seleccione una caseta para obtener informacion
                                </td>
                            </tbody>
                        </ng-template>

                    </table>

                </div>

                <div class="flex flex-col mt-4 space-y-2">
                    <div class="w-3/4 mx-auto">
                        <button (click)="ticketFondoModal = true" class="btn p-2 w-full bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600">Ticket fondo cabina</button>
                    </div>
    
                    <div class="w-3/4 mx-auto">
                        <button (click)="ticketEntregaModal = true" class="btn p-2 w-full bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600">Ticket fondo entrega</button>
                    </div>

                    <div class="w-3/4 mx-auto">
                        <button (click)="imprimirPDF()" class="btn p-2 w-full bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600">Documento de fondo inicial</button>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>

<!-- Ticket fondo actual carril -->
<div *ngIf="ticketFondoModal" class="modal-component-container fixed inset-0 overflow-y-auto">
    <div
      class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>
  
      <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>
  
      <div
        class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
        <div class=" modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
  
          <div class="modal-wrapper-flex sm:flex sm:items-start">
  
            <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">

                <div class="flex flex-col justify-center mx-auto text-black font-normal text-center text-lg uppercase w-auto">

                    <img aria-hidden="true" class="mx-auto h-12 w-auto mb-2" src="/assets/images/sogak-logo-light.svg" alt="SOGAK"/>
                    <span class="text-lg">{{ dateTime | date:'dd/MM/yyyy' }}</span>
                    <span class="text-lg">Comprobante</span>
                    <span class="text-lg">de fondo actual</span>
                    <span class="text-lg">en cabinas</span>
                    <span>TURNO: {{ turno?.name }}</span>
                    <span>JT: {{ usuario.nombre }}</span>
                    <span>{{ usuario.apellido_paterno }} {{ usuario.apellido_materno }}</span>
                  
                    <table class="table-fixed text-justify w-max mx-auto mt-4">
                        <thead class="border-y-2 border-black">
                          <tr>
                            <th>CABINA</th>
                            <th class="w-4">&nbsp;</th>
                            <th>FONDO INICIAL</th>
                          </tr>
                        </thead>
                        <tbody *ngIf="fondoCarrilActual && fondoCarrilActual.length > 0">
                            <tr *ngFor="let fca of fondoCarrilActual" class="border-b-2 border-black">
                                <td>{{ fca?.nombre }}</td>
                                <th class="w-4">&nbsp;</th>
                                <td>${{ fca?.fondo || 0 | number }}</td>
                            </tr>
                            <tr>
                                <td>Total: </td>
                                <th class="w-4">&nbsp;</th>
                                <td>${{ fondoCarrilSuma || 0 | number }}</td>
                                <!-- <td>${{ trasladoTotal | number }}</td> -->
                            </tr>
                        </tbody>
                    </table>
                  
                    <button (click)="imprent()" class="oculto-impresion inline-flex items-center w-full h-12 p-2 mt-4 font-semibold uppercase bg-gray-50 rounded border-2 border-gray-300 drop-shadow-lg hover:border-red-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                          </svg>
                  
                        <span class="ml-2 w-full">Imprimir</span>
                    </button>

                  </div>
                  
  
            </div>
          </div>
        </div>
  
        <div class="oculto-impresion modal-actions bg-gray-50 px-4 py-3 sm:px-6 flex flex-col">
            <div class="flex justify-end">
                <button (click)="ticketFondoModal = false"
                        class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    Cerrar
                </button>
            </div>
        </div>
  
      </div>
    </div>
</div>

<!-- Ticket fondo entrega carril -->
<div *ngIf="ticketEntregaModal" class="modal-component-container fixed inset-0 overflow-y-auto">
    <div
      class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>
  
      <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>
  
      <div
        class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
        <div class=" modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
  
          <div class="modal-wrapper-flex sm:flex sm:items-start">
  
            <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">

                <div class="flex flex-col justify-center mx-auto text-black font-normal text-center text-lg uppercase w-auto">

                    <img aria-hidden="true" class="mx-auto h-12 w-auto mb-2" src="/assets/images/sogak-logo-light.svg" alt="SOGAK"/>
                    <span class="text-lg">{{ dateTime | date:'dd/MM/yyyy' }}</span>
                    <span class="text-lg">Comprobante de</span>
                    <span class="text-lg">entrega de fondo</span>
                    <span class="text-lg">en cabinas</span>
                    <span>TURNO: {{ turno?.name }}</span>
                    <span>JT: {{ usuario.nombre }}</span>
                    <span>{{ usuario.apellido_paterno }} {{ usuario.apellido_materno }}</span>
                  
                    <table class="table-fixed text-justify w-max mx-auto mt-4">
                        <thead class="border-y-2 border-black">
                          <tr>
                            <th>CABINA / FECHA ENTREGA</th>
                            <!-- <th class="w-4">&nbsp;</th>
                            <th>FECHA ENTREGA</th> -->
                          </tr>
                        </thead>
                        <tbody *ngIf="fondoEntregaData && fondoEntregaData.length > 0">
                            <tr *ngFor="let fed of fondoEntregaData" class="border-b-2 border-black">
                                <td>{{ fed?.nombre }} <br> {{ fed?.fecha_hora }}
                                
                                </td>
                                <!-- <th class="w-4">&nbsp;</th>
                                <td>{{ fed?.fecha_hora }}</td> -->
                            </tr>
                        </tbody>
                    </table>
                  
                    <button (click)="imprent()" class="oculto-impresion inline-flex items-center w-full h-12 p-2 mt-4 font-semibold uppercase bg-gray-50 rounded border-2 border-gray-300 drop-shadow-lg hover:border-red-600">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                          </svg>
                  
                        <span class="ml-2 w-full">Imprimir</span>
                    </button>

                  </div>
                  
  
            </div>
          </div>
        </div>
  
        <div class="oculto-impresion modal-actions bg-gray-50 px-4 py-3 sm:px-6 flex flex-col">
            <div class="flex justify-end">
                <button (click)="ticketEntregaModal = false"
                        class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    Cerrar
                </button>
            </div>
        </div>
  
      </div>
    </div>
</div>