<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase font-semibold text-base text-center text-gray-500 dark:text-gray-200">
    <div class="flex flex-col">

        <div class="flex justify-between items-center">
            <div class="flex flex-1 text-sm items-center">
                <span class="font-semibold">Caseta:</span>
                <select (change)="getCaseta($event)" class="appearance-none block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                    <option selected disabled>Seleccionar caseta</option>
                    <option *ngFor="let caseta of usuario.caseta_usuario" value="{{ caseta.id }}">{{ caseta.nombre }}</option>
                </select>
            </div>
            <span class="flex-1 text-gray-500 dark:text-gray-50 py-2 font-bold">Pago de morralla</span>
            <span class="flex-1"></span>
        </div>

        <hr class="dark:border-gray-500 mb-4">

        <div class="flex justify-between">

            <div class="flex flex-1 flex-col">


                <div *ngIf="pagaMorralla" class="flex justify-center text-blue-600 dark:text-blue-50 mb-4">
                    <div class="w-3/4 bg-gray-100 dark:bg-gray-700 rounded text-lg font-bold p-2">
                        <span>Pagar: $ </span>
                        <span>{{ pagaMorralla | number }}</span>
                    </div>
                </div>

                <div class="flex justify-center text-blue-600 dark:text-blue-50 mb-4">
                    <div class="w-3/4 bg-gray-100 dark:bg-gray-700 rounded text-lg font-bold p-2">
                        <span>Total: $ </span>
                        <span>{{ morrallaFinal | number }}</span>
                    </div>
                </div>

                <div class="flex justify-center mb-4">
                    <div class="w-3/4">
                        <button (click)="saveMorralla()" class="btn p-2 w-full bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600">Pagar morralla</button>
                    </div>
                </div>

            </div>

            <div class="flex flex-1 flex-col">

                <div class="flex w-full mb-4">
                    <span class="flex-1">Denominacion</span>
                    <span class="flex-1">Cantidad</span>
                    <span class="flex-1">Total</span>
                </div>

                <div *ngFor="let denominacion of denominacionFormato" class="flex space-x-2 mb-2">
                    <input class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 font-bold text-base dark:border-gray-600 dark:bg-slate-800 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" disabled value="{{ denominacion.cantidad | number }}" type="text">

                    <input (change)="morrallaTotal($event, denominacion)" class="flex-1 w-6 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" type="number" min="0">

                    <input [value]="subtotales[denominacion.id] || 0 | number" class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-slate-800 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" disabled type="text">
                </div>

            </div>

            <div class="flex-1 text-center font-bold text-xs">
                <div class="px-4 overflow-y-auto">

                </div>
            </div>

        </div>

    </div>
</div>