import {createReducer, on} from '@ngrx/store';
import {NotificationState} from '../../core/models/notification.state';
import {clearNotification, loadedNotification} from '../actions/notification.actions';


export const initialState: NotificationState = {id : 0, status: null, type : null};


export const notificationReducer = createReducer(
  initialState,
  on(loadedNotification, (state, {notification}) => {
    return {...state, ...notification};
  }),
  on(clearNotification, () => {
    return initialState;
  })
);
