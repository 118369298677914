import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ControlFolio } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ControlRolioService {


  private ENDPOINT = environment.ENDPOINTS.CONTROLFOLIO;

  constructor( private http: HttpClient ) {}


  saveControlFolio( data : any ) {
    return this.http.post<ControlFolio>(this.ENDPOINT, data);
  }

}
