<div class="flex flex-row space-x-4 text-blue-700 text-center text-xs">

    <div class="flex flex-col flex-1 space-y-4">

        <span class="bg-blue-200 rounded-md p-2 font-bold">
            Folio de boletos consumidos
        </span>

        <table class="text-center text-gray-600 dark:text-gray-300">
            <thead>
                <tr>
                    <th scope="col" class="pb-2">No.</th>
                    <th scope="col" class="pb-2">Inicia</th>
                    <th scope="col" class="pb-2">Final</th>
                    <th scope="col" class="pb-2">Totales</th>
                    <th scope="col" class="pb-2">Editar Folios</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let rollo of rollos">
                    <td>{{ rollo.rollo }}</td>
                    <td>{{ rollo.folio_inicial }}</td>
                    <td>{{ rollo.folio_final }}</td>
                    <td>{{ (rollo.folio_final - rollo.folio_inicial) + 1 }}</td>
                    <td>
                        <button (click)="editFolioModal = true; getRolloId(rollo.id)" type="button" class="bg-gray-50 inline-flex items-center dark:bg-transparent rounded p-1 text-xs text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 uppercase">
                            <span>Editar</span>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <span class="bg-blue-200 rounded-md p-2 font-bold">
            Recepcion del fondo inicial
        </span>

        <div class="flex justify-around text-gray-600 dark:text-gray-300">
            <span>Recibe fondo cobrador</span>
            <span>${{ fondoCobrador | number }}</span>
        </div>

        <span class="bg-blue-200 rounded-md p-2 font-bold">
            Concentrado de aforo
        </span>

        <div class="flex flex-row justify-around text-gray-600 dark:text-gray-300">

            <div class="flex flex-col">
                <span>Aforo pagado</span>
                <span>Boletos recibidos</span>
                <span>Exentos / Residentes</span>
                <span>Eludidos</span>
                <span>Aforo total</span>
            </div>

            <div class="flex flex-col">
                <span>{{ aforoTotal }}</span>
                <span>{{ boletoRecibido }}</span>
                <span>{{ exentoResidente }}</span>
                <span>{{ eludido }}</span>
                <span>{{ aforoFinal }}</span>
            </div>

        </div>

    </div>

    <div class="flex flex-col flex-1 space-y-4">

        <span class="bg-blue-200 rounded-md p-2 font-bold">
            Resumen de folios consumidos
        </span>

        <div class="flex flex-row justify-around text-gray-600 dark:text-gray-300">

            <div class="flex flex-col">
                <span>Total de boletos</span>
                <span>+ Boletos no emitidos</span>
                <span>- Boletos cancelados</span>
                <span>Total de aforo</span>
            </div>

            <div class="flex flex-col">
                <span>{{ folioTotal }}</span>
                <span>{{ noEmitidos || 0 }}</span>
                <span>{{ cancelados || 0 }}</span>
                <span>{{ aforoTotal | number }}</span>
            </div>

        </div>

        <span class="bg-blue-200 rounded-md p-2 font-bold">
            Resumen de efectivo
        </span>

        <div class="flex flex-row justify-around text-gray-600 dark:text-gray-300">

            <div class="flex flex-col">
                <span>Total de retiros parciales</span>
                <span>+ Ultimo efectivo</span>
                <span>Total recaudado</span>
            </div>

            <div *ngFor="let ef of efectivo" class="flex flex-col">
                <span>${{ ef.retiro_parcial || 0 | number }}</span>
                <span>${{ ef.ultimo_efectivo || 0 | number }}</span>
                <span>${{ (ef.retiro_parcial + ef.ultimo_efectivo) || 0 | number }}</span>
            </div>

        </div>

    </div>

</div>


<div *ngIf="editFolioModal" class="modal-component-container fixed inset-0 overflow-y-auto">
    <div class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>
        <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>
    
        <div class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">

            <div class="modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="modal-wrapper-flex sm:flex sm:items-start">
                    <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">
                        <div class="flex items-center justify-between">
                            <div [formGroup]="foliosForm" class="flex items-center flex-col space-y-2 rounded py-1 w-full">
                                <span class="text-base font-semibold">Edicion de folios</span>

                                <div class="w-full">
                                    <span>Folio Inicial</span>
                                    <input formControlName="folioInicial" type="text" maxlength="8" class="w-full text-center block py-2 font-normal text-gray-700 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"/>
                                </div>

                                <div class="w-full">
                                    <span>Folio Final</span>
                                    <input formControlName="folioFinal" type="text" maxlength="8" class="w-full text-center block py-2 font-normal text-gray-700 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="modal-actions bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button (click)="editFolioModal = false"
                class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    Cerrar
                </button>
                <button (click)="updateFolios()"
                        class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    Aceptar
                </button>
            </div>
    
        </div>
    </div>
</div>