import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {retry} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HealthService {

  constructor(private http: HttpClient) { }

  getConnection(){
    return this.http.get(environment.ENDPOINTS.HEALTH)
      .pipe(
        retry(3)
      );
  }
}
