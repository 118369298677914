<!-- <div class="header"> -->

<div class="grid grid-cols-9 gap-4 border-solid	border border-gray-300 dark:border-gray-500 rounded m-2 p-2 text-lg font-semibold uppercase text-gray-600 dark:text-white text-center items-center">

    <span>{{usuario?.nom_caseta}}</span>
    <span>{{usuario?.nom_carril}}</span>
    <span>T {{usuario?.turno}}<br>{{usuario?.id_corte_usuario}}</span>
    <span>{{usuario?.nom_user}}</span>
    <span>{{ time | date: 'dd/MM/YYYY' }}</span>
    <span>{{ time | date: 'hh:mm:ss a' }}</span>
    <button *ngIf="hasAnyDevolution()" class="bg-red-600 w-full text-lg uppercase dark:bg-red-600 rounded py-1 text-white dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-red-600" [disabled]="isOffline" [class.dark:bg-gray-600] = "isOffline"
            [class.bg-gray-300] ="isOffline"
            [class.dark:bg-transparent] ="!isOffline" [style.cursor]="isOffline ? 'not-allowed' : 'pointer' "   (click)="cerrarSesion()">Corte</button>

    <div class="grid grid-cols-3 place-items-center">
        <button>
          <!-- icon cuando no existe conexión -->
          <svg *ngIf="isOffline" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
          </svg>
          <!-- icon cuando  existe conexión -->
            <svg *ngIf="!isOffline" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
            </svg>
        </button>

        <button>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>
        </button>

        <span class="group cursor-pointer relative inline-block border-b border-gray-400 text-center">{{morrallaCount+btnRapidoCount+crucesCount+devolucionCount+retiroCount+rolloCount}}
          <div class="w-36 opacity-0 bg-black text-white text-center -left-18 top-9 text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 px-3 pointer-events-none">
            <div class="flex flex-col text-left">
              <span>Morralla: {{morrallaCount}}</span>
              <span>Botones Rapidos: {{btnRapidoCount}}</span>
              <span>Cruces: {{crucesCount}}</span>
              <span>Devolucion: {{devolucionCount}}</span>
              <span>Retiro: {{retiroCount}}</span>
              <span>Rollo: {{rolloCount}}</span>
            </div>
          </div>
        </span>
    </div>


    <div class="flex justify-end">
        <button *ngIf="isDarkEnable" (click)="changeTheme(1)" id="modeLight" class="border rounded px-3 py-1 shadow-sm shadow-yellow-500">
            <svg class="w-5 h-5" aria-hidden="true" fill="#fcd34d" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>

          <button *ngIf="!isDarkEnable" (click)="changeTheme(2)" id="modeDark" class="ml-2 border rounded px-3 py-1 shadow-sm shadow-sky-500">
            <svg class="w-5 h-5" aria-hidden="true" fill="#d1d5db" viewBox="0 0 20 20">
              <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
            </svg>
          </button>
    </div>


</div>





<!-- </div> -->
