import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { CasetaService } from '../../services/caseta.service';
import { selectUser } from '../selectors/user.selectors';

@Injectable()
export class CasetaEffects {
user : any = null
  loadCaseta$ = createEffect(() => this.actions$.pipe(
      ofType('[Caseta-GET] Load Caseta'),
      mergeMap(() => this.casetaService.getCaseta(this.user.id_caseta)
        .pipe(
          map(caseta => ({ type: '[Caseta-GET] Loaded success', caseta })),
          catchError(() => EMPTY)
        ))
    )
  );

  constructor(
    private actions$: Actions,
    private casetaService: CasetaService,
    private store: Store<any>,

  ) {
    this.store.select(selectUser).subscribe(user => this.user = user);

  }
}
