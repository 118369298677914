<div
  class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase font-semibold text-base text-center text-gray-500 dark:text-gray-200">

  <div class="flex flex-col">
    <div class="flex justify-between items-center">
      <span></span>
      <span class="text-gray-500 dark:text-gray-50 py-2 font-bold">Ingresar ultimo efectivo</span>
      <span></span>
    </div>
    <hr class="mb-2 dark:border-gray-500">

    <div class="flex justify-between">

      <div class="flex flex-1 flex-col">

        <div *ngIf='user.tipoUsuario.id==22' class="flex items-center mx-auto mb-2">
          <span class="text-sm font-bold dark:text-gray-300 mr-4">Actualizar montos de cortes</span>
          <input class="w-4 h-4 mt-0.5 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" type="checkbox" (change)="updateMontosCortes()">
        </div>

        <div *ngIf="hiddenMontos" class="flex flex-1 flex-col">
          <div class="flex flex-row justify-around">
            <form [formGroup]="formSaveMonto">
              <div>
                <select formControlName="id_corte_usuario"
                  class="mt-2 form-select appearance-none block p-3 mb-4 text-sm font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                  <option value="" selected disabled>Seleccionar corte de usuario</option>
                  <option *ngFor="let corte of cortes" value="{{corte.id}}">
                    {{corte.carril.nombre}}-{{corte.fecha_hora_inicio}}-{{corte.fecha_hora_fin}}</option>
                </select>

                <label class="block text-sm">
                  <span class="text-gray-700 dark:text-gray-400 sr-only">CANTIDAD $</span>
                  <input formControlName="monto" disabled min="1" autocomplete="off" required
                    class="border rounded border-gray-300 placeholder-gray-500 px-3 py-2 block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray h-12 form-input"
                    placeholder="Monto total" type="text" />
                </label>

                <button (click)="saveMonto()"
                class="flex items-center mt-6 p-2 bg-gray-50 text-xs uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 hover:border-blue-600">

                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                  </svg>

                  <span class="ml-2">
                    Guardar ultimo efectivo
                  </span>

                </button>

              </div>

            </form>
          </div>
        </div>

        <div *ngIf="!hiddenMontos" class="flex flex-1 flex-col">
          <div class="flex flex-row justify-around">
            <form [formGroup]="formUpdateMonto">
              <div>
                <select formControlName="id_retiro"
                  class="mt-2 form-select appearance-none block p-3 mb-4 text-sm font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                  <option value="" selected disabled>Seleccionar corte a actualizar</option>
                  <option *ngFor="let corte of cortesPagados" value="{{corte.id_retiro}}">
                    {{corte.monto}}-{{corte.fecha_hora_inicio}}-{{corte.fecha_hora_fin}}</option>
                </select>

                <label class="block text-sm">
                  <span class="text-gray-700 dark:text-gray-400 sr-only">CANTIDAD $</span>
                  <input formControlName="monto" disabled min="1" autocomplete="off" required
                    class="border rounded border-gray-300 placeholder-gray-500 px-3 py-2 block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray h-12 form-input"
                    placeholder="Ingrese Monto" type="text" />
                </label>

                <button (click)="updateMonto()"
                class="flex items-center mt-6 p-2 bg-gray-50 text-xs uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 hover:border-blue-600">

                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                  </svg>

                  <span class="ml-2">
                    Actualizar ultimo efectivo
                  </span>

                </button>

              </div>

            </form>
          </div>
        </div>

      </div>

      <div class="flex flex-1 flex-col">
        <div class="flex w-full mb-4">
          <span class="flex-1">Denominacion</span>
          <span class="flex-1">Cantidad</span>
          <span class="flex-1">Total</span>
        </div>

        <div *ngFor="let denominacion of denominacionFormato" class="flex space-x-2 mb-2">
          <input
            class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 font-bold text-base dark:border-gray-600 dark:bg-slate-800 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center"
            disabled value="{{ denominacion.cantidad | number }}" type="text">

          <input (change)="montoTotal($event, denominacion)"
            class="montoUF flex-1 w-6 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center"
            type="number" min="0">

          <input [value]="subtotales[denominacion.id] || 0 | number"
            class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-slate-800 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center"
            disabled type="text">
        </div>

      </div>

      <div class="flex-1">
        &nbsp;
      </div>

    </div>

  </div>
</div>
