import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {RequestSendPush} from '../models/request';
import {NotificacionesPushService} from './notificaciones-push.service';
import {AppState} from '../state/app.state';
import {Store} from '@ngrx/store';
import {selectUser} from '../state/selectors/user.selectors';
import {clearNotification, loadedNotification} from '../state/actions/notification.actions';
import {STATUS_NOTIFICATION} from '../utils/enum';
import {selectNotFeature, selectNotification} from '../state/selectors/notification.selector';
import {addNotification} from '../state/actions/history.notification.actions';
import {selectHisNotification} from '../state/selectors/history.notification.selectors';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor(
    private http: HttpClient,
    private notificacionesPush: NotificacionesPushService,
    private store: Store<AppState>
  ) {
  }

  saveToken(body, idUser: number) {
    return this.http.post(`${environment.ENDPOINTS.PUSH_NOTIFICATION}/save/${idUser}`, body);
  }

  async sendNotification(request: RequestSendPush) {
    if(request.status === STATUS_NOTIFICATION.CANCELADO){
      return this.canceledNotification(request)
    }

    if(request.status === STATUS_NOTIFICATION.NOTIFICADO){
      //save
     return this.save(request);
    }
    //send

    //return this.http.post(`${environment.ENDPOINTS.PUSH_NOTIFICATION}/send`, request);

  }

  async canceledNotification(request: RequestSendPush){
      //search not to cancelled
      let not = await this.getHistoryNotification()
      let notification = not.find( not => not.type == request.type);
      if(!notification){
        console.log('Any notification cancelled');
        return;
      }
      const {id} = notification;
      console.log("ID TO UPDATE NOT " + id)
      try{
        if(id != 0){
          let resp = await this.notificacionesPush.updateStatus(id, STATUS_NOTIFICATION.CANCELADO).toPromise()
          console.log('UPDATED NOT '+ id);
          //clean state
          this.store.dispatch(clearNotification())
        }

      }catch (err){
        console.log('ERR -> pushnot-service -> canceledNotification',err)
        throw new Error("pushnot-service -> canceledNotification");

      }

  }

  async save(request: RequestSendPush) {
    const {title, body, type} = request;
    const {id_corte_usuario} = await this.getActualUser() || {};
    try {
      //save to database
      let resp : any = await this.notificacionesPush.saveNotification({title, body, id_corte_usuario, type}).toPromise();
      console.log(resp);
      //save last notification
      this.store.dispatch(loadedNotification({notification:{id: resp.id, type: resp.type, status: resp.estatus}}))
      this.store.dispatch(addNotification({notification: {...resp}}))
    } catch (err) {
      console.log(err);
      throw new Error("pushnot-service -> saveNotification");

    }

  }

  getActualUser() {
    return new Promise<any>((resolve, reject) => {
      this.store.select(selectUser).subscribe(user => resolve(user));
    });
  }

  getHistoryNotification(){
      //get history notfications
    return new Promise<any>((resolve,reject)=>{
      this.store.select(selectHisNotification).subscribe(async (not)=>{
        resolve(not)
      })
    })



  }
}
