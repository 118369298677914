import { createSelector } from '@ngrx/store';
import {AppState} from '../app.state';
import {BtnRapidoState} from '../../core/models/btnRapido.state';

export const selectBtnRapidoFeature = (state: AppState) => state.btnRapido;

export const selectBtnRapido = createSelector(
  selectBtnRapidoFeature,
  (state: BtnRapidoState) => state
);
