import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { TipoVehiculo } from '../models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TipoVehiculoService {

  private ENDPOINT = environment.ENDPOINTS.TIPOVEHICULO;
  constructor(private http:HttpClient) { }



  getTipoVehiculo(){
    return this.http.get<TipoVehiculo[]>(this.ENDPOINT);
  }
}
