<div class="grid grid-cols-3 mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase text-gray-500">

  <div class="m-2">

    <div class="flex flex-col font-bold text-sm text-center">
      <span *ngIf="!botonConfirmar" class="text-gray-500 dark:text-gray-50 text-base ">Fondo de operacion</span>
      <hr *ngIf="!botonConfirmar" class="mb-2 dark:border-gray-500">
      
      <div *ngIf="!botonConfirmar" class="grid grid-cols-3 grid-flow-row gap-1 bg-gray-200 dark:bg-gray-900/50 rounded p-2 mb-2 select-none">

        <span class="text-gray-500 dark:text-gray-200">Denominacion</span>
        <span class="text-gray-500 dark:text-gray-200">Cantidad</span>
        <span class="text-gray-500 dark:text-gray-200">Total</span>

        <span *ngIf="denominaciones.length < 1" class="col-span-3 py-4 text-gray-500 text-center text-base underline dark:text-white">Sin fondo de operacion</span>

        <div *ngFor="let denominacion of denominaciones" class="col-span-3 grid grid-cols-3 grid-flow-row gap-1 font-semibold">
          <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ denominacion.cantidad | number }}</span>
          <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">{{ denominacion.pivot.cantidad | number }}</span>
          <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ (denominacion.cantidad * denominacion.pivot.cantidad) | number }}</span>
        </div>

        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Total:</span>
        <span class="col-span-2 bg-gray-50 dark:bg-gray-300 rounded py-1">${{ bovedaTotal | number }}</span>

      </div>

      <div *ngIf="!botonConfirmar" class="flex flex-col font-semibold text-sm text-center">
        <span class="text-gray-500 dark:text-gray-50 font-bold text-base">Cabinas de cobro</span>
        <hr class="mb-2 dark:border-gray-500">

        <div class="grid grid-cols-2 grid-flow-row gap-1 bg-gray-200 dark:bg-gray-900/50 rounded p-2">
          <span class="font-bold dark:text-gray-200">Cabina</span>
          <span class="font-bold dark:text-gray-200">Fondo</span>
          <div *ngFor="let cabina of cabina.troncal" class="col-span-2 grid grid-cols-2 grid-flow-row gap-1 font-semibold">
            <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">{{ cabina.nombre }}</span>
            <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ cabina.fondo || 0 | number}}</span>
          </div>
          <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Total:</span>
          <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ cabina.troncalFondo | number }}</span>
        </div>

        <div class="grid grid-cols-2 grid-flow-row gap-1 mt-2 bg-gray-200 dark:bg-gray-900/50 rounded p-2">
          <span class="font-bold dark:text-gray-200">Auxiliar</span>
          <span class="font-bold dark:text-gray-200">Fondo</span>
          <div *ngFor="let cabina of cabina.auxiliar" class="col-span-2 grid grid-cols-2 grid-flow-row gap-1 font-semibold">
            <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">{{ cabina.nombre }}</span>
            <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ cabina.fondo || 0 | number}}</span>
          </div>
          <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Total:</span>
          <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ cabina.auxiliarFondo | number }}</span>
        </div>

      </div>

      <span class="text-gray-500 dark:text-gray-50 text-base ">Resumen revision</span>
      <hr class="mb-2 dark:border-gray-500">

      <div [formGroup]="revResumenForm" class="grid grid-cols-2 grid-flow-row gap-1 items-center bg-gray-200 dark:bg-gray-900/50 rounded p-2 font-semibold select-none">
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Fondo auxiliar</span>
        <input formControlName="auxiliar" #inputAux id="cresumAux" (change)="comprobacionResumen($event, inputAux)" class="border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:outline-none form-input text-center" type="number" min="0">
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Morralla para cabina</span>
        <input formControlName="morralla" #inputMo id="cresumMorralla" (change)="comprobacionResumen($event, inputMo)" class="border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:outline-none form-input text-center" type="number" min="0">
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">En transito para Morralla</span>
        <input formControlName="transito" #inputTran id="cresumTransito" (change)="comprobacionResumen($event, inputTran)" class="border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:outline-none form-input text-center" type="number" min="0">
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Vales pendientes cobro</span>
        <input formControlName="vale" #inputVale id="cresumVale" (change)="comprobacionResumen($event, inputVale)" class="border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:outline-none form-input text-center" type="number" min="0">
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Asaltos</span>
        <input formControlName="asalto" #inputAsal id="cresumAsalto" (change)="comprobacionResumen($event, inputAsal)" class="border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:outline-none form-input text-center" type="number" min="0">
      </div>

    </div>

  </div>

  <div class="m-2">

    <div class="flex flex-1 flex-col">

      <span class="text-gray-500 dark:text-gray-50 text-base font-bold text-center">Revision de fondo de operacion</span>
      <hr class="mb-2 dark:border-gray-500">

      <div class="flex w-full dark:text-gray-200 mb-1 font-bold text-sm text-center">
          <span class="flex-1">Denominacion</span>
          <span class="flex-1">Cantidad</span>
          <span class="flex-1">Total</span>
      </div>

      <div *ngFor="let denominacion of denominaciones" class="flex space-x-1 mb-1">
          <input class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 font-semibold text-sm dark:border-gray-600 dark:bg-slate-800 dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" disabled value="${{ denominacion.cantidad | number }}" type="text">

          <input #input id="a{{ denominacion.id }}" (change)="comprobacionTotal($event, denominacion, input)" class="flex-1 w-6 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:outline-none dark:text-gray-300 form-input text-center" type="number" min="0">

          <input [value]="subtotales[denominacion.id] || 0 | number" class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm dark:border-gray-600 dark:bg-slate-800 dark:text-gray-300 form-input text-center" disabled type="text">
      </div>

      <div class="flex dark:text-gray-200 font-bold text-sm text-center">
        <span class="flex-1">Total:</span>
        <span class="flex-1"></span>
        <span class="flex-1">${{ bovedaCapturado | number }}</span>
      </div>

      <hr class="my-2 dark:border-gray-500">

    </div>

  </div>

  <div class="m-2">

    <div class="flex flex-col font-bold text-sm text-center">
      <span class="text-gray-500 dark:text-gray-50 text-base">Resumen</span>
      <hr class="mb-2 dark:border-gray-500">

      <div id="resumenSistema" class="grid grid-cols-2 grid-flow-row gap-1 items-center bg-gray-200 dark:bg-gray-900/50 rounded p-2 font-semibold select-none">
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Morralla en boveda</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ resumen.bovedaTotal | number }}</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Fondo cabina</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ resumen.troncalFondo | number }}</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Fondo auxiliar</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ resumen.auxiliarFondo | number }}</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Fondo turno siguiente</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ resumen.preasignacionFondo | number }}</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Morralla para cabina</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ resumen.morrallaSolicitada | number }}</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">En transito para Morralla</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-3.5">${{ resumen.trasladoTransito | number }}</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Vales pendientes cobro</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ resumen.faltanteCobrador | number }}</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Faltante de fondo</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ resumen.faltanteFondo | number }}</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Caja de seguridad</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ resumen.trasladoCaja | number }}</span> 
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Asaltos</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ resumen.asalto | number }}</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Total:</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ resumen.total | number }}</span> 
      </div>

      <span *ngIf="!botonConfirmar" class="text-gray-500 dark:text-gray-50 text-base">Total</span>
      <hr *ngIf="!botonConfirmar" class="mb-2 dark:border-gray-500">

      <div *ngIf="!botonConfirmar" class="grid grid-cols-2 grid-flow-row gap-1 items-center bg-gray-200 dark:bg-gray-900/50 rounded p-2 font-semibold select-none">
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Depositos para Morralla</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ resumen.trasladoTransito | number }}</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Quedan</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ resumen.totalSinTraslado | number }}</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">Total en boveda</span>
        <span class="bg-gray-50 dark:bg-gray-300 rounded py-1">${{ resumen.total | number }}</span>
      </div>

    </div>

    <div class="flex justify-between mt-8">

      <button *ngIf="!botonConfirmar" class="flex bg-gray-50 w-auto px-2 uppercase dark:bg-transparent rounded h-auto text-base py-1 text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 dark:border-gray-500 drop-shadow-lg hover:border-blue-600 dark:hover:border-blue-600" (click)="openModal()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 pr-2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
        </svg> Arqueo
      </button>

      <button *ngIf="botonConfirmar && subtotales.length === 30 && revResumenForm.valid" class="flex bg-gray-50 w-auto px-2 uppercase dark:bg-transparent rounded h-auto text-base py-1 text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 dark:border-gray-500 drop-shadow-lg hover:border-blue-600 dark:hover:border-blue-600" (click)="verificarFondo()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 pr-2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
        </svg> Verificar
      </button>

      <button *ngIf="!botonConfirmar" class="flex bg-gray-50 w-auto px-2 uppercase dark:bg-transparent rounded h-auto text-base py-1 text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 dark:border-gray-500 drop-shadow-lg hover:border-blue-600 dark:hover:border-blue-600" (click)="generarFormatoPDF(3, 'Descargar')">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 pr-2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
        </svg> Descargar
      </button>
    </div>

  </div>

</div>

<!-- modal -->
<div *ngIf="arqueoModal" class="modal-component-container fixed inset-0 overflow-y-auto">
  <div class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>

      <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>

      <div class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
          <div class="modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

              <div class="modal-wrapper-flex sm:flex sm:items-start">

                  <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">

                      <div class="flex items-center justify-between">
                          <div class="flex items-center rounded py-2">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6z" />
                              </svg>                                  
                              <span class="pl-2">ARQUEO</span>
                          </div>
                          <div class="flex items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                              </svg>                                  
                              <span class="pl-2">{{ arqueoFechaModal }}</span>
                          </div>
                      </div>

                      <hr>

                      <div [formGroup]="arqueoForm" class="space-y-3">

                        <!-- <div class="flex justify-center">
                          <select formControlName="cabina" class="appearance-none w-full block p-2 text-sm font-semibold uppercase text-gray-500 bg-white  bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                            <option value="" selected disabled>Seleccionar cabina</option>
                            <option *ngFor="let cobrador of cobradoresActivos" value="{{ cobrador.id_carril }}">{{ cobrador.carril_nombre }}</option>
                          </select>
                        </div> -->

                        <input formControlName="solicitoNombre" type="text" name="solicitoNombre" id="solicitoNombre" class="w-full block border rounded border-gray-300 placeholder-gray-500 p-2 text-sm focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue text-left" placeholder="Solicitante">

                        <textarea formControlName="observacion" class="w-full resize-y text-sm p-2 text-gray-500 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" rows="4" placeholder="Observaciones"></textarea>

                      </div>

                      <hr>

                  </div>
              </div>
          </div>

          <div class="modal-actions bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

              <button (click)="saveArqueo()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-blue-700 font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="pr-2 w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0120.25 6v12A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18V6A2.25 2.25 0 016 3.75h1.5m9 0h-9" />
                </svg> Guardar
              </button>

              <button (click)="closeModal()" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="pr-2 w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg> Cerrar
              </button>
          </div>

      </div>
  </div>
</div>

<!-- modal -->
<div *ngIf="isFondoFaltante || isFondoSobrante" class="modal-component-container fixed inset-0 overflow-y-auto">
  <div class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>

      <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>

      <div class="modal-container inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
          <div class="modal-wrapper bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

              <div class="modal-wrapper-flex sm:flex sm:items-start">

                  <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">

                      <div class="flex items-center justify-between">
                          <div class="flex items-center rounded py-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                            </svg>
                            <span class="pl-2 font-bold">DIFERENCIA EN FONDO</span>
                          </div>
                          
                          <div class="flex items-center">                              
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                              </svg>   
                              
                              <span class="pl-2 font-bold">${{ diferenciaFondo | number }}</span>
                          </div>
                      </div>

                      <hr>

                      <div class="space-y-3 text-center">

                        <div [formGroup]="bitacoraForm" class="flex flex-col">

                          <div class="flex justify-center">
                            <!-- <button *ngIf="isFondoFaltante || isFondoSobrante" (click)="downloadActaAdministrativa()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-red-700 font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                              </svg>
                              <span class="pl-1">Formato Acta</span>
                            </button> -->
  
                            <button *ngIf="isFondoFaltante" (click)="downloadFaltante()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-red-700 font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                              </svg>
                              <span class="pl-1">Formato Vale</span>
                            </button>
                          </div>

                          <textarea *ngIf="isFondoSobrante" formControlName="observacion" class="w-full resize-y text-sm mt-4 p-2 text-gray-500 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" rows="4" placeholder="Observaciones"></textarea>
                        </div>

                        <div *ngIf="isFondoFaltante" class="flex flex-1 flex-col">

                          <div class="flex w-full mb-1 font-bold text-sm text-center">
                              <span class="flex-1">Denominacion</span>
                              <span class="flex-1">Cantidad</span>
                              <span class="flex-1">Total</span>
                          </div>
                    
                          <div *ngFor="let denominacion of denominaciones" class="flex space-x-1 mb-1">
                              <input class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 font-semibold text-sm focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue form-input text-center" disabled value="${{ denominacion.cantidad | number }}" type="text">
                    
                              <input #input id="fal{{ denominacion.id }}" (change)="comprobacionFaltante($event, denominacion, input)" class="flex-1 w-6 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue form-input text-center" type="number" min="0">
                    
                              <input [value]="subfaltantes[denominacion.id] || 0 | number" class="flex-1 w-6 bg-gray-200 border rounded border-gray-300 placeholder-gray-500 py-1 text-sm focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue form-input text-center" disabled type="text">
                          </div>
                    
                          <div class="flex font-bold text-sm text-center">
                            <span class="flex-1">Total:</span>
                            <span class="flex-1"></span>
                            <span class="flex-1">${{ faltanteTotal | number }}</span>
                          </div>
                                        
                        </div>

                      </div>

                      <hr>

                  </div>
              </div>
          </div>

          <div class="modal-actions bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

            <button (click)="confirmarFondo()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-md px-4 py-2 bg-blue-700 font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="pr-1 w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg> Confirmar
            </button>

            <button (click)="closeVerificacion()" class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md px-4 py-2  mt-3 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="pr-2 w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg> Cerrar
            </button>

          </div>

      </div>
  </div>
</div>