import { createSelector } from '@ngrx/store';
import {AppState} from '../app.state';
import {BtnRapidoState} from '../../core/models/btnRapido.state';
import {PanelIzquierdoState} from '../../core/models/panelIzquierdo.state';

export const selectPanelIzquiero = (state: AppState) => state.panelIzquierdo;

export const selectPanelIzquierdo = createSelector(
  selectPanelIzquiero,
  (state: PanelIzquierdoState) => state
);
