import {createAction, props} from '@ngrx/store';
import {Morralla} from '../../models';

export enum MorrallaActions {

  clearMorralla = '[Morralla-CLEAR] Clear',
  postMorralla = '[Morralla-POST] Post',
  postedMorralla = '[Morralla-POSTED] Posted',
}


export const clearMorralla = createAction(
  MorrallaActions.clearMorralla
);

export const postMorralla = createAction(
  MorrallaActions.postMorralla,
  props<{ morralla:  Morralla }>()

);

export const postedMorralla = createAction(
  MorrallaActions.postedMorralla,
  props<{ morralla: Morralla }>()
);
