import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {SubtipoCruce, TipoCruce} from '../models';

@Injectable({
  providedIn: 'root'
})
export class TipoCruceService {

  private ENDPOINT = environment.ENDPOINTS.TIPO_CRUCE;
  constructor(private http:HttpClient) { }

  getTipos(){
    return this.http.get<TipoCruce[]>(this.ENDPOINT);
  }

  getTipo(id: number){
    return this.http.get<TipoCruce>(this.ENDPOINT + `/${id}`);
  }
}
