import { Component, OnInit } from '@angular/core';
import { Cruce } from '../../models';
import * as moment from 'moment';
import { clearCruce, postCruce } from '../../state/actions/cruce.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import { selectCruce } from '../../state/selectors/cruce.selectors';
import { CruceState } from '../../core/models/cruce.state';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { selectUser } from 'src/app/state/selectors/user.selectors';

@Component({
  selector: 'app-dinero-eludido',
  templateUrl: './dinero-eludido.component.html',
  styleUrls: ['./dinero-eludido.component.scss']
})
export class DineroEludidoComponent implements OnInit {
  user: any;
  cruce: any;
  constructor(
    private store: Store<AppState>,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.store.select(selectCruce).subscribe(cruceState => {

      this.cruce = cruceState;
      console.log(this.cruce)
    })
    this.store.select(selectUser).subscribe(user => this.user = user);
  }

  confirmValue(e: string) {
    console.log(this.cruce);

    if (this.cruce.idChildren == 74) {//avento dinero
      return this.saveData(e);
    }
    if (!this.cruce?.id_clasificacion) {
      return;
    }
    this.saveData(e);
  }

  saveData(monto) {
    //Se guarda la info del cruce
    let cruce: Cruce = {
      fecha_hora: moment().format('YYYY-MM-DD HH:mm:ss'),
      id_corte_usuario: this.user.id_corte_usuario,
      id_subtipo: this.cruce.idChildren,
      id_clasificacion: this.cruce.id_clasificacion,
      aventoDinero: Number(monto),
      cantidad_ejes: this.cruce.cantidad_ejes,
      id_cat_observacion: this.cruce.id_cat_observacion,
    }
    this.store.dispatch(postCruce({ cruce }))

    this.toastr.success("Cruce guardado con éxito", '', { positionClass: 'toast-bottom-right' });

    this.store.dispatch(clearCruce());

    this.router.navigate(['/mopa/home'])
  }

}
