import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RolPermiso} from '../models';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionService {
  private ENDPOINT = environment.ENDPOINTS.CONFIGURACION;
  constructor(private http:HttpClient) { }

  getFondoCaseta(data){
    return this.http.post<any>(`${this.ENDPOINT}/`, data);
  }

  updateFondo(data){
    return this.http.post<any>(`${this.ENDPOINT}/update-fondo`, data);
  }

  getCaseta(id: number){
    return this.http.get<any>(this.ENDPOINT + `/${id}`);
  }

  getAuxiliares(id: number){
    return this.http.get<any[]>(`${this.ENDPOINT}/get/auxiliares/${id}`);
  }

}
