import {createReducer, on} from '@ngrx/store';
import {addEventRetiro, changeStatusRetiro, clearStatusRetiro, setStatusRetiro, StatusPanel} from '../actions/panelIzquierdoRetiro.actions';
import {PanelIzquierdoRetiroActions} from '../actions/panelIzquierdoRetiro.actions';
import {PanelIzquieroRetiroState} from '../../core/models/panelIzquieroRetiro.state';


export const initialState:PanelIzquieroRetiroState = {status: StatusPanel.INITIAL, id_denominacion: 0, countRetiro : Number(localStorage.getItem("panelizquierdoretiro") || 0)  };


export const panelIzquierdoRetiroReducer = createReducer(
  initialState,
  on(addEventRetiro, (state, {id_denominacion, status}) => {
    return {...state, id_denominacion, status };
  }),
  on(setStatusRetiro, (state) => {
    localStorage.setItem('panelizquierdoretiro', `${state.countRetiro + 1 }`);
    return {status: StatusPanel.INITIAL, id_denominacion:  0, countRetiro: state.countRetiro+1};
  }),
  on(changeStatusRetiro, (state,{status}) => {
    return {...state, status};
  }),
  on(clearStatusRetiro, (state) => {
    return {...state, countRetiro: 0};
  }),
);
