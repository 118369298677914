import {createAction, props} from '@ngrx/store';
import {Caseta} from '../../models';


export const loadCaseta = createAction(
  '[Caseta-GET] Load Caseta'
);


export const loadedCaseta = createAction(
  '[Caseta-GET] Loaded success',
  props<{ caseta: Caseta }>()
);
