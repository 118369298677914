<!-- <div>
  <h2>Avento Dinero</h2>
  <hr>
  <app-teclado-numerico  placeholder="Dinero" (confirmValue)="confirmValue($event)" min="0" max="1" ></app-teclado-numerico>
</div> -->

<div class="p-2 border rounded border-solid border-gray-300 dark:border-gray-500 h-112 uppercase text-lg text-gray-500">
  <div class="flex flex-col text-center">
    <h2 class="text-gray-500 dark:text-gray-50 font-bold text-base">Avento Dinero</h2>
    <hr class="mt-1 mb-2">
    <div class="grid grid-cols-3">
      <div class="col-start-2 w-96">
        <app-teclado-numerico placeholder="Dinero" (confirmValue)="confirmValue($event)" min="0" max="1" value="0" ></app-teclado-numerico>
      </div>
    </div>
  </div>
</div>


