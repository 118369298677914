import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {AppState} from '../../state/app.state';
import {Store} from '@ngrx/store';
import {addCruceType} from '../../state/actions/cruce.actions';
import { clearBtnRapido} from '../../state/actions/btnRapido.actions';
import {Caseta, Cruce} from '../../models';
import {selectCaseta} from '../../state/selectors/caseta.selectors';
import {selectCruce} from '../../state/selectors/cruce.selectors';

@Component({
  selector: 'app-container-header',
  templateUrl: './container-header.component.html',
  styleUrls: ['./container-header.component.scss']
})
export class ContainerHeaderComponent implements OnInit {

  caseta : Caseta;
  cruce:any;
  selected:boolean = false;
  id_corte_auxiliar : number = 0;
  constructor(private router : Router,private store:Store<AppState>) { }

  ngOnInit(): void {
    this.store.select(selectCaseta).subscribe(caseta=>this.caseta=caseta);
    this.store.select(selectCruce).subscribe(cruce=>this.cruce=cruce);
    this.id_corte_auxiliar = Number(localStorage.getItem('id_corte_auxiliar'));
  }

  goToSubtipo(id: number){
    let childrens = this.caseta?.btnTipos.find(btn => btn.id === id)?.subtipoCruces;

    this.store.dispatch(addCruceType({id_type:id, childrens}));
    this.store.dispatch(clearBtnRapido())
    this.router.navigate(['/mopa/subtipo', id]);
  }

  isSelected(id: number){
    return this.cruce?.id_type === id;
  }

  isAuxiliar() {
    if ( this.caseta && this.caseta.tipo === 0 && this.id_corte_auxiliar!=0) {
      return true;
    }
  }

  doUltimoEfectivo(btnUA) {
    // if (this.selected) {
    //   // btnUA.classList.remove('selected');
    //   // btnUA.classList.remove('dark:bg-blue-800');
    //   // btnUA.classList.remove('bg-blue-800');
    //   // btnUA.classList.remove('text-white');
    //   // btnUA.classList.add('dark:bg-transparent');
    //   this.selected = false;
    //   this.router.navigate(['/mopa/home']);
    // } else {
    //   // btnUA.classList.add('selected');
    //   // btnUA.classList.add('dark:bg-blue-800');
    //   // btnUA.classList.add('bg-blue-800');
    //   // btnUA.classList.add('text-white');
    //   // btnUA.classList.remove('dark:bg-transparent');
    //   this.selected = true;
    //   this.router.navigate(['/mopa/u-efectivo']);
    // }
    this.router.navigate(['/mopa/u-efectivo']);
  }

}
