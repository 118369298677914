<div class="p-2 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase text-lg text-gray-500">

    <div class="flex flex-col text-center">
        <h3 class="text-gray-500 dark:text-gray-50 font-bold text-base">Ultimo efectivo - Ingrese sus credenciales</h3>
        <hr class="mt-2 mb-2">

        <div class="grid grid-cols-3">

            <div class="col-span-2">
                <div class="w-1/2 mx-auto">
                    <app-teclado-numerico (change)="onChangeTN($event)" [multipleInputs]="true" (confirmValue)="saveMonto($event)" placeholder="Ultimo Efectivo"></app-teclado-numerico>

                    <div class="mt-2">
                    <button (click)="cancelAction()" class="bg-blue-600 p-3 w-full text-white font-bold uppercase border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancelar</button>
                    </div>
                </div>
            </div>

            <div class="w-96 flex flex-col mt-4 space-y-4">
                <div class="form-group">
                    <label class="block text-lg font-medium dark:text-gray-300 mr-2"> Numero de empleado </label>
                    <input (focus)="onFocusNumEmpleado($event)"  [value]="form.empleado" class="w-3/4 border rounded border-gray-300 placeholder-gray-500 px-3 py-3 text-lg dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input" type="text" min="0" placeholder="" />
                </div>
                <div class="form-group">
                    <label class="block text-lg font-medium dark:text-gray-300 mr-2"> Contraseña </label>
                    <input (focus)="onFocusPassword($event)"  [value]="form.password" type="password" class="w-3/4 border rounded border-gray-300 placeholder-gray-500 px-3 py-3 text-lg dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input" min="0" placeholder="" />
                </div>
            </div>

        </div>

    </div>
</div>

