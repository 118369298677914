import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { now } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RetiroService } from 'src/app/services/retiro.service';
import { AppState } from 'src/app/state/app.state';
import { selectRetiroPrint } from 'src/app/state/selectors/retiro.selectors';
import { selectUser } from 'src/app/state/selectors/user.selectors';

@Component({
  selector: 'app-print-retiro',
  templateUrl: './print-retiro.component.html',
  styleUrls: ['./print-retiro.component.scss']
})
export class PrintRetiroComponent implements OnInit {

  user: any;
  dateTime = now();
  ronda = 1;
  retiros = [];
  isReImprision: boolean = false;
  montoTotal: number = 0;
  tipoImpresion: string = "[ IMPRESION ]";

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private toastr: ToastrService,
    private retiroService: RetiroService,
    private ngxUiLoaderService: NgxUiLoaderService,
  ) { }

  ngOnInit(): void {
    //redux
    this.store.select(selectUser).subscribe(async (user) => {
      this.user = user;
    });

    this.store.select(selectRetiroPrint).subscribe((data) => {
      console.log(data);
      this.retiros = data;

      if (!this.retiros.length) {
        return this.toastr.info(`No hay retiros parciales recientes`, '', { positionClass: 'toast-bottom-right' });
      }

      for (let retiro of this.retiros) {
        this.montoTotal += retiro.importe;
      }

      if (this.retiros[0].id_retiro_impresion) {
        this.tipoImpresion = "[ REIMPRESION ]";
        this.isReImprision = true;
      } else {
        this.tipoImpresion = "[ IMPRESION ]";
        this.isReImprision = false;
      }

      
    })
  }


  imprent() { 

    if (!this.isReImprision) {
      this.retiroService.updateRetiroImpresion( this.retiros ).subscribe( impresion => {
        console.log( impresion );
      });
    }


    window.print();

    // despues de imprir recarga
    window.location.reload()

    // this.retiroService.printRetiro(data)
    // .subscribe(print=>{
    //   console.log(print);

    // })
  }
}
