import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Fondo, FondoDenominacion } from '../models/dash';
import { Caseta } from '../models';


@Injectable({
  providedIn: 'root'
})
export class AsignarFondoService {

  fondoDenominacion: FondoDenominacion[] = [];
  fondoBase: FondoDenominacion[] = [];

  private ENDPOINT = environment.ENDPOINTS.FONDO;
  private ENDPOINTAPERTURACARRIL = environment.ENDPOINTS.APERTURACARRIL;

  constructor( private http: HttpClient ) {}

  getFondoDenominacion( casetaId: number ) {
    return new Promise<void>( (resolve, reject ) => {
      this.http.get<Fondo[]>(this.ENDPOINT)
          .subscribe( fondos => {
            for( const fondo in fondos) {
              let casetas = fondos[fondo]['caseta'];
              for( const caseta in casetas ) {
                if ( casetas[caseta]['id'] == casetaId) {
                  this.fondoDenominacion = fondos[fondo]['fondoDenominacion'];
                }
              }
            }
            resolve();
          });
    });
  }

  getFondos() {
    return this.http.get<Fondo[]>(this.ENDPOINT);
  }

  saveAsignacionFondo( data : any ) {
    return this.http.post<Caseta>(this.ENDPOINT+'/asignar-fondo', data);
  }

  saveAperturaCarrril( data : any ){
    return this.http.post<Fondo>(this.ENDPOINTAPERTURACARRIL, data)
  }

}
