import {createReducer, on} from '@ngrx/store';
import {loadRetiro, loadedRetiro, postRetiro, postedRetiro, addMonto, clearRetiro, addRetirosPrint, clearRetiroPrint} from '../actions/Retiro.actions';
import {RetiroState} from '../../core/models/Retiro.state';


export const initialState:RetiroState = {loading: false, retiro: null, contRetiro: 0, monto : 0, retiroPrint: null};


export const retiroReducer = createReducer(
  initialState,
  on(loadRetiro, (state) => {
    return {...state, loading: true};
  }),
  on(loadedRetiro, (state,{retiro}) => {
    return {...state, loading: false, retiro};
  }),
  on(postRetiro, (state,{retiro}) => {
    return {...state, loading: true,contRetiro: 0};
  }),
  on(postedRetiro, (state,{retiro}) => {
    return {...state, loading: false, retiro, contRetiro:0};
  }),
  on(addMonto, (state,{monto}) => {
    return {...state, monto};
  }),
  on(clearRetiro, (_) =>{
    return initialState;
  }),
  on(addRetirosPrint, (state,{retiroPrint}) => {
    return {...state, retiroPrint};
  }),
  on(clearRetiroPrint, (_) =>{
    return initialState.retiroPrint;
  })

);
0
