import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FaltanteService {

  private ENDPOINTFALTANTE = environment.ENDPOINTS.FALTANTETOTAL;
  private ENDPOINTSAVEFALTANTE = environment.ENDPOINTS.FALTANTE;
  private ENDPOINTUSUARIOFALTANTE = environment.ENDPOINTS.USUARIO_FALTANTE;

  constructor(
    private http:HttpClient,
  ) { }

  saveFaltante(data: any) {
    return this.http.post<any>(this.ENDPOINTSAVEFALTANTE, data);
  }

  getUsuariosFaltante(id_caseta: number) {
    return this.http.get<any>(`${ this.ENDPOINTUSUARIOFALTANTE }/${ id_caseta }`);
  }

  getFaltanteTotal(id_fondo: number) {
    return this.http.get<any>(this.ENDPOINTFALTANTE+`/${ id_fondo }`);
  }

}
