
import {createAction, props} from '@ngrx/store';
import {BtnRapido, Input} from '../../models';

export enum InputActions {
  ADD_INPUT = '[INPUT] ADD_INPUT',
  CHANGE_INPUT = '[CHANGE] CHANGE_INPUT',
  CLEAR_INPUT = '[CLEAR] CLEAR_INPUT'
}

export const addInputAction = createAction(
  InputActions.ADD_INPUT,
  props<{ input: Input}>()
);

export const changeInputAction = createAction(
  InputActions.CHANGE_INPUT,
  props<{ input: Input, index: number}>()
);

export const clearInputAction = createAction(
  InputActions.CLEAR_INPUT
);



