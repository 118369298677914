import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { CorteUsuario } from '../models';
import { environment } from 'src/environments/environment';
import { CorteActual } from '../models/dash';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CorteService {

  private ENDPOINT = environment.ENDPOINTS.CORTEUSUARIO;
  private ENDPOINTACTUAL = environment.ENDPOINTS.CORTEACTUAL;
  private ENDPOINTINSTALL = environment.ENDPOINTS.INSTALACION;
  private ENDPOINTCORTEUSER = environment.ENDPOINTS.CORTEUSERS;
  private ENDPOINTUSERCORTE = environment.ENDPOINTS.USUARIO_GENERA_FALTANTE;
  private ENDPOINTPDF = environment.ENDPOINTS.PDF;
  private ENDPOINTPDFPRELIQUIDACION = environment.ENDPOINTS.PRELIQUIDACION;
  private ENDPOINTACTAPDF = environment.ENDPOINTS.ACTAADMINISTRATIVAPDF;

  constructor(private http:HttpClient, private toastrService: ToastrService) { }

  saveCorteUsuario(corteUsuario){
    let id_carril = Number(localStorage.getItem("id_carril"));
    if(!id_carril) {
      this.toastrService.error('No se ha configurado la cabina', '', {positionClass: 'toast-bottom-right'});
      return null;
    }

    return this.http.post<CorteUsuario[]>(this.ENDPOINT, {...corteUsuario, id_carril});
  }

  getCorteUsuario(){
    return this.http.get<CorteUsuario[]>(this.ENDPOINT);
  }

  getCortesPagados(id_usuario : number){
    return this.http.get<CorteUsuario[]>(this.ENDPOINT+ `/get-corte-pagados/${ id_usuario }`);
  }

  getCorteUsuarioFromJT(id_usuario){
    return this.http.get<CorteUsuario[]>(this.ENDPOINT+ `/get-corte-usuario-by-jt/${ id_usuario }`);
  }

  getCortesPreliquidadoByJT(id_usuario : number){
    return this.http.get<CorteUsuario[]>(this.ENDPOINT+ `/get-cortes-preliquidado-jt/${ id_usuario }`);
  }

  getCortePreliquidadoByJT(id_corte_usuario : number){
    return this.http.get<CorteUsuario[]>(this.ENDPOINT+ `/get-corte-preliquidado-jt/${ id_corte_usuario }`);
  }

  getCorteActual(id_jefe_turno: number, id_caseta: number) {
    return this.http.get<CorteActual[]>(this.ENDPOINTACTUAL+`/${ id_jefe_turno }/${ id_caseta }`);
  }

  saveAsignarCarril( data: any ) {
    return this.http.post(this.ENDPOINT +'/asignar-carril', data)
  }

  savePreliquidacionCorte( data: any ) {
    return this.http.post(this.ENDPOINT +'/save-preliquidacion-corte', data)
  }

  saveStatusCortes( data: any ) {
    return this.http.post(this.ENDPOINT +'/save-status-cortes', data)
  }

  getCobradorFormacion( id_caseta: number, id_usuario: number, id_turno: number) {
    return this.http.get<any[]>(this.ENDPOINT + `/cobrador-formacion/${ id_caseta }/${ id_usuario }/${ id_turno }`);
  }

  saveStartTurn(idCorte : number){
    return this.http.put(`${this.ENDPOINT}/start-turn/${idCorte}`, {});
  }

  checkInstalacion(idCaseta : number) {
    return this.http.get(`${this.ENDPOINTINSTALL}/${ idCaseta }`);
  }

  getCortesUser(id_usuario : number) {
    return this.http.get(`${this.ENDPOINTCORTEUSER}/${ id_usuario }`);
  }

  getUsersCortes(id_caseta: number, id_turno: number) {
    return this.http.get(`${ this.ENDPOINTUSERCORTE }/${ id_caseta }/${ id_turno }`);
  }

  getSumaCorte(id_corte_usuario : number) {
    return this.http.get(`${ this.ENDPOINT }/suma-by-corte/${ id_corte_usuario }`);
  }

  getCorteTurnoUsuarios(id_caseta: number, id_turno: number) {
    return this.http.get<any[]>(`${ this.ENDPOINT }/get-cortes-usuarios/${ id_caseta }/${ id_turno }`)
  }

  getCorteFormatoHeader(id_caseta: number,  fecha: any) {
    return this.http.get<any[]>(`${ this.ENDPOINT }/get-cortes-header/${ id_caseta }/${ fecha }`);
  }

  getCorteRetiros(id_corte_usuario: number) {
    return this.http.get<any[]>(`${ this.ENDPOINT }/get-formato-retiro/${ id_corte_usuario }`);
  }

  getCorteExentos(id_corte_usuario: number) {
    return this.http.get<any[]>(`${ this.ENDPOINT }/get-formato-exento/${ id_corte_usuario }`);
  }

  getCorteIncidencia(id_corte_usuario: number) {
    return this.http.get<any[]>(`${ this.ENDPOINT }/get-formato-incidencia/${ id_corte_usuario }`);
  }

  getCorteCorte(id_corte_usuario: number) {
    return this.http.get<any[]>(`${ this.ENDPOINT }/get-formato-corte/${ id_corte_usuario }`);
  }

  //FORMATO PDF
  getDataReporte(data:any){
    let { download } = data;
    if (download) {
      const httpOptions = {responseType: 'blob' as 'json'};
      return this.http.post(`${ this.ENDPOINTPDF }`,data, httpOptions).toPromise();
    } else {
      return this.http.post(`${ this.ENDPOINTPDF }`,data, { responseType: 'text'}).toPromise();
    }

  }

  // PDF PRELIQUIDACION
  getPDFPreliquidacion(data:any){
    const httpOptions = {responseType: 'blob' as 'json'};
    return this.http.post(`${ this.ENDPOINTPDFPRELIQUIDACION }`,data, httpOptions).toPromise();
  }

  getPDFActaAdministrativa(data:any){
    const httpOptions = {responseType: 'blob' as 'json'};
    return this.http.post(`${ this.ENDPOINTACTAPDF }`,data, httpOptions).toPromise();
  }

}
