import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { Evento } from '../models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventoService {

  private ENDPOINT = environment.ENDPOINTS.EVENTO;
  private ENDPOINT_CAT = environment.ENDPOINTS.CAT_EVENTO;

  constructor(private http:HttpClient) { }

  saveEvento(evento){
    return this.http.post<Evento[]>(this.ENDPOINT,evento);
  }

  getEvento(){
    return this.http.get<Evento[]>(this.ENDPOINT);
  }

  getEventoFalla( id_fondo: number ) {
    return this.http.get<any[]>(`${ this.ENDPOINT }/evento-falla/${ id_fondo }`);
  }

  updateEventoFalla(evento){
    return this.http.put<Evento[]>(`${ this.ENDPOINT }-falla/${evento.id_evento}`,evento);
  }

  getCatEvento() {
    return this.http.get<any[]>(this.ENDPOINT_CAT);
  }

}
