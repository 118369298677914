import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { now } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FondoDenominacion, Turno } from 'src/app/models/dash';
import { AsignarFondoService } from 'src/app/services/asignar-fondo.service';
import { DevolucionService } from 'src/app/services/Devolucion.service';
import { AppState } from 'src/app/state/app.state';
import { selectUser } from 'src/app/state/selectors/user.selectors';
import { getTurno } from 'src/app/utils';

@Component({
  selector: 'app-sepsa',
  templateUrl: './sepsa.component.html',
  styleUrls: ['./sepsa.component.scss']
})
export class SepsaComponent implements OnInit {

  usuario: any;
  caseta_id: number = null;
  turno: Turno;
  traslado = [];
  traslados: any = [];
  subtotales: number[] = [];
  tipo: number = null;
  trasladoTotal: number = 0;
  asignarTraslado = {};
  fichaSepsaCantidad: number = 0;
  tablaTraslados = [];
  traslados_pendientes = [];
  fondoTraslado : any = [];
  // ticket data
  ticketModal: boolean = false;
  dateTime = now();
  tipoImpresion: string = "[ IMPRESION ]";
  ticketDepositoModal: boolean = false;
  // ticket data
  entradaTraslado: number = null;
  denominaciones: FondoDenominacion[] = [];

  tipoSepsaForm = new FormGroup({
    tipoSepsa: new FormControl('', Validators.required)
  });

  get denominacionFormato() {
    return this.denominaciones.slice(0, ).reverse();
  }

  constructor(
    private router: Router,
    private toastr: ToastrService,
    public asignarFondo: AsignarFondoService,
    private store: Store<AppState>,
    private config: NgSelectConfig,
    private ngxUiLoaderService: NgxUiLoaderService,
    private devolucionService: DevolucionService,
  ) {
    this.config.notFoundText = 'Sin traslados';
  }

  ngOnInit(): void {

    // obtener turno actual
    this.turno = getTurno();
    // obtener casetas de usuario
    this.store.select(selectUser).subscribe( usuario => this.usuario = usuario);
    // obtener fondo para conjunto de casetas
    this.asignarFondo.getFondoDenominacion( this.usuario.caseta_usuario[0].id ).then( () => {
          this.denominaciones = this.asignarFondo.fondoDenominacion;
        });

  }

  getCaseta( caseta: any ) {
    this.caseta_id = Number(caseta.target.value);
    let data = {
      id_caseta : this.caseta_id
    }

    // obtener historial de depositos
    this.devolucionService.getTraslados(this.caseta_id).subscribe(trasladosCaseta => {
      console.log( 'HISTORIAL TRASLADO_CASETA', trasladosCaseta );
      this.tablaTraslados = trasladosCaseta;
      for (let tr of trasladosCaseta) {
        this.fichaSepsaCantidad += tr.traslado;
      }
    });

    this.devolucionService.getFondoTrasladoValores(data).
    subscribe(traslado=>{

      console.log( 'TRASLADOTOTAL', traslado );

      this.traslados = traslado
      this.fondoTraslado.fondo = traslado[0]['fondo'];
      this.fondoTraslado.devoluciones = traslado[0]['devoluciones'];
      // console.log(this.fondoTraslado);
    });
  }

  getTraslado(dataTranslado: any) {
    console.log('OBTENER TRASLADO', dataTranslado)
    let translado = dataTranslado.devoluciones.split(',');
    dataTranslado ? this.asignarTraslado = translado : this.asignarTraslado = {};
    this.tipo === 1 && dataTranslado ? this.entradaTraslado = dataTranslado.fondo : this.entradaTraslado = null;
    console.log( 'TRASLADO', this.asignarTraslado );
  }

  getTipoSepsa() {
    // valida caseta
    if ( this.caseta_id === null ) {
      this.tipoSepsaForm.reset();
      return this.toastr.error('Por favor, seleccione una caseta', 'Error', { positionClass: 'toast-bottom-right' } )
    }
    // salida = tipo.saliente | entrada tipo.entrante
    this.tipoSepsaForm.value.tipoSepsa === 'salida' ? this.tipo = 0: this.tipo = 1;

    if ( this.tipo === 0) { this.entradaTraslado = null }

    // reinicio total, array e inputs
    this.trasladoTotal = 0;
    this.traslado = [];
    let start: number = 20;
    for (let i = 0; i < this.denominacionFormato.length; i++) {
      (document.getElementById(`s${ (start+ i) }`) as HTMLInputElement).value = '0';
      this.subtotales[(start + i)] = 0;
    }

  }

  tTotal(event: any, denominacion, input: any) {
    this.trasladoTotal = 0;

    let cantidad = Number(event.target.value);
    let subTotal = cantidad * (denominacion.cantidad);
    this.subtotales[denominacion.id] = subTotal;

    // validacion individual en relacion al fondo
    if (this.tipo === 0 && cantidad > denominacion.pivot.cantidad) {
      cantidad = 0;
      this.subtotales[denominacion.id] = 0;
      (document.getElementById(input.id) as HTMLInputElement).value = '0';
      this.toastr.error(`La cantidad ingresada en $${ denominacion.cantidad } es mayor a la disponible`, 'Error', { positionClass: 'toast-bottom-right' } );
    }

    // total
    for (let total in this.subtotales){
      if(this.subtotales[total]){
        this.trasladoTotal += Number(this.subtotales[total]);
      }
    }

    this.insertTraslado( cantidad, denominacion)
  }


  insertTraslado(cantidad: number, denominacion: any){
    let index = this.traslado.findIndex( m => m.id_denominacion === denominacion.id);
    index === -1 ? this.traslado.push({
      id_denominacion: denominacion.id,
      cantidad: cantidad,
      denominacion: denominacion.cantidad
    }): this.traslado[index].cantidad = cantidad;

    console.log( this.traslado );
  }

  saveTraslado() {
    // valida caseta
    if ( this.caseta_id === null ) { return this.toastr.error('Por favor, seleccione una caseta', 'Error', { positionClass: 'toast-bottom-right' } ) }
    // valida entrada o salida
    if ( this.tipo === null ) { return this.toastr.error('Seleccione si el traslado es saliente o entrante', 'Error', { positionClass: 'toast-bottom-right' } ) }
    // valida cantidad x denominacion
    if ( this.traslado.length === 0 || this.trasladoTotal === 0) { return this.toastr.error('Ingresa una cantidad $ para traslado', 'Error', { positionClass: 'toast-bottom-right' } ) }

    // valida la entrada de traslado
    if( this.tipo === 1 ) {
      console.log(this.entradaTraslado)
      if (this.entradaTraslado != this.trasladoTotal ) {
        return this.toastr.error('La cantidades deben coincidir para realizar la entrada de traslado', 'Error', { positionClass: 'toast-bottom-right' } )
      }
    }
    this.ngxUiLoaderService.start();
    let data = {
      tipo: this.tipo,
      id_cat_evento: 2,
      id_caseta: this.caseta_id,
      fondo_denominacion: this.traslado,
      traslado: this.asignarTraslado,
      id_usuario_retira : this.usuario.id
    }
    console.log(data);
    this.devolucionService.saveTrasladoValores(data).
    subscribe(traslado=>{
      this.router.navigate(['dashboard/fondo-operacion']);
      this.ngxUiLoaderService.stop();
      return this.toastr.success('Se ha generado el deposito con éxito', '', { positionClass: 'toast-bottom-right' } )
      // this.downloadDeposito(); NO DESCARGAR FICHA
    })
    console.log( 'GUARDAR TRASLADO', data );
  }

  downloadDeposito(){
    // Solo descargar la ficha con el total final debajo de la tabla de historial
    if ( this.fichaSepsaCantidad === 0  || !this.fichaSepsaCantidad ) { return this.toastr.error('Por favor, seleccione una caseta', 'Error', { positionClass: 'toast-bottom-right' } ) }
    // valida entrada o salida

    this.ngxUiLoaderService.start();

    // actualiza id de depositos antes de enviar a sepsa
    let data = {
      tipo: 3,
      traslado: this.tablaTraslados,
    }
    this.devolucionService.saveTrasladoValores(data).
    subscribe(traslado=>{
      this.ngxUiLoaderService.stop();
      this.toastr.success('Se puesto en transito el traslado', '', { positionClass: 'toast-bottom-right' } )
      // this.downloadDeposito(); NO DESCARGAR FICHA
    })
    console.log( 'TRASLADO EN TRANSITO ACTUALIZADO', data );

    // Descargar ficha deposito PDF
    // if (this.tipo == 0) { //Saliente
      // this.devolucionService.fileFichaDeposito(this.trasladoTotal).subscribe(data => {
      this.devolucionService.fileFichaDeposito(this.fichaSepsaCantidad).subscribe(data => {
        let downloadURL = window.URL.createObjectURL(data);
        let link = document.createElement('a');
        link.href = downloadURL;
        link.download = "fichaDeposito.pdf";
        link.click();
        console.log("descargado");
        this.router.navigate(['dashboard/fondo-operacion']);
        this.ngxUiLoaderService.stop();
        return this.toastr.success('Se ha generado la ficha de deposito', '', { positionClass: 'toast-bottom-right' } )
      })
    // }else{
      // this.router.navigate(['dashboard/fondo-operacion']);
      // this.ngxUiLoaderService.stop();
      // return this.toastr.success('Se ha generado el traslado de valores con éxito', '', { positionClass: 'toast-bottom-right' } )
    // }

  }


  imprent() {

    window.print();
    this.toastr.success('Ticket impreso','', { positionClass: 'toast-bottom-right' } );
   
  }

}
