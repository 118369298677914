import {createReducer, on} from '@ngrx/store';
import {addNotification} from '../actions/history.notification.actions';
import {HistoryNotification} from '../../core/models/history.notification';


export const initialState: HistoryNotification = {notificaciones:[]};


export const historyNotificationsReducer = createReducer(
  initialState,
  on(addNotification, (state,{notification}) => {

    let notificaciones = [ notification, ...state.notificaciones]
    console.log(state, notification)
    return { notificaciones};
  }),
);
