import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Turnos } from '../models/dash';

@Injectable({
  providedIn: 'root'
})
export class TurnoService {

  private EDNPOINT = environment.ENDPOINTS.FONDO;

  constructor(
    private http: HttpClient
  ) { }

  getTurnos() {
    return this.http.get<Turnos[]>(this.EDNPOINT+'/get/turnos');
  }

}
