import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {RequestNotificacionesPush} from '../models/request';
import { NotificacionCaseta } from '../models/dash';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesPushService {

  private ENDPOINT = environment.ENDPOINTS.NOTIFICACIONES_PUSH;
  private PDF = environment.ENDPOINTS.PDFDEVOLUCIONFONDO;

  constructor(
    private http:HttpClient
  ) { }

  getNotificactions(){
    return this.http.get(environment.ENDPOINTS.NOTIFICACIONES_PUSH);
  }

  updateStatus(id: number, estatus: number){
    return this.http.put(environment.ENDPOINTS.NOTIFICACIONES_PUSH + '/' + id, {estatus})
  }

  saveNotification(body : RequestNotificacionesPush){
    return this.http.post(environment.ENDPOINTS.NOTIFICACIONES_PUSH, body);
  }

  getNotificacionCaseta(id_turno: number, id_jefe_turno: number) {
    return this.http.get<NotificacionCaseta[]>(this.ENDPOINT+`/${ id_turno }/${ id_jefe_turno }`);
  }

  getNotificacionRollo(id_caseta: number, id_turno: number, id_jefe_turno: number) {
    return this.http.get(this.ENDPOINT+`-caseta/${ id_caseta }/${ id_turno }/${ id_jefe_turno }`)
  }

  updateStateByCorte(body){
    return this.http.put(environment.ENDPOINTS.NOTIFICACIONES_PUSH + '/update/by-corte', body);
  }

  createFondoPDF(data:any){
    const httpOptions = {responseType: 'blob' as 'json'};
    return this.http.post(this.PDF ,data, httpOptions).toPromise();
  }

}
