export enum TYPE_DENOMINACION {
  MORRALLA = 1,
  DEVOLUCION = 0,
  RETIRO = 2
}

export enum TYPE_BTNS {
  MENU = 4,
}

export enum TYPE_CRUCE {
  INCIDENCIAS = 1,
  EXCENTOS = 2,
  ALARMAS = 3,
}

export enum TYPE_ROL {
  CARRIL = 22,
  JEFE_TURNO = 13
}

export enum ACTIONS_USER_DB {
  DEVOLUCION = 'DEVOLUCION'
}

export enum TYPE_CRUCE_PADRE {
  EJES_LEVANTADOS = 1,
  ELUDIDOS = 6,
  NO_EMITIDOS = 11,
  CANCELADOS = 13,
}

export enum TYPE_PERMISO {
  RETIRO = 1,
  DEVOLUCION = 2,
  CORTE = 3,
  TR = 4,
  ROLLO = 7,
}


export enum TYPE_NOTIFICATION {
  RETIRO = 'RETIRO',
  ROLLO = 'ROLLO',
  MORRALLA = 'MORRALLA',
  DEVOLUCION = 'DEVOLUCION'
}

export enum STATUS_NOTIFICATION {
  NOTIFICADO = 1,
  CANCELADO = 2,
  ATENDIDO = 3

}
export enum CAT_EVENTO {
  DEVOLUCION = 1,
  TRASLADO = 2,
  CAMBIO = 3,
  FONDO = 4,
  FALTANTE = 5,
  ASALTO = 6,
  MORRALLA = 7,

}

export enum CAT_PDF_FONDO {
  ARQUEO = 1,
  CONFIRMAR = 2,
  DESCARGAR = 3,
}

export enum CAT_FONDO_TIPO {
  SALIENTE = 0,
  ENTRANTE = 1,
}
