<div class="p-2 border rounded border-solid border-gray-300 dark:border-gray-500 h-104 uppercase text-lg text-gray-500">
  <div class="flex flex-col text-center">

      <p class="title text-gray-500 dark:text-gray-50 font-bold text-base">Devolución</p>
      <hr class="mt-2 mb-4">

      <div class="mt-8 grid grid-cols-3 grid-flow-row">
        <div class="col-start-2 w-96">
          <button *ngFor="let denominacion of devolucionDisponibles" class="btn w-2/4 p-3 bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 dark:border-gray-400 drop-shadow-lg hover:border-blue-600 dark:hover:border-blue-600" (click)="showFirma(denominacion.id)">${{user.fondo}}</button>
        </div>

        <div class="col-start-2 w-96 mt-8">
          <button (click)="cancelAction()" class="btn w-2/4 p-3 bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600">Cancelar</button>
        </div>
      </div>

  </div>
</div>
<!-- <div class="title_devolucion">
  <label>Denominacion</label> <label>Cantidad</label> <label>Total: {{total}}</label>
</div>

<div class="main">

  <div *ngFor="let morralla of devolucionDisponibles">
    <div class="btn-operators">
      <input type="text" disabled class="btn w-20 denominacion" value="${{morralla.cantidad}}">
      <button class="btn w-10" (click)="addInput(morralla)">+</button>
      <input class="btn w-20" type="number" [value]="valor[morralla.id]" min="0">
      <button class="btn w-10" (click)="restarInput(morralla)">-</button>
      <input class="btn w-20 total" disabled type="text" [value]="totales[morralla.id] || 0 ">
    </div>
  </div>
  <div class="body__returnPage">

    <button (click)="insertLocalDecolucion()" class="btn w-20">Guardar</button>

  </div>
</div> -->

<!-- <div class="ml-2 mr-2 p-2 border rounded border-solid border-gray-300 dark:border-gray-500 h-120 uppercase text-lg text-gray-500">
  <div class="flex flex-col text-center">

    <h3 class="text-gray-500 dark:text-gray-50 font-bold text-base">Devolucion</h3>
    <hr class="mt-2 mb-4">

    <div class="grid grid-cols-3">
      <div class="col-start-2 w-96">
        <div class="grid grid-cols-3 text-sm font-bold mb-4">
          <label class="text-left">Denominacion</label> <label class="text-center">Cantidad</label> <label>Total: {{total}}</label>
        </div>

        <div *ngFor="let morralla of devolucionDisponibles">
          <div class="flex justify-between mb-2">

            <input type="text" disabled class="btn border rounded border-gray-300 placeholder-gray-500 p-2 w-24 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" value="${{morralla.cantidad}}">

            <div>
              <button class="btn px-2 mr-1 bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 dark:border-gray-400 drop-shadow-lg hover:border-blue-600" (click)="addInput(morralla)">+</button>
              <input class="btn border rounded border-gray-300 placeholder-gray-500 p-2 w-24 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" type="number" [value]="valor[morralla.id]" min="0">
              <button class="btn px-2 ml-1 bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 dark:border-gray-400 drop-shadow-lg hover:border-blue-600" (click)="restarInput(morralla)">-</button>
            </div>

            <input class="btn border rounded border-gray-300 placeholder-gray-500 p-2 w-24 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input text-center" disabled type="text" [value]="totales[morralla.id] || 0 ">

          </div>
        </div>


        <button (click)="insertLocalDecolucion()" class="btn w-24 py-1 px-2 bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 float-right">Guardar</button>

      </div>
    </div>

  </div>
</div> -->


