import {createAction, props} from '@ngrx/store';
import {NotificationState} from '../../core/models/notification.state';

export enum NotificationActions {
  loadedNotification = '[NOTIFICATION] Loaded',
  clearNotification = '[NOTIFICATION] clear'
}


export const loadedNotification = createAction(
  NotificationActions.loadedNotification,
  props<{ notification: NotificationState }>()
);

export const clearNotification = createAction(
  NotificationActions.clearNotification
);
