import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BitacoraService {

  private ENDPOINT = environment.ENDPOINTS.BITACORA;
  private ENDPOINTCATBITACORA = environment.ENDPOINTS.CATBITACORA;

  constructor(
    private http: HttpClient
  ) { }

  saveSobranteFondo(data: any) {
    return this.http.post<any>(`${ this.ENDPOINT }/save-sobrante-fondo`, data);
  }

  saveArqueoFondo( data: any ) {
    return this.http.post<any>(`${ this.ENDPOINT }/save-arqueo-fondo`, data);
  }

  getCatBitacora() {
    return this.http.get<any>(`${ this.ENDPOINTCATBITACORA }`,);
  }

  getBitacora() {
    return this.http.get<any>(`${ this.ENDPOINT }`,);
  }

  saveBitacora(data: any) {
    return this.http.post<any>(`${ this.ENDPOINT }`, data);
  }

  getBitacoraCount() {
    return this.http.get<any>(`${ this.ENDPOINT }/count-bitacora/numers`,);
  }

  saveSeguimientoBitacora(data: any) {
    return this.http.post<any>(`${ this.ENDPOINT }-seguimiento/`, data);
  }

  busquedaBitacora(data: any) {
    return this.http.post<any>(`${ this.ENDPOINT }/busqueda/bitacora`, data);
  }
}
