import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { addMonto } from 'src/app/state/actions/Retiro.actions';
import { addCorteUserAuxiliar } from 'src/app/state/actions/user.actions';
import { AppState } from 'src/app/state/app.state';

@Component({
  selector: 'app-u-efectivo',
  templateUrl: './u-efectivo.component.html',
  styleUrls: ['./u-efectivo.component.scss']
})
export class UEfectivoComponent implements OnInit {
  monto : number;
  constructor(
    private router: Router,
    private store: Store<AppState>,

  ) { }

  ngOnInit(): void {
  }

  onChangeTN(e: number) {
    console.log(e);
    this.monto = e;
  }

  saveMonto(e) {
    console.log( e );
    //guardar en redux
    let monto =
    {
      monto : this.monto
    }
    this.store.dispatch(addMonto(monto))
    this.router.navigate(['/mopa/u-efectivo-firma']);
  }

  cancelAction() {
    this.router.navigate(['/mopa/home']);
  }

}
