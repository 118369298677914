import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AsignarFondoService } from 'src/app/services/asignar-fondo.service';
import { LogUsuarioService } from 'src/app/services/log-usuario.service';
import { FondoDenominacion } from '../../models/dash';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import { selectUser } from '../../state/selectors/user.selectors';
import { AuthService } from '../../services/auth.service';
import { changeAccion } from '../../state/actions/user.actions';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificacionesPushService } from 'src/app/services/notificaciones-push.service';
import { getNextTurno, getTurno } from 'src/app/utils';
import { FaltanteService } from 'src/app/services/faltante.service';
import { CorteService } from 'src/app/services/corte.service';
import { MorrallaService } from 'src/app/services/morralla.service';
import { DevolucionService } from 'src/app/services/Devolucion.service';
import { CAT_PDF_FONDO } from 'src/app/utils/enum';
import * as moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AsaltoService } from 'src/app/services/asalto.service';
import { BitacoraService } from 'src/app/services/bitacora.service';
import { format } from 'path';

@Component({
  selector: 'app-fondo-operacion',
  templateUrl: './fondo-operacion.component.html',
  styleUrls: ['./fondo-operacion.component.scss']
})
export class FondoOperacionComponent implements OnInit {

  turno: any = {
    actual: null,
    siguiente: null,
  }
  usuario: any;
  id_fondo: number;
  id_caseta: number;
  botonConfirmar: boolean = true;
  denominaciones: FondoDenominacion[] = [];
  denominacionesRevision = [];
  resumenRevision = [];
  bovedaTotal: number = 0;
  bovedaCapturado: number = 0;
  cabina: any = {
    troncal: [],
    auxiliar: [],
    troncalFondo: 0,
    auxiliarFondo: 0
  }
  subtotales: number[] = [];
  subfaltantes: number[] = [];
  faltanteTotal: number = 0;
  faltanteFondo = [];
  resumen: any = {
    bovedaTotal: 0,
    troncalFondo: 0,
    auxiliarFondo: 0,
    preasignacionFondo: 0,
    morrallaSolicitada: 0,
    trasladoTransito: 0,
    trasladoCaja: 0,
    faltanteCobrador: 0,
    faltanteFondo: 0,
    asalto: 0,
    total: 0,
    totalSinTraslado: 0,
  }
  arqueoModal: boolean = false;
  arqueoFechaModal: any = moment().format('DD-MM-YYYY');
  isFondoFaltante: boolean = false;
  isFondoSobrante: boolean = false;
  diferenciaFondo: number = 0;
  cobradoresActivos = [];
  revisionFondo:any[] = []; //Almacena los valores de la matriz "REVISION DE FONDO"

  arqueoForm = new FormGroup({
    // cabina: new FormControl('', Validators.required),
    solicitoNombre: new FormControl('', Validators.required),
    observacion: new FormControl('', Validators.required),
  });

  revResumenForm = new FormGroup({
    auxiliar: new FormControl('', Validators.required),
    morralla: new FormControl('', Validators.required),
    transito: new FormControl('', Validators.required),
    vale: new FormControl('', Validators.required),
    asalto: new FormControl('', Validators.required)
  });

  bitacoraForm = new FormGroup({
    observacion: new FormControl('', Validators.required),
  });

  constructor(
    private router: Router,
    private bitacoraService: BitacoraService,
    private toastr: ToastrService,
    private asignarFondo: AsignarFondoService,
    private logUsuario: LogUsuarioService,
    private faltanteService: FaltanteService,
    private asaltoService: AsaltoService,
    private corteService: CorteService,
    private morrallaService: MorrallaService,
    private store : Store<AppState>,
    private authService:AuthService,
    private devolucionService: DevolucionService,
    private notificacionCaseta: NotificacionesPushService,
    private ngxUiLoaderService : NgxUiLoaderService,
  ) { }

  ngOnInit() {
    // obtener turno actual y siguiente
    this.getTurnos();
    // obtener usuario, fondo, caseta troncal
    this.getUsuario();
    // verificia la confirmacion de fondo / mostrar fondo del sistema
    this.confirmacionFondo();
    // obtener fondo del sistema
    this.fondoOperacion();
    // resumen
    this.resumenFondo();
    // obtener cobradores activos
    this.getCobradores();
  }

  getTurnos() {
    this.turno.actual = getTurno().id;
    this.turno.siguiente = getNextTurno().id;
  }

  getUsuario() {
    this.store.select(selectUser).subscribe( usuario => {
      this.usuario = usuario;
      this.id_fondo = this.usuario.caseta_usuario[0].id_fondo;
      let casetaTroncal = this.usuario.caseta_usuario.find((caseta: any) => caseta.tipo === 1);
      this.id_caseta = casetaTroncal.id;
    });
  }

  confirmacionFondo() {
    if (this.usuario.id_cat_acciones > 0) {
      this.botonConfirmar = false;
      // (document.getElementById("fondoSistema") as HTMLElement).classList.remove('text-gray-50', 'dark:text-gray-300');
      // (document.getElementById("resumenSistema") as HTMLElement).classList.remove('text-gray-50', 'dark:text-gray-300');
      // (document.getElementById("finalSistema") as HTMLElement).classList.remove('text-gray-50', 'dark:text-gray-300');
    }
  }

  fondoOperacion() {
    this.asignarFondo.getFondos().subscribe( fondos => {
      let fondo = fondos.find((fondo: any) => fondo.id === this.id_fondo);
      this.denominaciones = fondo['fondoDenominacion'];
      // total en boveda
      for (let denominacion of this.denominaciones) {
        this.bovedaTotal += (denominacion.cantidad * denominacion.pivot.cantidad);
      }

      // resumen
      this.resumen.bovedaTotal = this.bovedaTotal;
      this.resumen.total += this.bovedaTotal;
    });

    // fondo troncal
    this.notificacionCaseta.getNotificacionCaseta(this.turno.actual, this.usuario.id).subscribe( cabinas => {
      for (let cabina of cabinas) {
        if (cabina.tipo === 1) {
          this.cabina.troncal.push(cabina);
          this.cabina.troncalFondo += cabina.fondo;
        } 
        // TEMPORAL
        // if (cabina.tipo === 0) {
        //   this.cabina.auxiliar.push(cabina);
        //   this.cabina.auxiliarFondo += cabina.fondo;
        // }
      }

      // resumen
      this.resumen.troncalFondo = this.cabina.troncalFondo;
      // this.resumen.auxiliarFondo = this.cabina.auxiliarFondo;
      // this.resumen.total += (this.cabina.troncalFondo + this.cabina.auxiliarFondo);
    });

    // TEMPORAL PARA AUXILIARES
    this.devolucionService.getDenominacionAuxiliarMonto().subscribe( auxiliar => {
      // console.log( 'AUX', auxiliar );
      this.cabina.auxiliarFondo += auxiliar[28]['cantidad'];
      this.cabina.auxiliar.push( {
        nombre: 'SBLAS1',
        fondo: auxiliar[28]['cantidad']
      });
      this.cabina.auxiliarFondo += auxiliar[29]['cantidad'];
      this.cabina.auxiliar.push( {
        nombre: 'SBLAS2',
        fondo: auxiliar[29]['cantidad']
      });
      this.cabina.auxiliarFondo += auxiliar[30]['cantidad'];
      this.cabina.auxiliar.push( {
        nombre: 'CAI1',
        fondo: auxiliar[30]['cantidad']
      });
      this.resumen.auxiliarFondo = this.cabina.auxiliarFondo;

      this.resumen.total += (this.cabina.troncalFondo + this.cabina.auxiliarFondo);
    });

  }

  resumenFondo() {
    this.ngxUiLoaderService.start();
    // preasignacion de fondo
    this.devolucionService.getFondoTurnoSiguiente(this.id_caseta, this.turno.siguiente).subscribe( preasignacionFondo => {
      if (preasignacionFondo[0].fondo) { this.resumen.preasignacionFondo = preasignacionFondo[0].fondo }
      this.resumen.total += this.resumen.preasignacionFondo;
    });
    // morralla solicitada
    this.morrallaService.getMorrallaTotal(this.usuario.id, this.turno.actual, this.id_caseta).subscribe( morrallaSolicitada => {
      if (morrallaSolicitada[0].morralla) { this.resumen.morrallaSolicitada = morrallaSolicitada[0].morralla }
      this.resumen.total += this.resumen.morrallaSolicitada;
    });
    // caja de seguridad /// CAMBIAR
    // this.devolucionService.getFondoTrasladoValores({ id_caseta: this.id_caseta}).subscribe( trasladoTransito => {
    //   if (trasladoTransito[0].fondo) { this.resumen.trasladoCaja = trasladoTransito[0].fondo }
    //   this.resumen.total += this.resumen.trasladoCaja;
    // });
    // CAJA DE SEGURIDAD
    this.devolucionService.getTraslados(this.id_caseta).subscribe(trasladosCaseta => {
      for (let tr of trasladosCaseta) {
        this.resumen.trasladoCaja += tr.traslado;
      }
      this.resumen.total += this.resumen.trasladoCaja;
    });
    // en transito para morralla
    this.devolucionService.getTrasladoTransito({ id_caseta: this.id_caseta }).subscribe( trasladoReal => {
      if (trasladoReal[0].fondo) { this.resumen.trasladoTransito = trasladoReal[0].fondo }
      this.resumen.total += this.resumen.trasladoTransito;
    });
    // faltantes cobradores
    this.faltanteService.getFaltanteTotal(this.id_fondo).subscribe( faltanteCobrador => {
      if (faltanteCobrador[0].faltante) { this.resumen.faltanteCobrador = faltanteCobrador[0].faltante }
      this.resumen.total += this.resumen.faltanteCobrador;
    });
    this.devolucionService.getFaltanteFondoTotal(this.id_fondo).subscribe( faltanteFondoTotal => {
      if (faltanteFondoTotal[0].faltanteFondo) { this.resumen.faltanteFondo = faltanteFondoTotal[0].faltanteFondo }
      this.resumen.total += this.resumen.faltanteFondo;
    });
    // asaltos
    this.asaltoService.getAsaltoTotal(this.id_fondo).subscribe( asaltoTotal => {
      if (asaltoTotal[0].asalto) { this.resumen.asalto = asaltoTotal[0].asalto }
      this.resumen.total += this.resumen.asalto;

      this.resumen.totalSinTraslado = (this.resumen.total - this.resumen.trasladoTransito);

      this.ngxUiLoaderService.stop();
    });
  }

  getCobradores() {
    this.corteService.getCorteTurnoUsuarios( this.id_caseta, this.turno.actual ).subscribe( cobradores => {
      this.cobradoresActivos = cobradores;
      console.log( this.cobradoresActivos );
    });
  }

  closeModal() {
    this.arqueoModal = false;
  }

  closeVerificacion() {
    this.isFondoFaltante = false;
    this.isFondoSobrante = false;
    this.faltanteFondo = [];
    this.subfaltantes = [];
    this.faltanteTotal = 0;
  }

  openModal() {
    this.arqueoModal = true;
  }

  verificarFondo() {
    this.diferenciaFondo = 0;
    if (!this.bovedaCapturado) { return this.toastr.error(`Capture el fondo de operacion`, 'Error', { positionClass: 'toast-bottom-right' } )}

    this.diferenciaFondo = this.bovedaCapturado - this.bovedaTotal;

    console.log( 'FONDO:', this.bovedaTotal, 'CAPTURADO', this.bovedaCapturado );
    console.log( 'DIFERENCIA', this.diferenciaFondo );

    if ( this.bovedaCapturado > this.bovedaTotal ) {
      console.log( 'SOBRANTE' );
      this.isFondoSobrante = true;
    }

    if ( this.bovedaTotal > this.bovedaCapturado ) {
      console.log( 'FALTANTE' );
      this.isFondoFaltante = true;
    }

    if ( this.bovedaTotal === this.bovedaCapturado) {
      this.confirmarFondo();      
    }
  }


  confirmarFondo() {

    if ( this.isFondoFaltante && ( this.faltanteTotal != Math.abs(this.diferenciaFondo) ) ) { 
      return this.toastr.error(`Ingrese el faltante correspondiente`, 'Error', { positionClass: 'toast-bottom-right' } ) 
    }

    if ( this.isFondoSobrante && !this.bitacoraForm.valid ) {
      return this.toastr.error(`Ingrese la observacion correspondiente`, 'Error', { positionClass: 'toast-bottom-right' } )
    }

    this.ngxUiLoaderService.start();

    if ( this.isFondoFaltante ) {

      // si es faltante se descarga acta
      this.downloadActaAdministrativa();

      let data = {
        tipo: 0,
        id_cat_evento: 8, // faltante Fondo
        id_caseta: this.id_caseta,
        fondo_denominacion: this.faltanteFondo,
        id_usuario_retira : this.usuario.id
      }

      this.devolucionService.saveFaltanteFondo( data ).subscribe( faltanteFondo => {
        console.log( faltanteFondo );
        this.toastr.success(`Se ha registrado faltante de fondo`, 'Correcto', {positionClass: 'toast-bottom-right'});
      });

    }

    // bitacora
    if ( this.isFondoSobrante ) {

      // si es sobrante se descarga acta
      this.downloadActaAdministrativa();

      let data = {
        id_caseta: this.id_caseta,
        id_usuario: this.usuario.id, //jt
        id_turno: this.turno.actual,
        cantidad: this.diferenciaFondo,
        observacion: this.bitacoraForm.value.observacion,
        tipo: 0,
        estado: 0,
        id_cat_bitacora: 10 //sobrante de fondo
      }

      this.bitacoraService.saveSobranteFondo( data ).subscribe( sobranteBitacora => {
        console.log( sobranteBitacora );
        this.toastr.success(`Se ha registrado un sobrante de fondo`, 'Correcto', {positionClass: 'toast-bottom-right'});
      });

    }

    // verificar si el pdf traer el cambio del faltante
    this.generarFormatoPDF(CAT_PDF_FONDO.CONFIRMAR, "Confirmar");

    this.store.dispatch( changeAccion({ id_cat_acciones: 1 }) );
    this.authService.login( this.usuario );

    this.logUsuario.updateLogUsuario({id_cat_acciones: 1}, this.usuario.id_log_usuario).subscribe( dataLog => {
      this.botonConfirmar = false;
      this.router.navigate(['dashboard/firma']);
      this.ngxUiLoaderService.stop();
    });
  }


  generarFormatoPDF(tipo: number, observacion: string) {
    switch (tipo) {
      case 1:
        this.descargarPDF(CAT_PDF_FONDO.ARQUEO, `fondo-arqueo-${ this.arqueoFechaModal }.pdf`, observacion);
        break;
      case 2:
        this.descargarPDF(CAT_PDF_FONDO.CONFIRMAR, `fondo-confirmar-${ this.arqueoFechaModal }.pdf`, observacion);
        break;
      case 3:
        this.descargarPDF(CAT_PDF_FONDO.DESCARGAR, `fondo-descargar-${ this.arqueoFechaModal }.pdf`, observacion);
      default:
        break;
    }
  }

  descargarPDF(tipo: CAT_PDF_FONDO, filename: string, observacion: string) {
    this.devolucionService.getFondoOperacionPDF(this.id_caseta, this.turno.actual, this.usuario.id, tipo, observacion).subscribe( data => {
      let downloadURL = window.URL.createObjectURL(data);
      let link = document.createElement('a');
      link.href = downloadURL;
      link.download = filename;
      link.click();
    });
  }

  saveArqueo() {
    if (!this.arqueoForm.valid) { return this.toastr.error(`Llene todos los campos para el documento de arqueo`, 'Error', { positionClass: 'toast-bottom-right' } )}

    let observacion = this.arqueoForm.value.observacion + "&" + this.arqueoForm.value.solicitoNombre;
    // let cobrador = this.cobradoresActivos.find((cobrador: any) => cobrador.id_carril === Number(this.arqueoForm.value.cabina));

    let data = {
      id_caseta: this.id_caseta,
      // id_carril: Number(this.arqueoForm.value.cabina), // activo
      // id_cobrador: cobrador.id_usuario, // activo
      id_usuario: this.usuario.id, //jt
      id_turno: this.turno.actual,
      observacion: observacion,
      tipo: 0,
      estado: 0,
      id_cat_bitacora: 3 // arqueo
    }

    this.ngxUiLoaderService.start();

    this.bitacoraService.saveArqueoFondo( data ).subscribe( arqueoBitacora => {
      this.toastr.success(`Se ha registrado arqueo de fondo`, 'Correcto', {positionClass: 'toast-bottom-right'});
    });

    this.toastr.success(`Documento de arqueo generado correctamente`, 'Correcto', { positionClass: 'toast-bottom-right' } );
    this.generarFormatoPDF(CAT_PDF_FONDO.ARQUEO, observacion);
    
    this.arqueoModal = false;
    this.arqueoForm.reset();

    setTimeout(() => {
      this.ngxUiLoaderService.stop();
    }, 1000);

  }

  comprobacionTotal(event: any, denominacion, input: any) {
    this.bovedaCapturado = 0;
    let cantidad = Number(event.target.value);
    let subtotal = cantidad * (denominacion.cantidad);
    this.subtotales[denominacion.id] = subtotal;
    this.revisionFondoMatriz(denominacion, cantidad);
    for (let subtotal in this.subtotales) {
      this.bovedaCapturado += Number(this.subtotales[subtotal]);
    }

    this.insertDenomacionRevision(denominacion, cantidad, input);
    this.verificarDenominacion(denominacion, input);
  }

  insertDenomacionRevision(denominacion, cantidad, input) {
    let index = this.denominacionesRevision.findIndex( m => m.id === denominacion.id);
    index === -1 ? this.denominacionesRevision.push({
      id: denominacion.id,
      cantidad: cantidad,
      input: input.id,
    }): this.denominacionesRevision[index].cantidad = cantidad;
  }

  verificarDenominacion(denominacion, input) {
    let findDenSistema = this.denominaciones.find(item => item.id === denominacion.id);
    let findDenConteo = this.denominacionesRevision.find(item => item.id === denominacion.id);

    this.setStyleRevision(findDenConteo.cantidad, findDenSistema.pivot.cantidad, input);
  }

  setStyleRevision(conteo, sistema, input) {
    if (conteo === sistema) {
      (document.getElementById(input.id) as HTMLElement).classList.add('border-2','border-green-600', 'dark:border-2', 'dark:border-green-600');
      (document.getElementById(input.id) as HTMLElement).classList.remove('border-red-600', 'dark:border-red-600');
    } else {
      (document.getElementById(input.id) as HTMLElement).classList.add('border-2','border-red-600', 'dark:border-2', 'dark:border-red-600');
      (document.getElementById(input.id) as HTMLElement).classList.remove('border-green-600', 'dark:border-green-600');
    }
  }

  comprobacionResumen(event, input) {
    let cantidad = Number(event.target.value);
    switch (input.id) {
      case 'cresumAux':
        this.setStyleRevision(cantidad, this.resumen.auxiliarFondo, input);
        break;
      case 'cresumMorralla':
        this.setStyleRevision(cantidad, this.resumen.morrallaSolicitada, input);
        break;
      case 'cresumTransito':
        this.setStyleRevision(cantidad, this.resumen.trasladoTransito, input);
        break;
      case 'cresumVale':
        this.setStyleRevision(cantidad, this.resumen.faltanteCobrador, input);
        break;
      case 'cresumAsalto':
        this.setStyleRevision(cantidad, this.resumen.asalto, input);
        break;
      default:
        break;
    }
  }

  revisionFondoMatriz(denominacion,cantidad){
    //REVISION DE FONDO
    let indice = this.revisionFondo.findIndex( m => m.denominacion === denominacion.cantidad);
    indice === -1 ? this.revisionFondo.push({'denominacion': denominacion.cantidad, 'cantidad':cantidad, 'id':denominacion.id})
    : this.revisionFondo[indice].cantidad = cantidad;
    
    this.revisionFondo.sort(( a, b ) => { return a.denominacion - b.denominacion; }); //ORDENAR ARRAY POR DENOMINACION
  }

  comprobacionFaltante(event: any, denominacion, input: any) {
    this.faltanteTotal = 0;
    let cantidad = Number(event.target.value);
    let subtotal = cantidad * (denominacion.cantidad);
    this.subfaltantes[denominacion.id] = subtotal;

    // validacion individual en relacion al fondo
    if (cantidad > denominacion.pivot.cantidad) {
      cantidad = 0;
      this.subfaltantes[denominacion.id] = 0;
      (document.getElementById(input.id) as HTMLInputElement).value = '0';
      this.toastr.error(`La cantidad ingresada en $${ denominacion.cantidad } es mayor a la disponible`, 'Error', { positionClass: 'toast-bottom-right' } );
    }

    for (let subtotal in this.subfaltantes) {
      this.faltanteTotal += Number(this.subfaltantes[subtotal]);
    }

    this.insertFaltanteFondo( cantidad, denominacion);
    
  }

  insertFaltanteFondo(cantidad: number, denominacion: any){
    let index = this.faltanteFondo.findIndex( m => m.id_denominacion === denominacion.id);
    index === -1 ? this.faltanteFondo.push({
      id_denominacion: denominacion.id,
      cantidad: cantidad,
    }): this.faltanteFondo[index].cantidad = cantidad;

    console.log( this.faltanteFondo );
  }

  //PDF FORNDO DE OPERACION
  downloadActaAdministrativa(){
    let data = {
      'fecha': moment().format(),
      'fondoOperacion' :JSON.stringify(this.denominaciones),
      'revisionFondo' : JSON.stringify(this.revisionFondo),
      'resumenRevision' : JSON.stringify(this.revResumenForm.value),
      'resumen': JSON.stringify(this.resumen),
      'diferencia' : this.diferenciaFondo < 0 ? this.diferenciaFondo * -1 : this.diferenciaFondo,
      'observacion': this.bitacoraForm.value.observacion,
      'jt': this.usuario.id,
      'caseta': this.id_caseta,
      'turno': this.turno.actual
    }
    console.log(data);
    this.ngxUiLoaderService.start();
    //CONSUMO DE API
    this.corteService.getPDFActaAdministrativa(data)
      .then(data => {
        let descarga = this.downloadFile(data,'acta');
        if (descarga) {
          this.ngxUiLoaderService.stop();
          return this.toastr.success('Se ha descargado con éxito el PDF', '', { positionClass: 'toast-bottom-right' } )  
        }
      })
      .catch(err =>{
        this.ngxUiLoaderService.stop();
        return this.toastr.error('Algo salio mál, favor de volver a intenter la descarga del PDF', '', { positionClass: 'toast-bottom-right' } )  
      });

  }

  downloadFaltante(){
    let data = {
      'fecha': moment().format(),
      'tipo': 1, //FALTANTE
      'diferencia' : this.diferenciaFondo * -1,
      'concepto': 2, //FALTANTE FONDO
      'jt': this.usuario.id,
      'caseta': this.id_caseta,
      'turno': this.turno.actual,
    }
    console.log(data);
    this.ngxUiLoaderService.start();
    //CONSUMO DE API
    this.corteService.getPDFPreliquidacion(data)
      .then(data => {
        let descarga = this.downloadFile(data,'vale');
        if (descarga) {
          this.ngxUiLoaderService.stop();
          return this.toastr.success('Se ha descargado con éxito el PDF', '', { positionClass: 'toast-bottom-right' } )  
        }
    })
    .catch(err =>{
      this.ngxUiLoaderService.stop();
      return this.toastr.error('Algo salio mál, favor de volver a intenter la descarga del PDF', '', { positionClass: 'toast-bottom-right' } )  
    });

  }

  downloadFile(data,tipo){
    const tipoPDF = tipo == 'acta' ? 'ActaAdministrativa' : 'ValeFaltante';
    let downloadURL = window.URL.createObjectURL(data);
    let link = document.createElement('a');
    link.href = downloadURL;
    link.download = `${tipoPDF}.pdf`;
    link.click();
    return true;
  }

  // showSection(id: number) {
  //   let sections = {
  //     1: 'faltante-pago',
  //     2: 'sepsa',
  //     3: 'morralla-pago',
  //   };
  //   this.router.navigate(['dashboard/' + sections[id]]);
  // }

}
