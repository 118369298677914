<!-- <div class="container__body">
  <div class="body__clasificacion" *ngFor="let clasificacion of [1]">
    <span *ngIf="cruce" class="header__clasificacion">{{cruce?.nombreSubtipo}} - {{cruce?.nameChildren}}</span>
    <span *ngIf="btnRapido" class="header__clasificacion">{{btnRapido?.nombreSubtipo}} </span>
    <div class="body__children">

      <button *ngFor="let btn of tiposVehiculo$ | async" (click)="addClasificacion(btn.id,btn.tipo)"
              class="btn btn-image w-100" [style.background-image]="'url(/assets/images/ic_moto.png' + ')'">
        <span>{{btn?.tipo}}</span></button>
      <button [ngClass]="activeCaravana ? 'selected' : ''" *ngIf="this.cruce.id_type === isCaravana"
              (click)="activeCaravanaHandler()" class="btn btn-image w-100">Caravana
      </button>

    </div>
    <div class="body__returnPage">
      <label *ngIf="activeCaravana">Contador</label> <input class="input-count" *ngIf="activeCaravana" [(ngModel)]="countCaravana" type="number" min="0">
      <button (click)="returnPage()" class="btn w-100">Regresar</button>

    </div>
  </div>
</div> -->

<div class="p-2 border rounded border-solid border-gray-300 dark:border-gray-500 h-104 uppercase text-lg text-gray-500">

  <div class="flex flex-col text-center" *ngFor="let clasificacion of [1]">
    <span *ngIf="cruce" class="text-gray-500 dark:text-gray-50 font-bold text-base">{{cruce?.namePadre}} - {{cruce?.nameChildren}}</span>
    <span *ngIf="btnRapido" class="text-gray-500 dark:text-gray-50 font-bold text-base">{{btnRapido?.nombreSubtipo}} </span>
    <hr class="mt-2 mb-4">

    <div class="grid grid-cols-6 grid-flow-row gap-4">

      <button #btnClasificacion *ngFor="let btn of tiposVehiculo$ | async" (click)="addClasificacion(btn.id,btn.tipo,btnClasificacion)" [hidden]="hiddenButton(btn)"
              class="btn btn-image w-44 py-1 px-2 bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 bg-no-repeat" [style.background-image]="'url(/assets/images/'+  btn.icon +')'" >
        <span>{{btn?.tipo}}</span>
      </button>

      <button #btnC [ngClass]="activeCaravana ? 'selected' : ''" *ngIf="this.cruce.id_type === isCaravana"
              (click)="activeCaravanaHandler(btnC)" class="btn btn-image w-44 py-1 px-2 bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600">Caravana
      </button>

    </div>

    <div class="mt-8">
      <label *ngIf="activeCaravana" class="text-gray-500 dark:text-gray-50 font-bold text-sm mr-2">Contador</label> <input class="border rounded border-gray-300 placeholder-gray-500 px-3 py-2 text-center text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input" *ngIf="activeCaravana" [(ngModel)]="countCaravana" type="number" min="0">

      <button (click)="returnPage()" class="btn w-44 py-1 px-2 bg-gray-50 text-base uppercase dark:bg-transparent rounded text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 float-right">Regresar</button>
    </div>
  </div>

</div>

