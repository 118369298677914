<div [formGroup]="corteForm" class="space-y-3">

  <div class="flex justify-between">

    <div>
      <span>Autopista: </span>
      <span class="font-bold">Benito Juarez</span>
    </div>

    <div class="flex items-center">
      <span>Plaza de cobro: </span>
      <select (change)="getCaseta()" formControlName="caseta"
        class="appearance-none block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
        <option value="" selected disabled>Seleccionar plaza</option>
        <option *ngFor="let caseta of casetas" value="{{ caseta.id }}">{{ caseta.nombre }}</option>
      </select>
    </div>
    <div class="flex items-center">
      <span>Fecha: </span>
      <input formControlName="fecha" type="date"
        class="block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        placeholder="Seleccionar fecha" />
    </div>
    <button (click)="enviarConsulta()" type="button"
      class="bg-gray-50 inline-flex items-center dark:bg-transparent rounded p-2 text-xs text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 uppercase">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
      </svg>
      <span class="ml-2">Consultar</span>
    </button>
    <!-- <div class="flex items-center">
            <span>Carril: </span>
            <select (change)="corteFormReset(2)" formControlName="carril" class="appearance-none block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                <option value="" selected disabled>Seleccionar carril</option>
                <option *ngFor="let carril of carriles" value="{{ carril.id }}">{{ carril.nombre }}</option>
            </select>
        </div>

        <div class="flex items-center">
            <span>Turno: </span>
            <select  formControlName="turno" class="appearance-none block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                <option value="" selected disabled>Seleccionar turno</option>
                <option *ngFor="let turno of turnos" value="{{ turno.id }}">{{ turno.turno }}</option>
            </select>
        </div>-->

  </div>

  <!-- <div class="flex justify-between"> -->



    <!-- <div class="flex items-center">
            <span>Corte: </span>
            <select formControlName="corte" (change)="getCorte()" class="appearance-none block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                <option value="" selected disabled>Seleccionar corte</option>
                <option *ngFor="let corte of cortes" value="{{ corte.id_corte_usuario }}">{{ corte.id_corte_usuario }}</option>
            </select>
        </div>

        <div class="flex items-center">
            <span>Formato: </span>
            <select id="formatoResumen" (change)="getFormato($event)" class="appearance-none block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                <option selected disabled>Seleccionar formato</option>
                <option value="1">Corte de caja</option>
                <option value="2">Reporte de incidencias</option>
                <option value="3">Registro de exentos y residentes</option>
                <option value="4">Retiros parciales</option>
            </select>

        </div>
     -->

    <!-- <button [disabled]="!corteForm.valid" (click)="imprimirPDF(false)" type="button"
      class="bg-gray-50 inline-flex items-center dark:bg-transparent rounded p-2 text-xs text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 uppercase">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"
        stroke-width="2">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
      </svg>
      <span class="ml-2">Imprimir</span>
    </button> -->

  <!-- </div> -->

  <!-- <div class="flex justify-between">

        <div>
            <span>Cobrador: </span>
            <span class="font-bold">{{ cobrador || 'Sin informacion' }}</span>
        </div>

        <div>
            <span>Jefe de turno: </span>
            <span class="font-bold">{{ jefeTurno || 'Sin informacion' }}</span>
        </div>

    </div> -->

</div>
<br>
<div class="flex items-center">
  <span>Turno: </span>
  <select (change)="getTurno($event)"
    class="appearance-none block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
    <option value="" selected disabled>Seleccionar turno</option>
    <option *ngFor="let turno of turnos" value="{{ turno.id }}">{{ turno.turno }}</option>
  </select>
</div>
<br><br>
<div class="flex-1 text-center font-bold text-xs">
  <div class="px-4 overflow-y-auto">

    <table
      class="border-collapse w-full border border-slate-400 dark:border-slate-500 bg-white dark:bg-slate-800 text-sm shadow-sm">
      <thead class="bg-slate-50 dark:bg-slate-700">
        <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">
          FECHA</th>
        <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">
          CARRIL</th>
        <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">
          TURNO</th>
        <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">
          CORTE</th>
        <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">
        </th>
        <th class="border border-slate-300 dark:border-slate-600 font-semibold py-2 text-slate-900 dark:text-slate-200">
          FORMATOS</th>


      </thead>

      <tbody class="font-semibold text-xs">
        <tr *ngFor="let corte of cortesTable">
          <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">{{
            corte.fecha_asignacion }}</td>
          <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">{{
            corte.nombre }}</td>
          <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">{{
            corte.turno }}</td>
          <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">{{
            corte.fecha_hora_inicio | date: 'H:mm:ss' }}-{{
            corte.fecha_hora_fin | date: 'H:mm:ss'}}</td>
          <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">
            <p>{{ corte.cobrador }}</p>
            <p>{{ corte.jefeturno}}</p>
          </td>
          <td class="border border-slate-300 dark:border-slate-700 py-2 text-slate-500 dark:text-slate-400">
            <button [disabled]="!corteForm.valid" (click)="imprimirPDF(true,corte)" type="button"
              class="bg-gray-50 inline-flex items-center dark:bg-transparent rounded p-2 text-xs text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 uppercase">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
              </svg>
              <span class="ml-2">Imprimir</span>
            </button>
          </td>

        </tr>
      </tbody>



    </table>

  </div>
</div>
<!-- MODAL -->
<!-- Main modal -->
<!-- <div *ngIf="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"> -->
<!-- <div class="relative p-4 w-full max-w-2xl h-full md:h-auto"> -->
<!-- Modal content -->
<!-- <div class="relative bg-white rounded-lg shadow dark:bg-gray-700"> -->
<!-- Modal header -->
<!-- <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    REPORTE PDF
                </h3>
                <button type="button"  (click)="openModal()" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal">
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div> -->
<!-- Modal body -->
<!-- <div class="grid h-screen place-items-center content-center">
                <iframe style="width: 70%; height: 600px;" [src]="base64Reporte | safe"></iframe>
            </div> -->
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->

<div *ngIf="showModal" class="modal-component-container fixed inset-0 overflow-y-auto">
  <div
    class="modal-flex-container flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="modal-bg-container fixed inset-0 bg-gray-700 bg-opacity-75"></div>

    <div class="modal-space-container hidden sm:inline-block sm:align-middle sm:h-screen">&nbsp;</div>

    <div
      class="modal-container inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
      <div class="modal-wrapper bg-white dark:bg-gray-700 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">

        <div class="modal-wrapper-flex sm:flex sm:items-start">

          <div class="modal-content mt-3 w-full sm:mt-0 sm:ml-0 sm:text-left space-y-3">

            <div class="flex items-center justify-between">

              <div class="flex items-center rounded py-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>
                <span class="pl-2 text-base">REPORTE PDF</span>
              </div>

              <button (click)="openModal()"
                class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-md p-1.5 mt-3 bg-white dark:bg-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>

            </div>

            <hr>

            <iframe
              class="flex flex-col container dark:bg-gray-700 max-w-md mx-auto w-full h-96 items-center justify-center bg-white shadow"
              [src]="base64Reporte | safe"></iframe>

            <hr>
            <div class="float-right">
              <button (click)="imprimirPDF(true)" type="button"
                class="bg-gray-50 inline-flex items-center dark:bg-transparent rounded p-2 text-xs text-gray-500 dark:text-gray-50 font-semibold border-2 border-gray-300 drop-shadow-lg hover:border-blue-600 uppercase">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z" />
                </svg>
                <span class="ml-2">Descargar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
