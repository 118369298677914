<div class="mx-2 mb-2 p-1 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase font-semibold text-base text-center text-gray-500 dark:text-gray-200">

    <div class="flex flex-col">
        <div class="flex justify-between items-center">
            <span></span>
            <span class="text-gray-500 dark:text-gray-50 py-2 font-bold">Aperturar carril</span>
            <span></span>
        </div>
        <hr class="mb-4 dark:border-gray-500">

        <div class="flex justify-between text-xs mb-4">

            <div class="flex items-center">
                <span class="font-semibold">Caseta:</span>
                <select (change)="getCaseta($event)" class="appearance-none block ml-2 p-1 text-xs font-semibold uppercase text-gray-500 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                    <option selected disabled>Seleccionar caseta</option>
                    <option *ngFor="let caseta of usuario.caseta_usuario" value="{{ caseta.id }}">{{ caseta.nombre }}</option>
                </select>
            </div>
        </div>

        <hr class="dark:border-gray-500 mb-4">

        <div class="flex flex-col bg-gray-200 dark:bg-gray-900/50 rounded p-2 font-semibold text-center my-2">
            <div class="grid grid-flow-col auto-cols-fr">
                <span>Asignacion /</span>
                <span *ngFor="let denominacion of denominacionFormato">${{ denominacion.cantidad | number }}</span>
                <span>Total</span>
            </div>

            <div class="grid grid-flow-col auto-cols-fr text-green-600">
                <span class="text-gray-500 dark:text-white">Carril</span>
                <span *ngFor="let denominacion of denominacionFormato">{{ denominacion.pivot.cantidad }}</span>
                <span></span>
            </div>
        </div>

        <div class="mb-2 font-semibold">
            <hr class="my-2 dark:border-gray-500">

            <div *ngIf="hasCarrilsClosed; else noCarrils">

                <div *ngFor="let carril of carriles; let ixF = index" class="grid grid-cols-10 gap-1 my-2 items-center">

                    <div class="flex justify-evenly items-center customInactivo">
                        <input (change)="onChangeCheckCarril(carril, $event)" class="mx-auto rounded" type="checkbox">
                        <span class="mr-3">{{carril.nombre}}</span>
                    </div>

                    <input #input id="c{{ carril.id }}-{{ i }}" (change)="getResult(carril, denominacion, $event, input)" *ngFor="let denominacion of denominacionFormato; let i = index" type="number" class="text-center block py-1 text-base font-normal text-gray-700 bg-white dark:text-gray-100 dark:bg-gray-700 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" min="0" max="5" placeholder="0" value="{{ fondoPerfil[i].pivot.cantidad }}">

                    <span id="c{{ carril.id }}">$0</span>

                    <span></span>

                    <div class="col-span-2" id="co{{ carril.id }}">
                        <label class="sr-only">Seleccionar cobrador</label>
                        <ng-select  class="custom" 
                                    placeholder="Seleccionar cobrador" 
                                    [items]="cobradores" 
                                    bindLabel="nombre" 
                                    bindValue="id"
                                    (change)="getCobrador(carril.id, $event)">
                        </ng-select>
                    </div>

                    <div class="col-span-3 flex justify-evenly text-xs" id="fo{{ carril.id }}">
                        <div *ngFor="let formacion of formaciones" class="flex rounded-lg bg-gray-200 dark:bg-gray-700 p-2 items-center">
                            <span class="font-medium dark:text-gray-300 pr-2">{{ formacion.concepto }}</span> 
                            <input [checked]="formacion.id === 1" (change)="updateFormacionList(carril.id, formacion.id, $event)" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300" name="{{ formacion.id }}" id="{{ formacion.id }}" type="checkbox" value="{{ formacion.concepto }}">
                        </div>
                    </div>

                    <div class="col-span-3">
                        <input (keyup)="justificacion($event, ixF)" id="j-{{ carril.id }}" autocomplete="off" required class="col-span-2 border rounded border-gray-300 placeholder-gray-500 h-10 px-3 py-2 w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:ring-blue-500 focus:z-10 focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray" placeholder="Escriba justificacion" type="text" />
                    </div>

                    <hr class="col-span-10 mb-1 dark:border-gray-500">

                </div>

            </div>

            <ng-template #noCarrils>
                Sin carriles disponibles, seleccione una caseta
                <hr class="my-2 dark:border-gray-500">
            </ng-template>

            
        </div>

        <div class="flex justify-between items-center font-semibold text-sm text-gray-500 dark:text-gray-50">
            <div>
                <button type="button" class="bg-gray-50 dark:bg-transparent border border-gray-300 drop-shadow-lg hover:border-blue-600 rounded p-2 uppercase font-semibold" (click)="aperturarCarril()">Realizar apertura</button>
            </div>
            <div class="mr-10 text-base">
                <span>Total Final</span>
                <span id="totalFinal" class="pl-6">$0</span>
            </div>
        </div>

    </div>
</div>