import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Route, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {CatPermisosService} from '../services/cat-permisos.service';
import {Store} from '@ngrx/store';
import {AppState} from '../state/app.state';
import {selectUser} from '../state/selectors/user.selectors';
import {TYPE_ROL} from '../utils/enum';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class RolGuard implements CanActivate {

  constructor(
    private menuPermService: CatPermisosService,
    private store: Store<AppState>,
    private router: Router,
    private ngxUiLoaderService: NgxUiLoaderService
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    this.ngxUiLoaderService.start();

    let userLocal = localStorage.getItem('user');
    let actualUser = JSON.parse(atob(userLocal));
    let id_rol = actualUser?.rol?.id || actualUser?.tipoUsuario?.id;
    let rol = await this.menuPermService.getPermissionByRol(id_rol).toPromise();

    let routes = [];
    //rutas del usuario permitidas (viene de api)
    for (const route of rol.menu_permisos) {
      if (route.childrens && route.childrens.length > 0) {
        for (const routeChildren of route.childrens) {
          routes = [...routes, routeChildren.url];
        }
      } else {
        routes = [...routes, route.url];
      }
    }

    //si no tiene permiso el usuario se redirecciona a su permiso default
    if (!routes.includes(state.url)) {
      switch (id_rol) {
        case TYPE_ROL.JEFE_TURNO:
          this.router.navigate(['/dashboard/home']);
          break;
        case TYPE_ROL.CARRIL :
          this.router.navigate(['/dashboard/ultimo-efectivo']);
          break;
        default:
          this.router.navigate(['/dashboard/login-mopa']);
          break;
          return false;
      }
    }
    this.ngxUiLoaderService.stop();
    return true;

  }

}
