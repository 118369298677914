<div class="p-2 border rounded border-solid border-gray-300 dark:border-gray-500 uppercase text-lg text-gray-500">

    <div class="flex flex-col text-center">
        <h3 class="text-gray-500 dark:text-gray-50 font-bold text-base">Ultimo efectivo - Ingrese cantidad</h3>
        <hr class="mt-2 mb-2">
    
        <div class="grid grid-cols-3">
    
            <div class="col-start-2 w-96">

                <app-teclado-numerico (change)="onChangeTN($event)" [multipleInputs]="false" (confirmValue)="saveMonto($event)" placeholder="Ultimo Efectivo"></app-teclado-numerico>
        
                <div class="mt-2">
                <button (click)="cancelAction()" class="bg-blue-600 p-3 w-full text-white font-bold uppercase border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancelar</button>
                </div>
                
            </div>
    
        </div>
  
    </div>
</div>
  
